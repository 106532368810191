import { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import styled from "styled-components"
import { imagePaths } from "./GalleryData"
import { thumbnailPaths } from "./GalleryData"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import useStore from "@state/store"
import compress from "@assets/icons/compress.png"
import enlarge from "@assets/icons/enlarge.png"
import { ArrowLeftIcon } from "@radix-ui/react-icons"
import StyledButton from "@components/buttons/StyledButton"
import Carousel from "./Carousel"

type Props = {
  isVisible: boolean
}

const Gallery = (props: Props) => {
  const galleryRef = useRef<HTMLDivElement>(null)
  const [images, setImages] = useState<string[]>([])
  const { selectedGalleryCategory } = useStore((s) => ({
    selectedGalleryCategory: s.selectedGalleryCategory,
  }))
  const { selectedGalleryImage, imageExpanded } = useStore((s) => ({
    selectedGalleryImage: s.selectedGalleryImage,
    imageExpanded: s.imageExpanded,
  }))

  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  useEffect(() => {
    setImages(thumbnailPaths[selectedGalleryCategory].paths)
  }, [selectedGalleryCategory])

  useEffect(() => {
    if (imageExpanded) {
      const tl = gsap.timeline()
      tl.fromTo(
        [".imageOverlay", ".imageButtonOverlay"],
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.6,
          ease: "power2.inOut",
        },
      )
    } else if (!imageExpanded) {
      const tl = gsap.timeline()
      tl.fromTo(
        [".imageOverlay", ".imageButtonOverlay"],
        { x: "0" },
        {
          autoAlpha: 0,
          duration: 0.6,
          ease: "power2.inOut",
        },
      )
    }
  }, [imageExpanded])

  useEffect(() => {
    if (galleryRef.current) {
      if (props.isVisible) {
        gsap.to(galleryRef.current, {
          autoAlpha: 1,
          x: 0,
          duration: 0.6,
          ease: "power3.inOut",
        })
      } else {
        gsap.to(galleryRef.current, {
          autoAlpha: 0,
          x: 60,
          duration: 0.6,
          ease: "power3.inOut",
        })
      }
    }
  }, [props.isVisible])

  return (
    <GalleryWrapper ref={galleryRef} visible={props.isVisible}>
      <div
        className="back"
        onClick={() => {
          syncState({ galleryVisible: false })
        }}
      >
        <ArrowLeftIcon height={20} width={20} color="#000" />
        <StyledButton
          border={false}
          color="#000"
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          Back
        </StyledButton>
      </div>
      <CategoryWrapper className="categoryWrapper">
        <div className="textWrapper">
          {Object.values(imagePaths).map(({ name }, index) => (
            <h1
              key={index}
              style={{
                color: selectedGalleryCategory === name ? "black" : "#9a9a9a",
                cursor: "pointer",
                transition: "0.3s ease",
              }}
              onClick={() =>
                syncState({
                  selectedGalleryImage: null,
                  selectedGalleryCategory: name,
                })
              }
            >
              {name}
            </h1>
          ))}
        </div>
      </CategoryWrapper>
      <ImagesWrapper className="imagesWrapper">
        <div className="image-grid">
          {images.map((src, index) => (
            <div
              key={index}
              style={{ backgroundColor: "black", position: "relative" }}
            >
              <img
                className="image"
                style={{
                  height: "100%",
                  opacity: selectedGalleryImage === index ? "1" : "",
                  cursor: "pointer",
                  transition: "0.6s ease",
                }}
                key={index}
                src={src}
                onClick={() => {
                  syncState({
                    selectedGalleryImage: index,
                    imageExpanded: true,
                  })
                }}
                alt={`Image ${index + 1}`}
              />
              {selectedGalleryImage === index && (
                <div
                  className="expand"
                  onClick={() => {
                    if (!imageExpanded) syncState({ imageExpanded: true })
                    else {
                      syncState({ imageExpanded: false })
                    }
                  }}
                >
                  {!imageExpanded && (
                    <img style={{ width: "100%" }} src={enlarge} alt="" />
                  )}
                  {imageExpanded && (
                    <img
                      style={{ width: "100%", zIndex: "50" }}
                      src={compress}
                      alt=""
                    />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </ImagesWrapper>
      <ImageOverlay
        className="imageOverlay"
        style={{
          placeSelf: "center",
          backgroundColor: "white",
          display: "grid",
          placeItems: "center",
          height: "100%",
          width: "100%",
          zIndex: 21,
          overflow: "hidden",
        }}
      >
        <Carousel slides={imagePaths[selectedGalleryCategory].paths} />
      </ImageOverlay>
    </GalleryWrapper>
  )
}

const GalleryWrapper = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
  position: absolute;
  z-index: 263;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? 1 : 0)};

  .embla__viewport {
    overflow: hidden;
    width: 100%;
  }

  .embla__container {
    display: flex;
    transition: transform 0.3s ease;
  }

  .embla__slide {
    min-width: 100%;
    position: relative;
  }

  .embla__slide img {
    width: 100%;
    height: auto;
  }

  .back {
    position: absolute;
    display: flex;
    align-items: center;
    top: 3em;
    left: 30px;
    gap: 1em;
    padding: 1em 1em 1em 0;
    z-index: 20;
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
`

const CategoryWrapper = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .textWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  h1 {
    text-align: start;
    font-family: "Masque-Hairline";
    font-size: 4rem;
    font-weight: 100;
    padding: 0.5rem;
    border-radius: 10px;
    transition: background-color 0.2s ease-in-out;

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`

const ImagesWrapper = styled.div`
  height: 100%;
  padding: auto;
  overflow-y: scroll;

  .image-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 1rem;
  }

  .expand {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 0.75rem;
    border-radius: 100%;
    position: fixed;
    bottom: 3em;
    right: 3em;
    color: white;
    z-index: 250;
    transition: background-color 0.2s ease-in-out;

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .image-grid img {
    width: 33vh;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
`

export default Gallery
