import globalStyles from "@data/style.globals"
import styled from "styled-components"

export interface INetworkMessageProps {
  message: string
  isOnline: boolean
}

const NetworkMessage = (props: INetworkMessageProps) => {
  return (
    <Wrapper isOnline={props.isOnline}>
      <div>{props?.message}</div>
    </Wrapper>
  )
}

export { NetworkMessage }

const Wrapper = styled.div<{ isOnline: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: ${globalStyles.colors.clay};
  width: 20%;
  height: 30px;
  opacity: 0.5;
  color: ${globalStyles.colors.white};
  font-family: Stolzl;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1.3em;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: ${({ isOnline }) => (isOnline ? "green" : "#ff0000")};
    animation: ${({ isOnline }) =>
      isOnline ? "none" : "pulsate 2.5s ease-in-out infinite"};
  }

  @keyframes pulsate {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`
