import SortArrowIcon from "@assets/icons/up-arrow.svg"
import { HeaderToLabelMap } from "@utils/tableHeaders"
import styled from "styled-components"

const TableHeader = ({ headers, sortConfig, handleSort }) => {
  return (
    <TableHeaderContainer className="flex-table-header">
      {headers.map((header: string, index: number) => (
        <div
          key={index}
          className="flex-table-cell header-cell"
          onClick={() => handleSort(header)}
        >
          {HeaderToLabelMap[header] || header}
          {index !== headers.length - 1 && header === sortConfig.field && (
            <img
              className={`arrow ${
                sortConfig.direction === "ascending" ? "up" : "down"
              }`}
              src={SortArrowIcon}
              alt="Arrow"
            />
          )}
        </div>
      ))}
    </TableHeaderContainer>
  )
}

export { TableHeader }

const TableHeaderContainer = styled.div`
  &.flex-table-header {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3em;
    position: sticky;
    font-weight: bold;
    height: 3em;
    width: 100%;
  }

  .header-cell {
    min-width: 8rem;
    padding: 1rem;
    text-align: center;
    white-space: nowrap;
  }

  .arrow {
    position: absolute;
    height: 0.9em;
    width: 1em;
    transition: transform 0.2s ease-in-out;

    &.up {
      transform: rotate(0deg);
    }

    &.down {
      transform: rotate(180deg);
    }
  }
`
