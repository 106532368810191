import { SwitchTransition, Transition } from "react-transition-group"
import { useLocation } from "react-router-dom"
import gsap from "gsap"

const TransitionComponent = ({ children }) => {
  const location = useLocation()

  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={500}
        onEnter={(node: any) => {
          gsap.set(node, { autoAlpha: 0 })
          gsap
            .timeline({ paused: true })
            .to(node, {
              autoAlpha: 1,
              duration: 0.3,
              onStart: () => {
                if (
                  location.pathname === "/location" ||
                  location.pathname === "/residences"
                ) {
                  document.body.style.backgroundColor = "#000"
                } else {
                  document.body.style.backgroundColor = "#fff"
                }
              },
            })
            .play()
        }}
        onExit={(node: any) => {
          gsap
            .timeline({ paused: true })
            .to(node, {
              autoAlpha: 0,
              duration: 0.3,
            })
            .play()
        }}
      >
        {children}
      </Transition>
    </SwitchTransition>
  )
}

export default TransitionComponent
