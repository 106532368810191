import { useEffect, useState } from "react"

export const useScreenTimeout = (delay: number, state: any) => {
  const [showMapScreenSaver, setSetShowMapScreenSaver] = useState(true)

  useEffect(() => {
    setSetShowMapScreenSaver(false)
    const timer = setTimeout(
      () => {
        setSetShowMapScreenSaver(true)
      },
      // 20 * 60 * 1000,
      delay,
      // 1000,
    ) // 20 minutes

    return () => {
      clearTimeout(timer)
    }
  }, state)

  return showMapScreenSaver
}
