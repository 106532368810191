import { amenitiesImages } from "@data/amenitiesData"
import globalStyles from "@data/style.globals"
import { useGSAP } from "@gsap/react"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { gsap } from "gsap"
import { useEffect, useState } from "react"

export default function FullScreenImage({ $screen = "ipad" }) {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const amenitiesSelector = useStore((state) => state.amenitiesSelector)
  const [imageSrc, setImageSrc] = useState(null)
  const amenitiesFullScreenImage = useStore(
    (state) => state.amenitiesFullScreenImage,
  )

  const [preventClick, setPreventClick] = useState(true)

  useEffect(() => {
    setPreventClick(true)
    setTimeout(() => setPreventClick(false), 1000)
  }, [amenitiesFullScreenImage])

  useEffect(() => {
    gsap.set(".image-full-screen-img", { y: 300, autoAlpha: 0 })
  }, [])

  useEffect(() => {
    if (amenitiesFullScreenImage) {
      setImageSrc(amenitiesFullScreenImage)
    } else {
      setTimeout(() => {
        setImageSrc(null)
      }, 1500)
    }
  }, [amenitiesFullScreenImage])

  const [gsapArray, setGSAPArray] = useState(null)

  useEffect(() => {
    setGSAPArray(
      amenitiesSelector
        ? [
            // "#sidebar-text-div",
            // ".image-gallery",
            // ".image-item",
            ".plate-labels",
            ".grid-lines",
            ".amenity-canvas",
          ]
        : [
            "#sidebar-text-div",
            ".image-gallery",
            // ".image-item",
            ".plate-labels",
            ".grid-lines",
            ".amenity-canvas",
          ],
    )
  }, [amenitiesSelector])

  useGSAP(
    () => {
      if (amenitiesSelector) {
        if (amenitiesFullScreenImage) {
          gsap.to([".plate-labels", ".grid-lines", ".amenity-canvas"], {
            y: 200,
            autoAlpha: 0,
            duration: 1,
            ease: "back.inOut",
          })
          gsap.to([".image-full-screen", ".image-full-screen-img"], {
            autoAlpha: 1,
            delay: 1,
            y: 0,
            duration: 1,
            pointerEvents: "auto",
            ease: "back.inOut",
          })
        } else {
          gsap.to([".plate-labels", ".grid-lines", ".amenity-canvas"], {
            y: 0,
            autoAlpha: 1,
            duration: 1,
            ease: "back.inOut",
          })
          gsap.to([".image-full-screen", ".image-full-screen-img"], {
            autoAlpha: 0,
            delay: 1,
            y: 200,
            duration: 1,
            pointerEvents: "auto",
            ease: "back.inOut",
          })
        }
      } else {
        if (amenitiesFullScreenImage) {
          gsap.to(
            [
              "#sidebar-text-div",
              ".image-gallery",
              // ".image-item",
              ".plate-labels",
              ".grid-lines",
              ".amenity-canvas",
            ],
            {
              y: 200,
              autoAlpha: 0,
              duration: 1,
              ease: "back.inOut",
            },
          )
          gsap.to([".image-full-screen", ".image-full-screen-img"], {
            autoAlpha: 1,
            delay: 1,
            y: 0,
            duration: 1,
            pointerEvents: "auto",
            ease: "back.inOut",
          })
        } else {
          gsap.to(
            [
              "#sidebar-text-div",
              ".image-gallery",
              // ".image-item",
              ".plate-labels",
              ".grid-lines",
              ".amenity-canvas",
            ],
            {
              y: 0,
              autoAlpha: 1,
              duration: 1,
              ease: "back.inOut",
            },
          )
          gsap.to([".image-full-screen", ".image-full-screen-img"], {
            autoAlpha: 0,
            delay: 1,
            y: 200,
            duration: 1,
            pointerEvents: "auto",
            ease: "back.inOut",
          })
        }
      }
    },
    { dependencies: [amenitiesFullScreenImage, amenitiesSelector] },
  )

  // useGSAP(
  //   () => {
  //     console.log("full screen image component")
  //     const array = gsap.utils.toArray(gsapArray)
  //     if (amenitiesFullScreenImage) {
  //       setTimeout(() => {
  //         gsap.to(array, {
  //           y: 200,
  //           // y: 0,
  //           autoAlpha: 0,
  //           duration: 1,
  //           ease: "back.inOut",
  //         })
  //         gsap.to([".image-full-screen", ".image-full-screen-img"], {
  //           autoAlpha: 1,
  //           delay: 1,
  //           y: 0,
  //           duration: 1,
  //           pointerEvents: "auto",
  //           // stagger: {
  //           //   each: 0.05,
  //           // },
  //           ease: "back.inOut",
  //         })
  //       }, 10)
  //     } else {
  //       setTimeout(() => {
  //         gsap.to(".image-full-screen", {
  //           autoAlpha: 0,
  //           y: 200,
  //           // y: 0,

  //           duration: 1,
  //           ease: "back.inOut",
  //           onComplete: () => {
  //             gsap.set(".image-full-screen-img", {
  //               y: 200,
  //               autoAlpha: 0,
  //             })
  //           },
  //         })

  //         gsap.to(array, {
  //           autoAlpha: 1,
  //           delay: 1,
  //           y: 0,
  //           duration: 1,
  //           ease: "back.inOut",
  //         })
  //       }, 10)
  //     }
  //   },
  //   { dependencies: [amenitiesFullScreenImage, gsapArray] },
  // )
  return (
    <div
      className="image-full-screen"
      onClick={() => {
        if (preventClick) return
        syncState({ amenitiesFullScreenImage: null })
      }}
      style={{
        height: "100%",
        width: "100%",
        opacity: 0,
        zIndex: 20,
        pointerEvents: "none",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        placeItems: "center",
        backgroundColor: globalStyles.colors.offBlack,
      }}
    >
      <div
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          height: "100%",
          width: "100%",
          placeSelf: "center",
          display: "grid",
          placeItems: "center",
          overflow: "hidden",
          // padding: "2rem",
        }}
      >
        <img
          className="image-full-screen-img"
          alt="720"
          src={
            $screen && imageSrc
              ? `/assets/images/amenities/${$screen}/${imageSrc}.webp`
              : `/assets/images/amenities/ipad/327_720_West_End_Bar.webp`
          }
          style={{
            pointerEvents: "auto",
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            zIndex: 20,
            overflow: "hidden",
            objectFit: "contain",
            height: "90%",
            width: "90%",
            maxHeight: "90%",
            maxWidth: "90%",
          }}
        />
      </div>
      {amenitiesFullScreenImage && (
        <p
          style={{
            zIndex: 101,
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            placeSelf: "start",
            padding: "8rem",
            fontSize: "6rem",
            fontFamily: "Masque-Hairline",
          }}
        >
          {amenitiesImages[amenitiesFullScreenImage].state}
        </p>
      )}
    </div>
  )
}
