import styled from "styled-components"
import UpEndSvg from "./components/UpEndSvg"
import { useEffect, useRef } from "react"
import gsap from "gsap"
import useStore from "@state/store"

export function Landing() {
  const svgRef = useRef<SVGSVGElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [setLocalState] = useStore((s) => [s.setLocalState])

  useEffect(() => {
    setLocalState({ roomTakenOver: null })
    if (svgRef.current && imgRef.current) {
      gsap.fromTo(
        svgRef.current.childNodes,
        { opacity: 0, y: 330 },
        {
          opacity: 1,
          y: 0,
          duration: 1.1,
          stagger: 0.1,
          ease: "power3.inOut",
          onComplete: () => {},
        },
      )

      gsap.fromTo(
        imgRef.current,
        { opacity: 0, y: -430 },
        {
          opacity: 1,
          y: window.innerHeight / 2 - 330,
          duration: 1.3,
          delay: 1,
          ease: "power3.inOut",
          onComplete: () => {},
        },
      )
    }
  }, [])

  return (
    <Wrapper>
      <div className="graphics-wrapper">
        <img ref={imgRef} src="/assets/images/yulia.png" alt="Model" />
        <UpEndSvg svgref={svgRef} />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;

  .graphics-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }

  img {
    height: 60%;
    width: auto;
    position: absolute;
    left: 40%;
    z-index: 2;
  }

  svg {
    height: 100%;
    width: auto;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
