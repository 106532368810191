import { Room } from "@providers/sockets/SocketIOProvider"
import { getRoomByNav, useJoinRoom } from "./useJoinRoom"
import useStore from "@state/store"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const useNavigator = () => {
  const [roomTakenOver, setLocalState] = useStore((s) => [
    s.roomTakenOver,
    s.setLocalState,
  ])

  const { pathname } = useLocation()

  useEffect(() => {
    navigateToPath(pathname)
  }, [pathname])

  const { roomSwitch, emitNavigation, isOtherAgentInSelectedRoom } =
    useJoinRoom()

  const navigateToPath = (path: string) => {
    const navRoot = path.split("/")[1]
    const roomName: Room = getRoomByNav(navRoot) as Room

    if (isOtherAgentInSelectedRoom(roomName)) {
      setLocalState({ attemptedRoom: roomName })
      setLocalState({ showRoomPopup: true })
    } else {
      if (!roomTakenOver) {
        roomSwitch(navRoot, (newRoom) => {
          emitNavigation(path, newRoom)
        })
      } else {
        emitNavigation(path, roomTakenOver)
      }
    }
  }

  const joinRoomBasedOnPath = (
    path: string,
    callBackOnJoin: (newRoom: string) => void,
  ) => {
    const navRoot = path.split("/")[1]
    const roomName: Room = getRoomByNav(navRoot) as Room
    if (isOtherAgentInSelectedRoom(roomName)) {
      setLocalState({ attemptedRoom: roomName })
      setLocalState({ showRoomPopup: true })
    } else {
      if (!roomTakenOver) {
        roomSwitch(navRoot, (newRoom) => {
          callBackOnJoin && callBackOnJoin(newRoom)
        })
      } else {
        callBackOnJoin && callBackOnJoin(roomTakenOver)
      }
    }
  }

  // This does not quite work as expected since the roomTakenOver is passed to a popup so we can't use it to navigate
  const navigateToPath_v2 = (path: string) => {
    joinRoomBasedOnPath(path, (newRoom) => {
      emitNavigation(newRoom)
    })
  }

  return { navigateToPath, joinRoomBasedOnPath }
}

export default useNavigator
