import { TPreset } from "@components/preset/Preset.model"
import { Unit } from "@pages/tablet/residences/components/ResidenceTable"

type TLocalStorageKeys = "presets" | "amenities"

function withLocalStorage<T extends TLocalStorageKeys>(key: T) {
  return {
    get: () => {
      const data = localStorage.getItem(key)
      return data ? JSON.parse(data) : undefined
    },
    set: (data: any) => {
      localStorage.setItem(key, JSON.stringify(data))
    },
    clear: () => {
      localStorage.removeItem(key)
    },
  }
}

const localStoragePresets = withLocalStorage("presets")

export function saveOrUpdatePresetToLocalStorage(
  units: Unit[],
  presetName: string,
): void {
  const currentUnits = localStoragePresets.get() || []
  const presetToUpdate = (currentUnits as any[])?.find(
    (preset) => preset.name === presetName,
  )

  if (presetToUpdate) {
    presetToUpdate.units = units
  } else {
    currentUnits.push({ name: presetName, units })
  }

  localStoragePresets.set(currentUnits)
}

export function getPresetFromLocalStorage(presetName: string): any {
  const currentUnits = localStoragePresets.get()
  const result = (currentUnits as any[])?.find(
    (preset) => preset.name === presetName,
  )
  return result || {}
}

export function deleteFromLocalStorage(presetName: string): void {
  const currentUnits = localStoragePresets.get()
  const updatedUnits = (currentUnits as any[])?.filter(
    (preset) => preset.name !== presetName,
  )
  localStoragePresets.set(updatedUnits)
}

export function getAllPresetsFromStorageAsArray(): TPreset[] {
  const presets = localStoragePresets.get()
  return presets ?? []
}

// This is to clear the presets from localStorage if the structure is incorrect
// This is to prevent the app from crashing, as the app expects the presets to be an array of TPreset
export function checkPresetsInLocalStorage() {
  let presets = []
  try {
    presets = JSON.parse(localStorage.getItem("presets"))
  } catch (error) {
    // If parsing fails, clear the localStorage and return false
    localStoragePresets.clear()
    return false
  }

  // If parsing is successful but presets is not an array, clear the localStorage and return false
  if (!Array.isArray(presets)) {
    localStoragePresets.clear()
    return false
  }

  const isCorrectType = presets.every(
    (preset) => typeof preset.name === "string" && Array.isArray(preset.units),
  )

  // If it's incorrect type, remove the presets object from localStorage
  if (!isCorrectType) {
    localStoragePresets.clear()
    return false
  } else {
    return true
  }
}
