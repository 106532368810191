export function formatItalicLetters(text: string) {
  const parts = text.split(/(<br \/>|\s+)/)

  return parts.map((part: string, index: number) => {
    if (part === "<br />") {
      return <br key={index} />
    }
    // this will apply italic only to capital W and E
    const subparts = part.split(/([WE])/g)
    return subparts.map((subpart, subIndex) => {
      if (/^[WE]$/i.test(subpart)) {
        return (
          <span
            key={`${index}-${subIndex}`}
            style={{ fontStyle: "italic", fontSize: "inherit" }}
          >
            {subpart}
          </span>
        )
      }
      return subpart
    })
  })
}
