export const residenceData: TResidenceData = {
  immersion: {
    0: [
      {
        "type": "polygon",
        "id": "2D",
        "coords": "924.07 878.02 952.19 885.05 952.19 917 924.07 909.33 924.07 878.02",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2D",
        "coords": "894.03 869.71 906.82 873.54 906.82 904.22 894.03 900.38 894.03 869.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2D",
        "coords": "878.06 865.24 878.06 895.91 851.22 888.24 851.22 858.85 878.06 865.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2D",
        "coords": "798.82 844.79 823.74 851.82 823.74 881.21 798.82 874.18 798.82 844.79",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2D",
        "coords": "772.61 837.76 784.12 840.95 784.12 869.71 772.61 866.51 772.61 837.76",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2B",
        "coords": "734.91 827.53 757.92 833.92 757.92 862.68 734.91 856.29 734.91 827.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2B",
        "coords": "688.26 815.39 710.63 821.14 710.63 849.26 688.26 842.87 688.26 815.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2A",
        "coords": "648 804.53 667.81 809.64 667.81 837.12 648 831.37 648 804.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "1085.75 868.51 1115.5 875.98 1115.5 910.27 1085.75 901.63 1085.75 868.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "1048.53 859.72 1056 861.33 1056 894.3 1047.94 891.66 1048.53 859.72",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "3E",
        "coords": "M980.83,843.45s29.31,6.59,29.31,7.03-.44,32.24-.44,32.24l-29.45-7.77.59-31.5Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3D",
        "coords": "924.07 829.68 952.19 836.42 952.19 868.36 924.07 860.6 924.07 829.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3D",
        "coords": "894.52 822.64 906.82 825.58 906.82 856.05 894.52 852.39 894.52 822.64",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3D",
        "coords": "852.03 812.39 877.53 818.54 877.53 848.43 852.03 841.4 852.03 812.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "3D",
        "coords": "M799.28,799.49s24.46,5.71,24.46,6.15v29.6l-24.46-7.18v-28.57Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3D",
        "coords": "773.63 793.19 784.12 795.83 784.12 824.7 773.63 821.33 773.63 793.19",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "3B",
        "coords": "M734.91,784.11l23.01,5.28v28.87l-23.01-5.86s0-29.01,0-28.28Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3B",
        "coords": "688.26 772.68 710.63 778.1 710.63 805.65 688.64 799.49 688.26 772.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3A",
        "coords": "648 762.86 667.81 767.84 667.81 795.24 648 789.16 648 762.86",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "1085.75 814.39 1115.5 821.23 1115.5 854.83 1085.75 847.99 1085.75 814.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "1043.4 805.6 1058.05 808.72 1058.05 841.94 1043.4 838.03 1043.4 805.6",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "981.08 792.51 1010.14 798.37 1010.14 830.41 981.08 823.77 981.08 792.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4D",
        "coords": "924.07 779.22 952.19 785.47 952.19 817.37 924.07 810.29 924.07 779.22",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4D",
        "coords": "894.03 773.36 906.82 775.51 906.82 806.97 894.03 803.25 894.03 773.36",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4D",
        "coords": "852.03 763.59 877.53 768.48 877.53 799.49 851.22 793.19 852.03 763.59",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4D",
        "coords": "798.82 752.65 823.74 757.53 823.74 787.04 799.28 781.54 798.82 752.65",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4D",
        "coords": "773.63 746.4 784.12 748.94 784.12 778.05 772.61 774.92 773.63 746.4",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4B",
        "coords": "734.91 737.8 756.78 742.69 757.92 771.99 734.91 766.72 734.91 737.8",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4B",
        "coords": "688.26 727.84 710.63 733.11 710.63 761.25 688.26 755.58 688.26 727.84",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4A",
        "coords": "648 718.85 667.81 723.73 667.81 750.5 648 746.4 648 718.85",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "1085.75 795.83 1085.75 762.61 1115.5 767.89 1115.5 801.49 1085.75 795.83",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "1043.4 754.41 1057.47 757.14 1058.05 789.97 1043.4 785.86 1043.4 754.41",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "980.44 741.76 1010.14 747.03 1010.14 779.22 980.1 773.36 980.44 741.76",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "5D",
        "coords": "M924.07,730.77l28.12,5.86v31.46s-28.12-7.42-28.12-6.64,0-30.67,0-30.67Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5D",
        "coords": "894.67 724.76 906.82 727.1 906.82 758.02 894.03 754.8 894.67 724.76",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5D",
        "coords": "851.88 716.55 877.53 721.68 878.26 752.16 851.88 746.4 851.88 716.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5D",
        "coords": "798.82 706.3 823.74 711.13 824.19 740.59 798.82 734.87 798.82 706.3",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5D",
        "coords": "773.19 701.46 784.12 703.37 784.12 732.23 773.19 729.74 773.19 701.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5B",
        "coords": "734.91 693.69 757.92 698.53 757.92 726.66 734.91 721.83 734.91 693.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5B",
        "coords": "688.26 684.46 710.63 689.15 710.63 715.97 688.26 711.57 688.26 684.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5A",
        "coords": "648 676.55 668.57 680.21 667.81 707.47 648 702.78 648 676.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "1085.75 743.01 1085.75 709.8 1115.5 715.07 1115.5 748.68 1085.75 743.01",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "1043.4 702.87 1057.47 705.6 1058.05 737.79 1043.4 734.33 1043.4 702.87",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "980.44 691.5 1010.14 696.77 1010.14 728.96 980.1 723.1 980.44 691.5",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "6D",
        "coords": "M924.07,681.78l28.12,4.58v31.46s-28.12-6.15-28.12-5.36,0-30.67,0-30.67Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6D",
        "coords": "894.67 677.05 906.82 678.76 906.82 709.68 894.03 707.09 894.67 677.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6D",
        "coords": "851.88 669.49 877.53 673.98 878.26 704.46 851.88 699.33 851.88 669.49",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6D",
        "coords": "798.82 660.51 823.74 664.71 824.19 694.16 798.82 689.08 798.82 660.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6D",
        "coords": "773.19 655.67 784.12 657.58 784.12 686.45 773.19 683.95 773.19 655.67",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6B",
        "coords": "734.91 649.18 757.92 653.38 757.92 681.52 734.91 677.32 734.91 649.18",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6B",
        "coords": "688.26 641.23 710.63 645.28 710.63 672.74 688.26 668.34 688.26 641.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6A",
        "coords": "648 633.96 668.57 637.62 667.81 664.24 648 660.19 648 633.96",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "1085.75 690.79 1085.75 657.25 1115.79 661.94 1115.79 695.87 1085.75 690.79",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "1043.57 651.15 1057.64 653.25 1058.23 685.43 1043.57 682.61 1043.57 651.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "980.64 641.7 1010.34 646.33 1010.34 678.52 980.31 673.3 980.64 641.7",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "7D",
        "coords": "M924.07,633.26l28.12,4.58v31.46s-28.12-6.15-28.12-5.36,0-30.67,0-30.67Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7D",
        "coords": "894.67 628.53 906.82 630.24 906.82 661.16 894.03 658.57 894.67 628.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7D",
        "coords": "851.86 622.24 877.5 626.09 878.23 656.57 851.86 652.09 851.86 622.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7D",
        "coords": "798.82 614.46 823.74 618.66 824.19 647.55 798.82 642.48 798.82 614.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7D",
        "coords": "773.25 610.99 784.12 612.88 784.12 641.03 773.25 638.55 773.25 610.99",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7B",
        "coords": "734.91 605.05 757.92 609.25 757.92 637.38 734.91 633.19 734.91 605.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7B",
        "coords": "688.26 598.38 710.63 601.79 710.63 629.24 688.26 625.49 688.26 598.38",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7A",
        "coords": "648 592.38 668.57 594.77 667.81 621.38 648 618.61 648 592.38",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "1085.75 638.12 1085.75 605.14 1115.79 609.19 1115.79 642.56 1085.75 638.12",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "1043.57 599.68 1057.64 601.78 1058.23 632.76 1043.57 630.58 1043.57 599.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "980.64 591.51 1010.34 595.5 1010.34 627.13 980.31 622.55 980.64 591.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "8C",
        "coords": "M924.07,584.35l28.12,3.94v31.46s-28.12-5.51-28.12-4.73,0-30.67,0-30.67Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "894.67 580.9 906.82 582.6 906.82 613.52 894.03 610.93 894.67 580.9",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8B",
        "coords": "851.86 575.25 877.5 578.46 878.23 609.58 851.86 605.09 851.86 575.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8B",
        "coords": "798.82 568.82 823.74 571.74 824.19 601.19 798.82 597.4 798.82 568.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8B",
        "coords": "773.25 565.35 784.12 566.61 784.12 595.31 773.25 593.47 773.25 565.35",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8B",
        "coords": "734.91 560.69 757.92 563.61 757.92 591.75 734.91 588.83 734.91 560.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8A",
        "coords": "688.26 554.66 710.63 557.43 710.63 584.88 688.26 581.77 688.26 554.66",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8A",
        "coords": "648 548.74 668.57 551.69 667.81 578.3 648 574.97 648 548.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "1085.75 584.99 1085.75 552.01 1115.79 555.42 1115.79 588.79 1085.75 584.99",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "1043.57 547.19 1057.64 548.64 1058.23 580.27 1043.57 578.09 1043.57 547.19",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "980.64 540.93 1010.34 544.29 1010.34 575.91 980.31 571.97 980.64 540.93",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "9C",
        "coords": "M924.07,535.05l28.12,2.67v31.46s-28.12-4.23-28.12-3.45,0-30.67,0-30.67Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "894.67 532.24 906.82 533.3 906.82 564.22 894.03 562.28 894.67 532.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9B",
        "coords": "851.86 527.23 877.5 529.8 878.23 560.92 851.86 557.07 851.86 527.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9B",
        "coords": "798.82 521.44 823.74 524.36 824.19 553.81 798.82 550.02 798.82 521.44",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9B",
        "coords": "773.25 518.61 784.12 519.86 784.12 548.57 773.25 546.73 773.25 518.61",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9B",
        "coords": "734.91 515.23 757.92 517.51 757.92 545.64 734.91 543.36 734.91 515.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9A",
        "coords": "688.26 509.83 710.63 512.6 710.63 540.06 688.26 536.94 688.26 509.83",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9A",
        "coords": "648 505.2 668.57 508.14 667.81 534.76 648 531.43 648 505.2",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "1085.75 532.28 1085.75 499.3 1115.79 501.43 1115.79 534.81 1085.75 532.28",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "1043.57 495.76 1057.64 496.58 1058.23 528.2 1043.57 526.66 1043.57 495.76",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "980.64 490.78 1010.34 493.5 1010.34 525.12 980.31 521.82 980.64 490.78",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "924.07 485.54 952.19 488.2 952.19 519.66 924.07 516.21 924.07 485.54",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "894.67 483.36 906.82 484.43 906.82 514.71 894.03 513.4 894.67 483.36",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10B",
        "coords": "851.86 480.27 877.5 482.2 877.59 512.68 851.86 510.12 851.86 480.27",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10B",
        "coords": "798.82 475.77 823.74 478.04 824.19 507.5 798.82 504.34 798.82 475.77",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10B",
        "coords": "773.25 473.57 784.12 474.19 784.12 502.89 773.25 501.69 773.25 473.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10B",
        "coords": "734.91 470.19 757.92 472.47 757.92 500.61 734.91 498.33 734.91 470.19",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10A",
        "coords": "688.26 466.71 710.63 468.85 710.63 496.3 688.26 493.82 688.26 466.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10A",
        "coords": "648 463.36 668.57 465.02 667.81 491.64 648 489.58 648 463.36",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "1085.75 479.52 1085.75 446.54 1115.79 448.68 1115.79 482.05 1085.75 479.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "1043.57 444.28 1057.64 445.1 1058.23 476.72 1043.57 475.18 1043.57 444.28",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "980.64 440.58 1010.34 442.02 1010.34 473.64 980.31 471.62 980.64 440.58",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11B",
        "coords": "924.07 436.61 952.19 438.64 952.19 469.46 924.07 467.29 924.07 436.61",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11B",
        "coords": "894.67 435.08 906.82 436.15 906.82 466.43 894.03 465.12 894.67 435.08",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11B",
        "coords": "851.86 432.63 877.5 434.56 877.59 464.4 851.86 462.47 851.86 432.63",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11B",
        "coords": "798.82 430.04 823.74 431.04 824.19 460.49 798.82 458.61 798.82 430.04",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11B",
        "coords": "773.25 428.48 784.12 429.1 784.12 457.16 773.25 456.6 773.25 428.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11B",
        "coords": "734.91 426.38 757.92 427.38 757.92 455.52 734.91 453.88 734.91 426.38",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11A",
        "coords": "688.26 422.9 710.63 424.39 710.63 451.85 688.26 450.01 688.26 422.9",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11A",
        "coords": "648 420.18 668.57 421.85 667.81 448.46 648 446.41 648 420.18",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "1085.75 426.92 1085.75 393.94 1115.79 395.43 1115.79 428.17 1085.75 426.92",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "1043.57 392.95 1057.64 393.13 1057.59 424.76 1043.57 424.49 1043.57 392.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "980.64 390.53 1010.34 391.33 1010.34 422.96 980.31 421.57 980.64 390.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12B",
        "coords": "924.07 388.48 952.19 389.23 952.19 420.05 924.07 419.16 924.07 388.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12B",
        "coords": "894.67 387.59 906.82 388.02 906.82 418.3 894.03 417.63 894.67 387.59",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12B",
        "coords": "851.86 385.77 877.5 387.07 877.59 416.91 851.86 415.62 851.86 385.77",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12B",
        "coords": "798.82 383.82 823.74 384.82 823.55 414.28 798.82 412.4 798.82 383.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12B",
        "coords": "773.25 382.91 784.12 383.52 784.12 411.59 773.25 411.03 773.25 382.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12B",
        "coords": "734.91 381.45 757.92 382.45 757.92 409.94 734.91 408.94 734.91 381.45",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12A",
        "coords": "688.26 379.88 710.63 380.1 710.63 407.55 688.26 406.99 688.26 379.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12A",
        "coords": "648 378.44 668.57 378.83 668.45 405.45 648 404.67 648 378.44",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "1085.75 375.09 1085.75 342.11 1115.79 342.33 1115.79 375.7 1085.75 375.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "1043.57 341.13 1057.64 341.31 1057.59 372.93 1043.57 372.66 1043.57 341.13",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "980.64 340.62 1010.34 340.78 1010.34 372.41 980.31 371.66 980.64 340.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14B",
        "coords": "924.07 339.21 952.19 339.96 952.19 370.78 924.07 369.89 924.07 339.21",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14B",
        "coords": "894.67 338.96 906.82 339.38 906.82 369.66 894.03 369 894.67 338.96",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14B",
        "coords": "851.86 338.42 877.5 339.08 877.59 368.92 851.86 368.26 851.86 338.42",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14B",
        "coords": "798.82 337.75 823.74 338.11 823.55 366.92 798.82 366.32 798.82 337.75",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14B",
        "coords": "773.25 337.47 784.12 337.45 784.12 365.52 773.25 365.59 773.25 337.47",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14B",
        "coords": "734.91 336.65 757.92 337.01 757.92 364.51 734.91 364.14 734.91 336.65",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14A",
        "coords": "688.26 336.36 710.63 336.58 710.63 364.03 688.26 363.47 688.26 336.36",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14A",
        "coords": "648 335.56 668.57 335.95 668.45 362.57 648 361.79 648 335.56",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "1085.75 321.55 1085.75 288.57 1115.79 288.78 1115.79 322.16 1085.75 321.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "1043.57 289.5 1057.64 289.68 1057.59 321.3 1043.57 321.04 1043.57 289.5",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "980.64 290.27 1010.34 290.43 1010.34 322.06 980.31 321.31 980.64 290.27",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15B",
        "coords": "924.07 290.14 952.19 290.25 952.19 321.07 924.07 320.82 924.07 290.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15B",
        "coords": "894.67 290.52 906.82 290.31 906.82 320.59 894.03 320.56 894.67 290.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15B",
        "coords": "851.86 291.9 877.5 291.92 877.59 321.76 851.86 321.11 851.86 291.9",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15B",
        "coords": "798.82 291.23 823.74 291.59 823.55 320.41 798.82 319.81 798.82 291.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15B",
        "coords": "773.25 291.59 784.12 291.57 784.12 319.64 773.25 319.72 773.25 291.59",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15B",
        "coords": "734.91 292.69 757.92 293.05 757.92 320.55 734.91 320.18 734.91 292.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15A",
        "coords": "688.26 292.4 710.63 292.62 710.63 320.07 688.26 319.51 688.26 292.4",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15A",
        "coords": "648 292.88 668.57 292.63 668.45 319.24 648 319.11 648 292.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1085.75 269.38 1085.75 236.4 1115.79 235.34 1115.79 268.71 1085.75 269.38",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1043.57 237.97 1057.64 237.51 1057.59 269.14 1043.57 269.51 1043.57 237.97",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "980.64 239.38 1010.34 238.91 1010.34 270.53 980.31 270.42 980.64 239.38",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1602",
        "coords": "924.07 241.81 952.19 241.28 952.19 272.1 924.07 272.48 924.07 241.81",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1602",
        "coords": "894.67 242.19 906.82 241.98 906.82 272.26 894.03 272.23 894.67 242.19",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1602",
        "coords": "851.86 243.57 877.5 242.95 877.59 272.79 851.86 272.78 851.86 243.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1602",
        "coords": "798.82 245.46 823.74 245.18 823.55 274 798.82 274.03 798.82 245.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1602",
        "coords": "773.25 246.46 784.12 246.44 784.12 274.5 773.25 274.58 773.25 246.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1602",
        "coords": "734.91 247.55 757.92 247.28 757.92 274.77 734.91 275.05 734.91 247.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1601",
        "coords": "688.26 248.55 710.63 248.12 710.63 275.58 688.26 275.66 688.26 248.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1601",
        "coords": "648 250.3 668.57 250.05 668.45 276.67 648 276.53 648 250.3",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1066.55 178.39 1092.49 177.12 1092.49 208.52 1066.55 209.49 1066.55 178.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1047.65 179.56 1047.65 210.13 1022.74 211.01 1022.74 180.83 1047.65 179.56",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1004.42 182.2 1004.86 212.04 980.98 213.06 980.98 183.46 1004.42 182.2",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1602",
        "coords": "964.13 184.69 964.13 211.45 941.27 207.2 941.27 185.51 964.13 184.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1602",
        "coords": "931.89 186.25 932.33 215.11 910.06 216.29 910.06 186.98 931.89 186.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1602",
        "coords": "900.97 187.86 900.97 216.29 891.89 216.87 891.89 188.3 900.97 187.86",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1701",
        "coords": "847.63 191.58 847.63 214.09 831.51 211.6 831.51 219.36 827.3 219.36 826.87 192.26 847.63 191.58",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1701",
        "coords": "814.66 192.99 814.66 220.24 795.61 221.27 795.61 193.72 814.66 192.99",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1701",
        "coords": "770.7 195.51 771.14 222.15 752.82 223.03 752.82 196.36 770.7 195.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1701",
        "coords": "740.08 197.24 740.66 223.03 722.79 224.2 722.79 198.41 740.08 197.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "PH1701",
        "coords": "M709.6,199v26.23h-16.76v-25.5l16.76-.73Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "1199.9 868.8 1207.81 856.79 1207.81 886.58 1199.31 900.45 1199.9 868.8",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "1216.9 873.3 1216.9 844.28 1225.3 831.49 1225.3 860.89 1216.9 873.3",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "1233.09 848.51 1233.09 820.89 1240.56 810.41 1240.56 837.52 1233.09 848.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "1245.03 830.26 1245.03 804.11 1248.11 799.13 1248.11 825.79 1245.03 830.26",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "1251.99 819.86 1251.99 793.04 1258.29 784.25 1258.29 809.82 1251.99 819.86",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2G",
        "coords": "1264.67 800.08 1264.67 774.43 1271.12 766.01 1271.12 790.7 1264.67 800.08",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2G",
        "coords": "1276.9 781.69 1276.9 757.73 1282.76 748.79 1282.76 772.38 1276.9 781.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2K",
        "coords": "1285.92 767.47 1285.92 744.4 1288.85 740.95 1288.85 763.52 1285.92 767.47",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2K",
        "coords": "1291.78 758.9 1291.78 735.53 1296.69 728.57 1296.69 750.92 1291.78 758.9",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2K",
        "coords": "1302.11 743 1302.11 721.1 1306.94 714.8 1306.94 735.38 1302.11 743",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2K",
        "coords": "1311.41 728.57 1311.41 707.62 1315.22 701.9 1315.22 722.27 1311.41 728.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "1160.34 907.34 1159.9 874.52 1170.01 860.89 1170.45 893.42 1160.34 907.34",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "1179.68 879.79 1179.68 848.51 1189.35 835.69 1189.35 866.46 1179.68 879.79",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "1199.9 823.18 1207.81 813.08 1207.81 840.96 1199.31 852.92 1199.9 823.18",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3F",
        "coords": "1216.9 827.68 1216.9 799.3 1225.3 787.78 1225.3 815.91 1216.9 827.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3F",
        "coords": "1233.09 804.8 1233.09 777.18 1240.56 766.71 1240.56 795.09 1233.09 804.8",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3G",
        "coords": "1245.03 788.48 1245.03 762.32 1248.11 757.98 1248.11 784.01 1245.03 788.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3G",
        "coords": "1251.99 779.35 1251.99 754.45 1258.29 745.66 1258.29 769.95 1251.99 779.35",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3G",
        "coords": "1264.67 760.85 1264.67 735.2 1271.12 726.78 1271.12 752.11 1264.67 760.85",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3G",
        "coords": "1276.9 743.74 1276.9 719.78 1282.76 712.12 1282.76 735.71 1276.9 743.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3K",
        "coords": "1285.92 731.44 1285.92 708.36 1288.85 704.92 1288.85 727.48 1285.92 731.44",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3K",
        "coords": "1291.78 723.51 1291.78 702.05 1296.69 695.73 1296.69 716.8 1291.78 723.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3K",
        "coords": "1302.11 708.89 1302.11 686.34 1306.94 680.04 1306.94 702.54 1302.11 708.89",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2J",
        "coords": "1310.77 696.37 1310.77 674.14 1315.22 669.06 1315.22 690.71 1310.77 696.37",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "1160.34 853.02 1159.9 820.19 1170.01 807.84 1170.45 840.37 1160.34 853.02",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "1179.68 828.03 1179.68 796.74 1189.35 784.56 1189.35 815.33 1179.68 828.03",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "1201.18 772.05 1207.81 764.51 1207.81 792.39 1200.59 801.79 1201.18 772.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4F",
        "coords": "1216.9 780.38 1216.9 751.37 1225.3 741.77 1225.3 770.53 1216.9 780.38",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4F",
        "coords": "1233.09 759.43 1233.09 731.81 1240.56 722.61 1240.56 750.35 1233.09 759.43",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "1245.03 744.38 1245.03 718.22 1248.11 714.52 1248.11 740.55 1245.03 744.38",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "1253.91 733.98 1253.91 709.08 1258.29 703.48 1258.29 727.77 1253.91 733.98",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "1264.67 719.94 1264.67 694.3 1271.12 686.51 1271.12 711.84 1264.67 719.94",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "1276.9 704.11 1276.9 680.15 1282.76 673.13 1282.76 696.72 1276.9 704.11",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4K",
        "coords": "1285.92 692.45 1285.92 669.37 1288.85 665.93 1288.85 688.5 1285.92 692.45",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4K",
        "coords": "1293.05 683.24 1293.05 661.79 1297.32 657.38 1297.32 678.45 1293.05 683.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4K",
        "coords": "1302.11 671.82 1302.11 649.27 1306.94 643.61 1306.94 666.11 1302.11 671.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4K",
        "coords": "1310.77 660.58 1310.77 638.35 1315.22 633.91 1315.22 655.55 1310.77 660.58",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4M",
        "coords": "1320.5 649.69 1320.5 628.74 1324.31 624.3 1324.31 644.67 1320.5 649.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4M",
        "coords": "1325.28 643.61 1325.28 622.65 1329.09 618.22 1329.09 638.59 1325.28 643.61",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4M",
        "coords": "1329.97 637.91 1329.97 616.95 1333.78 612.51 1333.78 632.88 1329.97 637.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "1160.34 799.58 1159.9 766.75 1170.01 756.32 1170.45 788.85 1160.34 799.58",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "1179.68 778.42 1179.68 745.22 1189.35 734.95 1189.35 767.64 1179.68 778.42",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "1199.26 723.73 1207.81 714.91 1207.81 745.34 1198.67 756.02 1199.26 723.73",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5F",
        "coords": "1216.9 735.25 1216.9 706.24 1225.3 696.63 1225.3 725.4 1216.9 735.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5F",
        "coords": "1233.09 716.21 1233.09 688.59 1240.56 680.03 1240.56 707.78 1233.09 716.21",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "1245.03 703.08 1245.03 675.65 1248.11 671.94 1248.11 699.25 1245.03 703.08",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "1251.99 693.95 1251.99 667.78 1258.29 661.54 1258.29 687.11 1251.99 693.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "1264.67 679.92 1264.67 654.28 1271.12 647.77 1271.12 673.1 1264.67 679.92",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "1276.9 666.01 1276.9 641.41 1282.76 635.03 1282.76 659.26 1276.9 666.01",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "1285.92 656.27 1285.92 631.91 1288.85 628.47 1288.85 652.31 1285.92 656.27",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "1291.78 648.97 1291.78 624.96 1296.69 619.92 1296.69 643.54 1291.78 648.97",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "1302.11 636.91 1302.11 614.36 1306.94 608.7 1306.94 631.21 1302.11 636.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "1310.77 626.95 1310.77 604.72 1315.22 600.28 1315.22 621.93 1310.77 626.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5M",
        "coords": "1320.5 616.29 1320.5 595.34 1324.31 591.64 1324.31 612.01 1320.5 616.29",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5M",
        "coords": "1325.28 611.14 1325.28 590.18 1329.09 586.49 1329.09 606.86 1325.28 611.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5M",
        "coords": "1329.97 606.35 1329.97 585.39 1333.78 581.7 1333.78 602.06 1329.97 606.35",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "1159.9 747.62 1159.9 713.48 1170.45 703.48 1170.45 736.92 1159.9 747.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "1179.68 727.69 1179.68 695.45 1189.35 685.34 1189.35 716.85 1179.68 727.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "1199.83 707.54 1199.17 676.56 1208.04 667.69 1208.04 698.49 1199.83 707.54",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6F",
        "coords": "1216.9 689.74 1216.9 659.11 1225.3 651.79 1225.3 680.8 1216.9 689.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6F",
        "coords": "1233.09 673.13 1232.43 644.61 1240.56 637.47 1240.56 665.02 1233.09 673.13",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "1245.03 660.82 1244.54 633.37 1248.11 629.81 1248.11 657.11 1245.03 660.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "1251.68 653.5 1251.68 626.92 1258.29 620.31 1258.71 646.27 1251.68 653.5",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "1264.67 640.48 1264.37 614.13 1271.12 608.12 1271.48 633.54 1264.67 640.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "1276.9 627.68 1276.9 602.48 1282.76 597.35 1282.98 621.67 1276.9 627.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "1285.92 618.82 1285.62 594.12 1288.55 591.78 1288.85 615.81 1285.92 618.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "1291.78 612.95 1291.48 588.7 1296.69 583.87 1296.69 607.53 1291.78 612.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "1301.74 602.33 1301.37 578.96 1306.94 573.98 1306.94 596.84 1301.74 602.33",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "1310.77 593.15 1310.53 570.68 1315.37 565.84 1315.66 588.15 1310.77 593.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6M",
        "coords": "1320.5 582.85 1320.5 561.89 1324.31 558.73 1324.31 579.1 1320.5 582.85",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6M",
        "coords": "1325.28 578.37 1325.28 557.41 1329.09 554.25 1329.09 574.62 1325.28 578.37",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6M",
        "coords": "1329.97 573.6 1329.97 552.64 1333.78 549.48 1333.78 569.85 1329.97 573.6",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "1159.9 695.16 1159.9 660.82 1170.45 651.79 1170.45 684.9 1159.9 695.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "1179.68 677.75 1179.68 644.31 1189.35 636.11 1189.79 667.69 1179.68 677.75",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "1199.17 659.16 1198.83 628.68 1208.04 621.26 1208.04 650.66 1199.17 659.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7F",
        "coords": "1216.9 643.82 1216.9 613.44 1225.3 607 1225.3 636.11 1216.9 643.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7F",
        "coords": "1233.02 629.27 1232.43 600.65 1239.95 594.49 1240.56 622.04 1233.02 629.27",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "1244.54 619.11 1244.54 590.49 1248.11 587.75 1248.45 614.91 1244.54 619.11",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "1251.68 612.29 1251.68 584.75 1258.22 579.69 1258.71 605.3 1251.68 612.29",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "1264.67 600.7 1264.67 574.2 1270.6 569.14 1271.26 594.12 1264.67 600.7",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "1276.61 589.66 1276.9 563.87 1282.69 559.76 1282.98 584.16 1276.61 589.66",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "1285.84 581.3 1285.62 557.12 1288.41 554.93 1288.41 578.81 1285.84 581.3",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "1291.48 576.25 1291.48 552.44 1296.32 548.04 1296.69 571.27 1291.48 576.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "1301.74 567.09 1301.37 544.23 1306.65 539.47 1306.94 562.14 1301.74 567.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "1310.9 558.72 1310.9 536.24 1315.15 532.73 1315.15 554.56 1310.9 558.72",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7M",
        "coords": "1320.5 550.12 1320.5 529.16 1324.31 526.54 1324.31 546.91 1320.5 550.12",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7M",
        "coords": "1325.28 546.31 1325.28 525.36 1329.09 522.73 1329.09 543.1 1325.28 546.31",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7M",
        "coords": "1329.97 542.2 1329.97 521.25 1333.78 518.62 1333.78 538.99 1329.97 542.2",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "1159.9 641.58 1159.9 608.12 1169.86 600.7 1169.86 634.06 1159.9 641.58",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "1179.68 626.44 1179.19 593.9 1189.79 586.77 1189.79 618.33 1179.68 626.44",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "1199.12 611.49 1199.12 579.94 1208.04 573.83 1208.04 604.31 1199.12 611.49",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8D",
        "coords": "1216.9 597.71 1216.9 567.92 1224.91 561.86 1225.3 590.97 1216.9 597.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8D",
        "coords": "1232.43 585.26 1232.72 557.05 1240.56 551.12 1240.56 578.89 1232.43 585.26",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "1245.03 576.25 1244.54 548.04 1247.96 545.26 1248.45 573.1 1245.03 576.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "1251.68 570.46 1251.68 543.13 1258 538.66 1258.37 564.74 1251.68 570.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "1264.67 560.35 1264.96 533.97 1270.9 529.72 1270.9 554.93 1264.67 560.35",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "1276.61 551.34 1276.61 525.99 1282.18 521.66 1282.69 546.06 1276.61 551.34",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "1285.62 544.16 1285.62 519.61 1288.41 517.71 1288.41 541.52 1285.62 544.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "1291.48 539.47 1291.48 515.73 1296.69 511.92 1296.69 535.36 1291.48 539.47",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "1301.74 531.48 1301.37 508.77 1306.94 504.67 1306.94 527.38 1301.74 531.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "1310.9 524.67 1310.61 501.88 1315.15 498.58 1315.15 521 1310.9 524.67",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "1320.5 516.56 1320.5 495.61 1324.31 493.52 1324.31 513.89 1320.5 516.56",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "1325.28 513.43 1325.28 492.47 1329.09 490.38 1329.09 510.75 1325.28 513.43",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "1329.97 509.97 1329.97 489.02 1333.78 486.93 1333.78 507.3 1329.97 509.97",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "1159.9 588.14 1159.9 553.75 1169.86 548.09 1170.4 581.5 1159.9 588.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "1179.68 575.64 1179.68 543.01 1189.79 537.44 1189.79 568.99 1179.68 575.64",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "1199.12 562.74 1199.51 531.38 1208.04 526.3 1208.04 556.88 1199.12 562.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "9D",
        "coords": "M1216.9,551.51v-30.09s8.4-5.57,8.4-5.18v29.6l-8.4,5.67Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9D",
        "coords": "1232.43 541.35 1232.43 512.33 1240.56 507.55 1240.56 535.88 1232.43 541.35",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "1244.54 532.95 1244.54 505.3 1248.45 503.15 1248.45 530.21 1244.54 532.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "1251.68 528.16 1251.68 501.3 1258.02 497.68 1258.37 524.45 1251.68 528.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "1264.67 520.12 1264.67 493.82 1270.9 490.09 1271.34 515.88 1264.67 520.12",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "1276.98 511.7 1276.61 486.79 1282.69 483.34 1282.69 508.26 1276.98 511.7",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "1285.62 506.28 1285.62 481.95 1288.41 480.19 1288.41 504.45 1285.62 506.28",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "1291.48 502.61 1291.48 478.51 1296.39 475.65 1296.69 498.95 1291.48 502.61",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "1301.74 495.65 1301.37 473.16 1306.94 469.79 1306.94 492.43 1301.74 495.65",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "1310.61 489.82 1310.44 467.79 1315.15 464.71 1315.32 486.69 1310.61 489.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "1320.5 483.17 1320.5 462.21 1324.31 460.59 1324.31 480.96 1320.5 483.17",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "1325.28 480.62 1325.28 459.67 1329.09 458.05 1329.09 478.41 1325.28 480.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "1329.97 477.74 1329.97 456.79 1333.78 455.17 1333.78 475.54 1329.97 477.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "1159.65 535.68 1159.65 501.1 1170.1 496.02 1170.1 529.92 1159.65 535.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "1179.68 525.13 1179.29 492.7 1189.79 487.62 1189.45 519.56 1179.68 525.13",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "1199.12 514.68 1199.12 483.52 1208.04 479.41 1208.04 509.79 1199.12 514.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10D",
        "coords": "1216.9 505.5 1216.9 475.31 1225.3 471.5 1225.3 500.22 1216.9 505.5",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10D",
        "coords": "1232.43 496.7 1232.43 468.37 1240.56 464.95 1240.56 492.21 1232.43 496.7",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "1244.54 490.09 1244.54 463.1 1248.06 461.73 1248.06 488.5 1244.54 490.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "1251.68 487.09 1251.68 459.97 1258.37 456.55 1258.37 483.03 1251.68 487.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "1264.67 479.9 1264.67 453.96 1271.34 450.74 1271.34 476.24 1264.67 479.9",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "1276.9 474.24 1276.9 448.47 1282.69 445.76 1282.69 470.67 1276.9 474.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "1285.62 468.91 1285.62 444.37 1288.41 443.12 1288.41 467.15 1285.62 468.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "1291.48 466.05 1291.48 442.1 1296.69 439.53 1296.69 462.9 1291.48 466.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "1301.67 460.41 1301.67 437.26 1306.94 434.55 1306.94 457.48 1301.67 460.41",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "1310.44 455.14 1310.44 433.08 1315.08 430.89 1315.08 452.79 1310.44 455.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "1320.5 450.74 1320.5 429.15 1324.31 427.53 1324.31 448.53 1320.5 450.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "1325.28 448.19 1325.28 427.06 1329.09 425.44 1329.09 445.99 1325.28 448.19",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "1329.97 445.32 1329.97 424.93 1333.78 423.31 1333.78 443.11 1329.97 445.32",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "1160.04 481.66 1159.65 448.15 1170.5 444.15 1170.1 477.36 1160.04 481.66",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "1179.58 474.14 1179.29 441.9 1189.79 438.19 1189.79 469.55 1179.58 474.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "1199.12 465.83 1199.12 435.26 1208.04 432.23 1208.04 462.61 1199.12 465.83",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "1216.9 458.61 1216.9 429.59 1225.3 426.56 1225.3 455.58 1216.9 458.61",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "1232.92 452.79 1232.92 424.51 1240.56 421.29 1240.56 449.42 1232.92 452.79",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "1244.84 447.48 1244.54 420.41 1248.06 419.24 1248.06 446 1244.84 447.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "1251.68 444.64 1251.68 418.16 1258.37 415.52 1258.37 442.19 1251.68 444.64",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "1264.67 439.53 1264.67 413.67 1271.02 411.62 1270.63 437.04 1264.67 439.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "1276.9 434.92 1276.9 410 1282.69 407.95 1282.69 432.2 1276.9 434.92",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1285.62 431.52 1285.62 407.37 1288.41 406.27 1288.41 430.08 1285.62 431.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1291.48 429.11 1291.48 405.39 1296.69 403.7 1296.69 426.64 1291.48 429.11",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1301.37 424.88 1301.37 402.31 1306.94 400.33 1306.94 422.39 1301.37 424.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1310.44 421.41 1310.44 399.16 1315.08 397.48 1315.08 419.24 1310.44 421.41",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "1159.65 429.1 1159.65 394.91 1170.5 392.57 1170.1 425.78 1159.65 429.1",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "1179.29 423.93 1179.29 391.1 1189.79 388.66 1189.79 420.7 1179.29 423.93",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "1199.12 418.16 1199.12 386.61 1208.04 384.85 1208.04 415.52 1199.12 418.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "1216.9 412.89 1216.9 383.38 1225.3 381.63 1225.3 410.84 1216.9 412.89",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "1232.92 408.88 1232.92 380.55 1240.56 378.99 1240.56 406.29 1232.92 408.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "1244.54 404.92 1244.54 378.21 1248.06 377.23 1248.06 404.09 1244.54 404.92",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "1251.68 403.22 1251.68 376.84 1258.37 375.18 1258.37 401.51 1251.68 403.22",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "1264.67 399.89 1264.67 374.1 1271.02 372.54 1271.02 397.74 1264.67 399.89",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "1276.9 396.23 1276.9 371.54 1282.69 370.44 1282.69 394.55 1276.9 396.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1285.62 394.09 1285.62 369.71 1288.63 369.12 1288.41 392.93 1285.62 394.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1291.48 391.76 1291.48 368.83 1296.69 367.66 1296.69 390.22 1291.48 391.76",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1301.37 366.72 1306.94 365.32 1306.94 387.45 1301.37 389.14 1301.37 366.72",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1310.44 386.78 1310.44 364.65 1315.08 363.7 1315.08 385.46 1310.44 386.78",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "1159.65 375.7 1159.65 342.11 1170.5 340.99 1170.5 373.66 1159.65 375.7",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "1179.29 372.44 1179.29 340.55 1189.79 339.38 1189.79 370.44 1179.29 372.44",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "1201.8 368.54 1201.8 339.38 1209.42 339.01 1209.42 367.58 1201.8 368.54",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "1216.9 366.85 1216.9 337.4 1224.96 336.96 1225.3 365.6 1216.9 366.85",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "1232.92 364.21 1232.92 336.3 1240.34 335.93 1240.34 363.11 1232.92 364.21",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "1244.54 362.23 1244.54 335.49 1248.06 335.13 1248.06 361.8 1244.54 362.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "1253.6 360.33 1253.6 335.29 1259.32 335.35 1259.32 359.74 1253.6 360.33",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "1264.67 359.08 1264.89 333.81 1271.02 333.44 1271.02 358.5 1264.67 359.08",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "1276.9 357.11 1276.9 333.22 1282.47 332.85 1282.47 356.45 1276.9 357.11",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1285.62 355.86 1285.62 332.41 1288.63 332.12 1288.63 355.86 1285.62 355.86",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1292.51 332.85 1297.56 332.56 1297.56 353.88 1292.31 354.4 1292.51 332.85",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1301.37 353.15 1301.37 331.32 1306.94 330.8 1306.94 352.78 1301.37 353.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1310.44 352.12 1310.44 330.44 1315.08 330.14 1315.08 351.46 1310.44 352.12",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "1179.29 321.52 1179.29 289.41 1189.79 289.41 1189.79 321.5 1179.29 321.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "15C",
        "coords": "M1200.88,320.86v-17.29s3.22-5.86,3.22-10.94h3.91v28.23h-7.13Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "1232.65 320.18 1232.92 292.12 1240.56 292.12 1240.34 320.18 1232.65 320.18",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15F",
        "coords": "1244.54 319.74 1244.54 292.63 1248.33 292.63 1248.06 319.74 1244.54 319.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "15F",
        "coords": "M1256.17,294.83h2.2v24.4h-5.13v-13.92s2.2-2.49,2.93-10.48Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "15F",
        "coords": "M1264.59,319.23v-19.27s1.69-2.2,1.69-5.86h4.98v24.84l-6.67.29Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15F",
        "coords": "1276.9 318.93 1276.9 294.83 1282.76 294.83 1282.54 318.93 1276.9 318.93",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "15H",
        "coords": "M1292.8,318.23l.29-14.95s2.34-1.12,2.34-6.45h1.37v21.39h-4.01Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "15H",
        "coords": "M1301.25,318.23l.12-15.7s1.9-2.05,1.9-6.52h3.66v22.22h-5.69Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1160.04 269.28 1160.04 235.29 1170.5 237.34 1170.5 269.77 1160.04 269.28",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1179.58 270.46 1179.58 238.9 1189.79 240.56 1189.79 271.33 1179.58 270.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1198.73 272.31 1199.22 242.32 1208.01 243.79 1208.01 272.31 1198.73 272.31",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1216.9 273.78 1216.9 245.45 1225.3 246.81 1224.81 274.51 1216.9 273.78",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1232.65 275.73 1232.65 248.38 1240.56 249.45 1240.56 276.9 1232.65 275.73",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1244.59 276.9 1244.59 250.43 1248.18 250.94 1248.18 277.24 1244.59 276.9",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1251.99 277.24 1251.48 251.82 1258.29 252.63 1258.29 277.68 1251.99 277.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1264.89 278.2 1264.59 253.87 1271.34 255.26 1270.9 279.22 1264.89 278.2",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1276.49 279.88 1276.73 256.19 1282.69 257.07 1282.69 280.22 1276.49 279.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1285.77 281.05 1285.43 257.76 1288.26 257.95 1288.46 281.05 1285.77 281.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1291.48 281.09 1291.09 258.78 1296.66 260 1296.66 281.05 1291.48 281.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1301.15 260.15 1306.87 261.37 1306.87 282.67 1301.15 281.74 1301.15 260.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1310.53 261.71 1315.08 262.59 1315.27 283.59 1310.53 282.86 1310.53 261.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1110.71 178.53 1117.65 180.78 1117.65 207.15 1110.71 207.15 1110.71 178.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1127.59 206.69 1127.59 183.1 1129.27 183.83 1129.27 206.69 1127.59 206.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1138.06 206.1 1138.06 185.73 1144.44 188.08 1144.44 206.1 1138.06 206.1",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1151.69 198.7 1151.69 190.2 1153.6 190.99 1153.6 198.7 1151.69 198.7",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1161.84 201.09 1161.84 192.69 1166.86 194.27 1166.86 202.32 1161.84 201.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "1174.63 204.31 1174.63 195.92 1179.65 197.49 1179.65 205.54 1174.63 204.31",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1196.35 209.96 1196.35 201.77 1197.59 202.55 1197.59 210.18 1196.35 209.96",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1204.61 211.82 1204.61 204.48 1208.62 206.05 1208.62 212.82 1204.61 211.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1216.15 214.85 1216.15 207.74 1220.16 209.31 1220.16 215.96 1216.15 214.85",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1226.37 217.25 1226.37 210.49 1230.38 212.06 1230.38 218.36 1226.37 217.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1235.95 219.75 1235.95 212.99 1239.87 214.4 1239.87 220.81 1235.95 219.75",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      }
    ],
    1: [
      {
        "type": "polygon",
        "id": "2E",
        "coords": "816.64 907.89 853.76 908.53 853.76 944.71 816.64 944.36 816.64 907.89",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "890.64 944.26 890.64 908.87 927.49 908.85 927.49 944.29 890.64 944.26",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "965.06 945.08 965.06 908.62 1001.99 908.62 1001.99 945.15 965.06 945.08",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "1024 945 1024 908.53 1040.87 908.29 1040.87 945 1024 945",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "1061.54 944.71 1061.54 908.67 1098.24 908.53 1097.6 945 1061.54 944.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2G",
        "coords": "1134.25 944.43 1134.25 908.67 1171.08 908.23 1171.08 944.59 1134.25 944.43",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2G",
        "coords": "1207.22 945.14 1207.22 907.94 1244.5 908.07 1244.5 944.09 1207.22 945.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2K",
        "coords": "1265.11 944.3 1265.11 908.7 1282.1 908.42 1282.1 944.83 1265.11 944.3",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2K",
        "coords": "1303.3 944.98 1303.3 908.25 1340.1 908.05 1340.1 945.02 1303.3 944.98",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2K",
        "coords": "1377.94 944.43 1377.94 908.8 1415.33 908.23 1415.33 944.82 1377.94 944.43",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2K",
        "coords": "1449.15 945 1449.15 908.55 1483.22 909.12 1483.22 945.04 1449.15 945",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "673.78 889.45 673.58 852.68 708.38 853.31 708.65 889.34 673.78 889.45",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "742.6 889.59 742.6 853.55 779.11 853.13 779.11 889.42 742.6 889.59",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "817.1 852.53 853.8 852.68 853.8 888.38 817.28 888.83 817.1 852.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3F",
        "coords": "891.28 889.15 891.28 853.29 928.13 853.64 928.13 888.71 891.28 889.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3F",
        "coords": "965.06 888.04 965.06 852.86 1001.99 852.94 1001.99 888.63 965.06 888.04",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3G",
        "coords": "1024.18 889.17 1024.18 853.48 1041.51 852.94 1041.51 888.77 1024.18 889.17",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3G",
        "coords": "1060.9 889.62 1060.9 852.41 1097.99 852.72 1097.99 888.43 1060.9 889.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3G",
        "coords": "1133.61 889.05 1133.61 853.29 1171.72 853.02 1171.72 889.04 1133.61 889.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3G",
        "coords": "1207.22 888.88 1207.22 852.97 1244.5 852.72 1244.5 888.74 1207.22 888.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3K",
        "coords": "1263.83 888.72 1263.83 853.13 1282.1 853.61 1282.1 888.74 1263.83 888.72",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3K",
        "coords": "1303.3 887.44 1303.3 853.72 1340.1 853.69 1340.1 887.33 1303.3 887.44",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3K",
        "coords": "1377.94 888.31 1377.94 852.93 1415.33 852.7 1415.33 888.62 1377.94 888.31",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2J",
        "coords": "1448.64 888.87 1448.64 852.91 1483.86 852.61 1483.86 889.44 1448.64 888.87",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "674.42 831.2 674.22 794.49 709.02 795.15 708.01 831.11 674.42 831.2",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "741.96 831.23 741.96 795.19 779.75 795.4 778.47 830.41 741.96 831.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "817.28 795.28 852.48 794.89 852.48 829.31 817.28 829.83 817.28 795.28",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4F",
        "coords": "891.28 831.29 891.28 794.62 928.13 794.81 928.13 831.99 891.28 831.29",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4F",
        "coords": "965.06 830.71 965.06 795.53 1001.99 794.8 1001.99 830.91 965.06 830.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "1024.18 830.92 1024.18 795.24 1041.51 795.05 1041.51 830.88 1024.18 830.92",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "1061.03 829.77 1061.03 794.92 1097.99 795.5 1097.99 829.93 1061.03 829.77",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "1134.25 831.25 1134.08 795.28 1171.08 795 1171.08 831.02 1134.25 831.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "1207.22 831.4 1207.22 795.49 1244.5 795.11 1244.5 831.13 1207.22 831.4",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4K",
        "coords": "1264.47 831.16 1264.47 795.57 1281.46 795.48 1281.46 830.61 1264.47 831.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4K",
        "coords": "1302.51 831.75 1302.51 795.33 1340.15 794.91 1340.15 831.18 1302.51 831.75",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4K",
        "coords": "1377.94 830.59 1377.94 795.21 1415.33 795.04 1415.33 830.96 1377.94 830.59",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4K",
        "coords": "1448.64 831.48 1448.64 795.52 1483.86 795.38 1483.86 830.92 1448.64 831.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4M",
        "coords": "1525.8 832.6 1525.8 795 1552.92 794.92 1552.92 831.99 1525.8 832.6",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4M",
        "coords": "1567.09 832.23 1567.09 795.28 1595.36 795.19 1595.36 832.89 1567.09 832.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4M",
        "coords": "1609.59 832.96 1609.59 795.37 1638.4 795.26 1638.4 832.33 1609.59 832.96",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "674.42 774.35 674.22 738.98 709.02 739.14 709.29 775.04 674.42 774.35",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "742.6 775.37 742.6 738.4 779.11 738.19 779.11 775.46 742.6 775.37",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "816.43 739.04 853.16 739.14 853.16 774.15 816.61 774.7 816.43 739.04",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5F",
        "coords": "891.28 774.88 891.28 738.21 928.13 738.4 928.13 774.3 891.28 774.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5F",
        "coords": "965.06 774.95 965.06 738.49 1001.99 738.72 1001.99 774.82 965.06 774.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "1024.18 775.22 1024.18 739.07 1041.28 739.01 1041.28 775.32 1024.18 775.22",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "1061.54 774.62 1061.54 738.19 1098.63 738.62 1098.63 774.86 1061.54 774.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "1134.25 774.09 1134.25 738.33 1171.08 738.59 1171.08 774.61 1134.25 774.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "1207.22 774.28 1207.22 738.69 1244.5 739.5 1244.5 773.93 1207.22 774.28",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "1264.47 776 1264.47 738.44 1281.46 739.08 1281.46 776.2 1264.47 776",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "1302,737.2 1338.71,737.2 1338.71,772.14 1302,772.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "1264.47 776 1264.47 738.44 1281.46 739.08 1281.46 776.2 1264.47 776",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "1377.94 774.47 1377.94 739.09 1415.33 739.26 1415.33 773.9 1377.94 774.47",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "1448.64 774.52 1448.64 737.28 1483.86 737.53 1483.86 774.35 1448.64 774.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5M",
        "coords": "1525.8 775.35 1525.8 739.03 1552.92 739.45 1552.92 775.24 1525.8 775.35",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5M",
        "coords": "1567.09 775.61 1567.09 738.66 1596 739.08 1596 775.51 1567.09 775.61",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5M",
        "coords": "1609.59 775.7 1609.59 738.11 1637.76 738.54 1637.76 775.6 1609.59 775.7",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "673.58 718.97 673.58 682.69 708.65 682.41 708.65 718.83 673.58 718.97",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "741.96 718.21 741.96 682.35 779.75 682.25 779.75 718.12 741.96 718.21",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "817.09 718.25 816.97 682.58 853.76 681.73 853.76 718.5 817.09 718.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6F",
        "coords": "891.28 718 891.28 681.85 928.13 682.36 928.13 718.58 891.28 718",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6F",
        "coords": "965.31 717.29 964.69 682.68 1002.24 681.86 1002.24 717.7 965.31 717.29",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "1023.94 717.57 1023.98 682.06 1041.28 681.66 1041.28 717.96 1023.94 717.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "1061.12 718.83 1061.12 681.88 1098.64 682.41 1098.4 718.51 1061.12 718.83",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "1133.61 717.76 1134.47 681.87 1171.72 682.11 1171.37 717.53 1133.61 717.76",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "1206.58 717.47 1206.58 682.24 1243.86 682.61 1244 717.52 1206.58 717.47",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "1264.47 718.01 1264.64 682.04 1281.92 682.04 1281.92 717.87 1264.47 718.01",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "1303.04 717.23 1303.28 682.04 1339.52 681.91 1338.88 717.87 1303.04 717.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "1377.92 718.51 1377.92 681.4 1415.33 681.86 1414.4 717.87 1377.92 718.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "1448.96 717.87 1448.96 682.68 1483.12 681.96 1482.88 717.87 1448.96 717.87",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6M",
        "coords": "1525.8 718.66 1525.8 682.34 1552.92 682.9 1552.92 718.69 1525.8 718.66",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6M",
        "coords": "1567.09 719.1 1567.09 682.15 1595.36 682.71 1595.36 719.14 1567.09 719.1",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6M",
        "coords": "1609.59 719.5 1609.59 681.91 1637.76 682.47 1637.76 719.54 1609.59 719.5",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "673.58 661.9 673.58 625.4 708.48 626.06 708.48 662.11 673.58 661.9",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "741.76 661.69 741.76 625.73 779.54 625.22 779.96 661.63 741.76 661.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "816.97 661.61 816.87 625.73 853.76 625.69 853.76 662.02 816.97 661.61",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7F",
        "coords": "890.64 661.24 890.88 625.73 927.36 625.73 927.49 660.82 890.64 661.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7F",
        "coords": "965.2 660.91 965.2 624.68 1002.24 624.68 1002.24 661.55 965.2 660.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "1024 661.53 1024 625.09 1041.3 625.25 1041.82 661.76 1024 661.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "1061.12 662.12 1061.12 625.09 1098.23 625.33 1098.4 660.94 1061.12 662.12",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "1134.08 662.09 1134.08 625.09 1171.66 625.9 1171.79 660.92 1134.08 662.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "1206.67 661.44 1207.04 625.73 1243.52 625.73 1244.16 661.56 1206.67 661.44",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "1264.84 663.28 1264.64 625.73 1281.92 625.76 1281.92 662.89 1264.84 663.28",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "1303.04 661.38 1303.04 625.27 1340.16 625.73 1339.28 660.68 1303.04 661.38",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "1378.45 662.23 1378.33 625.91 1415.03 626.26 1414.69 661.56 1378.45 662.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "1448.98 662.34 1448.98 625.97 1483.91 625.72 1483.91 661.57 1448.98 662.34",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7M",
        "coords": "1525.8 661.94 1525.8 625.62 1552.92 626.33 1552.92 662.12 1525.8 661.94",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7M",
        "coords": "1567.73 662.57 1567.73 625.62 1596 626.35 1596 662.77 1567.73 662.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7M",
        "coords": "1609.59 662.53 1609.59 624.94 1637.76 625.67 1637.76 662.73 1609.59 662.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "673.58 604.88 673.58 569.34 709.15 568.67 709.15 604.94 673.58 604.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "741.32 605.4 742.08 568.82 779.53 568.94 779.53 604.91 741.32 605.4",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "817.13 605.21 817.13 569.65 854.11 569.62 854.11 604.71 817.13 605.21",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8D",
        "coords": "890.88 605.73 890.88 569.34 928.48 569.85 927.74 605.26 890.88 605.73",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8D",
        "coords": "965.33 605.55 965.76 569.43 1002.24 569.43 1002.88 605.26 965.33 605.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "1024.64 605.26 1024 569.43 1041.28 569.43 1041.28 605.26 1024.64 605.26",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "1061.12 604.87 1061.12 569.43 1098.29 569.18 1097.77 605.23 1061.12 604.87",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "1134.42 604.68 1133.56 569.43 1171.2 569.1 1171.2 604.91 1134.42 604.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "1206.4 605.25 1206.4 569.84 1244.16 569.43 1243.52 605.26 1206.4 605.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "1264.46 604.69 1264.46 569.43 1281.71 569.4 1281.71 605.13 1264.46 604.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "1303.21 605.55 1303.21 569.56 1339.46 569.1 1339.46 605.26 1303.21 605.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "1377.81 605.79 1377.69 569.78 1415.33 569.22 1415.33 605.48 1377.81 605.79",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "1448.96 605.26 1449.51 569.43 1482.88 569.03 1482.88 604.6 1448.96 605.26",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "1525.8 605.69 1525.8 569.38 1552.92 568.95 1552.92 606.02 1525.8 605.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "1567.73 605.86 1567.73 568.91 1595.36 569.78 1595.36 606.2 1567.73 605.86",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "1609.59 605.99 1609.59 569.68 1637.12 569.27 1637.12 606.34 1609.59 605.99",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "673.58 548.64 673.58 512.09 709.15 512.01 708.48 548.72 673.58 548.64",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "742.6 548.8 742.6 512.5 779.53 512.63 779.53 548.59 742.6 548.8",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "816.49 548.88 816.79 512.55 853.47 512.18 853.47 548.68 816.49 548.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "9D",
        "coords": "M890.64,549.03v-36.75s36.86.16,36.86.67v35.7l-36.86.39Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9D",
        "coords": "965.08 548.71 965.08 513.05 1002.63 512.72 1002.63 548.33 965.08 548.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "1024.21 548.44 1024.21 512.05 1042.03 512.09 1042.03 548.09 1024.21 548.44",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "1061.12 548.56 1061.12 512.49 1098.42 512.39 1097.77 549.33 1061.12 548.56",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "1134.25 548.41 1134.25 512.98 1171.03 512.59 1171.12 548.39 1134.25 548.41",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "1206.4 548.15 1206.67 513.05 1244.02 512.96 1244.02 548.43 1206.4 548.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "1264.46 548.25 1264.46 512.05 1281.71 511.73 1281.71 548.14 1264.46 548.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "1303.21 548.33 1303.21 513.05 1339.94 512.84 1340.74 547.75 1303.21 548.33",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "1378.45 548.24 1378.33 512.66 1414.69 512.21 1414.69 548.35 1378.45 548.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "1448.62 548.57 1448.57 512.81 1483.27 512.03 1483.36 548.32 1448.62 548.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "1525.8 549.1 1525.8 512.78 1552.92 513.65 1552.92 549.44 1525.8 549.1",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "1567.73 549.29 1567.73 512.34 1594.72 513.23 1594.72 549.65 1567.73 549.29",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "1609.59 549.45 1609.59 511.86 1637.12 512.75 1637.12 549.82 1609.59 549.45",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "673.4 492.07 673.4 455.33 708.09 454.8 708.09 491.7 673.4 492.07",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "742.6 491.9 742.4 456.18 779.52 456.18 779.48 491.32 742.6 491.9",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "816.49 492.75 816.49 456.39 853.47 456.17 853.47 492.43 816.49 492.75",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10D",
        "coords": "890.64 491.51 890.64 455.91 927.49 455.76 927.49 491.6 890.64 491.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10D",
        "coords": "965.08 492.46 965.08 456.41 1002.63 456.62 1002.63 492.06 965.08 492.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "1024.21 491.32 1024.21 455.83 1041.26 456.08 1041.26 491.63 1024.21 491.32",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "1061.75 492.26 1061.75 455.84 1098.4 456.44 1098.4 492.69 1061.75 492.26",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "1134.25 491.59 1134.25 456.69 1171.12 456.25 1171.12 491.26 1134.25 491.59",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "1207.22 492.57 1207.22 456.5 1244.02 456.23 1244.02 491.69 1207.22 492.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "1264.46 492.59 1264.46 456.06 1281.71 456.01 1281.71 492.09 1264.46 492.59",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "1303.21 491.57 1303.21 456.52 1339.46 456.06 1339.46 492.11 1303.21 491.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "1377.27 492.22 1377.27 455.91 1415.33 456.52 1415.33 491.86 1377.27 492.22",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "1448.57 491.58 1448.57 455.97 1483.96 455.67 1483.96 491.64 1448.57 491.58",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "1525.16 492.89 1525.16 455.47 1553.56 455.54 1553.56 492.46 1525.16 492.89",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "1567.73 492.75 1567.73 455.47 1594.72 455.55 1594.72 492.3 1567.73 492.75",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "1609.59 492.55 1609.59 455.98 1637.76 456.06 1637.76 492.09 1609.59 492.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "674.07 435.4 673.28 399.24 709.12 399.24 708.73 435.06 674.07 435.4",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "742.24 435.03 741.16 399.08 779.53 398.67 779.53 434.41 742.24 435.03",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "816.49 434.39 816.49 400.01 853.47 399.8 853.47 434.77 816.49 434.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "890.64 434.8 890.64 399.4 927.49 398.97 927.49 435.18 890.64 434.8",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "964.87 435.5 964.87 399.47 1002.63 399.7 1002.63 435.05 964.87 435.5",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "1024.45 435.99 1024.21 398.95 1041.26 398.88 1041.26 435.71 1024.45 435.99",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "1061.11 435.16 1061.11 399.63 1097.76 398.92 1097.76 435.45 1061.11 435.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "1133.61 435.2 1133.61 399.13 1171.13 399.08 1171.34 435.01 1133.61 435.2",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "1206.58 434.9 1206.58 400.11 1243.38 399.8 1243.38 434.26 1206.58 434.9",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1263.82 435.63 1263.82 399.69 1282.35 399.37 1282.35 435.1 1263.82 435.63",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1302.57 435.48 1302.57 399.53 1340.1 399.49 1340.1 434.83 1302.57 435.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1378.33 434.98 1378.33 399.65 1414.69 399.32 1414.69 434.49 1378.33 434.98",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1449.21 435.81 1449.21 399.86 1483.32 399.51 1483.32 435.23 1449.21 435.81",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "673.4 378.72 673.4 342.41 709.47 342.2 709.37 378.24 673.4 378.72",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "742.44 378.39 742.44 343.17 779.53 342.77 779.53 378.03 742.44 378.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "816.49 378.08 816.49 342.53 853.47 342.55 853.47 377.88 816.49 378.08",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "890.64 378.93 890.64 342.92 928.77 342.81 928.77 379.28 890.64 378.93",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "964.87 378.85 964.87 342.75 1002.63 342.71 1002.63 378.22 964.87 378.85",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "1024.21 379.13 1024.21 342.73 1041.26 342.36 1041.26 379.32 1024.21 379.13",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "1061.11 379.49 1061.11 342.82 1097.76 342.36 1097.76 379.68 1061.11 379.49",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "1133.61 378.6 1133.61 342.63 1171.13 343.47 1171.13 379.3 1133.61 378.6",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "1207.22 379.46 1207.22 342.45 1244.02 342.54 1244.02 379.33 1207.22 379.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1264 379.22 1264 342.94 1282.73 342.96 1282.53 378.63 1264 379.22",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1302.57 378.77 1302.57 342.77 1340.1 342.56 1340.1 378.6 1302.57 378.77",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1377.69 343.19 1415.33 342.73 1415.33 378.03 1377.69 378.28 1377.69 343.19",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1449.21 378.51 1449.21 342.77 1483.32 342.72 1483.32 378.44 1449.21 378.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "673.4 322.56 673.4 286.91 708.19 286.61 708.19 322.17 673.4 322.56",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "742.44 322.97 742.44 286.27 779.53 285.87 779.53 322.54 742.44 322.97",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "817.85 322.48 817.85 287.02 853.62 287.33 853.62 322.76 817.85 322.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "890.64 322.66 890.64 286.71 928.47 286.96 928.77 322.74 890.64 322.66",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "964.87 321.84 964.87 286.29 1001.52 286.59 1001.52 321.92 964.87 321.84",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "1023.57 321.61 1023.57 286.45 1041.9 286.34 1041.9 321.76 1023.57 321.61",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "1061.29 321.55 1061.29 286.55 1097.98 287.29 1097.98 321.97 1061.29 321.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "1133.61 322.21 1134.89 287.01 1171.13 287.19 1171.84 322.46 1133.61 322.21",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "1206.58 322.09 1206.58 286.29 1244.52 286.42 1244.52 322.4 1206.58 322.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1264.46 322.26 1264.46 286.13 1281.9 286.05 1281.92 322.46 1264.46 322.26",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1303.22 287.06 1338.66 287.27 1338.66 321.42 1303.13 320.94 1303.22 287.06",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1378.33 321.83 1378.33 286.41 1414.69 286.31 1414.69 322.64 1378.33 321.83",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1449.21 322.45 1449.21 286.17 1483.32 286.31 1483.32 322.56 1449.21 322.45",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "673.4 265.61 673.4 229.96 708.19 229.44 708.19 266.03 673.4 265.61",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "742.44 265.01 742.44 230.61 779.53 230.09 779.53 265.41 742.44 265.01",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "816.64 265.52 817.28 231.61 853.35 231.18 853.35 266.05 816.64 265.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "891.28 266.22 891.28 229.92 928.13 229.48 928.13 266.6 891.28 266.22",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "964.86 265.65 964.87 229.93 1002.63 229.51 1002.8 266.02 964.86 265.65",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15F",
        "coords": "1024.21 266.27 1024.21 229.34 1041.39 229.13 1041.26 266.44 1024.21 266.27",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15F",
        "coords": "1061.76 230.33 1097.6 230.33 1097.76 266.24 1061.12 266.16 1061.76 230.33",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15F",
        "coords": "1134.47 265.92 1134.04 229.54 1171.32 229.25 1171.32 265.85 1134.47 265.92",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15F",
        "coords": "1207.22 266.15 1207.22 230.02 1244.5 229.68 1244.36 266.47 1207.22 266.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "1264.14 265.37 1264.14 229.5 1281.51 229.35 1281.51 265.51 1264.14 265.37",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "1303.04 266.8 1303.04 230.33 1340.96 230.09 1340.96 266.83 1303.04 266.8",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "1377.92 265.52 1377.92 229.69 1415.33 229.43 1415.33 266.16 1377.92 265.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "1448.57 265.36 1448.57 229.2 1483.96 228.9 1483.96 265.65 1448.57 265.36",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "673.43 208.71 673.43 172.58 708.19 172.97 708.19 208.25 673.43 208.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "741.6 209.41 741.6 173.07 780.17 173.07 780.17 209.39 741.6 209.41",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "816.64 208.58 817.3 173.39 853.76 173.44 853.76 208.68 816.64 208.58",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "890.64 208.21 890.64 173.68 928.77 173.7 927.79 208.26 890.64 208.21",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "964.86 209.62 964.86 173.56 1002.63 173.3 1002.63 210.29 964.86 209.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1023.83 209.82 1023.83 173.75 1041.26 173.67 1041.26 209.87 1023.83 209.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1060.9 208.14 1060.7 174.16 1097.99 173.76 1097.99 208 1060.9 208.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1134.25 208.48 1133.83 173.39 1171.12 173.86 1171.03 209.22 1134.25 208.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1207.19 208.94 1206.16 173.42 1244.02 173.33 1244.02 208.67 1207.19 208.94",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1264.14 209.54 1264.45 173.71 1282 173.33 1282.03 209.2 1264.14 209.54",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1302.79 208.96 1302.7 172.75 1340.16 173.31 1340.16 209.48 1302.79 208.96",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1378 172.95 1415.42 173.48 1415.42 209.95 1378 209.22 1378 172.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1448.05 173.74 1482.68 174.02 1482.97 209.7 1448.05 209.13 1448.05 173.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "766.08 109.68 797.87 110.1 797.87 135.3 766.08 135.44 766.08 109.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "820.25 135.27 820.25 109.81 834.77 110.11 834.77 134.9 820.25 135.27",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "855.46 134.88 855.46 109.69 886.63 109.12 886.63 134.68 855.46 134.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "903.08 135 903.08 109.37 920.32 108.95 920.32 134.88 903.08 135",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "939.04 134.96 939.04 109.19 970.24 110.01 970.24 135 939.04 134.96",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "990.04 134.31 990.04 109.7 1021.44 110.48 1021.44 134.36 990.04 134.31",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1083.42 135.25 1083.52 110.05 1098.24 110.05 1098.24 135 1083.42 135.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "1120.14 134.77 1120.14 109.93 1152 110.25 1152 135 1120.14 134.77",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1177.6 135 1177.6 110.05 1208.96 110.25 1208.96 135 1177.6 135",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1229.44 135 1229.47 109.75 1260.8 110.05 1260.8 135 1229.44 135",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1281.92 135 1281.75 109.79 1313.28 109.41 1312.64 135 1281.92 135",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1332.48 135 1332.95 120.66 1356.8 120.92 1356.8 135 1332.48 135",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      }

    ],
    2: [
      {
        "type": "polygon",
        "id": "2E",
        "coords": "415.24 990.39 428.37 1001.9 428.37 1035.17 414.67 1021.67 415.24 990.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "440.67 1048.49 440.67 1015.65 455.2 1028.87 455.2 1063.61 440.67 1048.49",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2E",
        "coords": "470.15 1083.95 470.15 1044.23 485.84 1058.88 485.84 1099.14 470.15 1083.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "415.41 973.75 415.43 940.18 428.37 951.18 427.18 984.49 415.41 973.75",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "440.67 997.4 440.67 963.61 454.66 976.68 454.66 1011.13 440.67 997.4",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3E",
        "coords": "470.37 994.07 484.65 1004.46 484.65 1038.7 469.17 1023.33 470.37 994.07",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3F",
        "coords": "502.06 1055.02 502.06 1019.43 518.7 1034.7 518.7 1071.27 502.06 1055.02",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3F",
        "coords": "536.63 1090.49 536.63 1050.77 554.88 1067.81 554.88 1109.97 536.63 1090.49",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "415.37 921.36 414.79 887.82 427.92 898.85 426.91 931.1 415.37 921.36",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "440.67 942.65 440.67 909.64 455.3 921.49 455.3 955.09 440.67 942.65",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4E",
        "coords": "469.83 936.31 483.37 947.22 483.37 980.82 468.61 967.79 469.83 936.31",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4F",
        "coords": "502.23 997.64 502.23 961.34 518.53 974.93 518.53 1012.17 502.23 997.64",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4F",
        "coords": "536.31 1027.11 536.31 990.59 554.55 1005.44 554.55 1043.46 536.31 1027.11",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "566.38 1054 566.38 1016.08 575.72 1022.8 575.72 1061.07 566.38 1054",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "586.09 1074.65 586.09 1034.05 603.19 1047.92 603.19 1088.37 586.09 1074.65",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4H",
        "coords": "626.11 1108.6 626.11 1065.95 648.21 1083.47 648.21 1127 626.11 1108.6",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "415.4 869.62 414.71 836.63 427.82 846.12 427.92 880.06 415.4 869.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "440.67 890.13 440.67 856.41 454.67 867.62 454.67 902.08 440.67 890.13",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5E",
        "coords": "470.33 879.52 485.29 890.88 485.29 926.51 469.77 914.12 470.33 879.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5F",
        "coords": "501.89 940.48 501.89 904.74 519.11 916.88 518.53 953.82 501.89 940.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5F",
        "coords": "536.31 969.42 536.31 930.98 553.91 944.72 553.91 982.74 536.31 969.42",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "566.07 992.29 566.07 953.9 575.42 961.29 575.42 1000.06 566.07 992.29",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "585.94 1007.91 586.31 970.1 605.15 983.88 605.15 1025.11 585.94 1007.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "626.35 1042.83 626.35 1001.46 648.35 1017.54 648.35 1061.07 626.35 1042.83",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5H",
        "coords": "671.09 1079.32 671.09 1035.76 694.62 1053.78 694.62 1098.07 671.09 1079.32",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5K",
        "coords": "708.58 1110.57 708.58 1065.28 720.09 1074.18 720.09 1119.26 708.58 1110.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "415.35 818.25 415.35 786.02 427.79 794.83 427.79 827.61 415.35 818.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "440.58 837.83 440.58 803.33 454.97 813.97 454.97 848.91 440.58 837.83",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6E",
        "coords": "470.49 859.71 470.49 825.03 486.36 835.82 485.38 870.67 470.49 859.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6F",
        "coords": "501.89 883.52 501.89 847.84 518.53 859.22 518.53 896.05 501.89 883.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6F",
        "coords": "536.31 909.69 536.31 872.17 554.33 884.67 554.33 923.31 536.31 909.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "565.65 931.3 566.11 892.77 575 898.95 575 938.36 565.65 931.3",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "585.94 946.73 585.94 907.13 605.59 921.39 605.24 960.91 585.94 946.73",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "626.35 978.15 626.57 936.58 648.35 950.62 648.35 994.29 626.35 978.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6H",
        "coords": "671.09 1011.83 671.09 968.47 695.12 984.89 694.62 1028.61 671.09 1011.83",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "708.21 1039.74 708.5 994.29 720.57 1002.84 720.09 1048.13 708.21 1039.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "735.27 1059.78 735.78 1013.88 762.79 1032.79 762.79 1080.24 735.27 1059.78",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6K",
        "coords": "792.44 1107.33 791.74 1053.55 822.82 1075.44 822.82 1121.65 792.44 1107.33",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "414.71 766.62 415.35 734.81 428.35 743.3 427.71 775.06 414.71 766.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "440.47 785.18 440.47 751.19 454.75 760.12 455.42 795.39 440.47 785.18",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7E",
        "coords": "470.3 805.3 469.74 770.22 485.38 779.78 485.38 815.39 470.3 805.3",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7F",
        "coords": "502.14 826.89 502.14 791.16 518.53 800.93 518.53 838.24 502.14 826.89",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7F",
        "coords": "536.31 851.16 536.78 813.74 554 824.35 554.34 862.96 536.31 851.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "565.61 870.7 565.61 832.47 575 837.43 575 877.89 565.61 870.7",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "585.9 884.75 585.9 845.2 605.59 858.09 605.24 897.97 585.9 884.75",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "626.57 913.45 626.57 871.29 648.12 885.29 648.35 926.7 626.57 913.45",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7H",
        "coords": "670.59 944.07 670.53 899.43 695.12 914.86 694.13 958.92 670.59 944.07",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "708.21 968.98 708.5 924.54 720.57 931.66 720.57 976.7 708.21 968.98",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "735.75 987.73 735.75 942.04 762.82 958.9 762.77 1005.63 735.75 987.73",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "791.89 1027.17 791.83 977.79 822.51 997.69 822.28 1046.69 791.89 1027.17",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7K",
        "coords": "851.47 1067.58 851.47 1016.5 883.14 1035.96 883.14 1086.99 851.47 1067.58",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7M",
        "coords": "920.77 1109.96 920.77 1061.73 947.78 1078.86 947.78 1123.17 920.77 1109.96",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "414.8 716.48 414.8 683.7 428.06 689.82 428.06 723.7 414.8 716.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "441.38 732.73 440.66 699.04 454.88 707.41 454.88 740.55 441.38 732.73",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8C",
        "coords": "469.75 750.48 469.75 715.36 485.37 724.59 485.37 759.68 469.75 750.48",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8D",
        "coords": "501.62 770.95 502.4 734.38 518.53 744.05 517.89 780.2 501.62 770.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8D",
        "coords": "536.7 791.53 536.13 754.34 555.7 764.71 555.7 803.46 536.7 791.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "565.52 810.05 565.7 771.08 575 776.35 574.86 815.46 565.52 810.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "585.9 822.77 585.9 782.94 606.11 794.48 605.68 835.38 585.9 822.77",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "625.97 847.33 627.03 806.82 648.49 819.11 648.49 861.09 625.97 847.33",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8F",
        "coords": "670.59 875.1 670.59 832.3 693.95 845.12 694.13 889.76 670.59 875.1",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "708.48 899.64 708.48 854.33 720.54 860.49 720.54 906.03 708.48 899.64",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "736.1 916.12 736.1 870.57 762.64 885.8 762.64 932.12 736.1 916.12",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "791.83 950.74 792.12 902.36 822.1 919.52 822.1 969.65 791.83 950.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8H",
        "coords": "851.54 987.13 851.54 937.4 883.37 955.43 882.49 1006.88 851.54 987.13",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "927.41 1032.71 927.41 981.29 949.95 994.41 949.95 1045.75 927.41 1032.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "966.02 1056.91 966.02 1003.84 994.93 1020.37 994.93 1073.39 966.02 1056.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "1011.99 1083.24 1011.99 1031.65 1042.95 1049.06 1042.95 1100.65 1011.99 1083.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "414.81 665.6 414.81 632.42 427.42 638.44 428.06 672.43 414.81 665.6",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "440.47 679.82 440.47 645.49 455.42 652.82 455.42 687.24 440.47 679.82",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9C",
        "coords": "469.92 695.51 470.57 660.58 485.08 668.45 485.08 704.3 469.92 695.51",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "9D",
        "coords": "M501.5,713.12v-35.97s17.03,7.95,17.03,8.5v36.71l-17.03-9.23Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9D",
        "coords": "536.14 732.24 536.14 694.45 554.98 704.56 554.98 742.79 536.14 732.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "565.7 747.85 565.7 709.87 574.68 714.05 574.68 753.17 565.7 747.85",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "585.9 759.47 585.9 720.06 605.05 729.78 606.11 770.95 585.9 759.47",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "626.57 782.45 626.57 741.12 647.82 752 649.51 795.12 626.57 782.45",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9F",
        "coords": "670.6 807.08 670.6 763.54 694.92 776.41 694.92 820.72 670.6 807.08",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "708.51 828.18 708.51 783.43 720.58 789.47 720.58 834.82 708.51 828.18",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "735.29 843.73 735.29 797.44 762.64 810.75 762.64 858.54 735.29 843.73",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "792.31 874.66 792.12 826.43 823.2 841.97 822.39 891.08 792.31 874.66",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9H",
        "coords": "851.54 907.39 852.01 856.92 883.37 873.45 882.71 924.7 851.54 907.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "926.13 948.06 926.13 896.63 949.95 908.16 949.95 959.5 926.13 948.06",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "969.16 970.31 969.16 919.16 994.93 931.46 994.93 984.48 969.16 970.31",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "1015.2 996.19 1015.2 942.05 1042.95 955.95 1042.95 1011.38 1015.2 996.19",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "415.61 614.25 415.61 581.56 427.58 586.9 427.58 620.15 415.61 614.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "440.56 627.14 440.65 593.24 455.25 599.45 454.73 633.25 440.56 627.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10C",
        "coords": "470.43 641.16 470.43 606.54 485.31 612.9 485.31 647.87 470.43 641.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10D",
        "coords": "501.79 657.02 501.79 620.28 517.89 627.23 517.89 664.63 501.79 657.02",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10D",
        "coords": "536.13 673.39 536.13 635.51 554.57 644.06 554.57 682.43 536.13 673.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "566.37 688.63 566.37 649.55 575.06 653.36 575.06 692.75 566.37 688.63",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "585.85 698.1 585.85 658.38 605.67 666.92 605.67 707 585.85 698.1",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "626.5 717.17 626.5 676.63 648.29 686.5 648.29 727.81 626.5 717.17",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10F",
        "coords": "670.87 739.54 670.87 696.06 694.25 706.35 694.25 750.94 670.87 739.54",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "708.2 757.7 708.2 713.25 720.59 718.29 720.59 763.72 708.2 757.7",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "735.29 771.04 735.29 725.22 762.64 737.06 762.64 783.83 735.29 771.04",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "792.31 798.78 792.31 750.8 823.02 764.54 823.02 813.91 792.31 798.78",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10H",
        "coords": "852.01 828.95 852.01 777.41 882.71 790.81 882.71 843.79 852.01 828.95",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "926.13 862.49 926.13 810.6 949.95 821.67 949.95 873.52 926.13 862.49",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "969.16 883.86 969.16 830.05 994.93 841.16 994.93 894.94 969.16 883.86",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "1016.37 905.57 1016.37 851.55 1042.95 863.3 1042.95 918.56 1016.37 905.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "415.42 563.07 414.97 530.74 427.71 535.14 427.23 567.7 415.42 563.07",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "440.57 574.14 440.18 540.44 455.38 545.71 455.38 580.1 440.57 574.14",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "470.19 585.91 470.19 551.38 485.35 557.04 485.35 592.65 470.19 585.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "501.49 599.74 501.49 563.29 517.89 569.19 517.89 607 501.49 599.74",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11C",
        "coords": "536.13 614.28 536.13 576.31 554.09 584.02 554.09 621.95 536.13 614.28",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "566.1 626.87 565.95 588.08 575.59 591.51 575.59 630.65 566.1 626.87",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "585.82 634.76 585.82 596 605.7 603.03 605.7 643.53 585.82 634.76",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "626.27 652.33 626.27 611.18 647.66 618.91 648.22 661.21 626.27 652.33",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11F",
        "coords": "670.53 670.91 670.53 628.22 694.25 637.61 694.25 680.54 670.53 670.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "708.2 686.87 708.2 642.64 720.59 646.95 720.59 691.88 708.2 686.87",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "734.94 698.34 734.94 652.66 762.64 663.59 762.64 709.72 734.94 698.34",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "792.31 723.11 792.31 675.04 822.57 686.26 822.57 735.61 792.31 723.11",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "851.81 748.22 851.81 698.37 882.91 709.34 882.91 760.94 851.81 748.22",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "414.97 511.63 414.97 478.91 427.88 483.09 427.4 516.4 414.97 511.63",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "440.81 521.81 440.81 487.36 454.93 491.76 454.93 526.67 440.81 521.81",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "470.24 531.56 470.24 497.09 485.59 501.65 485.59 537 470.24 531.56",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "501.93 542.75 501.93 506.93 518.53 512.42 518.53 549.23 501.93 542.75",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12C",
        "coords": "536.17 555.37 536.17 517.86 554.88 523.65 554.88 562.29 536.17 555.37",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "565.48 565.99 565.48 527.51 575.17 530.02 575.17 569.38 565.48 565.99",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "586.11 573.57 586.11 533.63 606.1 539.79 606.1 580.97 586.11 573.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "626.41 587.64 626.41 546.66 647.92 552.64 647.92 595.93 626.41 587.64",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12F",
        "coords": "670.53 603.52 670.53 560.74 694.3 567.98 694.3 611.74 670.53 603.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "708.35 617.41 708.35 572.74 721.25 576.64 720.29 621.85 708.35 617.41",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "735.87 627.05 735.87 581.61 762.53 589.33 762.53 636.76 735.87 627.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "791.99 599.72 822.66 608.3 822.66 658.64 791.99 647.09 791.99 599.72",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "851.63 668.38 851.63 618.28 882.91 627.6 882.91 680.39 851.63 668.38",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "414.97 460.49 414.97 427.46 427.75 431 427.75 464.32 414.97 460.49",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "440.33 468.12 440.33 434.71 455.12 438.26 455.12 472.73 440.33 468.12",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "468.39 476.04 468.39 442.91 483.38 446.51 483.38 479.97 468.39 476.04",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "502.08 486.16 502.08 449.76 518.34 454.06 518.38 490.76 502.08 486.16",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14C",
        "coords": "536.17 496.23 536.17 458.76 554.94 463.35 554.94 501.9 536.17 496.23",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "565.48 504.64 565.48 466.12 575.17 468.52 575.17 507.58 565.48 504.64",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "585.2 510.67 585.2 473.11 603.79 477.2 603.79 516.73 585.2 510.67",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "626.41 522.45 626.49 481.26 647.92 486.9 647.92 529.3 626.41 522.45",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14F",
        "coords": "670.53 535.46 670.53 492.52 693.98 498.25 693.98 541.94 670.53 535.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "708.58 546.91 708.58 502.1 720.39 505.27 720.39 550.09 708.58 546.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "736.84 511.28 760.21 517.58 760.21 561.89 735.87 554.47 736.84 511.28",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "791.99 571.24 791.99 523.62 823.07 530.89 823.07 580.37 791.99 571.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "851.6 588.69 851.6 538.46 882.29 545.95 882.29 597.48 851.6 588.69",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "414.89 409.39 414.89 376.86 428.26 379.15 428.26 412.7 414.89 409.39",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "440.49 415.13 440.49 381.79 454.88 384.72 454.88 419.45 440.49 415.13",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "15C",
        "coords": "M483.98,425.38v-19.35s-4.2-8.85-4.2-15.34l-10.26-1.97-.49,33.44,14.95,3.22Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "501.59 428.72 501.59 392.94 518.88 396 518.88 432.93 501.59 428.72",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15C",
        "coords": "536.09 437.05 536.7 399.52 554.61 403.13 554.08 441.35 536.09 437.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "15F",
        "coords": "M565.84,443.57v-39.08l5.55,1.12c.04,3.41.37,7.5,1.33,12.04.64,3.03,1.45,5.75,2.29,8.11.07,6.79.14,13.59.21,20.38l-9.37-2.58Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "15F",
        "coords": "M598.85,413.51l-13.17-2.62v37.43s17.61,3.66,17.61,3.66v-25.83s-2.02-.4-4.44-12.65Z",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15F",
        "coords": "626.24 457.6 626.66 416.29 647.52 420.54 648.09 462.46 626.24 457.6",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15F",
        "coords": "670.53 467.57 670.53 424.88 695.26 429.46 694.3 473.28 670.53 467.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "708.55 476.71 708.55 431.92 719.58 433.96 719.58 479.36 708.55 476.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "734.59 482.25 734.59 439.01 757.31 443.41 760.09 461.15 760.82 488.04 734.59 482.25",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "792.23 495.15 792.23 447.23 822.79 452.97 821.95 502.04 792.23 495.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "851.91 508.6 851.91 458.23 882.72 463.65 882.72 515.45 851.91 508.6",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "414.97 358.91 414.97 325.55 427.36 327.15 427.36 360.09 414.97 358.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "440.49 362.5 440.49 328.8 455.52 330.61 455.52 364.72 440.49 362.5",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "470.1 366.45 470.1 333.03 485.34 334.4 485.54 368.9 470.1 366.45",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "502.25 372.32 501.91 336.2 518.88 338.6 518.28 374.86 502.25 372.32",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "536.09 377.89 536.09 340.69 554.43 343 554.61 381.33 536.09 377.89",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "566.04 382.65 566.04 344.53 575 345.58 575 384.11 566.04 382.65",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "585.61 385.02 585.61 346.52 605.65 349.05 605.25 388.05 585.61 385.02",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "626.66 392.26 626.66 351.66 648.64 354.53 648.14 396.16 626.66 392.26",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "670.63 399.97 670.82 356.97 694.3 359.78 694.3 403.36 670.63 399.97",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "708.11 405.54 708.54 361.76 720.39 362.85 720.39 407.43 708.11 405.54",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "735.58 409.71 736.1 364.79 762.53 368.3 762.53 414.34 735.58 409.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "792.23 371.62 822.79 375 822.79 423.65 792.23 419.32 792.23 371.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "851.57 378.72 883.08 382.38 882.38 434.08 851.57 429.09 851.57 378.72",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "523.36 259.43 534.41 259.43 534.41 298.56 523.36 297.92 523.36 259.43",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "547.85 299.86 547.85 260.15 552.09 260.15 552.86 299.86 547.85 299.86",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "564.6 300.49 564.6 260.72 576.42 260.72 576.3 301.22 564.6 300.49",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "587.42 301.22 587.86 261.35 593.97 261.64 593.97 300.78 587.42 301.22",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "606.62 300.05 607.35 261.64 619.81 261.64 619.67 299.32 606.62 300.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1702",
        "coords": "633.74 299.32 633.74 262.38 648.09 262.96 647.37 298.88 633.74 299.32",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "688.71 306.53 688.71 263.94 694.72 263.84 694.72 306.53 688.71 306.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1603",
        "coords": "712.06 306.53 712.06 265.17 728.87 265.17 728.87 305.67 712.06 306.53",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "750.76 314.46 750.76 266.77 768.55 266.77 768.55 315.44 750.76 314.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "787.31 317.79 787.31 267.75 806.85 268.63 806.85 318.57 787.31 317.79",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "826.5 320.91 826.5 268.63 848.48 268.63 849.26 322.48 826.5 320.91",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "933.89 711.71 958.23 704.08 958.23 755.19 946.06 759.88 933.89 754.22 933.89 711.71",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "978.94 698.03 978.94 747.96 1001.61 739.46 1001.61 690.6 978.94 698.03",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1025.75 683.47 1041.58 678.77 1040.8 736.53 1025.36 741.81 1025.75 683.47",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1068.07 670.57 1068.07 716.98 1085.75 709.66 1086.63 664.7 1068.07 670.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "1126.21 652.88 1126.21 695.97 1141.55 690.31 1141.7 648.44 1126.21 652.88",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "1169.43 639.76 1169.43 682.12 1179.84 677.65 1180.28 636.09 1169.43 639.76",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "1199.89 629.72 1199.11 681.41 1208.98 677.41 1209.76 626.88 1199.89 629.72",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "933.89 629.32 958.23 622.97 958.23 673.44 933.89 680.78 933.89 629.32",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "978.94 618.19 978.94 668.13 1001.61 661.55 1001.61 612.69 978.94 618.19",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1023.41 606.52 1044.35 601.19 1044.21 650 1023.02 655.27 1023.41 606.52",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "1068.07 595.21 1068.07 642.27 1088.31 636.22 1088.55 589.99 1068.07 595.21",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "1126.11 580.66 1126.21 626 1144.53 620.53 1144.68 575.87 1126.11 580.66",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "1169.43 570.08 1169.43 613.3 1182.01 609.29 1182.5 566.42 1169.43 570.08",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "1199.31 562.29 1199.11 604.51 1211.43 601.08 1211.43 559.46 1199.31 562.29",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "933.89 547.67 958.23 542.61 958.23 593.08 933.89 599.13 933.89 547.67",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "978.94 537.83 978.94 587.76 1001.61 582.46 1001.61 533.6 978.94 537.83",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1023.41 529.03 1044.35 524.98 1044.21 572.51 1023.02 577.78 1023.41 529.03",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1068.07 520.46 1068.07 567.51 1088.31 562.1 1088.55 515.87 1068.07 520.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1126.11 508.06 1126.21 554.04 1144.53 549.21 1144.68 504.55 1126.11 508.06",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1169.43 500.41 1169.43 543.63 1182.01 540.26 1182.5 497.38 1169.43 500.41",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "1199.31 493.98 1199.11 536.2 1211.43 533.42 1211.43 491.79 1199.31 493.98",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "933.89 465.24 958.23 461.45 958.23 511.92 933.89 516.7 933.89 465.24",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "978.94 458.57 978.94 508.51 1001.61 503.84 1001.61 454.98 978.94 458.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "1023.41 451.55 1044.35 448.78 1044.21 496.3 1023.02 500.3 1023.41 451.55",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "1068.07 444.73 1068.07 491.79 1088.31 488.3 1088.55 442.07 1068.07 444.73",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "1126.11 436.62 1126.21 481.96 1144.53 478.41 1144.68 433.75 1126.11 436.62",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "1169.43 430.15 1169.43 473.37 1182.01 470.64 1182.5 427.76 1169.43 430.15",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "1199.31 425.68 1199.11 467.89 1211.43 465.11 1211.43 423.48 1199.31 425.68",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "933.89 383.43 958.23 380.93 958.23 431.4 933.89 434.89 933.89 383.43",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "978.94 378.05 978.94 427.98 1001.61 425.23 1001.61 376.37 978.94 378.05",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1023.41 374.35 1044.35 372.21 1044.21 419.74 1023.02 422.46 1023.41 374.35",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1068.07 370.64 1068.07 417.06 1088.31 414.21 1088.55 367.98 1068.07 370.64",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1126.11 364.3 1126.21 409.65 1144.53 407.37 1144.68 362.71 1126.11 364.3",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1169.43 360.57 1169.43 403.78 1182.01 401.7 1182.5 358.82 1169.43 360.57",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "1199.31 357.46 1199.11 399.68 1211.43 398.18 1211.43 356.55 1199.31 357.46",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "903.6 268.09 932.18 267.36 932.18 333.76 903.16 335.23 903.6 268.09",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "968.83 267.36 968.83 332.59 1005.18 335.23 1005.18 267.8 968.83 267.36",
        "color": "#fff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      }

    ],
    3:[
      {
        "type": "polygon",
        "id": "11H",
        "coords": "540.8 384.44 549.28 387.14 549.28 418.23 540.8 414.51 540.8 384.44",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "558.05 389.75 558.05 420.33 566.92 423.27 566.92 392.4 558.05 389.75",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "576.36 394.75 584.63 397.7 584.28 433.22 576.19 434.28 576.36 394.75",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "596.62 401.14 596.62 430.27 606.14 428.9 606.6 404.16 596.62 401.14",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "626.47 410.57 626.47 443.5 637 447.37 637.09 413.33 626.47 410.57",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "652.32 417.83 652.32 452.79 660.83 455.94 661.15 420.61 652.32 417.83",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "672.45 424.28 671.86 457.86 681.43 456.32 682.05 426.92 672.45 424.28",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "540.8 336.22 549.28 338.79 549.28 369.48 540.8 366.55 540.8 336.22",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "558.05 340.67 558.05 371.89 566.92 374.5 566.92 343 558.05 340.67",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "575.87 344.85 585.13 347.76 585.06 379.9 575.7 376.5 575.87 344.85",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11H",
        "coords": "596.44 349.84 596.44 382 606.01 385.61 606.13 353.08 596.44 349.84",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "626.8 357.93 626.86 390.77 637.32 394.09 637.42 360.14 626.8 357.93",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "652.66 363.81 652.66 398.22 660.8 401.14 661.15 366.4 652.66 363.81",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "672.01 368.77 671.86 403.61 681.45 406.64 681.45 371.33 672.01 368.77",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "540.8 289.07 549.28 290.88 549.28 320.94 540.8 318.76 540.8 289.07",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "558.1 292.56 558.1 322.5 566.97 324.35 566.97 294.13 558.1 292.56",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "576.23 295.42 585.49 297.61 585.42 329.51 576.06 327.1 576.23 295.42",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "596.39 299.59 596.39 331.74 606.6 334.12 606.72 301.57 596.39 299.59",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "626.41 304.95 626.47 338.29 637.57 340.98 637.67 307.66 626.41 304.95",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "652.21 310.08 652.21 344.49 660.99 346.6 661.34 311.8 652.21 310.08",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "672.33 313.7 672.18 348.56 681.13 350.71 681.13 315.41 672.33 313.7",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "540.8 241.39 549.28 242.42 549.28 272.48 540.8 271.08 540.8 241.39",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "557.61 243.58 557.61 274.16 566.48 275.5 566.48 244.63 557.61 243.58",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "576.23 246.01 585.49 247.49 585.42 279.4 576.06 277.72 576.23 246.01",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "596.39 248.32 596.39 281.12 606.6 283.2 606.72 249.98 596.39 248.32",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "626.76 252.39 626.82 285.85 637.28 287.79 637.37 253.81 626.76 252.39",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "652.05 255.95 652.05 289.72 660.83 291 661.18 256.79 652.05 255.95",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "671.96 258.53 671.81 293.42 681.4 294.73 681.4 259.43 671.96 258.53",
        "color": "#0bff00",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "540.8 193.91 549.28 194.18 549.28 224.24 540.8 223.6 540.8 193.91",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "558.1 195.1 558.1 225.68 566.97 226.67 566.97 195.81 558.1 195.1",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "576.23 196.57 585.49 196.89 585.42 228.81 576.06 227.87 576.23 196.57",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "596.39 197.74 596.39 230.07 606.6 231.56 606.72 198.32 596.39 197.74",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "626.76 199.86 626.82 233.32 637.28 234.51 637.37 200.51 626.76 199.86",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "652.37 201.66 652.37 236.07 660.51 237.18 660.86 202.27 652.37 201.66",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "672.28 203.14 672.13 238.05 681.08 239.09 681.08 203.79 672.28 203.14",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2J",
        "coords": "741.36 951.39 768.36 937.31 768.36 980.14 741.36 995.7 741.36 951.39",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2J",
        "coords": "800.35 957.26 800.35 920.29 828.24 906.2 829.41 941.23 800.35 957.26",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2H",
        "coords": "857.2 923.12 857.2 890.65 883.42 876.76 883.42 909.04 857.2 923.12",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2H",
        "coords": "909.44 904.15 909.44 864.05 930.38 853.1 930.96 891.83 909.44 904.15",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2H",
        "coords": "946.81 877.1 946.81 843.9 964.42 835.29 964.42 867.43 946.81 877.1",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2J",
        "coords": "741.58 894.03 772.4 879.94 772.4 915.19 741.58 930.74 741.58 894.03",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2J",
        "coords": "800.38 901.1 800.38 865.21 828.26 852.4 828.16 886.35 800.38 901.1",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2H",
        "coords": "857.42 872.4 857.42 838.64 883.64 826.04 883.64 858.31 857.42 872.4",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2H",
        "coords": "909.66 845.5 910.25 812.6 933.97 801.65 933.97 833.18 909.66 845.5",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2H",
        "coords": "947.55 826.04 947.55 794.71 964.42 786.52 964.42 816.78 947.55 826.04",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4M",
        "coords": "621.02 911.58 590.7 925.08 590.7 880.48 621.02 866.59 621.02 911.58",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4M",
        "coords": "652.72 896.91 652.72 852.4 681.29 840.77 681.29 883.81 652.72 896.91",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4L",
        "coords": "741.36 835.69 772.18 822.24 772.18 857.48 741.36 872.4 741.36 835.69",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4L",
        "coords": "800.16 844.44 800.16 809.6 828.04 797.44 827.94 831.39 800.16 844.44",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4L",
        "coords": "857.2 818.75 857.2 785 883.42 772.99 883.42 805.27 857.2 818.75",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4J",
        "coords": "909.44 794.05 909.44 761.43 933.75 750.2 933.75 781.73 909.44 794.05",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4J",
        "coords": "947.55 775.03 947.55 743.7 964.42 736.37 964.42 766.64 947.55 775.03",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5M",
        "coords": "621.02 840.31 590.7 853.17 590.7 818.62 621.02 806.15 621.02 840.31",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5M",
        "coords": "652.72 828.03 652.72 793.92 681.29 782.87 681.29 816.2 652.72 828.03",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5L",
        "coords": "741.36 778.18 771.88 766.17 771.54 801.69 741.36 814.68 741.36 778.18",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5L",
        "coords": "800.16 790.03 800.16 755.2 828.04 743.03 827.94 776.98 800.16 790.03",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5L",
        "coords": "857.2 765.57 857.2 731.81 883.42 720.18 883.42 753.41 857.2 765.57",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5J",
        "coords": "909.44 742.87 909.44 710.25 933.76 699.79 933.97 731.69 909.44 742.87",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5J",
        "coords": "947.55 725.88 947.55 694.55 964.42 687.86 964.42 718.13 947.55 725.88",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6M",
        "coords": "590.7 795.39 621.81 782.87 621.81 746.1 590.7 757.83 590.7 795.39",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6M",
        "coords": "652.72 771.33 681.29 760.18 682.46 724.77 652.72 735.53 652.72 771.33",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6L",
        "coords": "740.58 722.04 741.36 756.66 771.3 744.73 771.3 709.71 740.58 722.04",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6L",
        "coords": "800.16 699.79 800.16 734.36 828.04 723.6 828.04 689.17 800.16 699.79",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6L",
        "coords": "857.2 679.2 857.2 711.77 883.42 701.5 883.42 668.44 857.2 679.2",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6J",
        "coords": "909.44 659.25 909.44 691.52 933.75 681.35 933.75 649.86 909.44 659.25",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6J",
        "coords": "947.55 645.21 947.11 676.02 964.42 669.27 964.42 638.46 947.55 645.21",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7M",
        "coords": "590.7 734.16 621.81 723.21 622.59 686.04 590.7 697 590.7 734.16",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7M",
        "coords": "652.72 677.24 652.72 713.04 682.46 702.67 682.46 667.27 652.72 677.24",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "7L",
        "coords": "M741.36,664.33v36.19l29.94-11.35s.59-35.8.59-35.21-30.52,10.37-30.52,10.37Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7L",
        "coords": "800.16 644.77 799.62 679.69 828.04 669.56 828.04 635.09 800.16 644.77",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7L",
        "coords": "857.2 625.55 857.2 659.29 883.42 649.86 883.42 616.31 857.2 625.55",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7J",
        "coords": "908.8 607.8 908.8 639.2 933.75 630.83 933.97 599.88 908.8 607.8",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7J",
        "coords": "947.11 595.77 947.55 627.02 964.42 620.56 964.42 589.46 947.11 595.77",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8K",
        "coords": "589.47 674.26 622.05 663.99 622.05 626.58 590.06 636.41 589.47 674.26",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "8K",
        "coords": "M652.72,654.6s28.76-9.54,29.2-9.54,0-35.21,0-35.21l-29.2,8.36v36.38Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8J",
        "coords": "741.36 607.07 741.36 643.01 771.88 633.77 772.33 597.68 741.36 607.07",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8J",
        "coords": "799.62 589.9 799.62 625.26 828.04 615.72 828.68 580.66 799.62 589.9",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8J",
        "coords": "857.2 572.74 857.2 605.89 883.42 597.24 883.42 564.67 857.2 572.74",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8G",
        "coords": "909.44 557.33 909.44 589.46 933.75 581.83 933.97 548.97 909.44 557.33",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8G",
        "coords": "947.99 545.6 947.99 577.14 964.42 571.12 964.42 540 947.99 545.6",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "590.5 575.52 590.5 612.69 622.05 604.08 622.05 566.53 590.5 575.52",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9K",
        "coords": "652.72 559.68 652.72 595.28 682.12 586.87 682.12 551.66 652.72 559.68",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9J",
        "coords": "740.77 549.9 741.36 586.67 771.88 577.68 771.88 541.29 740.77 549.9",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9J",
        "coords": "800.26 534.64 799.62 570.24 828.04 561.05 828.04 527.01 800.26 534.64",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9J",
        "coords": "857.2 518.6 857.2 552.83 883.42 545.4 883.42 512.15 857.2 518.6",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9G",
        "coords": "909.44 505.5 909.44 537.58 933.97 529.95 933.97 499.04 909.44 505.5",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9G",
        "coords": "947.99 496.11 947.11 526.43 964.42 521.34 964.42 491.61 947.99 496.11",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "590.5 515.47 590.5 551.66 622.05 544.62 622.05 507.06 590.5 515.47",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10K",
        "coords": "652.72 502.17 652.72 536.99 682.12 530.34 682.12 495.13 652.72 502.17",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "10J",
        "coords": "M740.77,492.58c0,.59.59,37.17.59,37.17l30.52-8.22-.78-35.8-30.33,6.85Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "10J",
        "coords": "M799.62,478.7v35.99l29.06-6.94-.64-34.72-28.42,5.67Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10J",
        "coords": "857.2 466.76 857.2 499.63 884.39 492.98 884.39 460.5 857.2 466.76",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10G",
        "coords": "909.44 455.61 909.44 487.5 933.97 480.65 934.68 448.96 909.44 455.61",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10G",
        "coords": "947.99 446.42 947.11 476.74 964.42 472.44 964.42 442.7 947.99 446.42",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "740.77 435.47 741.56 470.09 771.88 463.44 771.88 429.21 740.77 435.47",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "799.62 423.73 799.62 457.77 828.68 451.9 828.68 418.64 799.62 423.73",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11G",
        "coords": "857.2 413.75 857.2 446.22 883.42 440.75 883.42 408.47 857.2 413.75",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11G",
        "coords": "908.85 403.19 909.44 435.47 933.97 429.79 934.68 398.1 908.85 403.19",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11G",
        "coords": "947.11 396.34 947.11 427.35 964.42 423.14 964.42 393.21 947.11 396.34",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "11J",
        "coords": "M740.77,377.96l.78,36.77s31.01-6.94,30.33-6.26,0-34.62,0-34.62l-31.11,4.11Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "799.62 369.35 800.45 403.97 828.68 398.1 828.68 364.66 799.62 369.35",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11J",
        "coords": "857.2 360.55 857.2 393.21 883.42 388.91 883.42 356.83 857.2 360.55",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12G",
        "coords": "908.85 352.72 909.44 385 933.7 380.7 933.7 348.03 908.85 352.72",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "12G",
        "coords": "M947.11,346.85v31.3l17.32-3.13s.59-30.91,0-30.91-17.32,2.74-17.32,2.74Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "740.77 321.03 740.77 356.83 771.9 352.53 771.9 317.32 740.77 321.03",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14H",
        "coords": "799.62 314.38 799.62 349.2 828.04 344.31 828.68 310.28 799.62 314.38",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14G",
        "coords": "857.2 308.12 857.2 340.01 883.42 336.1 883.42 304.41 857.2 308.12",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14G",
        "coords": "908.85 301.47 909.64 333.55 933.7 329.74 933.7 298.34 908.85 301.47",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14G",
        "coords": "947.11 297.17 947.11 327.29 964.42 325.14 963.84 295.21 947.11 297.17",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "740.77 263.92 740.77 299.32 771.3 295.6 771.9 260.98 740.77 263.92",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15H",
        "coords": "799.62 258.83 799.62 292.47 827.45 289.54 828.68 256.29 799.62 258.83",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15G",
        "coords": "857.2 254.14 856.61 287.19 883.42 284.26 883.42 251.79 857.2 254.14",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15G",
        "coords": "908.85 249.83 908.85 282.45 933.7 278.98 933.7 248.27 908.85 249.83",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15G",
        "coords": "947.11 247.48 947.11 278.59 964.42 276.04 964.68 245.33 947.11 247.48",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "740.77 206.41 740.77 242.99 771.9 240.83 771.9 204.65 740.77 206.41",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "799.62 203.67 799.62 238.1 828.68 236.14 828.68 202.1 799.62 203.67",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "780.39,130.5 795.84,130.5 795.84,173.9 780.39,173.9",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "732.33 130.5 768.76 130.07 768.76 173.89 732.33 175.01 732.33 130.5",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "808.78,130.5 824.87,130.5 824.87,173.65 808.78,173.65",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1703",
        "coords": "856.61 201.46 856.61 234.97 883.42 232.62 884.2 199.95 856.61 201.46",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "PH1605",
        "coords": "M909.44,199.17l-.59,32.67s25.53-2.84,24.85-2.15,0-31.69,0-31.69l-24.26,1.17Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1605",
        "coords": "947.11 197.99 947.11 228.71 964.68 227.73 964.68 196.87 947.11 197.99",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2A",
        "coords": "1208.62 888.11 1237.77 903.17 1237.77 937.4 1208.62 921.36 1208.62 888.11",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2A",
        "coords": "1153.63 860.92 1181.42 874.81 1181.42 906.89 1154.22 892.02 1153.63 860.92",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2F",
        "coords": "1100.8 834.32 1126.05 846.64 1126.05 877.94 1100.8 863.46 1100.8 834.32",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2F",
        "coords": "1051.69 809.08 1074.58 820.62 1074.58 850.55 1051.69 838.03 1051.69 809.08",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2F",
        "coords": "991.82 800.67 1013.54 789.72 1037.21 800.67 1037.21 829.82 1013.73 819.45 992.6 829.04 991.82 800.67",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2H",
        "coords": "977.73 838.82 980.86 836.47 980.86 805.17 977.73 807.32 977.73 838.82",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "3A",
        "coords": "M1208.62,868.55s0-32.47,0-31.88,29.15,12.13,29.15,12.71v33.25l-29.15-14.08Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "3A",
        "coords": "M1181.42,854.86l-26.61-12.91-1.17-30.71s27.2,11.35,27.2,11.93.59,31.69.59,31.69Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3C",
        "coords": "1100.8 816.32 1126.05 829.04 1126.83 797.15 1100.8 785.81 1100.8 816.32",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "3C",
        "coords": "1051.69 791.48 1074 803.02 1075.37 773.68 1051.69 762.53 1051.69 791.48",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2H",
        "coords": "993.77 784.04 1014.51 774.46 1037.21 786.52 1037.21 754.9 1013.73 744.53 993.77 753.53 993.77 784.04",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "2H",
        "coords": "977.73 792.46 980.86 790.89 980.86 759.98 977.73 762.53 977.73 792.46",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4A",
        "coords": "1208.62 815.15 1208.62 782.77 1237.77 795 1237.77 829.32 1208.62 815.15",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4A",
        "coords": "1154.42 790.7 1154.42 759.01 1181.42 770.16 1181.42 803.02 1154.42 790.7",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4C",
        "coords": "1100.8 766.44 1125.07 777.39 1126.05 745.9 1100.8 735.53 1100.8 766.44",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4C",
        "coords": "1051.69 744.53 1075.37 754.31 1075.37 723.8 1051.69 714.21 1051.69 744.53",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "4G",
        "coords": "M992.6,736.37l21.13-8.66s23.48,9.58,23.48,10.17,1.17-29.54,1.17-29.54l-24.65-10.76-21.13,9.29v29.5Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "4G",
        "coords": "976.55 744.53 980.27 742.87 979.29 711.08 976.55 712.84 976.55 744.53",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5A",
        "coords": "1208.62 762.53 1237.24 774.75 1237.77 741.01 1208.03 730.45 1208.62 762.53",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "5A",
        "coords": "M1154.42,739.45l26.22,11.74v-33.45s-27.59-10.18-27-9.59.78,31.3.78,31.3Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5C",
        "coords": "1100.8 717.74 1126.05 727.81 1126.05 695.63 1100.22 686.04 1100.8 717.74",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5C",
        "coords": "1051.1 696.22 1074.39 706.87 1074.39 676.02 1051.69 666.68 1051.1 696.22",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "5G",
        "coords": "M992.6,689.56l21.13-8.61s23.48,7.63,23.48,8.61,1.17-28.36,1.17-28.36l-24.26-9.39-21.52,7.63v30.12Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "5G",
        "coords": "976.55 696.8 979.29 694.65 979.29 664.53 976.55 666.68 976.55 696.8",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6A",
        "coords": "1208.03 709.91 1236.6 721.25 1237.77 687.41 1208.03 676.85 1208.03 709.91",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "6A",
        "coords": "M1154.42,688.39s26.22,9.19,26.22,10.17,0-31.88,0-31.88l-26.22-9.19v30.91Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6C",
        "coords": "1100.7 667.8 1124.68 676.85 1126.05 646.33 1100.8 636.95 1100.7 667.8",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6C",
        "coords": "1051.69 648.68 1074.39 657.48 1074.39 627.02 1051.69 619.34 1051.69 648.68",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6G",
        "coords": "992.6 642.23 1013.73 635.38 1036.62 643.6 1038.39 613.28 1013.73 605.45 992.6 613.28 992.6 642.23",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "6G",
        "coords": "976.55 649.27 979.29 647.7 979.29 619.34 976.55 620.32 976.55 649.27",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7A",
        "coords": "1208.03 656.9 1237.77 667.27 1237.77 633.62 1208.03 624.23 1208.03 656.9",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7A",
        "coords": "1153.63 639.1 1180.64 648.1 1181.22 614.25 1154.42 606.63 1153.63 639.1",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7C",
        "coords": "1100.7 619.34 1125.07 628.53 1125.07 596.45 1100.7 588.24 1100.7 619.34",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "7C",
        "coords": "M1051.69,600.95s23.28,7.63,22.7,8.22,0-29.73,0-29.73l-22.7-7.24v28.75Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7G",
        "coords": "992.6 595.67 1014.12 589.46 1036.43 596.65 1037.41 567.5 1013.93 559.29 992.6 565.94 992.6 595.67",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "7G",
        "coords": "976.55 602.32 979.88 599.88 979.88 571.61 976.55 573.37 976.55 602.32",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "8A",
        "coords": "M1208.03,603.69l28.96,9.39s1.37-34.23.78-33.65-29.74-7.82-29.74-7.82v32.08Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "8A",
        "coords": "M1153.63,587.65s28.09,7.34,27.6,7.82-.48-33.15,0-32.67-26.81-7.43-26.81-7.43l-.79,32.28Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "8A",
        "coords": "M1101.39,570.05l24.65,7.92s0-31.98,0-31.2-25.35-6.77-25.35-6.77l.69,30.05Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8A",
        "coords": "1051.69 554.2 1074.39 562.42 1075.37 532.49 1051.69 525.45 1051.69 554.2",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8E",
        "coords": "992.6 548.97 1013.73 543.93 1036.23 550.68 1037.41 521.14 1013.34 514.69 992.6 519.58 992.6 548.97",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "8E",
        "coords": "976.55 554.59 980.47 552.83 980.47 523.3 976.55 524.86 976.55 554.59",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9A",
        "coords": "1208.03 551.07 1237.38 558.98 1237.92 525.45 1209.01 518.21 1208.03 551.07",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "9A",
        "coords": "M1153.63,536.21l26.82,7.73s.78-33.55.78-32.96-27-6.85-27-6.85l-.6,32.08Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "9A",
        "coords": "M1100.7,521.34l24.37,7.04v-32.08s-24.37-6.46-24.37-5.87v30.91Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9A",
        "coords": "1051.69 507.26 1074.4 513.52 1075.37 483 1051.69 477.52 1051.69 507.26",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9E",
        "coords": "992.6 504.13 1014.12 497.87 1037.41 504.13 1038.39 473.81 1013.14 468.23 992.6 473.61 992.6 504.13",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "9E",
        "coords": "976.55 509.02 979.88 507.06 979.88 477.52 976.55 478.31 976.55 509.02",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "10A",
        "coords": "M1208.03,498.06l28.37,6.06.78-32.28s-28.57-7.04-29.15-6.46,0,32.67,0,32.67Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10A",
        "coords": "1153.63 485.35 1181.22 492 1181.22 458.35 1154.22 453.27 1153.63 485.35",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10A",
        "coords": "1101.59 472.05 1125.07 478.31 1126.05 447.01 1101.98 442.37 1101.59 472.05",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10A",
        "coords": "1051.69 460.02 1074 466.76 1075.37 435.47 1051.69 430.97 1051.69 460.02",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10E",
        "coords": "992.6 455.22 1014.32 451.31 1037.41 457.57 1038.39 426.47 1013.14 422.75 992.6 427.35 992.6 455.22",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "10E",
        "coords": "977.14 460.34 980.47 458.94 980.47 429.6 977.14 430.38 977.14 460.34",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11A",
        "coords": "1208.42 445.25 1208.42 412.19 1237.19 417.27 1237.19 451.12 1208.42 445.25",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11A",
        "coords": "1154.42 433.9 1180.44 439.77 1180.44 407.49 1154.42 402.6 1154.42 433.9",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11A",
        "coords": "1100.7 422.75 1125.07 428.42 1126.05 396.93 1100.7 392.63 1100.7 422.75",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11A",
        "coords": "1051.69 412.78 1074.78 418.06 1075.37 387.15 1051.69 383.43 1051.69 412.78",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11E",
        "coords": "992.6 408.86 1013.73 404.76 1036.62 408.86 1037.11 380.75 1013.14 376.39 992.01 380.7 992.6 408.86",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "11E",
        "coords": "977.14 413.75 980.47 412.19 980.47 382.85 977.14 383.43 977.14 413.75",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12A",
        "coords": "1208.42 392.63 1237.19 397.52 1237.19 364.07 1208.42 359.96 1208.42 392.63",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "12A",
        "coords": "M1154.42,383.43l26.61,4.69s0-33.06,0-32.28-26.61-4.11-26.61-4.11v31.69Z",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12A",
        "coords": "1101.34 374.24 1125.41 378.74 1125.26 347.83 1101.39 343.14 1101.34 374.24",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12A",
        "coords": "1051.69 366.02 1074.19 370.72 1074.78 339.42 1051.69 336.29 1051.69 366.02",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12E",
        "coords": "993.19 362.31 1013.54 359.57 1037.11 364.26 1037.11 333.55 1013.54 330.42 992.6 333.55 993.19 362.31",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "12E",
        "coords": "977.14 365.83 980.47 364.85 980.47 336.29 977.14 337.66 977.14 365.83",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "14A",
        "coords": "M1208.42,339.81l28.76,4.3v-34.04s-28.76-3.52-28.76-2.93v32.67Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14A",
        "coords": "1154.42 332.18 1181.03 336.29 1181.03 303.62 1153.83 300.89 1154.42 332.18",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "14A",
        "coords": "M1100.61,324.56l24.8,4.11s.64-31.88.64-31.3-24.71-2.74-24.71-2.74l-.73,29.93Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "14A",
        "coords": "M1051.69,318.59l23.09,3.62v-30.52s-23.09-3.33-23.09-2.54v29.44Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14E",
        "coords": "992.6 315.95 1013.34 313.8 1037.11 317.51 1037.8 287.19 1013.73 284.06 992.6 287.78 992.6 315.95",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "14E",
        "coords": "977.14 319.47 979.88 318.59 979.88 289.03 977.14 289.74 977.14 319.47",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15A",
        "coords": "1208.42 286.8 1237.19 289.74 1237.19 256.48 1208.42 253.94 1208.42 286.8",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15A",
        "coords": "1153.83 281.33 1181.03 284.06 1181.03 251.98 1154.42 249.83 1153.83 281.33",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15A",
        "coords": "1100.61 276.24 1126.05 279.37 1126.05 247.88 1100.61 245.33 1100.61 276.24",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15A",
        "coords": "1051.69 271.54 1074.78 273.89 1074.78 242.99 1051.69 242.2 1051.69 271.54",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15E",
        "coords": "992.6 269.98 1014.12 268.02 1037.8 271.74 1037.8 240.44 1013.54 238.88 992.6 240.64 992.6 269.98",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "15E",
        "coords": "977.14 271.74 979.88 270.96 979.88 241.42 977.14 241.42 977.14 271.74",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "PH1601",
        "coords": "M1208.42,233.99l28.76,2.15v-34.04l-28.76-1.28s.78,33.16,0,33.16Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "path",
        "id": "PH1601",
        "coords": "M1153.83,230.66s27.2,1.17,27.2,1.76,0-32.67,0-32.67l-27.2-1.37v32.28Z",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1601",
        "coords": "1100.61 227.34 1126.05 229.68 1126.05 197.41 1100.61 196.63 1100.61 227.34",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1601",
        "coords": "1051.69 223.62 1074.78 225.38 1074.78 195.06 1051.69 194.5 1051.69 223.62",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1604",
        "coords": "993.38 223.62 1013.54 221.27 1037.02 223.62 1037.8 193.3 1014.32 191.74 993.38 193.69 993.38 223.62",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1604",
        "coords": "977.14 224.79 979.88 223.62 979.88 192.71 977.14 192.71 977.14 224.79",
        "color": "#00e7ff",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1701",
        "coords": "1212.14 163.37 1233.66 164.55 1233.66 130.9 1212.14 130.9 1212.14 163.37",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      ///////////////////////////////////////////////
        {
        "type": "polygon",
        "id": "PH1701",
        "coords": "1159.04,129.96 1177.87,129.96 1177.87,162.13 1159.04,162.13",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
        {
        "type": "polygon",
        "id": "PH1701",
        "coords": "1115.3,129.96 1132.17,129.96 1132.17,160.56 1115.3,160.56",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      /////////////////////////////////////////////////
      {
        "type": "polygon",
        "id": "PH1701",
        "coords": "1069.69 160.83 1085.35 161.42 1085.35 132.07 1069.69 132.07 1069.69 160.83",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      },
      {
        "type": "polygon",
        "id": "PH1701",
        "coords": "1026.59 160.05 1041.32 160.05 1041.32 131.49 1026.25 132.07 1026.59 160.05",
        "color": "#ffe000",
        "attr": {
          "strokeMiterlimit": "10"
        }
      }
    ]
  },
  tablet: {
    0: [
      {
        id: 424,
        x: 846.02,
        y: 491.36,
        color: '#fff',
        attr: { strokeMiterlimit: '10' },
      },
      {
        id: 726,
        x: 975.14,
        y: 595.25,
        color: '#fff',
        attr: { strokeMiterlimit: '10' },
      },
      {
        id: 722,
        x: 1109.6,
        y: 408.61,
        color: '#fff',
        attr: { strokeMiterlimit: '10' },
      },
    ],
    1: [
   
    ],
    2: [
     
    ],
    3: [
     
    ],
  },
};

export type TSVGDataShape = {
  type: string;
  coords: string;
  color: string;
  id: string;
  attr: object;
};

export type TSVGDataCircle = {
  x: number;
  y: number;
  color: string;
  id: any;
  attr: object;
};

export type TResidenceData = {
  immersion: {
    [x: number]: TSVGDataShape[];
  };
  tablet: {
    [x: number]: TSVGDataCircle[];
  };
};