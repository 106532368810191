import styled from "styled-components"

function LoadingIcon({ color = "red" }) {
  return (
    <SVG width={50} height={50} viewBox="0 0 40 40">
      <circle
        r={15}
        cx={20}
        cy={20}
        fill="none"
        stroke={color}
        strokeWidth={3}
        strokeDasharray={70}
        className="spin"
      />
    </SVG>
  )
}

export default LoadingIcon

const SVG = styled.svg`
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .spin {
    transform-origin: center;
    animation: rotate 1.5s ease-in-out infinite;
  }
`
