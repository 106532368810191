import React, { useEffect, useState } from "react"
import styled from "styled-components"
import pois from "../data/pois"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import useStore from "@state/store"
import gsap from "gsap"
import picture from "@assets/icons/picture.png"

type Props = {}

const categoryOffsets = {
  Boutiques: 1,
  "Cuisine And Night Life": 16,
  Convenience: 43,
  Fitness: 50,
  "Culture, Entertainment & Parks": 59,
  Transportation: 78,
  Playground: 80,
}

export function Controls(props: Props) {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const selectedMapCategory = useStore((state) => state.selectedMapCategory)
  const poiImageVisible = useStore((state) => state.poiImageVisible)
  const poiImage = useStore((state) => state.poiImage)
  const selectedPoi = useStore((state) => state.selectedPoi)
  const [imageError, setImageError] = useState(false)

  function handlePoiImage() {
    if (poiImageVisible) {
      gsap.fromTo(
        "#poiImage",
        { x: 0 },
        { x: -20, autoAlpha: 0, duration: 0.6, ease: "power3.inOut" },
      )
      syncState({ poiImageVisible: false })
    } else if (!poiImageVisible) {
      gsap.fromTo(
        "#poiImage",
        { x: -20 },
        { x: 0, autoAlpha: 1, duration: 0.6, ease: "power3.inOut" },
      )
      syncState({ poiImageVisible: true })
    }
  }

  useEffect(() => {
    if (imageError) {
      gsap.to("#poiImage", { autoAlpha: 0, duration: 0.6 })
      syncState({ poiImageVisible: false })
    } else {
      gsap.fromTo(
        "#poiImage",
        { x: 0 },
        { x: -20, autoAlpha: 0, duration: 0.6, ease: "power3.inOut" },
      )
      syncState({ poiImageVisible: false })
    }
  }, [imageError, selectedMapCategory])

  return (
    <ControlsWrapper>
      <Image
        id="poiImage"
        src={poiImage}
        onLoad={() => setImageError(false)}
        onError={() => setImageError(true)}
      ></Image>
      <div style={{ display: "flex", gap: "2rem" }}>
        <Title>{selectedMapCategory}</Title>
        {imageError === false && selectedPoi !== null && (
          <div className="viewImage" onClick={handlePoiImage}>
            <img src={picture} alt="" />
          </div>
        )}
      </div>
      {selectedMapCategory && (
        <PoiList>
          {selectedMapCategory &&
            pois[selectedMapCategory].map((poi, index) => (
              <div key={index} style={{ display: "flex", gap: "0.5rem" }}>
                <PoiItem
                  className={poi === selectedPoi ? "selected" : ""}
                  key={index}
                  onClick={() => {
                    syncState({ selectedPoi: poi })
                  }}
                >
                  <div className="index">
                    {index + categoryOffsets[selectedMapCategory]}
                  </div>
                  {poi}
                </PoiItem>
                <div className="circle"></div>
              </div>
            ))}
        </PoiList>
      )}
      <LocationGroup>
        {Object.keys(pois).map((locationKey) => (
          <LocationItem
            className={locationKey === selectedMapCategory ? "selected" : ""}
            key={locationKey}
            onClick={() => {
              syncState({ selectedPoi: null })
              syncState({ selectedMapCategory: locationKey })
              syncState({ poiImageVisible: false })
            }}
          >
            {locationKey}
          </LocationItem>
        ))}
      </LocationGroup>
    </ControlsWrapper>
  )
}

const LocationGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0rem;
  align-items: center;
`

const Image = styled.img`
  width: 25%;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
`

const PoiList = styled.div`
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
  align-items: end;
  overflow-x: scroll;
  border-bottom: 1px solid rgba(256, 256, 256, 0.5);
  pointer-events: auto;
`
const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
  padding: 0 2rem;
  justify-content: center;
  align-items: start;
  z-index: 20;
  position: fixed;
  background-color: transparent;
  bottom: 1.5%;
  left: 0;
  width: 90%;
  pointer-events: none;

  .text-location {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 5em;
    font-weight: 700;
    color: #fff;
  }

  .circle {
    margin: auto;
    width: 5px;
    height: 5px;
    aspect-ratio: 1/1;
    border-radius: 10rem;
    background-color: white;
  }
  .index {
    font-size: 1.5rem;
    margin: auto;
    font-weight: 800;
  }

  .viewImage {
    border-radius: 10rem;
    margin: auto;
    background-color: white;
    padding: 0.75rem;
    aspect-ratio: 1/1;
    width: 3rem;
    height: 3rem;
    transition: background-color 0.2s ease-in-out;
    pointer-events: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`
const LocationItem = styled.div`
  padding: 0.75rem 2rem;
  margin: 0.75rem 0;
  font-family: Masque-Hairline;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  transition: background-color 0.2s ease-in-out;
  border-radius: 10px;
  pointer-events: auto;

  &.selected {
    background-color: rgba(256, 256, 256, 0.1);
    color: rgba(256, 256, 256, 0.5);
    transition: 0.3s;
  }

  &:active {
    background-color: rgba(256, 256, 256, 0.1);
    transition: 0.3s;
  }
`
const PoiItem = styled.div`
  padding: 0.75rem 1.5rem;
  margin: 0.75rem 0;
  font-family: stolzl;
  font-weight: 400;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  display: flex;
  gap: 0.5rem;
  white-space: nowrap;
  border-radius: 10px;
  transition: background-color 0.2s ease-in-out;
  pointer-events: auto;

  &.selected {
    background-color: rgba(256, 256, 256, 0.1);
    color: rgba(256, 256, 256, 0.5);
    transition: 0.3s;
  }

  &:active {
    background-color: rgba(256, 256, 256, 0.1);
    transition: 0.3s;
  }
`
const Title = styled.div`
  padding: 0.5rem 0rem;
  font-family: Masque-Hairline;
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  border-bottom: 1px solid rgba(256, 256, 256, 0.5);

  &.selected {
    opacity: 0.5;
  }
`
