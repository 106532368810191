import React, { useState, useEffect } from "react"
import * as Dialog from "@radix-ui/react-dialog"
import { Cross2Icon } from "@radix-ui/react-icons"
import "./styles.css"
import { formatPrice } from "@components/apartmentfinder-orbit/components/utils"

const UnitUpdateDialog = ({ isOpen, unit, onOpenChange, onSave }) => {
  const [price, setPrice] = useState("")
  const [status, setStatus] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setPrice(formatPrice(unit?.price))
      setStatus(unit?.status || "")
    } else {
      setPrice("")
      setStatus("")
    }

    return () => {
      setPrice("")
      setStatus("")
      setError("")
    }
  }, [isOpen, unit])

  const handleSave = (event: any) => {
    event.preventDefault()

    const numericPrice = Number(price.replace(/,/g, ""))

    if (!numericPrice) {
      setError("Price cannot be empty or zero.")
      return
    }

    setLoading(true)
    setError("")

    onSave(unit?.id, { PRICE: numericPrice, STATUS: status })
      .then(() => {
        setLoading(false)
        onOpenChange(false)
      })
      .catch((error: any) => {
        setLoading(false)
        console.error("Error saving data:", error)
        setError("There was a problem saving the data. Please try again later.")
      })
  }

  const handlePriceChange = (event: any) => {
    const inputValue = event.target.value
    if (inputValue.length > 0) setError("")
    const numericValue = inputValue.replace(/[^0-9]/g, "")
    setPrice(formatPrice(numericValue))
  }

  const handleStatusChange = (event: any) => {
    setStatus(event.target.value)
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">
            Update Unit Details
          </Dialog.Title>
          <Dialog.Description className="DialogDescription">
            Update the price and status for unit {unit?.unitID}.
            <br />
            {error && <span className="error">{error}</span>}
          </Dialog.Description>
          <form onSubmit={handleSave}>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="price">
                Price $
              </label>
              <input
                className="Input"
                id="price"
                type="text"
                value={price}
                autoComplete="off"
                onChange={handlePriceChange}
                onKeyDown={(e) => {
                  if (
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight" &&
                    isNaN(Number(e.key))
                  ) {
                    e.preventDefault()
                  }
                }}
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="status">
                Status
              </label>
              <select
                className="Input"
                value={status}
                onChange={handleStatusChange}
              >
                <option value="Available">Available</option>
                <option value="Sold">Sold</option>
                <option value="Reserved">Reserved</option>
              </select>
            </fieldset>
            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              {loading && <div className="spinner"></div>}
              <button type="submit" className="Button">
                Save changes
              </button>
            </div>
          </form>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon height={20} width={20} color="#000" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default UnitUpdateDialog
