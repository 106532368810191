import useStore from "@state/store"
import {
  checkPresetsInLocalStorage,
  getPresetFromLocalStorage,
} from "./withLocalStorage"
import { filterTable } from "./residenceTableFn"
import { useLiveData } from "@hooks/useLiveData"

const orbitAspects = {
  0: ["N", "W", "S", "E"],
  1: ["W", "S", "E"],
  2: ["E", "S"],
  3: ["N", "E"],
}

export default function useFilteredData() {
  // const isOnline = useNetworkStatus()
  const { data } = useLiveData()

  const orbitIndex = useStore((state) => state.orbitIndex)

  const { filters, selectedPreset } = useStore((state) => ({
    isTablet: state.isTablet,
    filters: state.filters,
    selectedPreset: state.selectedPreset,
  }))

  const getResidenceData = () => {
    checkPresetsInLocalStorage()
    const preset = getPresetFromLocalStorage(selectedPreset?.name)

    // check if there is internet connection
    return selectedPreset?.name ? preset?.units : data
  }

  return filterTable(
    filters,
    orbitAspects[orbitIndex],
    getResidenceData() || [],
  )
}
