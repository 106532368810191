import styled from "styled-components"
import globalStyles from "@data/style.globals"
import Text from "@pages/screen/amenities/components/Text"
import ScreenImages from "@pages/screen/amenities/components/ScreenImages"
import FullScreenImage from "@pages/screen/amenities/components/FullScreenImage"
import Selector from "./components/Selector"
import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import useStore from "@state/store"
import FocusText from "@pages/screen/amenities/components/FocusText"
import CloseSVG from "./components/CloseSVG"
import MeshSelector from "./components/MeshSelector"
import TCanvas from "@pages/screen/amenities/canvas-components/TCanvas"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { useEffect } from "react"
import SyncOnMount from "@components/SyncOnMount"
import cinema from "@assets/icons/cinema.png"
import cross from "@assets/icons/cross.svg"

type Props = {}

export function Amenities(props: Props) {
  const amenitiesSelector = useStore((state) => state.amenitiesSelector)
  const showAmenitiesScreenSaver = useStore(
    (state) => state.showAmenitiesScreenSaver,
  )
  const loadProgress = useStore((state) => state.loadProgress)
  const loaded = useStore((state) => state.loaded)
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  useGSAP(() => {
    if (loadProgress >= 100) {
      setTimeout(() => {
        gsap.to("#loading", { autoAlpha: 0, duration: 0.5 })
        setTimeout(() => {
          syncState({ loaded: true })
        }, 500)
      }, 500)
    }
  }, [loadProgress])

  useGSAP(
    () => {
      if (!loaded) return
      gsap.to(["#sidebar-text-div", ".image-gallery", "#sidebar-selector"], {
        duration: 0.3,
        autoAlpha: amenitiesSelector === null ? 1 : 0,
        y: amenitiesSelector === null ? 0 : 300,
        delay: amenitiesSelector === null ? 0.3 : 0,
      })
      gsap.to(["#amenities-text", "#amenities-selector", "#amenity-close"], {
        duration: 0.3,
        autoAlpha: amenitiesSelector !== null ? 1 : 0,
        y: amenitiesSelector !== null ? 0 : 300,
        delay: amenitiesSelector === null ? 0 : 0.3,
      })
    },
    { dependencies: [amenitiesSelector, loaded] },
  )

  useEffect(() => {
    syncState({
      amenitiesActiveMesh: null,
      amenitiesActiveImage: null,
      amenitiesFullScreenImage: null,
      amenitiesSelector: null,
    })

    return () => {
      syncState({
        amenitiesActiveMesh: null,
        amenitiesActiveImage: null,
        amenitiesFullScreenImage: null,
        amenitiesSelector: null,
      })
    }
  }, [])

  return (
    <Wrapper
      style={{
        backgroundColor: globalStyles.colors.offBlack,
        color: "white",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gridTemplateRows: "repeat(2, minmax(0, 1fr))",
      }}
    >
      <SyncOnMount />
      <div
        id="loading"
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 20,
          height: "100%",
          width: "100%",
          display: "grid",
          backgroundColor: globalStyles.colors.offBlack,
          placeItems: "center",
        }}
      >
        <p style={{ fontSize: "2rem" }}>
          LOADING AMENITIES... {loadProgress.toFixed(0)}%
        </p>
      </div>
      {loaded && (
        <>
          <video
            style={{
              opacity: showAmenitiesScreenSaver ? 1 : 0,
              pointerEvents: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              zIndex: 100,
              objectFit: "contain",
              transition: "opacity 0.5s",
              backgroundColor: "rgba(255,246, 248, 1)",
            }}
            loop
            autoPlay
            muted
          >
            <source src="/assets/videos/amenities.mp4" />
          </video>
          <Text />
          <ScreenImages screen="ipad" />
          <FullScreenImage $screen="ipad" />
          <Selector />
          <FocusText />
          <MeshSelector />
          <div
            className="screenSaver"
            onClick={() =>
              syncState({ showAmenitiesScreenSaver: !showAmenitiesScreenSaver })
            }
          >
            {showAmenitiesScreenSaver && (
              <img
                src={cross}
                style={{ filter: "invert(100%)", width: "90%" }}
                alt=""
              />
            )}
            {!showAmenitiesScreenSaver && <img src={cinema} alt="" />}
          </div>
          <CloseSVG
            onClick={() => {
              syncState({
                amenitiesSelector: null,
                amenitiesActiveMesh: null,
                amenitiesActiveImage: null,
              })
            }}
            id="amenity-close"
            style={{
              cursor: "pointer",
              opacity: 0,
              visibility: "hidden",
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
              zIndex: 19,
              placeSelf: "start start",
              margin: "6rem 4rem",
              width: "3rem",
              height: "3rem",
            }}
          />
        </>
      )}
      <TCanvas screen="ipad" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;

  .screenSaver {
    width: 4rem;
    height: 4rem;
    background-color: #fff;
    margin: 20px;
    font-size: 1.5rem;
    border: "1px solid #000";
    padding: 1rem;
    border-radius: 100%;
    cursor: pointer;
    z-index: 2000000;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`
