import StyledButton from "@components/buttons/StyledButton"
import { TPreset } from "@components/preset/Preset.model"
import { ArrowLeftIcon } from "@radix-ui/react-icons"
import useStore from "@state/store"
import styled from "styled-components"
import InfoCard from "./InfoCard"
import ResidenceTable from "./ResidenceTable"
import ResidenceFilters from "./ResidenceFilters"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { Filters } from "@state/types.store"
import { useEffect } from "react"
import gsap from "gsap"

interface IUnitsTableProps {
  show: boolean
  onClose: () => void
}

export default function UnitsTable(props: IUnitsTableProps) {
  const { show, onClose } = props
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const { setSelectedPreset, selectedUnit, filters } = useStore((state) => ({
    filters: state.filters,
    selectedUnit: state.selectedUnit,
    setSelectedPreset: (newPreset: TPreset) =>
      state.setLocalState({ selectedPreset: newPreset }),
  }))

  const openResidenceInfo = (unit: any) => {
    syncState({ selectedUnit: unit })
  }

  useEffect(() => {
    if (show === true)
      gsap.to(".wrapper", {
        autoAlpha: 1,
        duration: 0.3,
        ease: "power2.inOut",
      })
    else if (show === false)
      gsap.to(".wrapper", {
        autoAlpha: 0,
        duration: 0.3,
        ease: "power2.inOut",
      })
  }, [show])

  return (
    <Wrapper className="wrapper">
      <div className="back" onClick={onClose}>
        <ArrowLeftIcon height={20} width={20} color={"#fff"} />
        <StyledButton
          border={false}
          color={"#fff"}
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          Back
        </StyledButton>
      </div>

      <div className="units-table-container">
        <div className="table">
          <ResidenceTable
            openResidenceInfo={openResidenceInfo}
            selectedUnits={[selectedUnit]}
          />
        </div>
        <div className="filters-container">
          <div className="info-card">
            <InfoCard selectedUnit={selectedUnit} />
          </div>
          <div className="filters">
            <ResidenceFilters
              filters={filters}
              setFilters={(filters: Filters) => {
                syncState({ filters: filters })
              }}
              setPresetUnits={(preset: any) => setSelectedPreset(preset)}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 3em;
  padding: 6em 4em;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 51;

  .back {
    position: absolute;
    display: flex;
    align-items: center;
    top: 3em;
    left: 4em;
    gap: 1em;
    padding: 1em 1em 1em 0;
  }

  .units-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3em;
    width: 100%;
    height: 95%;
  }

  .table {
    height: 100%;
    max-height: 100%;
    width: 70%;
  }

  .filters-container {
    z-index: 1;
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1em;

    .info-card {
      z-index: 1;
      height: 20%;
      width: 100%;

      .separator-root {
        background-color: #fff;
      }
    }

    .filters {
      height: 80%;
      width: 100%;
    }
  }
`
