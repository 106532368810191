import { io, Socket } from "socket.io-client"

class SocketClient {
  private static instance: SocketClient
  private socket: Socket

  private constructor(url: string) {
    this.socket = io(url, {
      secure: true,
      rejectUnauthorized: false,
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
    })
    this.socket?.on("connect", () => {
      console.log("ON connected")
    })
    this.socket?.on("disconnect", (data) => {
      console.log("disconnected..", data)
    })
  }

  public static getInstance(url?: string): SocketClient {
    if (!SocketClient.instance) {
      SocketClient.instance = new SocketClient(url)
    }
    return SocketClient.instance
  }

  public getSocket(): Socket {
    return this.socket
  }
}

export default SocketClient
