import styled from "styled-components"

interface SocketStatusProps {
  color?: string
  bgColor?: string
  strokeWidth?: number
  open?: boolean
}

function SocketStatus(props: SocketStatusProps) {
  return (
    <Container>
      <SVG x="0" y="0" viewBox="0 0 500 500" $open={props.open}>
        <circle cx={250} cy={250} r={250} fill={props.bgColor} />
        <g>
          <path
            d="m214.35,265.88v128.18"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth={props.strokeWidth}
          />
          <path
            d="m296.04,187.96v45.24c0,18.04-14.63,32.67-32.67,32.67h-98.02c-18.04,0-32.67-14.63-32.67-32.67v-45.24c0-18.04,14.63-32.67,32.67-32.67h98.02c18.05,0,32.67,14.63,32.67,32.67Z"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth={props.strokeWidth}
          />
          <path
            d="m268.81,155.29v-55.29"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth={props.strokeWidth}
          />
          <path
            d="m159.9,155.29v-55.29"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth={props.strokeWidth}
          />
          <path
            d="m298.02,400l34.66-34.65m0,0l34.65-34.66m-34.65,34.66l-34.66-34.66m34.66,34.66l34.65,34.65"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={props.strokeWidth}
          />
        </g>
      </SVG>
    </Container>
  )
}

export default SocketStatus

type TSVGProps = {
  $open: boolean
}

const SVG = styled.svg<TSVGProps>`
  width: 100%;
  height: 100%;

  & g {
    transition: opacity 0.8s ease;
  }

  & text {
    text-align: center;
    text-anchor: middle;
  }
`

const Container = styled.div`
  position: absolute;
  height: 2.5em;
  width: 3.5em;
  border-radius: 2em;
  top: 2em;
  left: 2em;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`
