import styled from "styled-components"

export function Landing() {
  return <Wrapper></Wrapper>
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .text-location {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 5em;
    font-weight: 700;
    color: #fff;
  }
`
