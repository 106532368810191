import { useButtonFillColor } from "@hooks/useButtonFillColor"
import React from "react"
import styled from "styled-components"

type ButtonProps = {
  style?: React.CSSProperties
  className?: string
  arrowColor?: string
  onClick?: () => void
}

const RightArrow: React.FC<ButtonProps> = ({ onClick, style, className, arrowColor = "black" }) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="75"
        height="16"
        viewBox="0 0 75 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.7071 8.70712C75.0976 8.3166 75.0976 7.68343 74.7071 7.29291L68.3431 0.928944C67.9526 0.53842 67.3195 0.53842 66.9289 0.928944C66.5384 1.31947 66.5384 1.95263 66.9289 2.34316L72.5858 8.00001L66.9289 13.6569C66.5384 14.0474 66.5384 14.6806 66.9289 15.0711C67.3195 15.4616 67.9526 15.4616 68.3431 15.0711L74.7071 8.70712ZM-1.74846e-07 9L74 9.00001L74 7.00001L1.74846e-07 7L-1.74846e-07 9Z"
          fill={arrowColor}
        />
      </svg>
    </Button>
  )
}

export { RightArrow }

const Button = styled.button`
  padding: 1em;
  border-radius: 50%;
  height: 4em;
  width: 4em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg > path {
    transition: fill 0.4s ease-in-out;
  }
`
