import { LogoMap } from "@pages/tablet/location/LogoMap"
import LocationOrbit from "@pages/tablet/location/components/LocationOrbit"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import styled from "styled-components"
import Popup from "@components/popup"
import { Overlay } from "@pages/tablet/location/components/Overlay"
import OrbitCaptions from "@components/orbit-captions"
import gsap from "gsap"
import { useEffect, useState } from "react"
import { useScreenTimeout } from "@hooks/useScreenTimeout"

export function Location() {
  const {
    selectedMapCategory,
    selectedPoi,
    poiImage,
    // showMapScreenSaver,
    // resetState,
    poiImageVisible,
    orbit,
  } = useStore((state) => ({
    selectedMapCategory: state.selectedMapCategory,
    selectedPoi: state.selectedPoi,
    poiImage: state.poiImage,
    // showMapScreenSaver: state.showMapScreenSaver,
    resetState: state.resetState,
    poiImageVisible: state.poiImageVisible,
    orbit: state.orbit,
  }))

  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  useEffect(() => {
    if (poiImageVisible) {
      gsap.fromTo(
        "#poiImage",
        { x: -20 },
        { x: 0, autoAlpha: 1, duration: 0.6, ease: "power3.inOut" },
      )
      gsap.to(".overlay", { opacity: 0.7, duration: 0.6, ease: "power3.inOut" })
    } else if (!poiImageVisible) {
      gsap.fromTo(
        "#poiImage",
        { x: 0 },
        { x: -20, autoAlpha: 0, duration: 0.6, ease: "power3.inOut" },
      )
      gsap.to(".overlay", { opacity: 0, duration: 0.6, ease: "power3.inOut" })
    }
  }, [poiImageVisible])

  const { orbitIndex, orbitDir, orbitRunning } = useStore((s) => ({
    orbitIndex: s.orbitIndex,
    orbitDir: s.orbitDir,
    orbitRunning: s.orbitRunning,
  }))

  const showMapScreenSaver = useScreenTimeout(5 * 60 * 1000, [
    selectedMapCategory,
    selectedPoi,
    poiImage,
    poiImageVisible,
    orbit,
    orbitIndex,
    orbitDir,
    orbitRunning,
  ])

  return (
    <Wrapper>
      <img
        style={{
          opacity: showMapScreenSaver ? 1 : 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "100%",
          zIndex: 100,
          objectFit: "cover",
          transition: "opacity 0.5s",
        }}
        src="/assets/images/2L_Screen_A_V1.jpg"
      />

      <ScreenUI>
        <LogoMap />
        <div className="categoryWrapper">
          <div className="poiDetails">
            <div className="title">{selectedMapCategory}</div>
            <div className="subtitle">{selectedPoi}</div>
          </div>
          <Image id="poiImage" src={poiImage}></Image>
        </div>
        <Overlay />
        <img className="viewport" src="/assets/images/map/viewbox.jpg" />
        <img className="compass" src="/assets/images/map/compass.png" />
        <div className="overlay"></div>
      </ScreenUI>
      <Popup
        openState={orbit}
        setOpenState={(v) => syncState({ orbit: v })}
        durationMS={0}
        closeButton={null}
      >
        <LocationOrbit
          orbitIndex={orbitIndex}
          orbitDir={orbitDir}
          orbitRunning={orbitRunning}
        />
        <OrbitCaptions
          orbitIndex={orbitIndex}
          orbitRunning={orbitRunning}
          orbitDir={orbitDir}
        />
      </Popup>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 55%;
  margin-top: 14rem;
  margin-left: 0.25rem;
  border-radius: 0.5rem;
`

const ScreenUI = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    opacity: 0;
    z-index: 11;
  }

  .viewport {
    width: 10%;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    opacity: 0;
  }

  .compass {
    width: 3%;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    opacity: 0;
  }

  .categoryWrapper {
    z-index: 12;
    position: absolute;
    top: 0;
    left: 0;
    margin: 1.5% 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .poiDetails {
    position: absolute;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 1rem;
    border: 1px solid rgba(256, 256, 256, 0.5);
  }

  .title {
    display: inline-block;
    padding: 0.5rem 0;
    font-family: Masque-Hairline;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    border-bottom: 1px solid rgba(256, 256, 256, 0.5);
    white-space: nowrap;
  }
  .subtitle {
    padding: 0.5rem 0;
    font-family: stolzl;
    font-size: 2.5rem;
    font-weight: 400;
    // line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    z-index: 10;
    white-space: nowrap;
  }
`

const MapText = styled.div`
  padding: 6% 8px 8px 8px;
  font-family: Masque-Hairline;
  font-size: 109px;
  font-weight: 400;
  line-height: 111px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
`
