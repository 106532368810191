import React from "react"
import { LeftArrow } from "./LeftArrow"
import { RightArrow } from "./RightArrow"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import styled from "styled-components"
import { Close } from "./Close"
import useStore from "@state/store"

const OrbitButtons = ({ orbitRunning, orbitIndex, hasClose = true }) => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const onOrbitButtonClick = (dir: boolean, index: number) => {
    syncState({
      orbitIndex: index,
      orbitDir: dir,
      orbitRunning: true,
    })

    if (selectedUnit) {
      let newOrbitIndex
      switch (selectedUnit.aspect) {
        case "N":
        case "E":
        case "NE":
        case "WE":
        case "NS":
          newOrbitIndex = 3
          break
        case "W":
          newOrbitIndex = 0
          break
        case "SW":
          newOrbitIndex = 0
          break
        case "SE":
        case "SEN":
          newOrbitIndex = 2
          break
        default:
          newOrbitIndex = 0
      }

      if (newOrbitIndex != orbitIndex) syncState({ selectedUnit: null })
    }
  }

  const { selectedUnit } = useStore((s) => ({
    selectedUnit: s.selectedUnit,
  }))

  return (
    <Controls style={{ opacity: orbitRunning ? 0 : 1 }}>
      <LeftArrow
        className="arrow-button"
        onClick={() => {
          onOrbitButtonClick(false, orbitIndex)
        }}
        arrowColor="white"
      />
      <img src="/assets/svg/orbit-logo-white.svg" alt="Logo" />
      <RightArrow
        className="arrow-button"
        onClick={() => {
          onOrbitButtonClick(true, orbitIndex)
        }}
        arrowColor="white"
      />
      {hasClose && <Close onClick={() => syncState({ orbit: false })} />}
    </Controls>
  )
}

export default OrbitButtons

const Controls = styled.div`
  height: 9rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 3em 0;
  pointer-events: all;
  background: linear-gradient(
    to right,
    transparent 30%,
    #00000086,
    transparent 70%
  );
  transition: opacity 400ms ease-in-out;

  & img {
    max-height: 150%;
  }

  .arrow-button {
    width: 70px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`
