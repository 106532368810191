export default function ArrowSVG({ ...props }) {
  return (
    <svg
      width="318"
      height="6"
      viewBox="0 0 318 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M316.944 3.15544C317.057 3.04237 317.057 2.85905 316.944 2.74599L315.102 0.903466C314.989 0.7904 314.805 0.7904 314.692 0.903466C314.579 1.01653 314.579 1.19985 314.692 1.31292L316.33 2.95071L314.692 4.58851C314.579 4.70157 314.579 4.88489 314.692 4.99796C314.805 5.11102 314.989 5.11102 315.102 4.99796L316.944 3.15544ZM-2.5311e-08 3.24021L316.739 3.24024L316.739 2.66119L2.5311e-08 2.66116L-2.5311e-08 3.24021Z"
        fill="white"
      />
    </svg>
  )
}
