import { create } from "zustand"

interface FavoritesState {
  favorites: any[]
  addToFavorites: (unit: any) => void
  removeFromFavorites: (unit: any) => void
  clearFavorites: () => void
  loadFavorites: () => void
}

export const useFavoritesStore = create<FavoritesState>()((set, get) => ({
  favorites: [],

  addToFavorites: (unit) => {
    const updatedFavorites = get().favorites.filter(
      (favorite) => favorite.unitType !== unit.unitType,
    )

    if (updatedFavorites.length === get().favorites.length) {
      updatedFavorites.push(unit)
    }

    localStorage.setItem("favorites", JSON.stringify(updatedFavorites))
    set({ favorites: updatedFavorites })
  },

  removeFromFavorites: (unit) => {
    const updatedFavorites = get().favorites.filter(
      (favorite) => favorite.unitType !== unit.unitType,
    )

    localStorage.setItem("favorites", JSON.stringify(updatedFavorites))
    set({ favorites: updatedFavorites })
  },

  clearFavorites: () => {
    localStorage.removeItem("favorites")
    set({ favorites: [] })
  },

  loadFavorites: () => {
    const storedFavorites = localStorage.getItem("favorites")
    if (storedFavorites) {
      set({ favorites: JSON.parse(storedFavorites) })
    }
  },
}))
