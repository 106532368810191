import styled from "styled-components"
import React from "react"

type FloorplanProps = {
  unitType: string
  isDuplex: boolean
  activeDuplexFloorplan: string
}

const Floorplan: React.FC<FloorplanProps> = React.memo(function Floorplan({
  unitType,
  isDuplex,
  activeDuplexFloorplan,
}) {

  if (!unitType) {
    return null
  }
  return (
    <Wrapper>
      <div className="images">
      <div className="disclaimer">PURCHASER ACKNOWLEDGES AND ACCEPTS THAT THIS DRAWING (A) REFLECTS A PRELIMINARY SKETCH FOR
ILLUSTRATIVE PURPOSES ONLY, (B) IS BEING PROVIDED AT PURCHASER’S REQUEST, AND (C) HAS NEITHER BEEN
REVIEWED NOR APPROVED BY APPLICABLE GOVERNMENTAL AUTHORITIES (INCLUDING WITHOUT LIMITATION DOB
AND AG, TO THE EXTENT APPLICABLE), AND SPONSOR MAKES NO REPRESENTATIONS REGARDING WHETHER SUCH
GOVERNMENTAL AUTHORITIES SHALL APPROVE THIS DRAWING IN ITS CURRENT FORM. ALL MEASUREMENTS AND
SQUARE FOOTAGES ARE APPROXIMATE AND EXCEED USABLE FLOOR AREA. THIS IS NOT AN OFFER. NEITHER THIS
DRAWING NOR ANY OTHER INFORMATION SET FORTH HEREIN SHALL BE CONSTRUED TO OBLIGATE OR BIND EITHER
PARTIES TO ANY SALES, TERMS OF SALE, AND/OR ANY OTHER OBLIGATIONS. SPONSOR MAKES NO
REPRESENTATIONS OR GUARANTEES EXCEPT AS MAY BE SET FORTH IN THE OFFERING PLAN THE COMPLETE
OFFERING TERMS ARE IN AN OFFERING PLAN AVAILABLE FROM SPONSOR. FILE NO. CD220253. SPONSOR: 720 WEA
VENTURES LLC, C/O INTERVEST CAPITAL PARTNERS, 425 PARK AVENUE, 35TH FLOOR, NEW YORK, NY 10022. EQUAL
HOUSING OPPORTUNITY.
</div>
        <div className="floorplan">
          <img
            alt="Floorplan"
            src={`/assets/images/residences/floorplans/${unitType}-F${isDuplex ? "-" + activeDuplexFloorplan : ""}.png`}
          />
        </div>
        <div className="plate">
          <img
            alt="Floorplan"
            src={`/assets/images/residences/floorplans/${unitType}-P${isDuplex ? "-" + activeDuplexFloorplan : ""}.png`}
          />
        </div>
      </div>
    </Wrapper>
  )
})

export default Floorplan

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 2em;

  .images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .floorplan {
      height: 85%;
      width: 100%;
      padding: 3em;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .plate {
      height: 15%;
      width: 100%;
      padding: 2em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 2rem;

      img {
        object-fit: contain;
        width: auto;
        height: 100%;
      }
    }

    .disclaimer {
      width: 30%;
      font-size: 1rem;
      line-height: 18px;
      position: fixed;
      font-family: stolzl;
      left: 3em;
      text-transform: none;
      color: #a1a1a1;
      text-align: justify;
    }
  }
`
