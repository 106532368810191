import { amenitiesData, amenitiesImages } from "@data/amenitiesData"
import ArrowSVG from "@pages/tablet/amenities/components/ArrowSVG"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { useEffect, useState } from "react"

export function checkIfStateExists(stateToCheck) {
  return Object.values(amenitiesImages).some(
    (image) => image.state === stateToCheck,
  )
}

function findKeyByState(stateToMatch) {
  return Object.keys(amenitiesImages).find(
    (key) => amenitiesImages[key].state === stateToMatch,
  )
}

export default function FocusText({ screen = "ipad" }) {
  const amenitiesSelector = useStore((state) => state.amenitiesSelector)
  const amenitiesActiveMesh = useStore((state) => state.amenitiesActiveMesh)
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const [activeMesh, setActiveMesh] = useState(null)
  const [floor, setFloor] = useState(null)

  useEffect(() => {
    if (amenitiesActiveMesh) {
      setActiveMesh(amenitiesActiveMesh)
    } else {
      setTimeout(() => {
        setActiveMesh(null)
      }, 1100)
    }
  }, [amenitiesActiveMesh])

  useEffect(() => {
    if (amenitiesSelector !== null) {
      setFloor(amenitiesSelector)
    } else {
      setTimeout(() => {
        setFloor(null)
      }, 1200)
    }
  }, [amenitiesSelector])

  return (
    <div
      id="amenities-text"
      style={{
        zIndex: 10,
        opacity: 0,
        pointerEvents: "none",
        visibility: "hidden",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        placeSelf: "start",
        padding:
          screen === "ipad" ? "14rem 4rem 0rem 4rem" : "4rem 2rem 0rem 2rem",
        display: "grid",
        gridAutoFlow: "row",
        rowGap: "0.4rem",
      }}
    >
      <p style={{ fontSize: "6.5rem", fontFamily: "Masque-Hairline" }}>
        {floor}
      </p>

      <>
        <p
          style={{
            opacity: amenitiesActiveMesh ? 1 : 0,
            transition: "opacity 0.3s",
            textTransform: "uppercase",
            fontSize: "1.7rem",
            letterSpacing: "0.4rem",
            marginBottom: "1.2rem",
          }}
        >
          {amenitiesActiveMesh}
        </p>
        {/* <p
          style={{
            opacity: amenitiesActiveMesh ? 0.9 : 0,
            transition: "opacity 0.3s",
            width: "28rem",
            fontSize: "1.7rem",
          }}
        >
          {amenitiesData[activeMesh]?.description}
        </p> */}
        {screen === "ipad" &&
          amenitiesActiveMesh &&
          checkIfStateExists(amenitiesActiveMesh) && (
            <button
              onClick={() => {
                syncState({
                  amenitiesFullScreenImage: findKeyByState(amenitiesActiveMesh),
                })
              }}
              style={{
                display: "grid",
                gridAutoFlow: "row",
                backgroundColor: "transparent",
                border: "none",
                marginTop: "2rem",
                marginLeft: "-0.5rem",
                cursor: "pointer",
                pointerEvents: "auto",
              }}
            >
              <span
                style={{
                  fontSize: "1.4rem",
                  fontFamily: "Stolzl",
                  letterSpacing: "0.04rem",
                  color: "#ffffff",
                  placeSelf: "end start",
                  transition: "opacity 0.3s, color 0.3s",
                }}
              >
                VIEW PHOTO
              </span>
              <ArrowSVG
                style={{
                  placeSelf: "start start",
                  width: "18rem",
                  marginLeft: "0.02rem",
                  marginTop: "0.2rem",
                  zIndex: 10,
                }}
              />
            </button>
          )}
      </>
    </div>
  )
}
