import React from "react"

type Props = {
  orbitIndex: number
  orbitRunning: boolean
  orbitDir: boolean
}

const OrbitBGImage = ({ orbitIndex, orbitRunning, orbitDir }: Props) => {
  return (
    <svg
      x="0"
      y="0"
      width="100%"
      height="100%"
      viewBox="0 0 1920 1080"
      className="markers"
      preserveAspectRatio="xMidYMid slice"
    >
      <image
        href={`assets/videos/orbit-nodes/${orbitIndex}.jpg`}
        x="0"
        y="0"
        width="100%"
        height="100%"
        className="mainImage"
      />
      <image
        className="_0"
        href={`assets/videos/orbit-nodes/0.jpg`}
        x="0"
        y="0"
        width="100%"
        height="100%"
        opacity={0}
      />
      <image
      className="_1"
        href={`assets/videos/orbit-nodes/1.jpg`}
        x="0"
        y="0"
        width="100%"
        height="100%"
        opacity={0}
      />
      <image
      className="_2"
        href={`assets/videos/orbit-nodes/2.jpg`}
        x="0"
        y="0"
        width="100%"
        height="100%"
        opacity={0}
      />
      <image
      className="_3"
        href={`assets/videos/orbit-nodes/3.jpg`}
        x="0"
        y="0"
        width="100%"
        height="100%"
        opacity={0}
      />
    </svg>
  )
}

export default OrbitBGImage
