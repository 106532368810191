const globalStyles = {
  colors: {
    sunset: "#C1642D",
    clay: "#151F3C",
    clayH: "#E6BAA8",
    black: "#2A2A2A",
    offBlack: "#1D1D1B",
    white: "#FFFFFF",
    offWhite: "#FFFFFF",
    transparent: "#FFFFFF00",
    plan: "#C1642D",
  },
}
// clay: "#CD545B",

export default globalStyles
