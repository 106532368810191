//@ts-nocheck

function EmailTemplate({ link, name }) {
  const containerStyle = {
    margin: "auto",
    padding: "2em",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    fontFamily:
      "Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri, Helvetica, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    color: "#000",
    maxWidth: "800px",
    textAlign: "left",
    backgroundColor: "#ffffff",
  }

  const resetStyle = {
    margin: 0,
    padding: 0,
    border: 0,
    verticalAlign: "baseline",
  }

  const listStyle = {
    margin: "1em 0",
    paddingLeft: "1.5em",
    fontWeight: "normal",
  }

  const paragraphStyle = {
    margin: "1em 0",
    fontWeight: "normal",
  }

  const buttonStyle = {
    display: "inline-block",
    margin: "1em 0",
    borderRadius: "5px",
    textDecoration: "underline",
    textAlign: "center",
    fontWeight: "bold",
  }
  return (
    <html
      style={{
        width: "100%",
        maxWidth: "100vw",
        backgroundColor: "#ffffff",
        fontFamily:
          "Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri, Helvetica, sans-serif",
      }}
    >
      <head></head>
      <body
        style={{
          width: "100%",
          maxWidth: "100vw",
          backgroundColor: "#ffffff",
          margin: 0,
          padding: 0,
        }}
      >
        <table cellSpacing={0} style={{ margin: "auto", padding: "2em 0" }}>
          <tbody>
            <tr>
              <th>
                <div style={containerStyle}>
                  <div style={{ textAlign: "center", marginBottom: "1em" }}>
                    <img
                      src="https://720-luna-luna-app-git-master-vmi-interactive.vercel.app/email-logo-dark.png"
                      alt="Logo"
                      className="email-logo"
                      style={{
                        display: "block",
                        height: "auto",
                        maxHeight: "70px",
                        marginBottom: "1em",
                      }}
                    />
                  </div>
                  <div style={paragraphStyle}>Hello {name},</div>
                  <div style={paragraphStyle}>
                    Thank you for visiting 720 West End Avenue. We hope you
                    enjoyed your visit. Your personal e-brochure from 720 West
                    End Avenue includes the floorplans and pricing that we
                    discussed during your appointment. Click on the link
                    directly below to view your favorite residences.
                  </div>
                  <a href={link} style={buttonStyle} target="_blank">
                    Your Favorite Residences
                  </a>
                  <div style={{ ...paragraphStyle, fontWeight: "bold" }}>
                    Enclosed please also find the following documents for your
                    review and consideration:
                  </div>
                  <ul style={listStyle}>
                    <li>Digital Brochure</li>
                    <li>Fact Sheet</li>
                    <li>Procedure to Purchase</li>
                    <li>Preferred Attorneys List</li>
                    <li>
                      *Preferred Lenders List and Affiliated Business
                      Arrangement Disclosure Statement
                    </li>
                    <li>Closing Costs and Adjustments</li>
                    <li>Fair Housing Notice</li>
                  </ul>
                  <div style={paragraphStyle}>
                    To recap, 720 West End Avenue is an Upper West Side pre-war
                    landmark retold with modern finesse. Originally designed in
                    1927 by celebrated architect Emery Roth, 720 West End Avenue
                    is re-envisioned today as 131 elegant residences by the
                    award-winning designer Thomas Juul-Hansen. A grand welcome
                    through a restored Renaissance Revival entrance sets the
                    tone for a wide array of exceptional amenities, including
                    on-site parking, basketball/squash, coworking lounge,
                    fitness suite, music rehearsal studio and much more. Take in
                    Hudson River views from new angles and find yourself in
                    conversation with a neighborhood anew, where progress is
                    beautiful.
                  </div>
                  <div
                    style={{
                      ...paragraphStyle,
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Condominium Pricing Starts at:
                  </div>
                  <ul style={listStyle}>
                    <li>One Bedrooms from: $975,000</li>
                    <li>Two Bedrooms from: $2,650,000</li>
                    <li>Three Bedrooms from: $2,995,000</li>
                    <li>Four Bedrooms from: $5,575,000</li>
                    <li>Five Bedrooms from: $10,275,000</li>
                  </ul>
                  <div style={paragraphStyle}>
                    Please note, if you would like to submit a formal offer to
                    purchase an apartment at 720 West End Avenue, the Sponsor
                    requires a completed Procedure to Purchase Package
                    (attached). Please let us know if you have any additional
                    questions or if you require any further information.
                  </div>
                  <div style={paragraphStyle}>Thank you,</div>
                  <div style={paragraphStyle}>
                    The 720 West End Avenue Sales Team
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  )
}

export default EmailTemplate
