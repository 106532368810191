import { useSocketIO } from "@providers/sockets/SocketIOProvider"
import useStore from "@state/store"
import { InitialState } from "@state/types.store"

export const useSyncStatesWithSocket = () => {
  const setLocalState = useStore((state) => state.setLocalState)
  const { emitSyncMultipleStateEvent } = useSocketIO()

  const syncStatesWithSocket = (payload: Partial<InitialState>) => {
    emitSyncMultipleStateEvent(payload)
    setLocalState(payload)
  }

  return {
    syncStatesWithSocket,
  }
}
