import { TableRow } from "./TableRow"

const TableBody = ({
  uniqueKey,
  data,
  cells,
  selectedRows,
  disabledRows,
  handleRowClick,
}) => (
  <div className="flex-table-body">
    {data.map((row: any, index: number) => (
      <TableRow
        key={index}
        row={row}
        rowIndex={index}
        cells={cells}
        disabled={disabledRows.some(
          (rowItem: any) => rowItem[uniqueKey] === row[uniqueKey],
        )}
        isSelected={
          Array.isArray(selectedRows)
            ? selectedRows.some(
                (rowItem) =>
                  rowItem && row && rowItem[uniqueKey] === row[uniqueKey],
              )
            : selectedRows === row
        }
        onClick={() => handleRowClick(row)}
      />
    ))}
  </div>
)

export { TableBody }
