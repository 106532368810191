import React from "react"
import styled from "styled-components"
import CloseSVG from "@pages/tablet/amenities/components/CloseSVG"
import Form from "./components/Form"
import shared from "@assets/icons/shared.svg"

export interface IShareProps {
  className?: string
  onClose: () => void
}

export default function Share(props: IShareProps) {
  return (
    <Wrapper className={props.className}>
      {/* <img className="shared" src={shared} alt="help" /> */}
      <Form />
      <div className="close-button">
        <CloseSVG onClick={props.onClose} color="white" />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .shared {
    width: 30%;
    margin-top: 10%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  position: absolute;
  left: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 300;

  .close-button {
    position: absolute;
    top: 1em;
    right: 1em;
    height: 4em;
    width: 4em;
    padding: 1em;
  }

  /* reset */
  input,
  textarea,
  button {
    all: unset;
    box-sizing: border-box;
  }

  .FormRoot {
    width: 50%;
  }

  .FormField {
    display: grid;
    margin-bottom: 10px;
  }

  .FormLabel {
    font-size: 15px;
    font-weight: 500;
    line-height: 35px;
    color: white;
  }

  .FormMessage {
    font-size: 13px;
    color: white;
    opacity: 0.8;
  }

  .Input,
  .Textarea {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: white;
  }
  .Input:hover,
  .Textarea:hover {
  }
  .Input:focus,
  .Textarea:focus {
  }
  .Input::selection,
  .Textarea::selection {
    color: white;
  }

  .Input {
    height: 35px;
    line-height: 1;
    border-bottom: 1px solid #fff;
  }

  .Textarea {
    resize: none;
    padding: 10px;
  }

  .Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 35px;
    width: 100%;
    border: 2px solid white;
  }

  .Button:focus {
    box-shadow: 0 0 0 2px black;
  }
`
