import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import React from "react"
import { useEffect, useState } from "react"
import styled from "styled-components"
import Carousel from "@components/gallery/Carousel"
import gsap from "gsap"
import compress from "@assets/icons/compress.png"

const Gallery = React.memo(function Gallery() {
  const [images, setImages] = useState<string[]>([])
  const { selectedGalleryImage } = useStore((s) => ({
    selectedGalleryImage: s.selectedGalleryImage,
  }))
  const { selectedUnit, imageExpanded } = useStore((state) => ({
    selectedUnit: state.selectedUnit,
    imageExpanded: state.imageExpanded,
  }))

  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  useEffect(() => {
    const loadImages = async () => {
      let imageModules = {}
      const images = import.meta.glob(
        "../../../../../public/assets/images/gallery/Interiors/*.{jpg,png,webp}",
      )
      Object.assign(imageModules, await images)

      const imagePaths: string[] = await Promise.all(
        Object.values(imageModules).map(async (importer: any) => {
          const module: any = await importer()
          return module.default
        }),
      )

      setImages(imagePaths)
    }

    loadImages()

    return () => {
      setImages([])
    }
  }, [selectedUnit])

  useEffect(() => {
    if (imageExpanded) {
      const tl = gsap.timeline()
      tl.fromTo(
        [".imageOverlay", ".imageButtonOverlay"],
        {
          autoAlpha: 0,
          duration: 0.6,
          ease: "power2.inOut",
        },
        {
          autoAlpha: 1,
          duration: 0.6,
          ease: "power2.inOut",
        },
      )
    } else if (!imageExpanded) {
      const tl = gsap.timeline()
      tl.fromTo(
        [".imageOverlay", ".imageButtonOverlay"],
        {
          autoAlpha: 1,
          duration: 0.6,
          ease: "power2.inOut",
        },
        {
          autoAlpha: 0,
          duration: 0.6,
          ease: "power2.inOut",
        },
      )
    }
  }, [imageExpanded])

  return (
    <Wrapper>
      <div className="image-grid">
        {images.map((src, index) => (
          <div
            key={index}
            style={{ backgroundColor: "black", position: "relative" }}
          >
            <img
              style={{
                opacity: selectedGalleryImage === index ? "0.5" : "",
              }}
              key={index}
              src={src}
              onClick={() => {
                syncState({ selectedGalleryImage: index, imageExpanded: true })
              }}
              alt={`Image ${index + 1}`}
            />
          </div>
        ))}
        <ImageOverlay
          className="imageOverlay"
          style={{
            placeSelf: "center",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "white",
            placeItems: "center",
            height: "100%",
            width: "100%",
            zIndex: "500",
            overflow: "hidden",
          }}
        >
          <Carousel slides={images} />
          <div
            className="expand"
            onClick={() => {
              if (!imageExpanded) syncState({ imageExpanded: true })
              else {
                syncState({ imageExpanded: false })
              }
            }}
          >
            {imageExpanded && (
              <img
                style={{ width: "100%", zIndex: "50" }}
                src={compress}
                alt=""
              />
            )}
          </div>
        </ImageOverlay>
      </div>
    </Wrapper>
  )
})

export default Gallery

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .image-grid {
    position: absolute;
    right: 10em;
    width: 50%;
    max-height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 10px;
    overflow-y: auto;
    padding-bottom: 20rem;
  }

  .expand {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 0.75rem;
    border-radius: 100%;
    position: fixed;
    bottom: 5rem;
    right: 3rem;
    color: white;
    z-index: 250;
    transition: background-color 0.2s ease-in-out;

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .image-grid img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    transition: 0.6s ease;
  }

  .image-grid img:nth-child(1),
  .image-grid img:nth-child(3n + 4) {
    grid-column: 1 / -1;
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  .image-overlay {
    opacity: 0;
  }
`
