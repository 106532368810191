import React from "react"
import styled from "styled-components"
import { useButtonFillColor } from "../../hooks/useButtonFillColor"

type ButtonProps = {
  style?: React.CSSProperties
  location: string
  onClick?: () => void
}

const MediaButton: React.FC<ButtonProps> = ({ onClick, location, style }) => {
  const { fillColor } = useButtonFillColor(location)

  return (
    <Button
      onClick={onClick}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8742 0L9.14816 2.15759H0V17.6921H25.1354V2.15759H23.3882L21.6621 0H10.8742Z"
          fill={fillColor}
        />
      </svg>
    </Button>
  )
}

export { MediaButton }

const Button = styled.button`
  padding: 1rem;
  border-radius: 50%;
  height: 4em;
  width: 4em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg > path {
    transition: fill 0.4s ease-in-out;
  }
`
