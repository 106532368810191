import { useSocketIO } from "@providers/sockets/SocketIOProvider"
import roomToNav from "@data/roomToNav.map.json"
import { useNavigate } from "react-router-dom"
import { useJoinRoom } from "./useJoinRoom"
import useStore from "@state/store"

const useRoomSelector = () => {
  const { room, emitSocketEvent } = useSocketIO()
  const navigate = useNavigate()
  const { roomConnectAttempt } = useJoinRoom()
  const [setLocalState] = useStore((s) => [s.setLocalState])

  const handleRoomSelector = (txt: any) => {
    const roomName = txt.replaceAll(" ", "").trim()
    if (txt === "reset") {
      emitSocketEvent("reload", {})
      setLocalState({ roomTakenOver: null })
      navigate("/")
      window.location.reload()
    } else {
      if (room !== roomName) {
        roomConnectAttempt((newRoom) => {
          const newLocation = roomToNav[newRoom].nav
          // navigate(`/${newLocation}`)
        }, roomName)
      }
      // Take over room
      setLocalState({ roomTakenOver: roomName })
    }
  }

  return { handleRoomSelector }
}

export default useRoomSelector
