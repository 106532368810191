import InfoCard from "@pages/tablet/residences/components/InfoCard"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

interface ISingleUnitProps {
  selectedRows: any
  removeFromFavorites: any
}

export const partIndex = {
  N: ["7", "9"],
  E: ["8", "10"],
  S: ["3", "2", "1"],
  W: ["6", "5", "4"],
}

export function findMatchingKeys(item) {
  return Object.keys(partIndex).filter((key) => partIndex[key].includes(item))
}

export default function SingleUnit(props: ISingleUnitProps) {
  const { selectedRows } = props
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const { activeDuplexFloorplan, compareSection } = useStore((state) => ({
    activeDuplexFloorplan: state.activeDuplexFloorplan,
    compareSection: state.compareSection,
  }))
  const exceptions = ["PH16A", "PH16D", "PH16E", "PH17A"]
  const [parts, setParts] = useState<string[]>([])
  const [suffix, setSuffix] = useState<string>("")
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeView, setActiveView] = useState<string>("")


  const isDuplex = (unit: any) => {
    return (
      unit?.unitType?.startsWith("TH") ||
      (unit?.unitType?.startsWith("PH") && !exceptions.includes(unit?.unitType))
    )
  }

  const handleSetActiveView = useCallback((index: number) => {
    setActiveIndex(index)
  }, [])


  useEffect(() => {
    setActiveIndex(0)
    if (selectedRows?.view) {
      const splitParts = selectedRows?.view.split("-")
      const lastPart = splitParts.pop()
      setSuffix(lastPart?.toUpperCase() || "")
      setParts(splitParts)
    }
  }, [selectedRows?.view])

  useEffect(() => {
    if (parts.length) {
      const view = `${parts[activeIndex]}-${suffix}.webp`
      setActiveView(view)
      syncState({ activeView: view })
    }

    return () => {
      syncState({ activeView: null })
    }
  }, [parts, activeIndex, suffix])

  const toggleDuplexFloorplan = () => {
    syncState({
      activeDuplexFloorplan:
        activeDuplexFloorplan === "UPPER" ? "LOWER" : "UPPER",
    })
  }

  useEffect(() => {
    return () => {
      syncState({ activeDuplexFloorplan: "UPPER" })
    }
  }, [selectedRows])

  // just check if we have selected rows, and don't render anything if we don't
  if (
    !selectedRows ||
    (Array.isArray(selectedRows) && !selectedRows[0] && !selectedRows[1])
  ) {
    return null
  }

  return (
    <Wrapper>
      {compareSection === 'floorplan' && (
      <div
        className={`single-unit ${!selectedRows || (selectedRows[0] === null && selectedRows[1] === null) ? "hidden" : ""}`}
      >
        <div
          className="remove-favorite"
          onClick={() => {
            props.removeFromFavorites(selectedRows)
            syncState({ selectedRows: null })
          }}
        >
          Remove Favorite
        </div>
        <div className="residence-info">
          <InfoCard selectedUnit={selectedRows} />
          <div
            className={`duplex-toggle ${isDuplex(selectedRows) ? "show" : "hide"}`}
          >
            <span
              onClick={toggleDuplexFloorplan}
              className={activeDuplexFloorplan === "UPPER" ? "active" : ""}
            >
              UPPER
            </span>
            <span
              onClick={toggleDuplexFloorplan}
              className={activeDuplexFloorplan === "LOWER" ? "active" : ""}
            >
              LOWER
            </span>
          </div>
        </div>
        <div className="unit-floorplan">
          <img
            src={`/assets/images/residences/floorplans/${selectedRows?.unitType}-F${isDuplex(selectedRows) ? "-" + activeDuplexFloorplan : ""}.png`}
            alt="Floorplan"
          />
        </div>
        <div className="unit-floorplate">
          <img
            alt="Floorplan"
            src={`/assets/images/residences/floorplans/${selectedRows?.unitType}-P${isDuplex(selectedRows) ? "-" + activeDuplexFloorplan : ""}.png`}
          />
        </div>
      </div>
      )}
       {compareSection === 'view' && (
         <div className="compare-unit">
         <img
         style={{width: "100%", height: "100%", objectFit: "contain"}}
           className="img"
           src={`/assets/images/residences/views/${activeView}`}
           alt="Floorplan"
         />
         {parts.length > 1 && (
          <div className={`views-buttons`}>
            {parts.map((part, index) => (
              <button key={index} onClick={() => handleSetActiveView(index)}>
                {findMatchingKeys(part)}
              </button>
            ))}
          </div>
          )}
     </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;


  .views-buttons {
    position: absolute;
    bottom: 3em;
    right: 3em;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 1.5em;
    background: rgba(255, 255, 255, 1);
    border-radius: 1em;

    button {
      padding: 2rem 2.25rem;
      border: 1px solid #000;
      cursor: pointer;
      font-size: 1rem;
      background-color: transparent;

      &:hover {
        background: #000;
        color: #fff;
      }
    }

    &.hide {
      display: none;
    }
  }

  .single-unit {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.6s;

    &.hidden {
      opacity: 0;
      transition: all 0.6s;
    }

    .residence-info {
      position: relative;
      width: 100%;
      height: 25%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .unit-info {
        transform: scale(1.2);
        transform-origin: left;
      }

      .duplex-toggle {
        display: flex;
        font-weight: bold;

        &.show {
          opacity: 1;
          margin-left: 0em;
          pointer-events: auto;
          transition: all 0.3s ease-in-out;
        }

        &.hide {
          opacity: 0;
          margin-left: -5em;
          pointer-events: none;
          transition: all 0.3s ease-in-out;
        }

        span {
          color: #000;
          font-size: 1.3em;
          background: none;
          padding: 0.5em 1em;
          border: 1px solid #000;
          transition: all 0.3s ease-in-out;

          &.active {
            background-color: rgba(0, 0, 0, 0.8);
            color: white;
          }
        }
      }

      .separator-root[data-orientation="vertical"] {
        height: 100%;
        width: 0.5px;
        margin: 0 10px;
        background-color: #000;
      }
    }

    .unit-floorplan {
      height: 75%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5em;

      img {
        object-fit: contain;
        width: 100%;
        height: 85%;
        margin-bottom: auto;
      }
    }

    .unit-floorplate {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1em;
      height: 13em;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .remove-favorite {
      position: absolute;
      font-size: 1.2em;
      top: 4em;
      right: 3em;
      padding: 1em 1.5em;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      cursor: pointer;
      z-index: 1;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .compare-unit{
    height: 100%;
  }

  .single-unit.visible {
    opacity: 1;
    transition: all 0.6s;
  }
`
