import useStore from "@state/store"
import ArrowSVG from "./ArrowSVG"
import { useEffect, useState } from "react"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"

export default function Selector() {
  const amenitiesSelector = useStore((state) => state.amenitiesSelector)
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  return (
    <div
      id="sidebar-selector"
      style={{
        padding: "3rem 1rem",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        placeSelf: "center end",
        display: "grid",
        placeItems: "center start",
        gridAutoFlow: "row",
        rowGap: "0.4rem",
        zIndex: 10,
        marginRight: "6rem",
        marginTop: "48rem",
      }}
    >
      <p
        style={{
          fontSize: "1.8rem",
          letterSpacing: "0.2rem",
          marginLeft: "0.3rem",
        }}
      >
        EXPLORE AMENITIES
      </p>
      <br />
      {["UPPER", "LOWER"].map((item, index) => {
        const active = item === amenitiesSelector
        return (
          <button
            onClick={() => {
              syncState({ amenitiesSelector: item, amenitiesActiveMesh: null })
            }}
            key={index}
            style={{
              display: "grid",
              gridAutoFlow: "row",
              backgroundColor: "transparent",
              border: "none",
              marginBottom: "0.8rem",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                fontSize: "1.3rem",
                opacity: active ? 1 : 0.6,
                letterSpacing: "0.2rem",
                color: active ? "#fff" : "#cbc6c6",
                placeSelf: "end start",
                transition: "opacity 0.3s, color 0.3s",
              }}
            >
              {item} LEVEL
            </span>
            <ArrowSVG
              style={{
                placeSelf: "start start",
                width: "24rem",
                marginLeft: "0.02rem",
              }}
            />
          </button>
        )
      })}
    </div>
  )
}
