import { Filters, SortConfig } from "@state/types.store"

export function filterTable(
  filters: Filters,
  orbitAspects: any,
  data: any[],
): any[] {
  const [minFloor, maxFloor] = filters.floor || []
  const [minPrice, maxPrice] = filters.price || []
  return data.filter((item) => {
    // const isOrbitViewValid = item.aspect
    //   .split("")
    //   .some((v: string) => orbitAspects.includes(v))

    const isFloorValid =
      !filters.floor ||
      (filters.floor.length === 2 &&
        item.floor >= minFloor &&
        item.floor <= maxFloor)

    const isPriceValid =
      !filters.price ||
      (filters.price.length === 2 &&
        Number(item.price) >= Number(minPrice) &&
        Number(item.price) <= Number(maxPrice))

    const isBedroomValid =
      !filters.bedroom ||
      filters.bedroom.length === 0 ||
      filters.bedroom.includes(item.beds.toString())

    const isViewValid: boolean =
      !filters.view ||
      filters.view.length === 0 ||
      item.aspect.split("").some((v: string) => filters.view.includes(v))

    const isStatusValid =
      !filters.status ||
      filters.status.length === 0 ||
      filters.status.includes(item.status.toString())

    return (
      // isOrbitViewValid &&
      isBedroomValid &&
      isViewValid &&
      isFloorValid &&
      isPriceValid &&
      isStatusValid
    )
  })
}

export function sortTable(data: any[], sortConfig: SortConfig) {
  if (!sortConfig.field) {
    return { sortedData: data, newSortConfig: sortConfig }
  }

  const newDirection =
    sortConfig.direction === "ascending" ? "descending" : "ascending"

  const sortedData = [...data].sort((a, b) => {
    let valueA = a[sortConfig.field]
    let valueB = b[sortConfig.field]

    // Special handling for unitID field
    // This is because we want to sort by the numeric part first, then the alphabetic part
    // e.g. 2A to come before 10A
    if (sortConfig.field === "unitID") {
      const [numA, alphaA] = valueA.match(/(\d+)([A-Za-z]*)/).slice(1, 3)
      const [numB, alphaB] = valueB.match(/(\d+)([A-Za-z]*)/).slice(1, 3)

      const numericComparison = parseInt(numA, 10) - parseInt(numB, 10)
      if (numericComparison !== 0) {
        return newDirection === "ascending"
          ? numericComparison
          : -numericComparison
      }

      return newDirection === "ascending"
        ? alphaA.localeCompare(alphaB)
        : alphaB.localeCompare(alphaA)
    }

    // Convert to numbers if both values are numeric
    if (!isNaN(valueA) && !isNaN(valueB)) {
      valueA = parseFloat(valueA)
      valueB = parseFloat(valueB)
    }

    if (newDirection === "ascending") {
      return valueA > valueB ? 1 : -1
    } else {
      return valueA < valueB ? 1 : -1
    }
  })

  return {
    sortedData,
    newSortConfig: { field: sortConfig.field, direction: newDirection },
  }
}
