import React from "react"
import * as Select from "@radix-ui/react-select"
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons"
import "./styles.css"

type SelectItemProps = {
  children: React.ReactNode
  value: string
  disabled?: boolean
}

const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <Select.Item className="SelectItem" {...props} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="SelectItemIndicator">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    )
  },
)

const Dropdown = ({ options, onValueChange }) => {
  const [presetFieldValue, setPresetFieldValue] = React.useState("")

  return (
    <div style={{ position: "relative" }}>
      <Select.Root
        disabled={options?.length === 0}
        value={presetFieldValue}
        onValueChange={(v) => {
          setPresetFieldValue(v)
          onValueChange(v)
        }}
      >
        <Select.Trigger className="SelectTrigger">
          <Select.Value
            placeholder={options?.length > 0 ? "SELECT A PRESET" : "NO PRESETS"}
          />
          <Select.Icon className="SelectIcon">
            <ChevronDownIcon />
          </Select.Icon>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content className="SelectContent" position="popper">
            <Select.ScrollUpButton className="SelectScrollButton">
              <ChevronUpIcon />
            </Select.ScrollUpButton>
            <Select.Viewport className="SelectViewport">
              <Select.Group>
                {options.map((option: any) => (
                  <SelectItem key={option.name} value={option.name}>
                    {option.name}
                  </SelectItem>
                ))}
              </Select.Group>
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
      <div
        style={{ position: "absolute", top: "-35px", right: 0 }}
        onClick={() => {
          setPresetFieldValue("")
          onValueChange("")
        }}
      >
        CLEAR
      </div>
    </div>
  )
}

export default Dropdown
