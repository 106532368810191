import gsap from "gsap"
import { useEffect, useState, useRef, ReactNode } from "react"
import styled from "styled-components"

function Popup({
  openState,
  setOpenState,
  children,
  durationMS,
  closeButton,
  onClose,
}: {
  openState: boolean
  setOpenState: (v: boolean) => void
  children: ReactNode
  durationMS: number
  closeButton?: ReactNode
  onClose?: () => void
}) {
  const popupRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    gsap.to(popupRef.current, {
      autoAlpha: openState ? 1 : 0,
    })
  }, [openState])

  return (
    <PopupWrapper

      ref={popupRef}
      // ref={(node) => node && handleRef(node)}
    >
      {children}
      <Close onClick={() => setOpenState(false)}>{closeButton}</Close>
      {/* {closeButton ? (
      ) : (
        <Close onClick={handleClose}>
          <CloseIcon strokeWidth={7} strokeColor={"#FFFFFF"} />
        </Close>
      )} */}
    </PopupWrapper>
  )
}

export default Popup

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  /* transition-property: opacity; */
  /* transition-timing-function: ease-in-out; */
  pointer-events: none;
`

const Close = styled.div`
  position: absolute;
  z-index: 100;
  z-index: 1;
  width: 1rem;
  aspect-ratio: 1/1;
  cursor: pointer;
  pointer-events: auto;
  top: 2rem;
  right: 2rem;
  display: grid;
  place-content: center;
  border-radius: 100%;
`

type TSVGPropTypes = {
  strokeWidth: number
  strokeColor: string
  bgColor?: string
  [x: string]: any
}

function CloseIcon({
  strokeWidth,
  strokeColor,
  bgColor,
  ...props
}: TSVGPropTypes) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width="100%"
      height="100%"
      {...props}
    >
      <circle cx="50" cy="50" r="50" fill={bgColor ?? "none"} />
      <line
        x1="5"
        y1="5"
        x2="95"
        y2="95"
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <line
        x1="95"
        y1="5"
        x2="5"
        y2="95"
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
