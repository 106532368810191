import { useEffect } from "react"
import {
  Amenities as TabletAmenities,
  Landing as TabletLanding,
  Location as TabletLocation,
  Residences as TabletResidences,
} from "@pages/tablet"

import {
  Amenities as ScreenAmenities,
  Landing as ScreenLanding,
  Location as ScreenLocation,
  Residences as ScreenResidences,
} from "@pages/screen"

import { useMediaQuery } from "react-responsive"
import useStore from "@state/store"

const RoutesConfig = () => {
  // Override the default value of isTablet with the value from the store
  const { setLocalState } = useStore((s) => ({
    setLocalState: s.setLocalState,
  }))
  // const isTablet = useMediaQuery({ maxWidth: 1366, maxHeight: 1024 })
  const isTablet = true
  // || true
  useEffect(() => {
    setLocalState({ isTablet: isTablet })
  }, [isTablet])

  const Landing = isTablet ? TabletLanding : ScreenLanding
  const Amenities = isTablet ? TabletAmenities : ScreenAmenities
  const Location = isTablet ? TabletLocation : ScreenLocation
  const Residences = isTablet ? TabletResidences : ScreenResidences

  const routes = [
    {
      name: "Landing",
      path: "/",
      exact: true,
      isNavLink: false,
      element: Landing,
    },
    {
      name: "Upper West Side",
      path: "/location",
      exact: true,
      isNavLink: true,
      element: Location,
    },
    {
      name: "Amenities",
      path: "/amenities",
      exact: true,
      isNavLink: true,
      element: Amenities,
    },
    {
      name: "Residences",
      path: "/residences",
      exact: true,
      isNavLink: true,
      element: Residences,
    },
  ]

  return { routes, isTablet }
}

export default RoutesConfig
