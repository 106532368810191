import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

const emailSuggestions = [
  { email: "blake.weissberg@corcoransunshine.com", name: "Blake Weissberg" },
  { email: "crista.villella@corcoransunshine.com", name: "Crista Villella" },
  { email: "dina.wyche@corcoransunshine.com", name: "Dina Wyche" },
]

const AutocompleteInput = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value)
  const [filteredSuggestions, setFilteredSuggestions] =
    useState(emailSuggestions)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleChange = (e) => {
    const value = e.target.value
    setInputValue(value)
    onChange(value)

    if (value) {
      const filtered = emailSuggestions.filter((suggestion) =>
        suggestion.email.toLowerCase().includes(value.toLowerCase()),
      )
      setFilteredSuggestions(filtered)
    } else {
      setFilteredSuggestions(emailSuggestions)
    }

    setShowSuggestions(true)
  }

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.email)
    onChange(suggestion.email)
    setShowSuggestions(false)
  }

  const handleBlur = () => {
    setTimeout(() => setShowSuggestions(false), 100)
  }

  return (
    <AutocompleteWrapper>
      <input
        style={{ width: "100%" }}
        type="email"
        placeholder="Email"
        value={inputValue}
        onChange={handleChange}
        onFocus={() => setShowSuggestions(true)}
        onBlur={handleBlur}
        ref={inputRef}
      />
      {showSuggestions && (
        <SuggestionsList>
          {filteredSuggestions.map((suggestion, index) => (
            <SuggestionItem
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.name}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
    </AutocompleteWrapper>
  )
}

const AutocompleteWrapper = styled.div`
  position: relative;
  width: 80%;
`

const SuggestionsList = styled.ul`
  position: absolute;
  width: 100%;
  background-color: #000000;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  display: flex;
  gap: 1em;
  margin-top: 1em;
`

const SuggestionItem = styled.li`
  padding: 8px 8px;
  cursor: pointer;
  color: white;
  border: 1px dashed;
  border-radius: 5px;

  &:hover {
    background-color: #eee;
    color: black;
  }
`

export default AutocompleteInput
