import styled from "styled-components"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import Popup from "@components/popup"
import useStore from "@state/store"
import LocationOrbit from "./components/LocationOrbit"
import { Controls } from "./components/Controls"
import { Overlay } from "./components/Overlay"
import OrbitCaptions from "@components/orbit-captions"
import StyledButton from "@components/buttons/StyledButton"
import { DesktopIcon } from "@radix-ui/react-icons"
import { useSocketIO } from "@providers/sockets/SocketIOProvider"
import SyncOnMount from "@components/SyncOnMount"
import { useEffect } from "react"

type Props = {}

export function Location(props: Props) {
  const { socket } = useSocketIO()
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const { orbitIndex, orbitDir, orbitRunning, showMapScreenSaver, orbit } =
    useStore((s) => ({
      orbitIndex: s.orbitIndex,
      orbitDir: s.orbitDir,
      orbitRunning: s.orbitRunning,
      showMapScreenSaver: s.showMapScreenSaver,
      orbit: s.orbit,
    }))

  useEffect(() => {
    syncState({ orbit: true, orbitIndex: orbitIndex })
  }, [])

  return (
    <Wrapper>
      <SyncOnMount />
      {socket?.connected ? (
        <StyledButton
          onClick={(e) => {
            syncState({ showMapScreenSaver: !showMapScreenSaver })
          }}
          icon={<DesktopIcon height={15} width={15} color="#fff" />}
          color="#fff"
          border={true}
          style={{
            position: "absolute",
            top: "2em",
            left: "2em",
            margin: "auto",
            borderRadius: 50,
            zIndex: 2000,
          }}
        ></StyledButton>
      ) : null}

      <Controls />
      <Overlay />
      <Popup
        openState={orbit}
        setOpenState={(v) => syncState({ orbit: v })}
        durationMS={300}
        closeButton={null}
      >
        <LocationOrbit
          orbitIndex={orbitIndex}
          orbitDir={orbitDir}
          orbitRunning={orbitRunning}
        />
        <OrbitCaptions
          orbitIndex={orbitIndex}
          orbitRunning={orbitRunning}
          orbitDir={orbitDir}
        />
      </Popup>
      <Vignette></Vignette>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
`

const Vignette = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  box-shadow: inset 0px -100px 100px 0px rgba(0, 0, 0, 0.8);
  pointer-events: none;
`
