import { amenitiesImages } from "@data/amenitiesData"
import useStore from "@state/store"
import { gsap } from "gsap"
import { useEffect } from "react"

export default function ImageScrollLogic() {
  const amenitiesActiveImage = useStore((state) => state.amenitiesActiveImage)

  useEffect(() => {
    setTimeout(() => {
      const hasMatchingState = Object.values(amenitiesImages).some(
        (image) => image.state === amenitiesActiveImage,
      )
      if (!hasMatchingState) {
        return
      }
      if (!amenitiesActiveImage || amenitiesActiveImage === "Lobby") {
        gsap.to(".image-gallery", {
          duration: 1.8,
          y: "-8rem",
          ease: "power4.inOut",
          onStart: () => {
            const imgs = gsap.utils.toArray(".image-item")
            imgs.forEach((img, index) => {
              gsap.to(`#amenities-imgs-${index}`, {
                duration: 0.7,
                ease: "power4.inOut",
                scale: (i) => 1 - (i + 1) * 0.02,
                delay: (i) => i * 0.3,
                onStart: () => {
                  gsap.set("#amenities-imgs-0", {
                    src: `/assets/amenities/lowres/${Object.keys(amenitiesImages)[index]}.webp`,
                  })
                },
                onComplete: () => {
                  gsap.to(`#amenities-imgs-${index}`, {
                    scale: 1,
                    duration: 1,
                    delay: (i) => i * 0.04,
                    ease: "power4.inOut",
                    onComplete: () => {
                      gsap.set("#amenities-imgs-0", {
                        src: `/assets/amenities/${screen}/${Object.keys(amenitiesImages)[index]}.webp`,
                      })
                    },
                  })
                },
              })
            })
          },
        })
      } else {
        const keys = Object.keys(amenitiesImages)
        for (let i = 0; i < keys.length; i++) {
          const item = keys[i]
          if (amenitiesImages[item].state === amenitiesActiveImage) {
            const element = document.getElementById(`amenities-imgs-${i}`)
            if (element) {
              const elementRect = element.getBoundingClientRect()

              const currentScroll = window.scrollY

              const y = -(i + 1) * 15 + 5

              gsap.to(".image-gallery", {
                duration: 1.8,
                y: `${y}rem`,
                ease: "power4.inOut",
                onStart: () => {
                  const imgs = gsap.utils.toArray(".image-item")
                  imgs.forEach((img, index) => {
                    gsap.to(`#amenities-imgs-${index}`, {
                      duration: 0.7,
                      ease: "power4.inOut",
                      scale: (i) => 1 - (i + 1) * 0.02,
                      delay: (i) => i * 0.3,
                      onComplete: () => {
                        gsap.to(`#amenities-imgs-${index}`, {
                          scale: 1,
                          duration: 1,
                          delay: (i) => i * 0.04,
                          ease: "power4.inOut",
                        })
                      },
                    })
                  })
                },
              })
              break
            } else {
              gsap.to(".image-gallery", {
                duration: 1.8,
                y: "-8rem",
                ease: "power4.inOut",
                onStart: () => {
                  const imgs = gsap.utils.toArray(".image-item")
                  imgs.forEach((img, index) => {
                    gsap.to(`#amenities-imgs-${index}`, {
                      duration: 0.7,
                      ease: "power4.inOut",
                      scale: (i) => 1 - (i + 1) * 0.02,
                      delay: (i) => i * 0.3,
                      onComplete: () => {
                        gsap.to(`#amenities-imgs-${index}`, {
                          scale: 1,
                          duration: 1,
                          delay: (i) => i * 0.04,
                          ease: "power4.inOut",
                        })
                      },
                    })
                  })
                },
              })
            }
          }
        }
      }
    }, 10)
  }, [amenitiesActiveImage])
  return null
}
