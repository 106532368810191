uniform float uOpacity;
uniform float uBW; 
uniform sampler2D uTexture;
varying vec2 vUv;

void main() {
    vec4 color = texture2D(uTexture, vUv);
    float gray = dot(color.rgb, vec3(0.16,0.16,0.16));  // Luminosity method for grayscale
    vec3 grayscale = vec3(gray);  // Grayscale color

    // Interpolate between grayscale and original color based on uBW
    vec3 finalColor = mix(grayscale, color.rgb, uBW);

    gl_FragColor = vec4(finalColor, uOpacity);
}