import { Html, Text } from "@react-three/drei"
import { stolzlJson } from "./stolzlJson"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"

export default function CanvasText({ name, pos }) {
  const amenitiesActiveMesh = useStore((state) => state.amenitiesActiveMesh)
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  return (
    <group
      position={[pos[0], pos[1] + 550, pos[2]]}
      onClick={() => syncState({ amenitiesActiveMesh: name })}
    >
      {/* <mesh>
        <cylinderGeometry args={[4, 4, 300, 16]} />
        <meshBasicMaterial
          color="white"
          opacity={
            amenitiesActiveMesh ? (amenitiesActiveMesh === name ? 1 : 0.5) : 1
          }
          transparent
        />
      </mesh> */}
      <mesh position={[0, -250, 0]}>
        <sphereGeometry args={[15, 12, 12]} />
        <meshBasicMaterial
          color="white"
          opacity={
            amenitiesActiveMesh ? (amenitiesActiveMesh === name ? 1 : 0.5) : 1
          }
          transparent
        />
      </mesh>
      <group position={[0, 350, 0]}>
        <Html center>
          <p
            onClick={() => syncState({ amenitiesActiveMesh: name })}
            style={{
              color: "white",
              opacity: amenitiesActiveMesh
                ? amenitiesActiveMesh === name
                  ? 1
                  : 0.5
                : 1,
              transition: "opacity 0.4s",
              fontSize: 7,
              letterSpacing: "0.1rem",
              width: "1000rem",
              textAlign: "center",
              fontFamily: "Stolzl",
              textTransform: "uppercase",
              pointerEvents: "auto",
            }}
          >
            {name}
          </p>
        </Html>
      </group>
    </group>
  )
}
