import * as Dialog from "@radix-ui/react-dialog";
import RoomTakePopup from "./RoomTakePopup";
import globalStyles from "@data/style.globals";
import styled from "styled-components";
import useStore from "@state/store";

function RoomPopup() {
  const showRoomPopup = useStore((s) => s.showRoomPopup);

  return (
    <DialogRoot
      open={showRoomPopup}
    >
      {/* <DialogOverlay /> */}
      <DialogContent>
        <RoomTakePopup />
      </DialogContent>
    </DialogRoot>
  );
}

export default RoomPopup;

const DialogRoot = styled(Dialog.Root)`
  z-index: 100;
  position: absolute;
  background-color: ${globalStyles.colors.white};
  font-family: "Stolzl";
`;

const DialogContent = styled(Dialog.Content)`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: grid;
  place-items: center;
  grid-template-rows: minmax(0, 0.5fr) minmax(0, 1fr) minmax(0, 5fr) minmax(
      0,
      1fr
    );
  grid-auto-flow: row;
  background-color: ${globalStyles.colors.white};

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  & p {
    grid-column: 1 / -1;
    grid-row: 2;
    font-size: 2.4rem;
    height: 100%;
    width: 100%;
    text-align: center;
    display: grid;
    place-items: center;
    place-self: start center;
    color: ${globalStyles.colors.clay};
  }

  & section {
    grid-column: 1 / -1;
    grid-row: 3;
    display: grid;
    place-items: center;
    grid-auto-flow: row;
    gap: 2rem;

    & div {
      height: 100%;
      width: 100%;
      text-align: center;
      display: grid;
      grid-auto-flow: row;
      place-items: center start;
      border: 2px solid ${globalStyles.colors.clay};
      position: relative;
      grid-template-rows: minmax(0, 2fr) minmax(0, 1fr);

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 8px;
        height: 100%;
        width: 100%;
        border-right: 2px solid ${globalStyles.colors.clay};
        border-bottom: 2px solid ${globalStyles.colors.clay};
        border-radius: 0;
        pointer-events: none;
        transform: translate(-3px, -3px);
      }

      & h3 {
        grid-row: 1 / -1;
        grid-row: 2;
        height: 100%;
        width: 100%;
        display: grid;
        place-items: end;
        color: ${globalStyles.colors.clay};
        font-size: 3rem;
        text-transform: uppercase;
        padding-right: 2rem;
        padding-bottom: 2rem;
      }

      & p {
        grid-row: 1 / -1;
        grid-row: 1;
        height: 100%;
        width: 100%;
        display: grid;
        place-items: end;
        color: ${globalStyles.colors.clay};
        font-size: 2rem;
        text-transform: uppercase;
        padding-right: 2rem;
      }
    }
  }
`;

const DialogOverlay = styled(Dialog.Overlay)`
  background-color: ${globalStyles.colors.white};
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  z-index: 99;
  &[data-state="open"] {
    animation: fadeIn 0.2s cubic-bezier(1, -0.09, 0.46, 1);
  }
  &[data-state="closed"] {
    animation: fadeOut 0.2s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
