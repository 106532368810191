const path_poly_shared = {
  fill: "#18568086",
  stroke: "#185680",
  strokeWidth: "2",
  className: "svg-overlay",
  style: {
    cursor: "pointer",
    transition: "opacity 0.5s ease-in-out",
  },
}

const opacity = (selectedUnit, data, override, residenceData) => {
  if (override) return 0

  if (selectedUnit) {
    return selectedUnit.unitID === data.id &&
      selectedUnit.status === "Available"
      ? 1
      : 0
  } else {
    return residenceData.find(
      (unit) => unit.unitID === data.id && unit.status === "Available",
    )
      ? 1
      : 0
  }
}

const ObitPoly = ({
  initialFilters,
  data,
  selectedUnit,
  isTablet,
  filters,
  residenceData,
  unitClick,
}) => {
  const filtersOn = JSON.stringify(filters) !== JSON.stringify(initialFilters)

  let override = !isTablet

  if (filtersOn || selectedUnit) {
    override = false
  }

  const _opacity = opacity(selectedUnit, data, override, residenceData)

  return data.type === "polygon" ? (
    <polygon
      id={`${data.id}`}
      points={data.coords}
      {...path_poly_shared}
      {...data.attr}
      opacity={_opacity}
      onClick={() => {
        unitClick(data.id)
      }}
    />
  ) : (
    <path
      id={`${data.id}`}
      d={data.coords}
      {...path_poly_shared}
      {...data.attr}
      opacity={_opacity}
      onClick={() => {
        unitClick(data.id)
      }}
    />
  )
}

export default ObitPoly
