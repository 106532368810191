import React from "react"
import styled from "styled-components"
import * as Slider from "@radix-ui/react-slider"
import * as ToggleGroup from "@radix-ui/react-toggle-group"
import { initialFilters } from "@state/initialState"
import Dropdown from "@components/select/Dropdown"
import { getAllPresetsFromStorageAsArray } from "@utils/withLocalStorage"
import { RefreshButton } from "@components/buttons"
import { Filters } from "@state/types.store"
import { formatPrice } from "@components/apartmentfinder-orbit/components/utils"

type ResidenceFiltersProps = {
  filters: Filters
  setFilters: (newFilters: Filters) => void
  setPresetUnits: any
}

const ResidenceFilters: React.FC<ResidenceFiltersProps> = ({
  filters,
  setFilters,
  setPresetUnits,
}) => {
  const allPresets = getAllPresetsFromStorageAsArray()

  return (
    <FiltersWrapper>
      <RefreshButton
        color="#ffffff"
        height="20"
        width="20"
        style={{ position: "absolute", top: "2em", right: "2em" }}
        onClick={() => {
          setFilters(initialFilters)
        }}
      />
      <div className="bedroom">
        <div className="label">Bedroom</div>
        <ToggleGroup.Root
          value={filters.bedroom}
          className="filter"
          type="multiple"
          defaultValue={filters.bedroom}
          onValueChange={(value) => setFilters({ ...filters, bedroom: value })}
        >
          <ToggleGroup.Item
            className="option"
            value="1"
            aria-label="Left aligned"
          >
            1
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="option"
            value="2"
            aria-label="Center aligned"
          >
            2
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="option"
            value="3"
            aria-label="Right aligned"
          >
            3
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="option"
            value="4"
            aria-label="Right aligned"
          >
            4
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="option"
            value="5"
            aria-label="Right aligned"
          >
            5
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      </div>
      <div className="floor2">
        <div className="label">Floor</div>
        <div className="filter">
          <div className="floor-values">
            <div className="floor-value">{filters.floor[0] + 1}</div>
            <div className="floor-value">{filters.floor[1]}</div>
          </div>
          <form>
            <Slider.Root
              className="SliderRoot"
              defaultValue={initialFilters.floor}
              value={filters.floor}
              minStepsBetweenThumbs={1.5}
              min={0}
              max={16}
              onValueChange={(value) => {
                setFilters({ ...filters, floor: value })
              }}
            >
              <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
              </Slider.Track>
              <Slider.Thumb className="SliderThumb" />
              <Slider.Thumb className="SliderThumb" />
            </Slider.Root>
          </form>
        </div>
      </div>
      <div className="view">
        <div className="label">View</div>
        <ToggleGroup.Root
          value={filters.view}
          className="filter"
          type="multiple"
          defaultValue={filters.view}
          onValueChange={(value) => setFilters({ ...filters, view: value })}
        >
          <ToggleGroup.Item
            className="option"
            value="N"
            aria-label="Left aligned"
          >
            N
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="option"
            value="E"
            aria-label="Center aligned"
          >
            E
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="option"
            value="S"
            aria-label="Right aligned"
          >
            S
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="option"
            value="W"
            aria-label="Right aligned"
          >
            W
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      </div>
      <div className="status">
        <div className="label">Status</div>
        <ToggleGroup.Root
          value={filters.status}
          className="filter"
          type="multiple"
          defaultValue={filters.status}
          onValueChange={(value) => setFilters({ ...filters, status: value })}
        >
          <ToggleGroup.Item
            className="option"
            value="Available"
            aria-label="Center aligned"
          >
            Available
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="option"
            value="Sold"
            aria-label="Right aligned"
          >
            Sold
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="option"
            value="Reserved"
            aria-label="Right aligned"
          >
            Reserved
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      </div>
      <div className="price">
        <div className="label">Price</div>
        <div className="filter">
          <div className="price-values">
            <div className="price-value">${formatPrice(filters.price[0])}</div>
            <div className="price-value">${formatPrice(filters.price[1])}</div>
          </div>
          <form>
            <Slider.Root
              value={filters.price}
              className="SliderRoot"
              defaultValue={[initialFilters.price[0], initialFilters.price[1]]}
              step={500000}
              min={initialFilters.price[0]}
              max={initialFilters.price[1]}
              onValueChange={(value) => {
                setFilters({ ...filters, price: value })
              }}
            >
              <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
              </Slider.Track>
              <Slider.Thumb className="SliderThumb" aria-label="Volume" />
              <Slider.Thumb className="SliderThumb" aria-label="Volume" />
            </Slider.Root>
          </form>
        </div>
      </div>
      <div className="presets">
        <div className="label">Presets</div>
        <Dropdown
          options={allPresets}
          onValueChange={(value: string) => {
            setFilters(initialFilters)
            setPresetUnits(allPresets.find((preset) => preset.name === value))
          }}
        />
      </div>
    </FiltersWrapper>
  )
}

export default ResidenceFilters

const FiltersWrapper = styled.div`
  position: relative;
  font-family: Stolzl;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  padding: 1em 3em;
  border-radius: 1rem;

  .floor2 .filter,
  .price .filter {
    border-top: 1px solid #ffffff45;
    border-bottom: 1px solid #ffffff45;
    padding: 1em 0;
  }

  .label {
    font-family: Stolzl;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    padding: 2rem 0;
    letter-spacing: 0.15em;
  }

  .status .filter,
  .bedroom .filter,
  .view .filter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    font-size: 1.8em;

    button {
      all: unset;
    }

    .option {
      box-sizing: border-box;
      width: 100%;
      text-align: center;
      padding: 0.6em 0;
    }

    .option:not(:last-child) {
      border-right: 1px solid #fff;
    }

    .option[data-state="on"] {
      background-color: #9191919d;
      color: #fff;
    }
  }

  .floor2 .floor-values,
  .price .price-values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.8em;
    padding: 0 1rem;
  }
`
