import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { Suspense, useEffect, useRef, useState } from "react"
import {
  CameraControls,
  Environment,
  OrbitControls,
  PerspectiveCamera,
  PresentationControls,
  // useGLTF,
} from "@react-three/drei"
import { Camera, DoubleSide, Vector3 } from "three"
import Amenities from "./CanvasAmenities"
import useStore from "@state/store"
import HandleLoadingProgress from "./HandleLoading"
import { useSocketIO } from "@providers/sockets/SocketIOProvider"
import { gsap } from "gsap"
import { amenitiesData } from "@data/amenitiesData"

const initialPos = {
  ipad: {
    UPPER: { x: 3, y: 30, z: 30 },
    LOWER: { x: 3, y: 30, z: 30 },
  },
  tv: {
    UPPER: { x: 3, y: 30, z: 30 },
    LOWER: { x: 3, y: 30, z: 30 },
  },
}
const initialTarget = {
  ipad: {
    UPPER: { x: 0, y: 0, z: 0 },
    LOWER: { x: 0, y: 0, z: 0 },
  },
  tv: {
    UPPER: { x: 0, y: 0, z: 0 },
    LOWER: { x: 0, y: 0, z: 0 },
  },
}
const cameraData = {
  Lobby: {
    position: {
      x: -5,
      y: 25,
      z: 15,
    },
    target: {
      x: -0.75,
      y: 0,
      z: 3.5,
    },
  },
  "The Marcy Library": {
    position: {
      x: -5,
      y: 20,
      z: 5,
    },
    target: {
      x: 4.45,
      y: 0,
      z: 0,
    },
  },
  "Private Dining Room": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: 2,
      y: 0,
      z: -4,
    },
  },
  "Emery’s Bar": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: -0.4,
      y: 0,
      z: -3.4,
    },
  },
  "Billiards/VR Sports Lounge": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: -3.2,
      y: 0,
      z: -3.9,
    },
  },

  "Garden Terrace": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: 5,
      y: 0,
      z: -3.6,
    },
  },
  "Fitness Suite": {
    position: {
      x: 0,
      y: 30,
      z: 15,
    },
    target: {
      x: 5.8,
      y: 0,
      z: 1,
    },
  },
  "Children’s Playroom": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: 2,
      y: 0,
      z: -6,
    },
  },
  "Music Practice Room": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: -6,
      y: 0,
      z: 4.3,
    },
  },
  "Makers' Studio": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: 1.3,
      y: 0,
      z: -3.5,
    },
  },
  "Bicycle Storage": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: -3.3,
      y: 0,
      z: -4.1,
    },
  },
  "Residential Storage": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: -3.9,
      y: 0,
      z: 0.1,
    },
  },
  "Play Garden": {
    position: {
      x: 0,
      y: 20,
      z: 5,
    },
    target: {
      x: 5.2,
      y: 0,
      z: -6,
    },
  },
  "Pet Wash": {
    position: {
      x: 0,
      y: 10,
      z: -5,
    },
    target: {
      x: 2.0,
      y: 0,
      z: 0.9,
    },
  },
}

// useGLTF.preload("/models/amenities_plate.glb")

export default function TCanvas({ screen = "ipad" }) {
  return (
    <div
      className="amenity-canvas"
      style={{
        touchAction: "auto",
        position: "fixed",
        inset: 0,
        zIndex: 0,
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Canvas
        dpr={window.devicePixelRatio}
        camera={{ fov: 25, position: new Vector3(0, 0, 50) }}
      >
        {screen === "ipad" && <CustomControls screen={screen} />}
        {screen === "tv" && <ScreenCamera />}
        <Suspense fallback={null}>
          <Environment path="assets/maps/" files="hdri.hdr" />
          <HandleLoadingProgress
            totalItems={14}
            logAssets={false}
            onProgress={() => null}
            onLoaded={() => null}
          />

          <Amenities screen={screen} />
        </Suspense>
      </Canvas>
    </div>
  )
}

function CustomControls({ screen }) {
  const { emitSocketEvent, room } = useSocketIO()
  const { camera } = useThree()
  const orbitRef = useRef(null)
  const amenitiesActiveMesh = useStore((state) => state.amenitiesActiveMesh)
  const amenitiesSelector = useStore((state) => state.amenitiesSelector)

  const [allowPan, setAllowPan] = useState(true)
  const [target, setTarget] = useState(new Vector3(0, 0, 0))
  const currentTarget = useRef(new Vector3(0, 0, 0))

  //Set target
  useEffect(() => {
    if (amenitiesActiveMesh) {
      const {
        x: tx,
        y: ty,
        z: tz,
      } = cameraData[amenitiesActiveMesh]?.target || {
        x: initialTarget[screen][
          amenitiesSelector ? "UPPER" : amenitiesSelector
        ]?.x,
        y: initialTarget[screen][
          amenitiesSelector ? "UPPER" : amenitiesSelector
        ]?.y,
        z: initialTarget[screen][
          amenitiesSelector ? "UPPER" : amenitiesSelector
        ]?.z,
      }
      setTarget(new Vector3(tx, ty, tz))
    } else {
      setTarget(
        new Vector3(
          initialTarget[screen][
            amenitiesSelector ? "UPPER" : amenitiesSelector
          ]?.x,
          initialTarget[screen][
            amenitiesSelector ? "UPPER" : amenitiesSelector
          ]?.y,
          initialTarget[screen][
            amenitiesSelector ? "UPPER" : amenitiesSelector
          ]?.z,
        ),
      )
    }
  }, [amenitiesActiveMesh])

  //Set position
  useEffect(() => {
    if (orbitRef.current) {
      const controls = orbitRef.current
      if (amenitiesActiveMesh) {
        gsap.to(controls.object.position, {
          x: cameraData[amenitiesActiveMesh]?.position.x,
          y: cameraData[amenitiesActiveMesh]?.position.y,
          z: cameraData[amenitiesActiveMesh]?.position.z,
          duration: 1.5,
          ease: "power4.out",
          onStart: () => setAllowPan(false),
          onComplete: () => setAllowPan(true),
        })
      } else {
        gsap.to(controls.object.position, {
          x: initialPos[screen][amenitiesSelector ? amenitiesSelector : "UPPER"]
            ?.x,
          y: initialPos[screen][amenitiesSelector ? amenitiesSelector : "UPPER"]
            ?.y,
          z: initialPos[screen][amenitiesSelector ? amenitiesSelector : "UPPER"]
            ?.z,
          duration: 1.5,
          ease: "power4.out",
          onStart: () => setAllowPan(false),
          onComplete: () => setAllowPan(true),
        })
      }
    }
  }, [amenitiesActiveMesh])

  useFrame(() => {
    if (orbitRef.current) {
      const controls = orbitRef.current
      currentTarget.current.lerp(target, 0.1)
      controls.target.copy(currentTarget.current)
      controls.update()
    }
  })

  return (
    <OrbitControls
      enablePan={allowPan}
      enableZoom={allowPan}
      enableRotate={allowPan}
      ref={orbitRef}
      minPolarAngle={0}
      maxPolarAngle={Math.PI * 0.5 - 0.33}
      onChange={() => {
        const pos = orbitRef.current.object.position
        const rot = orbitRef.current.object.rotation
        emitSocketEvent("cameraPos", {
          room: room,
          data: {
            id: "",
            x: pos?.x.toFixed(6),
            y: pos?.y.toFixed(6),
            z: pos?.z.toFixed(6),
            rx: rot?._x.toFixed(6),
            ry: rot?._y.toFixed(6),
            rz: rot?._z.toFixed(6),
          },
        })
      }}
    />
  )
}

function ScreenCamera({ screen = "tv" }) {
  const amenitiesSelector = useStore((state) => state.amenitiesSelector)
  const amenitiesActiveMesh = useStore((state) => state.amenitiesActiveMesh)
  const ref = useRef<any>(null)
  const newCameraPos = useRef({
    x: initialPos[screen][amenitiesSelector ? amenitiesSelector : "UPPER"]?.x,
    y: initialPos[screen][amenitiesSelector ? amenitiesSelector : "UPPER"]?.y,
    z: initialPos[screen][amenitiesSelector ? amenitiesSelector : "UPPER"]?.z,
  })
  const newCameraRot = useRef({ x: 0, y: 0, z: 0 })
  const { socket } = useSocketIO()

  // const [target, setTarget] = useState(new Vector3(0, 0, 0))
  const currentTarget = useRef(
    new Vector3(
      initialTarget[screen][amenitiesSelector ? amenitiesSelector : "UPPER"]?.x,
      initialTarget[screen][amenitiesSelector ? amenitiesSelector : "UPPER"]?.y,
      initialTarget[screen][amenitiesSelector ? amenitiesSelector : "UPPER"]?.z,
    ),
  )

  //Set target
  useEffect(() => {
    if (amenitiesActiveMesh) {
      const {
        x: tx,
        y: ty,
        z: tz,
      } = cameraData[amenitiesActiveMesh]?.target || {
        x: 0,
        y: 0,
        z: 0,
      }
      // console.log(tx)
      gsap.to(currentTarget.current, {
        x: tx,
        y: ty,
        z: tz,
        duration: 1.5,
        ease: "power4.out",
        // onUpdate: () => console.log(currentTarget.current),
      })
      // setTarget(new Vector3(tx, ty, tz))
    } else {
      // setTarget(new Vector3(0, 0, 0))
      gsap.to(currentTarget.current, {
        x: initialTarget[screen][
          amenitiesSelector ? amenitiesSelector : "UPPER"
        ]?.x,
        y: initialTarget[screen][
          amenitiesSelector ? amenitiesSelector : "UPPER"
        ]?.y,
        z: initialTarget[screen][
          amenitiesSelector ? amenitiesSelector : "UPPER"
        ]?.z,
        duration: 1.5,
        ease: "power4.out",
      })
    }
  }, [amenitiesActiveMesh])

  useEffect(() => {
    // if (!amenitiesSelector) return
    socket?.on("got-cameraPos", (data) => {
      // console.log(data)
      newCameraPos.current.x = data.data.x
      newCameraPos.current.y = data.data.y
      newCameraPos.current.z = data.data.z
      newCameraRot.current.x = data.data.rx
      newCameraRot.current.y = data.data.ry
      newCameraRot.current.z = data.data.rz
    })
    return () => {
      socket.off(`got-cameraPos`, () => console.log("unsub"))
    }
  }, [])

  useEffect(() => {
    if (amenitiesActiveMesh && amenitiesSelector) {
      const { x, y, z } = cameraData[amenitiesActiveMesh]?.position || {
        x: 0,
        y: 0,
        z: 0,
      }
      gsap.to(ref.current.position, {
        x: x,
        y: y,
        z: z,
        duration: 1.5,
        ease: "power4.out",
      })
    } else {
      gsap.to(ref.current.position, {
        x: 0,
        y: 0,
        z: 50,
        duration: 1.5,
        ease: "power4.out",
      })
      gsap.to(ref.current.rotation, {
        x: 0,
        y: 0,
        z: 0,
        duration: 1.5,
        ease: "power4.out",
      })
      gsap.to(ref.current, {
        duration: 1.5,
        ease: "power4.out",
        onUpdate: () => ref.current?.lookAt(0, 0, 0),
      })
    }
  }, [amenitiesActiveMesh, amenitiesSelector])

  useFrame(() => {
    if (ref.current) {
      if (amenitiesSelector) {
        // console.log(currentTarget.current.y)
        // console.log(ref.current)
        ref.current.position.set(
          newCameraPos.current.x,
          newCameraPos.current.y + 30,
          newCameraPos.current.z,
        )
        ref.current.rotation.set(
          newCameraRot.current.x,
          newCameraRot.current.y,
          newCameraRot.current.z,
        )
        ref.current?.lookAt(
          new Vector3(
            currentTarget.current.x,
            currentTarget.current.y,
            currentTarget.current.z,
          ),
        )
      } else {
        // ref.current.position.set(0, 0, 50)
        // ref.current.rotation.set(0, 0, 0)
        // ref.current.lookAt(0, 0, 0)
      }
    }
  })
  return (
    <PerspectiveCamera ref={ref} makeDefault position={[0, 0, 50]} fov={25} />
  )
}
