import styled from "styled-components"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"

export function LogoMap() {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  return (
    <>
      <MapImage></MapImage>
    </>
  )
}

export const MapImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const LogoWrapper = styled.div`
  position: absolute;
  top: 36%;
  left: 63%;
  transform: translate(-50%, -50%);
  width: 164px;
  height: 164px;
  z-index: 10;
  zoom: 0.5;
  background-image: url("/assets/svg/logo.svg");
  opacity: 0;
`
