import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import useStore from "@state/store";
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets";
import { useEffect, useRef } from "react";
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"
import ZoomImage from "@components/zoomImage/zoomImage";

import { EffectFade, Navigation, Pagination } from "swiper/modules";

type Props = {
  slides: any[];
  slideEffect?: string;
};

const Carousel = (props: Props) => {
  const slides = props.slides;
  const slideEffect = props.slideEffect;
  const { selectedGalleryImage, selectedUnit, selectedGalleryCategory, isPanning } = useStore((s) => ({
    selectedGalleryImage: s.selectedGalleryImage,
    selectedUnit: s.selectedUnit,
    selectedGalleryCategory: s.selectedGalleryCategory,
    isPanning: s.isPanning,
  }));
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket();

  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && selectedGalleryCategory !== undefined) {
      swiperRef.current.slideTo(0); 
    }
  }, [selectedGalleryCategory]);

  useEffect(() => {
    if (swiperRef.current && selectedGalleryImage !== undefined) {
      swiperRef.current.slideTo(selectedGalleryImage); 
    }
  }, [selectedGalleryImage]);

  useEffect(() => {
    if (isPanning) {
      swiperRef.current.allowTouchMove = false;
    } 
    else if (!isPanning) {
      setTimeout(() => {
        swiperRef.current.allowTouchMove = true;
      }, 1000);
    }
  }, [isPanning]);



  return (
    <Swiper
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
      effect={slideEffect}
      spaceBetween={50}
      slidesPerView={1}
      centeredSlides={true}
      onSlideChange={(swiper) =>
        syncState({ selectedGalleryImage: swiper.activeIndex })
      }
      fadeEffect={{ crossFade: true }}
      modules={[EffectFade, Navigation, Pagination]}
      speed={800}
      style={{ height: "100%", width: "100%" }}
    >
      {slides.map((content, index) => (
        <SwiperSlide
          key={index}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ZoomImage image={content} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
