import { useEffect, useState } from "react"
import { formatItalicLetters } from "@utils/formatItalicLetters"
import styled from "styled-components"

export interface IOrbitCaptionsProps {
  orbitIndex: number
  orbitRunning: boolean
  orbitDir: boolean
}

export default function OrbitCaptions({
  orbitIndex,
  orbitRunning,
  orbitDir,
}: IOrbitCaptionsProps) {
  const [nextIndex, setNextIndex] = useState(orbitIndex)
  const maxOrbitIndex = 5

  function getNexIndex(dir) {
    let nextIndex
    if (dir) {
      nextIndex = orbitIndex + 1
      setNextIndex(nextIndex > maxOrbitIndex ? 0 : nextIndex)
    } else {
      nextIndex = orbitIndex - 1
      setNextIndex(0 > nextIndex ? maxOrbitIndex : nextIndex)
    }
  }

  function getActiveState(n) {
    if (n === orbitIndex) {
      return orbitRunning ? false : true
    }
    if (n === nextIndex) {
      return orbitRunning ? true : false
    }
  }

  useEffect(() => {
    if (orbitRunning) {
      getNexIndex(orbitDir)
    }
  }, [orbitRunning])

  return (
    <CaptionsWrapper>
      <CaptionWrapper
        className="caption1"
        $active={!orbitRunning && 0 === orbitIndex}
      >
        <div className="caption">
          <p className="orbit-caption textRight">
            {formatItalicLetters("Your Grand<br />WelcomE")}
          </p>
          <p className="orbit-subcaption textRight">
            A grand welcome through a restored Renaissance Revival entrance sets
            the tone for a wide array of exceptional amenities, including
            on-site parking, basketball, squash, and much more.
          </p>
        </div>
      </CaptionWrapper>

      <CaptionWrapper
        className="caption2"
        $active={!orbitRunning && 1 === orbitIndex}
      >
        <p className="orbitOneP">
          {formatItalicLetters(
            "An Upper West SidE pre-War landmark rEtold With modErn finessE",
          )}
        </p>
      </CaptionWrapper>

      <CaptionWrapper
        className="caption3"
        $active={!orbitRunning && 2 === orbitIndex}
      >
        <div className="caption">
          <p className="orbit-caption">
            {formatItalicLetters("An<br />Icon<br /> Re-<br />ImaginEd")}
          </p>
          <p className="orbit-subcaption">
            Originally designed in 1927 by celebrated architect Emery Roth, 720
            West End Avenue is re-envisioned today as 131 elegant condominiums
            by the award-winning designer Thomas Juul-Hansen.
          </p>
        </div>
      </CaptionWrapper>

      <CaptionWrapper
        className="caption4"
        $active={!orbitRunning && 3 === orbitIndex}
      >
        <div className="caption">
          <p className="orbit-caption noWrap">
            {formatItalicLetters("TakE in hudson rivEr views ")}
            <span className="rotate">
              {formatItalicLetters("from new angles")}
            </span>
          </p>
          <p className="orbit-subcaption">
            Explore the pre-war charm of West End Avenue. Discover a
            neighborhood anew, where progress is beautiful.
          </p>
        </div>
      </CaptionWrapper>

      <CaptionWrapper
        className="caption5"
        //REPLACE WHEN COPY IS PROVIDED
        // $active={!orbitRunning && 4 === orbitIndex}
        $active={false}
      >
        <div className="caption">
          <p className="orbit-caption">
            {
              formatItalicLetters(
                "lorEm<br />Ipsum",
              ) /* apply italic to capital W and E only */
            }
          </p>
          <p className="orbit-subcaption">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </CaptionWrapper>

      <CaptionWrapper
        className="caption6"
        $active={!orbitRunning && 5 === orbitIndex}
      >
        <p className="orbitOneP">
          {formatItalicLetters(
            "An Upper West SidE pre-War landmark rEtold With modErn finessE",
          )}
        </p>
      </CaptionWrapper>
    </CaptionsWrapper>
  )
}

const CaptionsWrapper = styled.div`
  position: absolute;
  z-index: 51;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  .italic {
    font-style: italic;
  }

  .orbitOneP {
    transform: translateY(43%);
    font-family: "Masque-Hairline";
    font-size: 3.5rem;
    line-height: 2.8vw;
    text-align: center;
    letter-spacing: 0.05em;
    max-width: 98%;
  }

  .caption1 {
    .caption {
      bottom: 5em;
      right: 5em;
    }
  }

  .caption3 {
    .caption {
      top: 5em;
      left: 5em;
      > * {
        text-align: left;
      }
    }
  }

  .caption4 {
    .caption {
      top: 39%;
      left: 7em;
      width: 12rem;

      > * {
        text-align: left;
      }

      .noWrap {
        white-space: nowrap;
        & .rotate {
          font-family: "Masque-Hairline";
          font-size: 1em;
          color: #000;
          display: inline-block;
          transform-origin: 0% 50%;
          transform: rotate(-14.5deg);
        }
      }
    }
  }

  .caption5 {
    .caption {
      top: 12em;
      left: 27em;
      width: 15rem;
      > * {
        text-align: right;
      }
    }
  }
  
  .caption6 {
    .orbitOneP {
      color: white;
    }
  }
`

type TCaption = {
  $active: boolean
}

const CaptionWrapper = styled.div<TCaption>`
  --duration: ${(p) => (p.$active ? "800ms" : "200ms")};
  --delay: ${(p) => (p.$active ? "200ms" : "0ms")};
  --ease: ${(p) => (p.$active ? "ease-in" : "ease-out")};
  --opacity: ${(p) => (p.$active ? 1 : 0)};
  --blur: ${(p) => (p.$active ? "50px" : "0px")};
  --translation: ${(p) => (p.$active ? "0%" : "10%")};
  position: absolute;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-duration: var(--duration);
  transition-delay: var(--delay);
  transition-timing-function: var(--ease);
  opacity: var(--opacity);

  .caption {
    position: absolute;
    width: 25rem;
    z-index: 10;
  }

  .orbit-caption {
    font-family: "Masque-Hairline";
    font-size: 5em;
    text-align: right;
    color: #000;
    transition-property: filter;
    transition-duration: var(--duration);
    transition-delay: var(--delay);
    transition-timing-function: var(--ease);
    filter: blur(var(--opacity));
  }

  .orbit-subcaption {
    position: relative;
    display: inline-block;
    font-family: "Stolzl";
    font-size: 1em;
    text-align: right;
    color: #000;
    transition-property: opacity, transform;
    transition-duration: var(--duration);
    transition-delay: var(--delay);
    transition-timing-function: var(--ease);
    opacity: var(--opacity);
    transform: translateX(var(--translation));
  }
`
