import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import { Pagination } from "swiper/modules"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch"
import { useEffect, useState } from "react"

type Props = {
  image: any
}

const ZoomImage = (props: Props) => {
  const { selectedGalleryImage, selectedUnit, zoomRef, isPanning } = useStore((s) => ({
    selectedGalleryImage: s.selectedGalleryImage,
    selectedUnit: s.selectedUnit,
    zoomRef: s.zoomRef,
    isPanning: s.isPanning,
  }))
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const [pan, setPan] = useState(false)
  const minScale = 1


  const handlePanning = (ref: any) => {
    syncState({isPanning: false})
    const {scale, positionX, positionY} = ref.state;
    if (scale <= minScale)
      setPan(false)
    else 
      setPan(true)
    if (scale < 1)
      syncState({zoomRef: [0, 0, 1]})
    else
      syncState({zoomRef: [positionX, positionY, scale]})
  }

  const handlePanningCondition = (ref: any) => {
    const {scale, panning} = ref.state;
    if (scale === 1)
      setPan(false)
    else if (scale > 1)
      setPan(true)
  }

  return (
    <TransformWrapper
      panning={{disabled: !pan}} 
      onPanningStart={handlePanningCondition}
      onZoomStart={() => syncState({isPanning: true})}
      onPanningStop={handlePanning} 
      onZoomStop={handlePanning} 
      minScale={minScale} 
      smooth={true} 
      maxScale={2.5}
      wheel={{disabled: false, step: 0.00001}}
    >
        <TransformComponent>
          <img
            style={{ objectFit: "contain", backgroundColor: "white", width: "100vw", height: "100vh" }}
            src={props.image}
            alt=""
          />
        </TransformComponent>
    </TransformWrapper>
  )
}

export default ZoomImage
