import useStore from "@state/store"

export const useButtonFillColor = (location: string) => {
  const { galleryVisible, favoritesVisible } = useStore((s) => ({
    galleryVisible: s.galleryVisible,
    favoritesVisible: s.favoritesVisible,
  }))
  const fillColor =
    (location.includes("/location") && !galleryVisible && !favoritesVisible) ||
    (location.includes("/residences") &&
      !galleryVisible &&
      !favoritesVisible) ||
    (location.includes("/amenities") && !galleryVisible)
      ? "#fff"
      : "#000"

  return { fillColor }
}
