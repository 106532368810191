import { useEffect, useState } from "react"
import styled from "styled-components"
import useStore from "@state/store"
import { FlexTable } from "@components/table"
import useSortConfig from "@utils/useSortConfig"
import {
  ArrowLeftIcon,
  TrashIcon,
  Cross2Icon,
  CheckIcon,
} from "@radix-ui/react-icons"
import * as Switch from "@radix-ui/react-switch"
import StyledButton from "@components/buttons/StyledButton"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import SingleUnit from "./SingleUnit"
import CompareTablet from "./CompareTablet"
import { ShareButton } from "@components/buttons/ShareButton"
import Share from "./Share"
import gsap from "gsap"
import { useFavoritesStore } from "@state/favoritesStore"

type Props = {
  isVisible: boolean
}

const Favorites = (props: Props) => {
  const [shareSection, setShareSection] = useState<"table" | "share">("table")
  const { sortConfig } = useSortConfig()
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const { selectedRows, compareMode, compareSection } = useStore((state) => ({
    selectedRows: state.selectedRows,
    compareMode: state.compareMode,
    compareSection: state.compareSection,
  }))

  const { loadFavorites, favorites, removeFromFavorites, clearFavorites } =
    useFavoritesStore((state) => ({
      loadFavorites: state.loadFavorites,
      favorites: state.favorites,
      removeFromFavorites: state.removeFromFavorites,
      clearFavorites: state.clearFavorites,
    }))

  useEffect(() => {
    loadFavorites()
  }, [loadFavorites])

  const headers = [
    "unitID",
    "beds",
    "baths",
    "aspect",
    "sqft",
    "price",
    "status",
  ]
  const cells = [
    "unitType",
    "beds",
    "baths",
    "aspect",
    "sqft",
    "price",
    "status",
  ]

  const handleSelectionChange = (newRows: any[]) => {
    let updatedRows = [...selectedRows]

    if (newRows?.find((row) => row.unitType === selectedRows[0]?.unitType)) {
      updatedRows[0] = selectedRows[0]
    } else {
      updatedRows[0] = null
    }

    if (newRows?.find((row) => row.unitType === selectedRows[1]?.unitType)) {
      updatedRows[1] = selectedRows[1]
    } else {
      updatedRows[1] = null
    }

    newRows?.forEach((newRow) => {
      if (!updatedRows.find((row) => row && row.unitType === newRow.unitType)) {
        const nullIndex = updatedRows.indexOf(null)
        if (nullIndex !== -1) {
          updatedRows[nullIndex] = newRow
        }
      }
    })

    syncState({ selectedRows: updatedRows })
  }

  useEffect(() => {
    const fadeIn = (selector: any) =>
      gsap.to(selector, { autoAlpha: 1, duration: 0.6, ease: "power3.inOut" })
    const fadeOut = (selector: any) =>
      gsap.to(selector, { autoAlpha: 0, duration: 0.6, ease: "power3.inOut" })

    switch (shareSection) {
      case "share":
        fadeIn(".share-section")
        fadeOut(".table-container")
        break
      case "table":
        fadeIn(".table-container")
        fadeOut(".share-section")
        break
      default:
        fadeOut(".share-section")
        break
    }
  }, [shareSection])

  const handleClearFavorites = () => {
    const prompt = document.querySelector(".prompt")
    prompt.classList.toggle("active")
  }

  return (
    <Wrapper visible={props.isVisible}>
      <div
        className="back"
        onClick={() => {
          syncState({ favoritesVisible: false })
          setShareSection("table")
        }}
      >
        <ArrowLeftIcon height={20} width={20} color="#fff" />
        <StyledButton
          border={false}
          color="#fff"
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          Back
        </StyledButton>
      </div>
      <div className="toggle">
        <StyledButton
          border={false}
          color={compareSection === "floorplan" ? "#ffffff" : "#ffffff3B"}
          style={{
            margin: 0,
            padding: "1rem",
            borderRadius: "10px",
          }}
          onClick={() => {
            syncState({ compareSection: "floorplan" })
          }}
        >
          Floorplan
        </StyledButton>
        <StyledButton
          border={false}
          color={compareSection === "view" ? "#ffffff" : "#ffffff3B"}
          style={{
            margin: 0,
            padding: "1rem",
            borderRadius: "10px",
          }}
          onClick={() => {
            syncState({ compareSection: "view" })
          }}
        >
          View
        </StyledButton>
      </div>
      <div className="left">
        {favorites && favorites.length === 0 ? (
          <div
            style={{
              textTransform: "uppercase",
              fontSize: "2rem",
              fontFamily: "Stolzl",
              color: "white",
            }}
          >
            No Favorites added yet
          </div>
        ) : (
          <>
            <div className="table-container">
              <div className="clear-favorites">
                <StyledButton
                  onClick={() => handleClearFavorites()}
                  border={true}
                  icon={
                    <TrashIcon
                      height={20}
                      width={20}
                      style={{ marginRight: "10px" }}
                      color="#fff"
                    />
                  }
                  color="#fff"
                  style={{
                    margin: 0,
                    padding: "1em",
                    width: "max-content",
                  }}
                >
                  Clear Favorites
                </StyledButton>
                <div className="prompt">
                  <StyledButton
                    border={true}
                    icon={
                      <Cross2Icon
                        height={20}
                        width={20}
                        style={{ marginRight: "10px" }}
                        color="#fff"
                      />
                    }
                    color="#fff"
                    style={{
                      margin: 0,
                      padding: "1em",
                      width: "max-content",
                    }}
                    onClick={() =>
                      document
                        .querySelector(".prompt")
                        .classList.toggle("active")
                    }
                  >
                    No
                  </StyledButton>
                  <StyledButton
                    border={true}
                    icon={
                      <CheckIcon
                        height={20}
                        width={20}
                        style={{ marginRight: "10px" }}
                        color="#fff"
                      />
                    }
                    color="#fff"
                    style={{
                      margin: 0,
                      padding: "1em",
                      width: "max-content",
                    }}
                    onClick={() => {
                      clearFavorites()
                      syncState({
                        selectedRows:
                          compareMode === true ? [null, null] : null,
                      })
                      syncState({ compareMode: false })
                    }}
                  >
                    Yes
                  </StyledButton>
                </div>
              </div>

              <div className="title-container">
                <div className="title">Favorite Residences</div>
                <form className="switch">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                      className="Label"
                      htmlFor="compare-mode"
                      style={{ paddingRight: 15 }}
                    >
                      Compare
                    </label>
                    <Switch.Root
                      className="SwitchRoot"
                      id="compare-mode"
                      defaultChecked={false}
                      onCheckedChange={(v) => {
                        syncState({ compareMode: v })
                        if (v === true) {
                          syncState({ selectedRows: [null, null] })
                        } else {
                          syncState({ selectedRows: [] })
                        }
                      }}
                    >
                      <Switch.Thumb className="SwitchThumb" />
                    </Switch.Root>
                  </div>
                </form>
              </div>

              <FlexTable
                uniqueKey="unitID"
                headers={headers}
                data={favorites}
                sortConfig={sortConfig}
                cells={cells}
                selection={compareMode === true ? "double" : "single"}
                // preselectedRows={selectedRows || []}
                onSelectionChange={(rows) => {
                  if (compareMode === true) {
                    handleSelectionChange(rows)
                  } else {
                    syncState({ selectedRows: rows })
                  }
                }}
              />
              <div className="share-button">
                <span>Share</span>
                <ShareButton
                  onClick={() => {
                    setShareSection("share")
                  }}
                />
              </div>
            </div>
          </>
        )}
        <Share
          className="share-section"
          onClose={() => setShareSection("table")}
        />
      </div>
      <div className="right">
        {compareMode ? (
          <CompareTablet selectedRows={selectedRows} />
        ) : (
          <SingleUnit
            selectedRows={selectedRows}
            removeFromFavorites={removeFromFavorites}
          />
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  z-index: 270;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 0.3s ease-in-out;

  .back {
    position: absolute;
    display: flex;
    align-items: center;
    top: 3em;
    left: 30px;
    gap: 1em;
    padding: 1em 1em 1em 0;
    z-index: 300;
  }

  .toggle {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 3em;
    left: 30px;
    gap: 2em;
    padding: 1em 1em 1em 0;
    z-index: 300;
  }

  .left {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.85);

    .table-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      transform: scale(0.85);
      color: white;
      height: auto;
      font-family: Stolzl;
      text-transform: uppercase;
      z-index: 1;

      .clear-favorites {
        display: flex;
        gap: 1em;

        .prompt {
          display: flex;
          gap: 1em;
          margin-left: -2em;
          opacity: 0;
          transition: all 0.3s ease-in-out;

          &.active {
            opacity: 1;
            margin-left: 2em;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      .header-cell {
        font-size: 1vw;
        padding: 0;
      }

      .title-container {
        display: flex;
        align-items: center;
        padding: 2rem 1rem;
        display: flex;
        justify-content: space-between;
        position: relative;
        border-top: 1px solid white;
        border-bottom: 1px solid white;

        .title {
          font-family: "Masque-Hairline";
          font-size: 2rem;
        }

        .switch {
          button {
            all: unset;
          }

          .SwitchRoot {
            width: 42px;
            height: 25px;
            background-color: #666666;
            border-radius: 9999px;
            position: relative;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          }

          .SwitchRoot[data-state="checked"] {
            background-color: #007300;
          }

          .SwitchThumb {
            display: block;
            width: 21px;
            height: 21px;
            background-color: white;
            border-radius: 9999px;
            transition: transform 100ms;
            transform: translateX(2px);
            will-change: transform;
          }
          .SwitchThumb[data-state="checked"] {
            transform: translateX(19px);
          }

          .Label {
            color: white;
            font-size: 15px;
            line-height: 1;
          }
        }
      }

      .share-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 1rem;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        font-family: "Stolzl";

        span {
          font-size: 2rem;
          line-height: 3rem;
        }
      }
    }
  }

  .right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;

    .info-card-content {
      padding: 1.5em 1em;
    }
  }
`

export default Favorites
