import React from "react"
import styled from "styled-components"

type StyledButtonProps = {
  icon?: any
  onClick?: (e: any) => void
  background?: string
  color?: string
  disabled?: boolean
  style?: React.CSSProperties
  border?: boolean
  className?: string
  children?: React.ReactNode
}

const StyledButton: React.FC<StyledButtonProps> = ({
  icon,
  onClick,
  background,
  color,
  disabled,
  style,
  border = true,
  className,
  children,
}) => {
  return (
    <Button
      style={{
        ...style,
        background,
        color,
        border: border ? "1px solid #fff" : "none",
      }}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {typeof icon === "string" ? <img src={icon} alt="" /> : icon}
      {children}
    </Button>
  )
}

export default StyledButton

const Button = styled.button`
  font-family: Stolzl;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;

  &:active {
    background-color: #ffffff4e;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  & img {
    width: 1.5rem;
    height: 1.5rem;
  }
`
