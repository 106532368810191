import { FavouritesButton, MediaButton } from "@components/buttons"
import { Logo } from "@components/logo"
import Menu from "@components/menu"
import Navbar from "@components/navbar"
import RoomPopup from "@components/rooms/RoomPopup"
import RoomTakenOver from "@components/rooms/RoomTakenOver"
import ToastPopup from "@components/toast/ToastPopup"
import globalStyles from "@data/style.globals"
import useToast from "../hooks/useToast"
import { useSocketIO } from "@providers/sockets/SocketIOProvider"
import useStore from "@state/store"
import { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import roomMapper from "@data/roomToNav.map.json"
import Gallery from "@components/gallery"
import Favorites from "@components/favorties"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { NetworkStatusIndicator } from "@components/network-message"
import { useJoinRoom } from "@hooks/useJoinRoom"
import SocketStatus from "@components/socket-status/SocketStatus"

const roomMapping = {
  "/location": "room1",
  "/amenities": "room2",
  "/residences": "room3",
}

function TabletLayout() {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const location = useLocation()
  const navigate = useNavigate()
  const { open, setOpen, title, setTitle } = useToast()
  const { onSocketEvent, socket } = useSocketIO()
  const { roomConnectAttempt } = useJoinRoom()
  const [showMenu, setShowMenu] = useState(false)
  const {
    amenitiesFullScreenImage,
    galleryVisible,
    favoritesVisible,
    roomTakenOver,
    setLocalState,
  } = useStore((s) => ({
    amenitiesFullScreenImage: s.amenitiesFullScreenImage,
    galleryVisible: s.galleryVisible,
    favoritesVisible: s.favoritesVisible,
    roomTakenOver: s.roomTakenOver,
    setLocalState: s.setLocalState,
  }))

  useEffect(() => {
    socket.io.on("reconnect", (e) => {
      console.log("Reconnected", e)
      roomConnectAttempt(null, roomMapping[window.location.pathname])
    })
  }, [])

  onSocketEvent("kicked", (data) => {
    setLocalState({ roomTakenOver: null })
    setTitle(`You have been kicked out from ${roomMapper[data?.room].title}`)
    setOpen(true)
    navigate("/")
  })

  const closeMenu = () => {
    setShowMenu(false)
  }

  const handleGallery = () => {
    if (!galleryVisible) {
      syncState({ galleryVisible: true })
      syncState({ selectedGalleryImage: 0 })
      syncState({ favoritesVisible: false })
    } else syncState({ galleryVisible: false })
  }

  const handleFavorties = () => {
    if (!favoritesVisible) {
      syncState({ favoritesVisible: true })
      syncState({ galleryVisible: false })
    } else syncState({ favoritesVisible: false })
  }

  return (
    <TabletLayoutWrapper
      visible={showMenu}
      location={location.pathname}
      galleryVisible={galleryVisible}
      favoritesVisible={favoritesVisible}
    >
      {socket?.connected ? null : (
        <SocketStatus strokeWidth={20} color="#dd0000" bgColor="transparent" />
      )}
      <div
        className="logo-text-wrap"
        style={{
          visibility: amenitiesFullScreenImage !== null ? "hidden" : "visible",
          right: "2rem",
          paddingTop: "2rem",
          pointerEvents: location.pathname === "/amenities" ? "none" : "auto",
        }}
      >
        <Logo onClick={() => setShowMenu(true)} location={location.pathname} />
      </div>

      <Navbar />
      <Menu isVisible={showMenu} onClose={closeMenu} />
      <Gallery isVisible={galleryVisible} />
      <Favorites isVisible={favoritesVisible} />

      <div className="bottom-controls">
        <MediaButton
          onClick={() => handleGallery()}
          location={location.pathname}
        />
        <FavouritesButton
          onClick={() => handleFavorties()}
          location={location.pathname}
        />
      </div>
      <NetworkStatusIndicator />
      {roomTakenOver && (
        <RoomTakenOverContainer>
          <RoomTakenOver />
        </RoomTakenOverContainer>
      )}
      <RoomPopup />
      {open && (
        <ToastPopup open={open} title={title} setOpen={setOpen}></ToastPopup>
      )}
      <Outlet />
    </TabletLayoutWrapper>
  )
}

export default TabletLayout

const TabletLayoutWrapper = styled.div<{
  visible: boolean
  location: string
  galleryVisible: boolean
  favoritesVisible: boolean
}>`
  width: 100%;
  height: 100%;
  transform: ${({ visible }) =>
    visible ? "translateX(-60px)" : "translateX(0)"};
  transition: all 0.4s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .logo-text-wrap {
    position: absolute;
    top: 2em;
    /* right: 2em; */
    z-index: 1;
  }

  .text-layout {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 5em;
    font-weight: 700;
    color: #8e8e8d;
  }

  .bottom-controls {
    z-index: 50;
    position: absolute;
    bottom: 2em;
    right: 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5em;
    transform: ${({ visible }) => (visible ? "translateX(60px)" : "none")};
    transition: all 0.4s ease-in-out;
    background-color: ${({ location, favoritesVisible, galleryVisible }) =>
      location === "/residences" && !galleryVisible && !favoritesVisible
        ? "rgba(0,0,0,0.5)"
        : "transparent"};
    border-radius: 1rem;
    padding: 1rem;
  }
`

const RoomTakenOverContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30%;
  height: 30px;
  background-color: ${globalStyles.colors.clay};
  opacity: 0.5;
  color: ${globalStyles.colors.white};
  font-family: Stolzl;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  z-index: 999;
`
