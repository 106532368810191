import * as THREE from "three"
export const generateMasks = (unitsArr, meshColData) => {
  const masksArr = [] as THREE.Vector3[]
  unitsArr?.forEach((unit) => {
    for (const key in meshColData) {
      if (key === unit.unitID) {
        const arr = meshColData[key]
        masksArr.push(new THREE.Vector3(arr[0], arr[1], arr[2]))
        break
      }
    }
  })

  return masksArr
}

export const createDataTexture = (masks) => {
  const size = masks.length
  const data = new Uint8Array(4 * size)

  for (let i = 0; i < size; i++) {
    const stride = i * 4
    data[stride] = masks[i].x
    data[stride + 1] = masks[i].y
    data[stride + 2] = masks[i].z
    data[stride + 3] = 255
  }

  return new THREE.DataTexture(data, size, 1)
}

export const updateUniforms = (masks, shader, suffix = "") => {
  let dataTexture = createDataTexture(masks)

  if (!dataTexture.source) return

  shader.uniforms[`uActiveArr${suffix}`].value = dataTexture
  shader.uniforms[`uCount${suffix}`].value = masks.length
  dataTexture.needsUpdate = true
}

export const formatPrice = (val: number) => {
  const formattedVal = Number(val).toLocaleString()
  return formattedVal
}
