import * as Toast from '@radix-ui/react-toast';
import './styles.css';

const ToastPopup = ({title, description, open, setOpen}: {title: string, description?: string, open?:boolean, setOpen?: (flag: boolean) => void }) => {
  return (
    <Toast.Provider swipeDirection="right" duration={3000}>
      <Toast.Root className="ToastRoot" onOpenChange={setOpen}>
        <Toast.Title className="ToastTitle">{title}</Toast.Title>
        <Toast.Description asChild>
          {description}
        </Toast.Description>
      </Toast.Root>
      <Toast.Viewport className="ToastViewport" />
    </Toast.Provider>
  );
};

export default ToastPopup;