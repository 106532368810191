import { NavLink, useLocation } from "react-router-dom"
import RoutesConfig from "@routes/routes"
import styled from "styled-components"
import { AnimatedNavBar } from "./AnimatedNavBar"
import useNavigator from "../../hooks/useNavigator"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import gsap from "gsap"
import { useEffect, useState } from "react"
import leftArrow from "@assets/icons/left-arrow.png"

export default function NavBar() {
  const { routes } = RoutesConfig()
  const location = useLocation()
  const { navigateToPath } = useNavigator()
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const { galleryVisible, favoritesVisible, orbitRunning } = useStore((s) => ({
    galleryVisible: s.galleryVisible,
    favoritesVisible: s.favoritesVisible,
    orbitRunning: s.orbitRunning,
  }))

  const [navBarVisible, setNavBarVisible] = useState(false)

  useEffect(() => {
    setNavBarVisible(false)
  }, [location, galleryVisible, favoritesVisible])

  useEffect(() => {
    if (navBarVisible) {
      gsap.to(".links", {
        opacity: 1,
        x: "0%",
        duration: 0.6,
        ease: "power1.inOut",
      })
      gsap.to(".toggleIcon", {
        transform: "rotate(-180.01deg)",
        ease: "power1.inOut",
      })
    } else if (!navBarVisible) {
      gsap.to(".links", {
        opacity: 0,
        zIndex: 500,
        x: "20px",
        duration: 0.6,
        ease: "power1.inOut",
      })
      gsap.to(".toggleIcon", {
        transform: "rotate(0deg)",
        ease: "power1.inOut",
      })
    }
  }, [navBarVisible])
  return (
    <NavBarWrapper
      style={{
        pointerEvents: navBarVisible ? "auto" : "none",
      }}
      showNavBar={!orbitRunning}
      location={location.pathname}
      galleryVisible={galleryVisible}
      favoritesVisible={favoritesVisible}
    >
      <div className="toggle" onClick={() => setNavBarVisible(!navBarVisible)}>
        <img className="toggleIcon" src={leftArrow} alt="" />
      </div>
      <AnimatedNavBar className="links">
        <NavLink to="/">
          <div className="dot"></div>
        </NavLink>

        {routes.map((route, index) => {
          return (
            route.isNavLink && (
              <NavLink key={index} to={navBarVisible ? route.path : null}>
                <NavButton
                  onClick={() => {
                    if (!navBarVisible) return
                    navigateToPath(route.path)
                    // syncState({ galleryVisible: false })
                    // syncState({ favoritesVisible: false })
                  }}
                >
                  {route.name}
                </NavButton>
                <div
                  className={`dot ${
                    location.pathname === route.path ? "active" : ""
                  }`}
                ></div>
              </NavLink>
            )
          )
        })}
      </AnimatedNavBar>
    </NavBarWrapper>
  )
}

const NavBarWrapper = styled.div<{
  location: string
  showNavBar: boolean
  galleryVisible: boolean
  favoritesVisible: boolean
}>`
  z-index: 1;
  opacity: ${({ showNavBar }) => (showNavBar ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;

  .toggle {
    pointer-events: auto;
    display: flex;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    aspect-ratio: 1/1;
    background-color: rgba(0, 0, 0, 0.75);
    border: 1px solid rgba(256, 256, 256, 0.2);
    border-right: none;
    border-radius: 100% 0 0 100%;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    z-index: 100;

    img {
      padding: 0.5rem;
      width: 80%;
      object-fit: contain;
    }
  }

  .links {
    position: absolute;
    top: 38%;
    right: 3em;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    font-family: Stolzl;
    z-index: 1;
  }

  .links::after {
    content: "";
    position: absolute;
    top: 0.8em;
    right: calc(0.4em - 0.025em);
    height: var(--pseudo-element-height, 0%);
    width: 0.05em;
    background-color: #8e8e8d;
    opacity: var(--pseudo-element-opacity, 0);
    z-index: -1;
  }

  .dot {
    width: 1.3em;
    height: 1.3em;
    background-color: rgba(0, 0, 0, 0.75);
    border: 1px solid rgba(256, 256, 256, 0.2);
    border-radius: 50%;

    &.active {
      background-color: ${(props) =>
        (props.location.includes("/location") &&
          !props.galleryVisible &&
          !props.favoritesVisible) ||
        props.location.includes("/amenities")
          ? "white"
          : "white"};
      transition: background-color 0.3s ease-in-out;
    }
  }

  a {
    text-decoration: none;
    color: #000;
    /* color: ${(props) =>
      (props.location.includes("/location") &&
        !props.galleryVisible &&
        !props.favoritesVisible) ||
      props.location.includes("/amenities")
        ? "#ffffff"
        : "#1d1d1b"}; */
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 0.1em;
    gap: 0.8em;
    padding: 0.5em 0;
    transition: color 0.3s ease-in-out;
  }
`

const NavButton = styled.button`
  all: unset;
  cursor: pointer;
  color: white;
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(256, 256, 256, 0.2);
  border-radius: 0.5rem;

  &.active {
    opacity: 0.5;
  }
`
