"use client"

import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { DefaultLoadingManager } from "three"

const HandleLoadingProgress = ({
  totalItems,
  logAssets,
  onProgress,
  onLoaded,
}) => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  DefaultLoadingManager.onError = function (url) {
    console.log("There was an error loading " + url)
  }

  DefaultLoadingManager.onProgress = function (url, itemsLoaded, totalAssets) {
    const progress = (itemsLoaded / totalItems) * 100
    logAssets &&
      console.log(
        "object",
        itemsLoaded,
        totalItems,
        totalAssets,
        url,
        `${progress}%`,
      )
    if (progress <= 100) {
      syncState({ loadProgress: progress })
      onProgress(progress)
    } else {
      // onloaded()
    }
  }

  return null
}

export default HandleLoadingProgress
