import { amenitiesData } from "@data/amenitiesData"
import { useGSAP } from "@gsap/react"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { gsap } from "gsap"
import { useState } from "react"
import SplitType from "split-type"

export default function Text({
  fontSize = "3.2vh",
  screen = "ipad",
  ...props
}) {
  const amenitiesActiveImage = useStore((state) => state.amenitiesActiveImage)
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const [allowPointerEvents, setAllowPointerEvents] = useState(false)

  useGSAP(
    () => {
      const text = gsap.utils.toArray("#sidebar-text-div p")
      gsap.set(text, {
        opacity: 0,
        y: 10,
        overwrite: true,
      })
      // Object.keys(amenitiesData).map((item, index) => {

      gsap.to(text, {
        duration: 0.5,
        opacity: 1,
        overwrite: true,
        y: 0,
        delay: 0.5,
        stagger: {
          each: 0.1,
        },
        ease: "back.out",
        onComplete: () => {
          setAllowPointerEvents(true)
        },
      })
      // })
    },
    { dependencies: [] },
  )

  return (
    <div
      id="sidebar-text-div"
      style={{
        padding: "1.2rem",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        placeItems: "center start",
        gridAutoFlow: "row",
        rowGap: "0.6rem",
        placeSelf: "center start",
        textTransform: "uppercase",
        fontFamily: "Masque-Hairline",
        zIndex: 11,
        paddingBottom: `${screen === "tv" ? "5.3vh" : "0vh"}`,
        visibility: "hidden",
        opacity: 0,
      }}
    >
      {Object.keys(amenitiesData).map((item, index) => {
        return (
          <p
            className="sidebar-text"
            id={`sidebar-text-${index}`}
            onClick={(event) => {
              event.stopPropagation()
              amenitiesActiveImage === item
                ? syncState({ amenitiesActiveImage: null })
                : syncState({ amenitiesActiveImage: item })
            }}
            style={{
              pointerEvents: allowPointerEvents ? "auto" : "none",
              cursor: "pointer",
              letterSpacing: "0.01rem",
              fontSize: fontSize,
              fontWeight: 100,
              fontStyle: "normal",
              zIndex: -1,
              opacity: !amenitiesActiveImage
                ? null
                : amenitiesActiveImage === item
                  ? 1
                  : 0.3,
              transition: "opacity 0.3s",
            }}
            key={index}
          >
            {item}
          </p>
        )
      })}
    </div>
  )
}
