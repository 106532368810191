import { useState } from "react"
import styled from "styled-components"
import globalStyles from "@data/style.globals"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import useStore from "@state/store"
import roomNames from "@data/roomToNav.map.json"
import { useJoinRoom } from "../../hooks/useJoinRoom"
import { Room } from "@providers/sockets/SocketIOProvider"
import { useNavigate } from "react-router-dom"
import StyledButton from "@components/buttons/StyledButton"

export default function RoomTakePopup() {
  const { roomConnectAttempt } = useJoinRoom()
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const attemptedRoom = useStore((s) => s.attemptedRoom)
  const navigate = useNavigate()

  return (
    <Wrapper>
      <PopupMessage>
        There is another agent connected to{" "}
        <span>
          {attemptedRoom &&
            String(roomNames[attemptedRoom].title).toUpperCase()}{" "}
          Room
        </span>{" "}
        at the moment.
      </PopupMessage>
      <PopupMessage style={{ marginTop: "2em" }}>
        Do you want to take over?
      </PopupMessage>
      <PopupSection $bgColor={"#fffff"}>
        {["GO BACK", "OKAY"].map((label, index) => {
          return (
            <StyledButton
              background="#000"
              style={{
                width: "100%",
                height: "100%",
                fontSize: "2rem",
              }}
              key={index}
              onClick={() => {
                setTimeout(() => {
                  if (label === "OKAY") {
                    roomConnectAttempt(() => {}, attemptedRoom as Room)
                  } else {
                    navigate(-1)
                  }
                  syncState({
                    showRoomPopup: false,
                  })
                }, 50)
              }}
            >
              <h3>{label}</h3>
            </StyledButton>
          )
        })}
      </PopupSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: ${globalStyles.colors.white};
`

const PopupMessage = styled.div`
  width: 50%;
  transition: opacity 0.3s ease-in-out;
  font-size: 2rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.13rem;
  text-align: center;

  span {
    font-weight: bold;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    white-space: nowrap;
  }
`

const PopupSection = styled.div<{ $bgColor: any }>`
  margin-top: 3em;
  display: flex;
  gap: 1em;
  background-color: ${({ $bgColor }) => $bgColor};
  z-index: 50;
  width: 40em;
  height: 7em;

  h3 {
    font-size: 2rem;
  }
`
