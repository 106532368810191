import { create } from "zustand"
import { combine } from "zustand/middleware"
import { initialState } from "./initialState"
import { InitialState } from "./types.store"

export interface SocketPayload {
  data: any
}

type SetLocalState = {
  setLocalState: (state: Partial<InitialState>) => void
}

const mutations = (set: any) => {
  // Reset state based on specific key or reset entire state if no key is provided
  const resetState = (key?: string) => {
    set((state: any) => {
      if (key) {
        return { ...state, [key]: initialState[key] }
      } else {
        return initialState
      }
    })
  }
  return {
    path: "/",
    setLocalState: (stateToUpdate: Partial<InitialState>) => {
      set({ ...stateToUpdate })
    },
    resetState,
  }
}

const useStore = create<
  InitialState & SetLocalState & { resetState: (key?: string) => void }
>()(combine(initialState, mutations))

export default useStore
