import styled from "styled-components"
import StyledButton from "@components/buttons/StyledButton"
import { Capacitor } from "@capacitor/core"
import gsap from "gsap"
import { useEffect, useState } from "react"
import {
  deleteFromLocalStorage,
  getAllPresetsFromStorageAsArray,
  getPresetFromLocalStorage,
  saveOrUpdatePresetToLocalStorage,
} from "@utils/withLocalStorage"

import {
  ArrowLeftIcon,
  UploadIcon,
  TrashIcon,
  Pencil1Icon,
  ReloadIcon,
} from "@radix-ui/react-icons"
import useStore from "@state/store"
import { FlexTable } from "@components/table"
import { headers as initialHeaders } from "@utils/tableHeaders"
import { TPreset } from "./Preset.model"
import PresetDialog from "./components/PresetDialog"
import { useLiveData } from "@hooks/useLiveData"
import { formatPrice } from "@components/apartmentfinder-orbit/components/utils"
import { Unit } from "@pages/tablet/residences/components/ResidenceTable"
import UnitUpdateDialog from "./components/UnitUpdateDialog"

type PresetProps = {
  show: boolean
  onClose: () => void
}

// remove the last two default headers and add `actions` one
const headers = Capacitor.isNativePlatform()
  ? [...initialHeaders.slice(0, -1), "actions"]
  : [...initialHeaders.slice(0, -1)]

const createCells = (setUnitToEdit: any, setPriceDialogOpen: any) =>
  [
    "unitType",
    "beds",
    "baths",
    "aspect",
    "sqft",
    {
      key: "price",
      render: (row: Unit) => `$${formatPrice(row.price)}`,
    },
    "status",
    // Capacitor.isNativePlatform() && {
    !window.location.href.includes("web") && {
      name: "actions",
      render: (row: Unit) => {
        return (
          <div className="actions">
            <StyledButton
              onClick={(e) => {
                e.stopPropagation()
                setPriceDialogOpen(true)
                setUnitToEdit(row)
              }}
              icon={<Pencil1Icon height={15} width={15} color="#fff" />}
              color="#fff"
              border={true}
              style={{
                margin: "auto",
                borderRadius: 50,
              }}
            ></StyledButton>
          </div>
        )
      },
    },
  ].filter(Boolean)

const Preset: React.FC<PresetProps> = ({ show, onClose }) => {
  const { sortConfig, selectedPreset, setSortConfig, setSelectedPreset } =
    useStore((state) => ({
      sortConfig: state.sortConfig,
      selectedPreset: state.selectedPreset,
      setSelectedPreset: (newPreset: TPreset) =>
        state.setLocalState({ selectedPreset: newPreset }),
      setSortConfig: (newSortConfig: any) =>
        state.setLocalState({ sortConfig: newSortConfig }),
    }))
  useEffect(() => {
    if (show === true)
      gsap.to(".preset-wrapper", {
        autoAlpha: 1,
        duration: 0.3,
        ease: "power2.inOut",
      })
    else if (show === false)
      gsap.to(".preset-wrapper", {
        autoAlpha: 0,
        duration: 0.3,
        ease: "power2.inOut",
      })
  }, [show])

  const [presets, setPresets] = useState([])
  const [unitToEdit, setUnitToEdit] = useState(null)
  const [presetDialogOpen, setPresetDialogOpen] = useState(false)
  const [priceDialogOpen, setPriceDialogOpen] = useState(false)
  const { data, updateRecordViaAirtable, fetchData } = useLiveData()

  const cells = createCells(setUnitToEdit, setPriceDialogOpen)

  useEffect(() => {
    setPresets(getAllPresetsFromStorageAsArray())
  }, [])

  const refreshAllPresetsFromLocalStorage = () => {
    const allPresets = getAllPresetsFromStorageAsArray()
    setPresets([...allPresets])
  }

  const deletePreset = (index: number, e: any) => {
    e.stopPropagation()
    deleteFromLocalStorage(presets[index].name)
    refreshAllPresetsFromLocalStorage()
  }

  const presetCells = [
    {
      key: "name",
      render: (row: any, index: number) => {
        return row.name
      },
    },
    {
      name: "units",
      render: (row: TPreset) => {
        return row.units.length
      },
    },
    {
      name: "actions",
      render: (_, index: number) => {
        return (
          <div className="actions">
            <StyledButton
              onClick={(e: any) => deletePreset(index, e)}
              icon={<TrashIcon height={15} width={15} color="#fff" />}
              color="#fff"
              border={true}
              style={{
                margin: "auto",
                borderRadius: 50,
              }}
            ></StyledButton>
          </div>
        )
      },
    },
  ]

  return (
    <Wrapper className="preset-wrapper">
      <div className="back" onClick={onClose}>
        <ArrowLeftIcon height={20} width={20} color="#fff" />
        <StyledButton
          border={false}
          color="#fff"
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          Back
        </StyledButton>
      </div>

      <div className="presets-content">
        <div className="presets-table-container">
          <div className="presets-table-controls">
            <div style={{ fontSize: "2em" }}>Presets</div>
            <PresetDialog
              isOpen={presetDialogOpen}
              onOpenChange={setPresetDialogOpen}
              presetName=""
              presets={presets}
              onSave={(value: string) => {
                saveOrUpdatePresetToLocalStorage([], value)
                setPresets(getAllPresetsFromStorageAsArray())
                // set selectedPreset to be the newly created preset
                setSelectedPreset({ name: value, units: [] })
              }}
            />
          </div>
          <div className="table">
            <FlexTable
              headers={["Name", "Units", "Actions"]}
              uniqueKey="name"
              data={getAllPresetsFromStorageAsArray()}
              cells={presetCells}
              selection="single"
              sortable={false}
              preselectedMode={true}
              preselectedRows={[selectedPreset]}
              onSelectionChange={(selected) => {
                if (selectedPreset?.name === selected?.name) {
                  setSelectedPreset(null)
                } else {
                  setSelectedPreset(selected)
                }
              }}
            />
          </div>
        </div>
        <div className="units-table-container">
          <div className="presets-table-controls">
            <div style={{ flex: 1, fontSize: "2em" }}>
              Select units for preset list
            </div>
            <StyledButton
              onClick={() => fetchData()}
              icon={
                <ReloadIcon
                  style={{ marginRight: "10px" }}
                  height={20}
                  width={20}
                  color="#fff"
                />
              }
              border={true}
              color="#fff"
              style={{
                margin: 0,
                padding: "1em",
                borderRadius: 15,
              }}
            >
              Update Pricing
            </StyledButton>
          </div>
          <div className="table">
            <UnitUpdateDialog
              isOpen={priceDialogOpen}
              onOpenChange={setPriceDialogOpen}
              unit={unitToEdit}
              onSave={(
                id: string,
                fields: { price: string | number; status: string },
              ) => {
                return updateRecordViaAirtable({
                  id,
                  fields,
                }).then(() => fetchData())
              }}
            />
            <FlexTable
              uniqueKey="unitID"
              headers={headers}
              data={data}
              cells={cells}
              selection={selectedPreset?.name ? "multiple" : "none"}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              preselectedMode={true}
              preselectedRows={
                getPresetFromLocalStorage(selectedPreset?.name)?.units
              }
              onSelectionChange={(selected) => {
                if (selectedPreset?.name) {
                  saveOrUpdatePresetToLocalStorage(
                    selected,
                    selectedPreset?.name,
                  )
                  setPresets(getAllPresetsFromStorageAsArray())
                }
              }}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Preset

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5em 4em;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 263;

  .actions {
    display: flex;
    transition: all 0.3s ease-in-out;
  }

  .back {
    position: absolute;
    display: flex;
    align-items: center;
    top: 3em;
    left: 4em;
    gap: 1em;
    padding: 1em 1em 1em 0;
  }

  .presets-table-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    padding: 0px 0.5em 2em;
    gap: 1em;
  }

  .presets-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3em;
    width: 100%;
    height: 75%;
    text-transform: uppercase;

    .header-cell {
      font-size: 1em;
    }

    .flex-table-cell {
      min-width: 8rem;
      flex: 1;
    }
  }

  .units-table-container {
    height: 100%;
    max-height: 100%;
    width: 70%;

    .table {
      height: 100%;
      width: 100%;
      padding: 2em 1em;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 1rem;
    }
  }

  .presets-table-container {
    z-index: 1;
    height: 100%;
    width: 30%;

    .table {
      height: 100%;
      width: 100%;
      padding: 2em 1em;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 1rem;
    }
  }
`
