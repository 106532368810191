import React from "react"
import styled from "styled-components"

type ButtonProps = {
  style?: React.CSSProperties
  location?: string
  height?: string
  width?: string
  className?: string
  onClick?: () => void
}

const TableFavButton: React.FC<ButtonProps> = ({
  onClick,
  height,
  width,
  className,
  style,
}) => {
  const fillColor = true ? "#fff" : "transparent"

  return (
    <Button
      onClick={onClick}
      className={className}
      style={{
        ...style,
        display: "flex",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "24"}
        height={height ?? "24"}
        viewBox="0 0 512 512"
      >
        <g>
          <path
            d="M376 30c-27.783 0-53.255 8.804-75.707 26.168-21.525 16.647-35.856 37.85-44.293 53.268-8.437-15.419-22.768-36.621-44.293-53.268C189.255 38.804 163.783 30 136 30 58.468 30 0 93.417 0 177.514c0 90.854 72.943 153.015 183.369 247.118 18.752 15.981 40.007 34.095 62.099 53.414C248.38 480.596 252.12 482 256 482s7.62-1.404 10.532-3.953c22.094-19.322 43.348-37.435 62.111-53.425C439.057 330.529 512 268.368 512 177.514 512 93.417 453.532 30 376 30z"
            fill={fillColor}
          ></path>
        </g>
      </svg>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "24"}
        height={height ?? "24"}
        viewBox="0 0 512 512"
        style={{ position: "absolute" }}
      >
        <g>
          <path
            d="M474.644 74.27C449.391 45.616 414.358 29.836 376 29.836c-53.948 0-88.103 32.22-107.255 59.25-4.969 7.014-9.196 14.047-12.745 20.665-3.549-6.618-7.775-13.651-12.745-20.665-19.152-27.03-53.307-59.25-107.255-59.25-38.358 0-73.391 15.781-98.645 44.435C13.267 101.605 0 138.213 0 177.351c0 42.603 16.633 82.228 52.345 124.7 31.917 37.96 77.834 77.088 131.005 122.397 19.813 16.884 40.302 34.344 62.115 53.429l.655.574c2.828 2.476 6.354 3.713 9.88 3.713s7.052-1.238 9.88-3.713l.655-.574c21.813-19.085 42.302-36.544 62.118-53.431 53.168-45.306 99.085-84.434 131.002-122.395C495.367 259.578 512 219.954 512 177.351c0-39.138-13.267-75.746-37.356-103.081zM309.193 401.614c-17.08 14.554-34.658 29.533-53.193 45.646-18.534-16.111-36.113-31.091-53.196-45.648C98.745 312.939 30 254.358 30 177.351c0-31.83 10.605-61.394 29.862-83.245C79.34 72.007 106.379 59.836 136 59.836c41.129 0 67.716 25.338 82.776 46.594 13.509 19.064 20.558 38.282 22.962 45.659a15 15 0 0 0 28.524 0c2.404-7.377 9.453-26.595 22.962-45.66 15.06-21.255 41.647-46.593 82.776-46.593 29.621 0 56.66 12.171 76.137 34.27C471.395 115.957 482 145.521 482 177.351c0 77.007-68.745 135.588-172.807 224.263z"
            fill="#fff"
          ></path>
        </g>
      </svg> */}
    </Button>
  )
}

export { TableFavButton }

const Button = styled.button`
  padding: 1em;
  border-radius: 50%;
  height: 4em;
  width: 4em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg > path {
    transition: fill 0.4s ease-in-out;
  }
`
