/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, forwardRef } from "react"
import styled from "styled-components"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import OrbitButtons from "@components/orbit-buttons/orbit-buttons"
import VideoOrbit from "@components/orbit-video/VideoOrbit"
import { useMediaQuery } from "react-responsive"

const LocationOrbit = forwardRef(function LocationOrbit(
  { orbitIndex, orbitDir, orbitRunning, onOrbitCreated, ...props }: any,
  ref,
) {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const isTablet = true

  useEffect(() => {
    return () => {
      syncState({
        orbitLoaded: false,
        orbitIndex: 0,
      })
    }
  }, [])

  return (
    <OrbitWrapper>
      <VideoOrbit
        direction={orbitDir}
        fwdVideos={[
          "/assets/videos/orbits/0-fw.mp4",
          "/assets/videos/orbits/1-fw.mp4",
          "/assets/videos/orbits/2-fw.mp4",
          "/assets/videos/orbits/3-fw.mp4",
          "/assets/videos/orbits/4-fw.mp4",
          "/assets/videos/orbits/5-fw.mp4",
        ]}
        bwdVideos={[
          "/assets/videos/orbits/5-rv.mp4",
          "/assets/videos/orbits/0-rv.mp4",
          "/assets/videos/orbits/1-rv.mp4",
          "/assets/videos/orbits/2-rv.mp4",
          "/assets/videos/orbits/3-rv.mp4",
          "/assets/videos/orbits/4-rv.mp4",
        ]}
        videoProps={{
          playsInline: true,
          muted: true,
        }}
        indexState={orbitIndex}
        indexSet={(n) => {
          syncState({ orbitIndex: n })
        }}
        playState={orbitRunning}
        playSet={(v) => {
          syncState({ orbitRunning: v })
        }}
        lockWhilePLaying={true}
      />
      {isTablet && (
        <OrbitButtons orbitRunning={orbitRunning} orbitIndex={orbitIndex} />
      )}
    </OrbitWrapper>
  )
})
export default LocationOrbit

const OrbitWrapper = styled.div`
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s linear;
  z-index: 50;

  .videoWrapper {
    transition: opacity;
    transition-duration: 200ms;

    & video {
      object-fit: cover;
    }
  }

  .controls {
    height: 7rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0;
    pointer-events: all;
    background: linear-gradient(
      to right,
      transparent 20%,
      #00000086,
      transparent 80%
    );
    transition: opacity 400ms ease-in-out;

    & img {
      max-height: 150%;
    }

    .arrow-button {
      width: 70px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`
