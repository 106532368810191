import styled from "styled-components"
import Text from "./components/Text"
import globalStyles from "@data/style.globals"
import ScreenImages from "./components/ScreenImages"
import useStore from "@state/store"
import TCanvas from "./canvas-components/TCanvas"
import FullScreenImage from "./components/FullScreenImage"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import FocusText from "./components/FocusText"
import MeshSelector from "@pages/tablet/amenities/components/MeshSelector"
import { useEffect } from "react"
import { useScreenTimeout } from "@hooks/useScreenTimeout"

type Props = {}

export function Amenities(props: Props) {
  const amenitiesSelector = useStore((state) => state.amenitiesSelector)
  const amenitiesActiveMesh = useStore((state) => state.amenitiesActiveMesh)
  const amenitiesActiveImage = useStore((state) => state.amenitiesActiveImage)
  const amenitiesFullScreenImage = useStore(
    (state) => state.amenitiesFullScreenImage,
  )
  const showAmenitiesScreenSaver = useStore(
    (state) => state.showAmenitiesScreenSaver,
  )

  const loadProgress = useStore((state) => state.loadProgress)
  const loaded = useStore((state) => state.loaded)
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  useGSAP(() => {
    if (loadProgress >= 100) {
      setTimeout(() => {
        gsap.to("#loading", { autoAlpha: 0, duration: 1 })
        syncState({ loaded: true })
      }, 100)
    }
  }, [loadProgress])

  useGSAP(
    () => {
      if (!loaded) return
      gsap.to(
        [
          "#sidebar-text-div",
          ".image-gallery",
          "#sidebar-selector",
          ".plate-labels",
        ],
        {
          duration: 0.5,
          stagger: 0.2,
          autoAlpha: amenitiesSelector ? 0 : 1,
          y: amenitiesSelector ? 300 : 0,
          delay: amenitiesSelector ? 0 : 0.5,
        },
      )
      gsap.to(["#amenities-text", "#amenities-selector", "#amenity-close"], {
        duration: 0.5,
        stagger: 0.2,
        autoAlpha: amenitiesSelector !== null ? 1 : 0,
        y: amenitiesSelector !== null ? 0 : 300,
        delay: amenitiesSelector === null ? 0 : 0.5,
      })
    },
    { dependencies: [amenitiesSelector, loaded] },
  )

  useEffect(() => {
    syncState({
      amenitiesActiveMesh: null,
      amenitiesActiveImage: null,
      amenitiesFullScreenImage: null,
      amenitiesSelector: null,
    })

    return () => {
      syncState({
        amenitiesActiveMesh: null,
        amenitiesActiveImage: null,
        amenitiesFullScreenImage: null,
        amenitiesSelector: null,
      })
    }
  }, [])

  const showScreenSaver = useScreenTimeout(5 * 60 * 1000, [
    amenitiesSelector,
    amenitiesActiveImage,
    amenitiesActiveMesh,
    amenitiesFullScreenImage,
    showAmenitiesScreenSaver,
  ])

  return (
    <Wrapper
      style={{
        backgroundColor: globalStyles.colors.offBlack,
        color: "white",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gridTemplateRows: "repeat(2, minmax(0, 1fr))",
        zIndex: 3,
      }}
    >
      <video
        style={{
          opacity: showScreenSaver ? 1 : 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "100%",
          zIndex: 100,
          objectFit: "cover",
          transition: "opacity 0.5s",
        }}
        loop
        autoPlay
        muted
      >
        <source src="/assets/videos/amenities.mp4" />
      </video>
      <video
        style={{
          opacity: showAmenitiesScreenSaver ? 1 : 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "100%",
          zIndex: 101,
          objectFit: "cover",
          transition: "opacity 0.5s",
        }}
        loop
        autoPlay
        muted
      >
        <source src="/assets/videos/amenities.mp4" />
      </video>
      <div
        id="loading"
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 20,
          height: "100%",
          width: "100%",
          display: "grid",
          backgroundColor: globalStyles.colors.offBlack,
          placeItems: "center",
        }}
      >
        <p style={{ fontSize: "2rem" }}>
          LOADING AMENITIES... {loadProgress.toFixed(0)}%
        </p>
      </div>
      {loaded && (
        <>
          <Text screen="tv" />
          <ScreenImages screen="tv" />
          <FullScreenImage $screen="tv" />
          <FocusText screen="tv" />
          <MeshSelector screen="tv" />
        </>
      )}
      <TCanvas screen="tv" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
`
