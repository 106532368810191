import { useCallback, useState } from "react"

const useDisplayMessage = () => {
  const [message, setMessage] = useState<string | null>(null)

  const show = useCallback((msg: string) => {
    setMessage(msg)

    // if msg contains string ONLINE, close the message after 3 seconds
    if (msg.includes("ONLINE")) {
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }, [])

  const close = useCallback(() => {
    setMessage(null)
  }, [])

  return { show, close, message }
}

export default useDisplayMessage
