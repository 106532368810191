import { Room, useSocketIO } from "@providers/sockets/SocketIOProvider"
import defaultRoom from "@data/defaultRoom.generated.json"
import roomToNav from "@data/roomToNav.map.json"
import useStore from "@state/store"

export const getRoomByNav = (nav: string): string => {
  return Object.keys(roomToNav).find((key) => roomToNav[key].nav === nav)
}

export const useJoinRoom = () => {
  const {
    socket,
    usersInRooms: stateUsers,
    joinRoom,
    emitSocketEvent,
  } = useSocketIO()
  const [isTablet, setLocalState] = useStore((s) => [
    s.isTablet,
    s.setLocalState,
  ])

  const emitNavigation = (path: string, room?: string) => {
    const payload = room ? { path, room } : { path }
    emitSocketEvent("navigation", payload)
  }

  const roomForceJoin = (attemptedRoom: Room) => {
    emitSocketEvent("forceJoin", {
      userJoining: socket.id,
      room: attemptedRoom,
      path: window.location.pathname,
      isTablet: isTablet,
    })
  }

  const isOtherAgentInSelectedRoom = (room: Room) => {
    let usersInRooms = stateUsers
    if (usersInRooms && !Array.isArray(usersInRooms)) {
      usersInRooms = Object.keys(usersInRooms).map((key: any) => {
        return { room: key, users: usersInRooms[key].users }
      }, [])
    }

    return usersInRooms?.find(
      (userInRoom: any) =>
        userInRoom.room === room &&
        userInRoom.users.length > 0 &&
        // does not include current user and its another tablet user
        userInRoom?.users?.some(
          (user) => user.tablet === true && user.id !== socket.id,
        ),
    )
  }

  /**
   * Attempt to connect to a specific room
   *
   * @param callback  - callback function to be called after room connection
   * @param room  - Room to connect to
   * @returns
   */
  const roomConnectAttempt = async (
    callback?: (room: Room) => void,
    room?: Room,
  ) => {
    try {
      if (!room) {
        console.log("Connect to default room:", defaultRoom.room as Room)
        room = defaultRoom.room as Room
      }

      if (isTablet && stateUsers) {
        const otherAgentInSelectedRoom = isOtherAgentInSelectedRoom(room)
        if (otherAgentInSelectedRoom) {
          console.log("Other agent in room:", room, otherAgentInSelectedRoom)

          const userToKick = otherAgentInSelectedRoom.users?.find(
            (user: any) => user.tablet === true,
          )
          emitNavigation(`/${roomToNav[room].nav}`, room)
          setLocalState({ roomTakenOver: null })
          setLocalState({ attemptedRoom: room })
          roomForceJoin(room)
          callback && callback(room)
          return
        }
      }

      await joinRoom({ room, payload: { isTablet } })
      callback && callback(room)
    } catch (error) {
      console.error("Error Connecting:", error)
    }
  }

  /**
   * Does a room connect attempt and switches to the room based on the path required in the menu
   *
   * @param navigation  - navigation path string
   * @param callback
   */
  const roomSwitch = async (
    navigation: string,
    callback?: (room: Room) => void,
  ) => {
    const room = getRoomByNav(navigation?.trim())
    console.log("Switching Rooms:", navigation, "<")
    if (room) {
      await roomConnectAttempt(callback, room as Room)
    } else {
      console.error(
        "Error Switching Rooms: Room not found for navigation: ",
        navigation,
      )
    }
  }

  return {
    roomConnectAttempt,
    roomSwitch,
    isOtherAgentInSelectedRoom,
    emitNavigation,
  }
}
