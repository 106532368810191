import React, { ReactNode } from "react"
import styled from "styled-components"

type ButtonProps = {
  onClick?: () => void
  color?: string
  width?: string
  height?: string
  style?: React.CSSProperties
}

const RefreshButton: React.FC<ButtonProps> = ({
  onClick,
  style,
  color,
  width,
  height,
}) => {
  return (
    <Button
      onClick={onClick}
      style={{
        ...style,
      }}
    >
      <svg
        height={height ? height : "30"}
        width={width ? width : "30"}
        viewBox="0 -43 469 469"
        xmlns="http://www.w3.org/2000/svg"
        fill={color ? color : "black"}
      >
        <path d="m466.078125 152.554688c-4.164063-4.164063-10.921875-4.164063-15.082031 0l-25.1875 25.1875c-6.113282-82.757813-64.765625-152.195313-145.335938-172.0625-80.566406-19.871094-164.777344 14.339843-208.667968 84.765624-2.058594 3.230469-2.226563 7.320313-.4375 10.710938 1.789062 3.390625 5.257812 5.5625 9.089843 5.683594 3.832031.125 7.4375-1.820313 9.4375-5.089844 38.722657-62.121094 112.777344-92.601562 184.007813-75.734375 71.234375 16.867187 123.757812 77.3125 130.515625 150.203125l-23.675781-23.664062c-4.183594-4.039063-10.835938-3.984376-14.953126.132812-4.113281 4.113281-4.171874 10.765625-.128906 14.953125l42.667969 42.664063c4.164063 4.167968 10.917969 4.167968 15.082031 0l42.667969-42.664063c4.167969-4.164063 4.167969-10.921875 0-15.085937zm0 0" />
        <path d="m393.894531 279.054688c-4.996093-3.113282-11.574219-1.597657-14.707031 3.386718-38.722656 62.128906-112.777344 92.609375-184.015625 75.742188-71.234375-16.867188-123.757813-77.316406-130.511719-150.210938l23.667969 23.667969c4.183594 4.039063 10.835937 3.984375 14.953125-.132813 4.113281-4.113281 4.171875-10.765624.128906-14.953124l-42.667968-42.664063c-4.164063-4.167969-10.917969-4.167969-15.082032 0l-42.664062 42.664063c-4.042969 4.1875-3.988282 10.839843.128906 14.953124 4.113281 4.117188 10.765625 4.171876 14.953125.132813l25.183594-25.1875c6.117187 82.757813 64.765625 152.195313 145.335937 172.0625 80.570313 19.871094 164.777344-14.335937 208.667969-84.765625 3.117187-4.988281 1.609375-11.5625-3.371094-14.695312zm0 0" />
      </svg>
    </Button>
  )
}

export { RefreshButton }

const Button = styled.button`
  border-radius: 50%;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 50ms ease-in-out;

  &:active {
    opacity: 0.5;
  }
`
