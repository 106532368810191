import useStore from "@state/store"
import React from "react"
import styled from "styled-components"

type ButtonProps = {
  onClick?: () => void
  location: string
  style?: React.CSSProperties
}

const Logo: React.FC<ButtonProps> = ({ onClick, location, style }) => {
  const { galleryVisible, favoritesVisible } = useStore((s) => ({
    galleryVisible: s.galleryVisible,
    favoritesVisible: s.favoritesVisible,
  }))
  const fillColor =
    (location.includes("/location") && !galleryVisible && !favoritesVisible) ||
    (location.includes("/amenities") && !galleryVisible)
      ? "#fff"
      : "#000"

  return (
    <Button
      onClick={onClick}
      style={{
        pointerEvents: "auto",
        ...style,
      }}
    >
      <svg
        className="logo-svg"
        width="79"
        height="80"
        viewBox="0 0 79 80"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M78.9972 30.2318H61.9649V30.5415H69.3297V54.2696H71.6352V30.5415H78.9999V30.2318H78.9972ZM47.9959 34.3979C47.9959 31.8501 50.5762 30.2318 53.4314 30.2318C56.0117 30.2318 58.8002 31.5404 60.1424 34.5367L60.9669 34.1228C59.8302 31.1611 56.8389 29.7831 53.9144 29.7831C50.2667 29.7831 46.6537 31.9169 46.6537 35.9468C46.6537 43.1093 61.1057 42.3509 61.1057 47.9991C61.1057 51.754 57.7675 53.7836 54.1545 53.7836C50.8511 53.7836 47.3421 52.0958 45.9999 48.4451L45.0714 48.8591C46.3789 52.5097 50.0959 54.3337 53.7436 54.3337C58.1144 54.3337 62.3465 51.786 62.3465 46.6879C62.3438 39.4933 47.9959 40.9755 47.9959 34.3979ZM30.6541 54.267H45.2448V53.9572H36.1616C33.5119 53.9572 33.0636 53.6821 32.9969 51.754V42.0437H41.2556V41.7339H32.9969V32.7448C33.0663 30.8166 33.5119 30.5415 36.1616 30.5415H44.8339V30.2318H30.6567V54.267H30.6541ZM26.3873 38.4625L21.3627 50.7552L16.7864 38.0833L17.6803 36.0162C19.0572 32.8142 21.1546 31.5056 22.9798 31.5056C25.7682 31.5056 28.0043 34.502 26.3873 38.4625ZM18.2994 33.6047L16.5783 37.5652L13.9286 30.1623H11.3483L15.2014 40.8393L10.9347 50.8593L3.43383 30.1623H0.888184L9.69653 54.6809L15.3749 41.3547L20.1566 54.6809L27.4173 36.8067C29.2078 32.4323 26.6248 29.5054 23.5988 29.5054C21.6376 29.508 19.5055 30.7472 18.2994 33.6047Z"
          fill={fillColor}
        />
        <path
          d="M46.8993 79.2418H44.6285C41.7039 79.2418 41.4638 78.8973 41.4638 76.3495V58.7184C41.4638 56.1706 41.7039 55.8261 44.6285 55.8261H46.8993C59.1472 55.7887 59.1472 79.2418 46.8993 79.2418ZM46.8993 55.5137H39.1236V79.5516H46.8993C62.3813 79.6904 62.416 55.6178 46.8993 55.5137ZM34.5793 55.5137V75.6605L20.3328 55.5137H19.06V79.5516H19.4389V58.3391L34.5793 80.0002H34.9582V55.5163H34.5793V55.5137ZM2.30518 79.5516H16.8933V79.2418H7.81006C5.16035 79.2418 4.71474 78.9667 4.64536 77.0385V67.3283H12.904V67.0185H4.64536V58.0294C4.71474 56.1012 5.16035 55.8261 7.81006 55.8261H16.4823V55.5163H2.30518V79.5516Z"
          fill={fillColor}
        />
        <path
          d="M14.4523 28.6204H28.8376L31.1271 20.7956H30.999C29.2699 25.6801 26.0758 25.6801 23.1379 25.6801H14.4977C14.4977 22.3525 15.8372 19.9303 22.6176 15.2621C26.1159 12.8399 30.3506 10.1186 30.3506 6.01124C30.3506 2.20834 26.3774 0.00244141 22.2734 0.00244141C20.2428 0.00244141 18.2601 0.702136 16.6191 0.702136L14.0654 8.51892L14.1348 8.50557C15.7759 3.53295 18.5136 0.133301 22.4442 0.133301C25.9851 0.133301 27.3673 3.50624 27.3673 6.40115C27.3673 8.73524 26.7616 12.2364 22.4869 15.1767C16.1361 19.5858 14.367 21.9653 14.367 25.6827C14.367 26.5026 14.367 27.4961 14.4523 28.6204Z"
          fill={fillColor}
        />
        <path
          d="M32.7945 14.8297C32.7945 7.73934 34.3502 0.13086 40.354 0.13086C46.3152 0.13086 47.8709 7.73934 47.8709 14.8297C47.8709 21.832 46.3152 29.2268 40.354 29.2268C34.3502 29.2241 32.7945 21.832 32.7945 14.8297ZM40.354 29.355C47.7828 29.355 50.9795 21.7038 50.9795 14.6561C50.9795 7.65388 47.7828 0 40.354 0C32.8799 0 29.7285 7.65121 29.7285 14.6561C29.7285 21.7038 32.8825 29.355 40.354 29.355Z"
          fill={fillColor}
        />
        <path
          d="M4.57628 28.6206H7.68762C8.46413 18.0718 12.0931 12.8855 14.0811 8.64732C14.7295 7.26396 15.2472 6.09691 16.3706 3.7201L17.7528 0.737062L17.7101 0.694336H2.28947L0 8.51913H0.173444C1.8572 3.63464 5.05392 3.63464 8.03451 3.63464H16.2425C15.466 5.23432 14.7295 6.87672 13.9103 8.56186C12.0051 12.4956 5.18201 24.0379 4.57628 28.6206Z"
          fill={fillColor}
        />
      </svg>
    </Button>
  )
}

export { Logo }

const Button = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  svg > path {
    transition: fill 0.4s ease-in-out;
  }
`
