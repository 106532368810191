import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import OrbitButtons from "@components/orbit-buttons/orbit-buttons"
import useStore from "@state/store"
import VideoOrbit from "@components/orbit-video/VideoOrbit"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
// import CanvasOrbit from "./components/CanvasOrbit"
// import MapSVGIconGroup from "@components/MapSVGIconGroup"
import { residenceData } from "./data/residencesData"
import OrbitSVG from "./components/OrbitSVG"
import OrbitBGImage from "./components/OrbitBGImage"
import gsap from "gsap"

const ApartmentFinderOrbit = ({ showUI = true }) => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const isTablet = showUI

  const { orbitIndex, orbitRunning, orbitDir, selectedUnit, selectionChanged } = useStore(
    (s) => ({
      orbitDir: s.orbitDir,
      orbitIndex: s.orbitIndex,
      orbitRunning: s.orbitRunning,
      selectedUnit: s.selectedUnit,
      selectionChanged: s.selectionChanged,
    }),
  )

  const [data, setData] = useState(residenceData.immersion[orbitIndex])

  React.useEffect(() => {
    return () => syncState({ orbitIndex: 0 })
  }, [])

  React.useEffect(() => {
    setData(residenceData.immersion[orbitIndex])
  }, [orbitIndex])

  React.useEffect(() => {
    if (selectedUnit) {
      if (selectedUnit.unitType === 'PH17C') {
        const newOrbitIndex = 2;
        const tl = gsap.timeline()

        tl.to(`._0, ._1, ._2, ._3`, { opacity: 0, duration: 0.3 })
        .to(`.svgOverlays`, { opacity: 0, duration: 0.3},"<")
        .to(`._${newOrbitIndex}`, { opacity: 1, duration: 0.3 })
        .add(() => { syncState({ orbitIndex: newOrbitIndex }); })
        .to(`.svgOverlays`, { opacity: 1, duration: 0.3 })
        .to(`._${newOrbitIndex}`, { opacity: 0, duration: 0.3 });
    } 
      else if (selectedUnit.unitType === 'PH17A') {
        const newOrbitIndex = 0;
        const tl = gsap.timeline()

        tl.to(`._0, ._1, ._2, ._3`, { opacity: 0, duration: 0.3 })
        .to(`.svgOverlays`, { opacity: 0, duration: 0.3},"<")
        .to(`._${newOrbitIndex}`, { opacity: 1, duration: 0.3 })
        .add(() => { syncState({ orbitIndex: newOrbitIndex }); })
        .to(`.svgOverlays`, { opacity: 1, duration: 0.3 })
        .to(`._${newOrbitIndex}`, { opacity: 0, duration: 0.3 });
    } else {
        let newOrbitIndex;
        switch (selectedUnit.aspect) {
            case 'N':
            case 'E':
            case 'NE':
            case 'WE':
                newOrbitIndex = 3;
                break;
            case 'W':
            case 'SW':
                newOrbitIndex = 0;
                break;
            case 'S':
            case 'NS':
                newOrbitIndex = 1;
                break;
            case 'SE':
            case 'SEN':
                newOrbitIndex = 2;
                break;
            default:
                newOrbitIndex = 0; 
        }

        const tl = gsap.timeline()

        tl.to(`._0, ._1, ._2, ._3`, { opacity: 0, duration: 0.3 })
        .to(`.svgOverlays`, { opacity: 0, duration: 0.3},"<")
        .to(`._${newOrbitIndex}`, { opacity: 1, duration: 0.3 })
        .add(() => { syncState({ orbitIndex: newOrbitIndex }); })
        .to(`.svgOverlays`, { opacity: 1, duration: 0.3 })
        .to(`._${newOrbitIndex}`, { opacity: 0, duration: 0.3 });
      }
      
    
    }
  }, [selectionChanged])

  



  return (
    <OrbitWrapper>
    
      <OrbitSVG
        orbitRunning={orbitRunning}
        data={data}
        selectedUnit={selectedUnit}
        isTablet={showUI}
      />
      <OrbitBGImage
        orbitDir={orbitDir}
        orbitIndex={orbitIndex}
        orbitRunning={orbitRunning}
      />

      <VideoOrbit
        direction={orbitDir}
        fwdVideos={[
          "/assets/videos/night-orbits/0-fw.mp4",
          "/assets/videos/night-orbits/1-fw.mp4",
          "/assets/videos/night-orbits/2-fw.mp4",
          "/assets/videos/night-orbits/3-fw.mp4",
        ]}
        bwdVideos={[
          "/assets/videos/night-orbits/3-rv.mp4",
          "/assets/videos/night-orbits/0-rv.mp4",
          "/assets/videos/night-orbits/1-rv.mp4",
          "/assets/videos/night-orbits/2-rv.mp4",
        ]}
        wrapperProps={{
          className: orbitRunning ? "videoWrapper show" : "videoWrapper hide",
        }}
        videoProps={{
          playsInline: true,
          muted: true,
        }}
        indexState={orbitIndex}
        indexSet={(n) => {
          syncState({ orbitIndex: n })
        }}
        playState={orbitRunning}
        playSet={(v) => {
          syncState({ orbitRunning: v })
        }}
        lockWhilePLaying={true}
        delayMS={200}
      />

      {isTablet && (
        <OrbitButtons
          orbitRunning={orbitRunning}
          orbitIndex={orbitIndex}
          hasClose={false}
        />
      )}
    </OrbitWrapper>
  )
}

export default ApartmentFinderOrbit

const OrbitWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .videoWrapper {
    transition: opacity;
    transition-duration: 200ms;
  }

  .show {
    opacity: 1;
  }

  .hide {
    opacity: 0;
  }
`
