import React from "react"
import styled from "styled-components"

type ButtonProps = {
  style?: React.CSSProperties
  mode?: "preset" | "close"
  onClick?: () => void
}

const PresetButton: React.FC<ButtonProps> = ({ onClick, style, mode }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        ...style,
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 37 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0.883789"
          y1="3.03186"
          x2="36.1336"
          y2="3.03186"
          stroke="white"
          strokeWidth="1.25574"
        />
        <line
          x1="0.883789"
          y1="12.1805"
          x2="36.1336"
          y2="12.1805"
          stroke="white"
          strokeWidth="1.25574"
        />
        <line
          x1="0.883789"
          y1="21.3298"
          x2="36.1336"
          y2="21.3298"
          stroke="white"
          strokeWidth="1.25574"
        />
        <ellipse
          cx="24.0028"
          cy="3.43083"
          rx="3.43342"
          ry="3.43083"
          fill="white"
        />
        <ellipse
          cx="23.5438"
          cy="21.2714"
          rx="3.43343"
          ry="3.43083"
          fill="white"
        />
        <ellipse
          cx="11.6414"
          cy="11.6642"
          rx="3.43343"
          ry="3.43083"
          fill="white"
        />
      </svg>
    </Button>
  )
}

export { PresetButton }

const Button = styled.button`
  padding: 1em;
  border-radius: 50%;
  height: 4em;
  width: 4em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:active {
    background-color: rgba(213, 213, 213, 0.1);
  }

  svg > path {
    transition: fill 0.4s ease-in-out;
  }
`
