/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
const messages = {
  noChars: "can't contain special characters",
  noEmpty: "can't be empty",
  notLongEnough: "Phone must have at least 10 digits",
  validEmail: "Please provide a valid email",
  noData: "Form must be filled",
}

export function validation(obj) {
  let message
  if (Object.values(obj).every((v) => v === null || v === "")) {
    message = messages.noData
  } else {
    for (const key in obj) {
      if (key === "cc") return
      if (obj[key] === null || obj[key] === "") {
        message = `${key} ${messages.noEmpty}`
        break
      } else {
        if (key.toLowerCase() === "email") {
          if (!validateEmail(obj[key])) {
            message = messages.validEmail
          }
        } else {
          if (!checkForChars(obj[key])) {
            message = `${key} ${messages.noChars}`
            break
          }
        }
      }
    }
  }

  return message
}

function checkForChars(value) {
  return /[A-Za-z]$/i.test(value)
}

function validateEmail(value) {
  return value
    ?.toString()
    .match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}
