import styled from "styled-components"
import { TableBody } from "./TableBody"
import { TableHeader } from "./TableHeader"
import { useCallback, useEffect, useState, useMemo } from "react"
import { sortTable } from "@utils/residenceTableFn"
import { SortConfig } from "@state/types.store"
import useStore from "@state/store"

type FlexTableProps = {
  uniqueKey: string
  headers?: any[]
  data: any[]
  cells: any[]
  selection?: "single" | "double" | "multiple" | "none"
  sortable?: boolean
  sortConfig?: SortConfig
  preselectedRows?: any[] | any
  preselectedMode?: boolean
  disabledRows?: any[]
  setSortConfig?: (config: SortConfig) => void
  onSelectionChange?: (row: any) => void
}

const FlexTable: React.FC<FlexTableProps> = ({
  uniqueKey,
  headers = [],
  data,
  sortConfig = { field: null, direction: "ascending" },
  cells,
  selection,
  sortable = true,
  preselectedRows = [],
  preselectedMode,
  disabledRows = [],
  setSortConfig,
  onSelectionChange,
}) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [dataState, setDataState] = useState<any>(data)
  const { selectedUnit } = useStore((s) => ({
    selectedUnit: s.selectedUnit,
  }))

  useEffect(() => {
    setDataState(data)
  }, [data.length, JSON.stringify(data)])

  useEffect(() => {
    if (selection === "double") setSelectedRows([])
    else if (selection === "single") setSelectedRows([])
  }, [selection])

  useEffect(() => {
    setSelectedRows([])
  }, [])

  useEffect(() => {
    if (!selectedUnit) setSelectedRows([])
  }, [selectedUnit])

  useEffect(() => {
    if (preselectedMode) {
      setSelectedRows(preselectedRows)
    }
  }, [JSON.stringify(preselectedRows)])

  const handleSort = useCallback(
    (field: any) => {
      const { sortedData, newSortConfig } = sortTable(dataState, {
        field,
        direction: sortConfig.direction,
      })
      setDataState(sortedData)
      setSortConfig && setSortConfig(newSortConfig)
    },
    [dataState, sortConfig],
  )

  const handleRowClick = useCallback(
    (row: any) => {
      if (selection === "none") {
        setSelectedRows([])
        onSelectionChange && onSelectionChange([])
      }
      if (selection === "single") {
        if ((selectedRows as any)?.[uniqueKey] === row[uniqueKey]) {
          // If the row is already selected, deselect it
        } else {
          // Otherwise, select the new row
          setSelectedRows(row)
          onSelectionChange && onSelectionChange(row)
        }
      } else if (selection === "multiple") {
        const newSelection = selectedRows.some(
          (unit) => unit[uniqueKey] === row[uniqueKey],
        )
          ? selectedRows.filter((unit) => unit[uniqueKey] !== row[uniqueKey])
          : [...selectedRows, row]

        setSelectedRows(newSelection)
        onSelectionChange(newSelection)
      } else if (selection === "double") {
        const index = selectedRows?.findIndex(
          (selected) => selected[uniqueKey] === row[uniqueKey],
        )

        let newSelectedRows = [...selectedRows]

        if (index > -1) {
          // Row is already selected, deselect it
          newSelectedRows.splice(index, 1)
        } else {
          // Add new row, but ensure only two are selected at most
          if (newSelectedRows.length === 2) {
            // Remove the most recent selection (last in the list), not the oldest
            newSelectedRows.pop() // Removes the latest, keeps the first (anchor)
          }
          newSelectedRows.push(row) // Add the new selection
        }

        setSelectedRows(newSelectedRows)
        onSelectionChange && onSelectionChange(newSelectedRows)
      } else {
        // Handle toggling for other selection types if necessary
      }
    },
    [selection, selectedRows, onSelectionChange, uniqueKey],
  )

  return (
    <FlexTableContainer className="flex-table" cells={headers.length}>
      <TableHeader
        headers={headers}
        sortConfig={sortConfig}
        handleSort={sortTable && sortable ? handleSort : () => {}}
      />
      <TableBody
        uniqueKey={uniqueKey}
        data={dataState}
        cells={cells}
        selectedRows={selectedRows}
        disabledRows={disabledRows}
        handleRowClick={handleRowClick}
      />
    </FlexTableContainer>
  )
}

export { FlexTable }

const FlexTableContainer = styled.div<{ cells: number }>`
  &.flex-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .flex-table-body {
      overflow-y: auto;

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f516;
      }

      ::-webkit-scrollbar {
        width: 2px;
        background-color: #f5f5f512;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #ffffff2b;
      }
    }
  }
`
