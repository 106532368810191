import { useButtonFillColor } from "@hooks/useButtonFillColor"
import React from "react"
import styled from "styled-components"

type ButtonProps = {
  style?: React.CSSProperties
  className?: string
  onClick?: () => void
}

const Close: React.FC<ButtonProps> = ({ onClick, style, className }) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="100"
        height="100"
        viewBox="0 0 272 266"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="10.8666"
          y1="4.29601"
          x2="268.204"
          y2="261.633"
          stroke="black"
          strokeWidth="20"
        />
        <line
          x1="3.79552"
          y1="261.633"
          x2="261.133"
          y2="4.29561"
          stroke="black"
          strokeWidth="20"
        />
      </svg>
    </Button>
  )
}

export { Close }

const Button = styled.button`
  position: fixed;
  top: 1.5%;
  right: 1%;
  padding: 1em;
  border-radius: 50%;
  height: 4em;
  width: 4em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg > path {
    transition: fill 0.4s ease-in-out;
  }
`
