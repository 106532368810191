import React from "react"
import styled from "styled-components"

type ButtonProps = {
  style?: React.CSSProperties
  location?: string
  onClick?: () => void
}

const TableButton: React.FC<ButtonProps> = ({ onClick, style }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        ...style,
        display: "flex",
      }}
    >
      <svg
        width="36"
        height="21"
        viewBox="0 0 36 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="-5.48901e-08"
          y1="1.42926"
          x2="35.2498"
          y2="1.42926"
          stroke="white"
          strokeWidth="1.25574"
        />
        <line
          x1="-6.00319e-08"
          y1="10.5779"
          x2="35.2498"
          y2="10.5779"
          stroke="white"
          strokeWidth="1.25574"
        />
        <line
          x1="-6.00319e-08"
          y1="19.7272"
          x2="35.2498"
          y2="19.7272"
          stroke="white"
          strokeWidth="1.25574"
        />
      </svg>
    </Button>
  )
}

export { TableButton }

const Button = styled.button`
  padding: 1em;
  border-radius: 50%;
  height: 4em;
  width: 4em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:active {
    background-color: rgba(213, 213, 213, 0.1);
  }

  svg > path {
    transition: fill 0.4s ease-in-out;
  }
`
