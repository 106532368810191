import { useButtonFillColor } from "@hooks/useButtonFillColor"
import React from "react"
import styled from "styled-components"

type ButtonProps = {
  style?: React.CSSProperties
  className?: string
  arrowColor?: string
  onClick?: () => void
}

const LeftArrow: React.FC<ButtonProps> = ({ onClick, style, className, arrowColor = "black"}) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="75"
        height="16"
        viewBox="0 0 75 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.292892 7.29289C-0.0976334 7.68342 -0.0976334 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM75 7L1 7V9L75 9V7Z"
          fill={arrowColor}
        />
      </svg>
    </Button>
  )
}

export { LeftArrow }

const Button = styled.button`
  padding: 1em;
  border-radius: 50%;
  height: 4em;
  width: 4em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg > path {
    transition: fill 0.4s ease-in-out;
  }
`
