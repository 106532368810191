import InfoCard from "@pages/tablet/residences/components/InfoCard"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

interface ICompareProps {
  selectedRows: any[]
}

export const partIndex = {
  n: ["7", "9"],
  e: ["8", "10"],
  s: ["3", "2", "1"],
  w: ["6", "5", "4"],
}

export function findMatchingKeys(item) {
  return Object.keys(partIndex).filter((key) => partIndex[key].includes(item))
}

export default function CompareTablet(props: ICompareProps) {
  const { selectedRows } = props

  if (!selectedRows) return null

  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const { compareActiveDuplexFloorplan, compareSection } = useStore(
    (state) => ({
      compareActiveDuplexFloorplan: state.compareActiveDuplexFloorplan,
      compareSection: state.compareSection,
    }),
  )

  const [parts, setParts] = useState<string[]>([])
  const [suffix, setSuffix] = useState<string>("")
  const [activeView, setActiveView] = useState<string>("")
  const [activeIndex, setActiveIndex] = useState(0)

  const [parts2, setParts2] = useState<string[]>([])
  const [suffix2, setSuffix2] = useState<string>("")
  const [activeView2, setActiveView2] = useState<string>("")
  const [activeIndex2, setActiveIndex2] = useState(0)

  useEffect(() => {
    setActiveIndex(0)
    if (selectedRows[0]) {
      const splitParts = selectedRows[0]?.view.split("-")
      const lastPart = splitParts.pop()
      setSuffix(lastPart?.toUpperCase() || "")
      setParts(splitParts)
    }
  }, [selectedRows[0]])

  useEffect(() => {
    if (parts.length) {
      const view = `${parts[activeIndex]}-${suffix}.webp`
      setActiveView(view)
      syncState({ activeView: view })
    }

    return () => {
      syncState({ activeView: null })
    }
  }, [parts, activeIndex, suffix])

  useEffect(() => {
    setActiveIndex2(0)
    if (selectedRows[1]) {
      const splitParts2 = selectedRows[1]?.view.split("-")
      const lastPart2 = splitParts2.pop()
      setSuffix2(lastPart2?.toUpperCase() || "")
      setParts2(splitParts2)
    }
  }, [selectedRows[1]])

  useEffect(() => {
    if (parts2.length) {
      const view2 = `${parts[activeIndex2]}-${suffix2}.webp`
      setActiveView2(view2)
      syncState({ activeView2: view2 })
    }

    return () => {
      syncState({ activeView2: null })
    }
  }, [parts2, activeIndex2, suffix2])

  const exceptions = ["PH16A", "PH16D", "PH16E", "PH17A"]

  const isDuplex = (unit: any) => {
    return (
      unit?.unitType?.startsWith("TH") ||
      (unit?.unitType?.startsWith("PH") && !exceptions.includes(unit?.unitType))
    )
  }

  const toggleDuplexFloorplan = (unit: string) => {
    syncState({
      compareActiveDuplexFloorplan:
        unit === "unit1"
          ? [
              compareActiveDuplexFloorplan[0] === "UPPER" ? "LOWER" : "UPPER",
              compareActiveDuplexFloorplan[1],
            ]
          : [
              compareActiveDuplexFloorplan[0],
              compareActiveDuplexFloorplan[1] === "UPPER" ? "LOWER" : "UPPER",
            ],
    })
  }

  useEffect(() => {
    return () => {
      syncState({ compareActiveDuplexFloorplan: ["UPPER", "UPPER"] })
    }
  }, [selectedRows])

  const handleSetActiveView = useCallback((index: number) => {
    setActiveIndex(index)
  }, [])

  const handleSetActiveView2 = useCallback((index: number) => {
    setActiveIndex2(index)
  }, [])

  return (
    <Wrapper
      selectedCompareUnit1={selectedRows[0]}
      selectedCompareUnit2={selectedRows[1]}
    >
      <div className="floorplan1">
        <div className={`no-unit ${selectedRows[0] === null ? "visible" : ""}`}>
          Select unit to compare
        </div>
        {compareSection === "floorplan" && (
          <div className="compare-unit">
            <div className="residence-details">
              <div className="info-card">
                <InfoCard selectedUnit={selectedRows[0]} />
                <div
                  className={`duplex-toggle ${isDuplex(selectedRows[0]) ? "show" : "hide"}`}
                >
                  <span
                    onClick={() => toggleDuplexFloorplan("unit1")}
                    className={
                      compareActiveDuplexFloorplan[0] === "UPPER"
                        ? "active"
                        : ""
                    }
                  >
                    UPPER
                  </span>
                  <span
                    onClick={() => toggleDuplexFloorplan("unit1")}
                    className={
                      compareActiveDuplexFloorplan[0] === "LOWER"
                        ? "active"
                        : ""
                    }
                  >
                    LOWER
                  </span>
                </div>
              </div>

              <div className="floorplate">
                {selectedRows[0]?.unitType && (
                  <img
                    alt="Floorplan"
                    src={`/assets/images/residences/floorplans/${selectedRows[0]?.unitType}-P${isDuplex(selectedRows[0]) ? "-" + compareActiveDuplexFloorplan[0] : ""}.png`}
                  />
                )}
              </div>
            </div>

            <div className="unit-floorplan">
              {selectedRows[0]?.unitType && (
                <img
                  className="img"
                  src={`/assets/images/residences/floorplans/${selectedRows[0]?.unitType}-F${isDuplex(selectedRows[0]) ? "-" + compareActiveDuplexFloorplan[0] : ""}.png`}
                  alt="Floorplan 1"
                />
              )}
            </div>
          </div>
        )}
        {compareSection === "view" && (
          <div className="compare-unit">
            <img
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              className="img"
              src={`/assets/images/residences/views/${activeView}`}
              alt="Floorplan 2"
            />
            {parts.length > 1 && (
              <div className="views-buttons">
                {parts.map((part, index) => (
                  <button
                    key={index}
                    onClick={() => handleSetActiveView(index)}
                  >
                    {findMatchingKeys(part)}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="floorplan2">
        <div className={`no-unit ${selectedRows[1] === null ? "visible" : ""}`}>
          Select unit to compare
        </div>
        {compareSection === "floorplan" && (
          <div className="compare-unit">
            <div className="residence-details">
              <div className="info-card">
                <InfoCard selectedUnit={selectedRows[1]} />
                <div
                  className={`duplex-toggle ${isDuplex(selectedRows[1]) ? "show" : "hide"}`}
                >
                  <span
                    onClick={() => toggleDuplexFloorplan("unit2")}
                    className={
                      compareActiveDuplexFloorplan[1] === "UPPER"
                        ? "active"
                        : ""
                    }
                  >
                    UPPER
                  </span>
                  <span
                    onClick={() => toggleDuplexFloorplan("unit2")}
                    className={
                      compareActiveDuplexFloorplan[1] === "LOWER"
                        ? "active"
                        : ""
                    }
                  >
                    LOWER
                  </span>
                </div>
              </div>

              <div className="floorplate">
                {selectedRows[1]?.unitType && (
                  <img
                    alt="Floorplan"
                    src={`/assets/images/residences/floorplans/${selectedRows[1]?.unitType}-P${isDuplex(selectedRows[1]) ? "-" + compareActiveDuplexFloorplan[1] : ""}.png`}
                  />
                )}
              </div>
            </div>
            <div className="unit-floorplan">
              {selectedRows[1]?.unitType && (
                <img
                  className="img"
                  src={`/assets/images/residences/floorplans/${selectedRows[1]?.unitType}-F${isDuplex(selectedRows[1]) ? "-" + compareActiveDuplexFloorplan[1] : ""}.png`}
                  alt="Floorplan 2"
                />
              )}
            </div>
          </div>
        )}
        {compareSection === "view" && (
          <div className="compare-unit">
            <img
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              className="img"
              src={`/assets/images/residences/views/${activeView2}`}
              alt="Floorplan 2"
            />
            {parts.length > 1 && (
              <div className="views-buttons">
                {parts2.map((part, index) => (
                  <button
                    key={index}
                    onClick={() => handleSetActiveView2(index)}
                  >
                    {findMatchingKeys(part)}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  selectedCompareUnit1: any
  selectedCompareUnit2: any
}>`
  .views-buttons {
    position: absolute;
    bottom: 3em;
    right: 3em;
    display: flex;
    flex-direction: row;
    gap: 1em;
    padding: 1.5em;
    background: rgba(255, 255, 255, 1);
    border-radius: 1em;

    button {
      padding: 1em 1.5em;
      background: transparent;
      border: 1px solid #000;
      cursor: pointer;

      &:hover {
        background: #000;
        color: #fff;
      }
    }

    &.hide {
      display: none;
    }
  }

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .floorplan1,
  .floorplan2 {
    width: 100%;
    height: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: all 0.6s;

    .compare-unit {
      height: 100%;
      width: 100%;
      display: flex;
    }

    .residence-details {
      width: 40%;
      height: 100%;
      padding: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .info-card {
        width: 100%;
        height: 50%;

        // need to override the InfoCard component styles
        // to make it fit the compare unit
        > *:first-child {
          padding: 0;
          display: block;
          height: 50%;
        }

        .unit-number {
          font-size: 7em;
        }

        .floor {
          margin: 40px 0px 0px;
        }

        .duplex-toggle {
          margin-top: 1em;
          display: flex;
          font-weight: bold;

          &.show {
            opacity: 1;
            margin-left: 1em;
            margin-top: 2em;
            pointer-events: auto;
            transition: all 0.3s ease-in-out;
          }

          &.hide {
            opacity: 0;
            margin-left: -5em;
            pointer-events: none;
            transition: all 0.3s ease-in-out;
          }

          span {
            color: #000;
            font-size: 1.3em;
            background: none;
            padding: 0.5em 1em;
            border: 1px solid #000;
            transition: all 0.3s ease-in-out;

            &.active {
              background-color: rgba(0, 0, 0, 0.8);
              color: white;
            }
          }
        }
      }

      .floorplate {
        height: 50%;
        width: 100%;
        position: relative;

        img {
          object-fit: contain;
          width: 60%;
          height: auto;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .unit-info {
        transform: scale(0.9);
        transform-origin: left;
      }

      .separator-root[data-orientation="vertical"] {
        height: 100%;
        width: 0.5px;
        margin: 0 10px;
        background-color: #000;
      }
    }

    .unit-floorplan {
      height: 100%;
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2em;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .no-unit {
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0s ease-in;

    &.visible {
      opacity: 1;
      transition: opacity 0.6s ease-in;
    }
  }

  .floorplan1 > .compare-unit {
    opacity: ${(props) => (props.selectedCompareUnit1 ? 1 : 0)};
    transform: ${(props) =>
      props.selectedCompareUnit1 ? "scale(1)" : "scale(0)"};
    transition: opacity 0.6s;
  }

  .floorplan2 > .compare-unit {
    opacity: ${(props) => (props.selectedCompareUnit2 ? 1 : 0)};
    transform: ${(props) =>
      props.selectedCompareUnit2 ? "scale(1)" : "scale(0)"};
    transition: opacity 0.6s;
  }
`
