import { Filters, InitialState, SortConfig } from "./types.store"

export const initialFilters: Filters = {
  floor: [0, 16],
  price: [500000, 12500000],
  bedroom: [],
  view: [],
  sqft: [0, 6000],
  status: [],
}
const initialSortConfig: SortConfig = {
  field: null,
  direction: "ascending",
}

const isTablet =
  navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints > 2

export const initialState: InitialState = {
  selectedUnit: null,
  poiImage: null,
  poiImageVisible: false,
  imageExpanded: false,
  favoritesVisible: false,
  galleryVisible: false,
  selectedMapCategory: "Boutiques",
  selectedPoi: null,
  selectedGalleryImage: 0,
  selectedGalleryCategory: "Interiors",
  residenceOfflineData: [],
  stateFavorites: [],
  filters: initialFilters,
  sort: "N/A",
  sortConfig: initialSortConfig,
  isTablet,
  zoom: [1, 0, 0],
  zoomRight: [1, 0, 0],
  residenceDetailsVisible: false,
  roomTakenOver: null,
  attemptedRoom: null,
  compareSection: "floorplan",
  showRoomPopup: false,
  selectedPreset: null,
  unitDetailsSection: null,
  orbit: true,
  orbitIndex: 0,
  orbitDir: true,
  orbitLoaded: false,
  orbitRunning: false,
  path: "",
  amenitiesActiveImage: null,
  amenitiesFullScreenImage: null,
  amenitiesSelector: null,
  amenitiesActiveMesh: null,
  loadProgress: 0,
  loaded: false,
  showUnitsTable: false,
  presetVisible: false,
  compareMode: false,
  selectedRows: [null, null] || null,
  activeDuplexFloorplan: "UPPER",
  compareActiveDuplexFloorplan: ["UPPER", "UPPER"],
  viewXPosition: 0,
  activeView: null,
  activeView2: null,
  selectionChanged: false,
  zoomRef: [0, 0, 1],
  isPanning: false,
  showMapScreenSaver: false,
  showAmenitiesScreenSaver: false,
}
