import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import Floorplan from "./Floorplan"
import View from "./View"
import Gallery from "./Gallery"
import gsap from "gsap"
import useStore from "@state/store"
import { ArrowLeftIcon } from "@radix-ui/react-icons"
import StyledButton from "@components/buttons/StyledButton"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { TUnitDetailsSection } from "@state/types.store"

type DetailsProps = {}

const ResidenceDetails: React.FC<DetailsProps> = ({}) => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const detailsRef = useRef<HTMLDivElement>(null)
  const {
    residenceDetailsVisible,
    unitDetailsSection,
    selectedUnit,
    activeDuplexFloorplan,
  } = useStore((state) => ({
    residenceDetailsVisible: state.residenceDetailsVisible,
    unitDetailsSection: state.unitDetailsSection,
    selectedUnit: state.selectedUnit,
    activeDuplexFloorplan: state.activeDuplexFloorplan,
  }))

  const floorplanContainerRef = useRef(null)
  const viewContainerRef = useRef(null)
  const gallerContainerRef = useRef(null)

  useEffect(() => {
    gsap.set([viewContainerRef.current, gallerContainerRef.current], {
      autoAlpha: 0,
    })
  }, [])

  useEffect(() => {
    const tl = gsap.timeline()
    if (unitDetailsSection === "floorplan") {
      tl.to([viewContainerRef.current, gallerContainerRef.current], {
        autoAlpha: 0,
        duration: 0.3,
      }).to(floorplanContainerRef.current, { autoAlpha: 1, duration: 0.6 }, "<")
    } else if (unitDetailsSection === "view") {
      tl.to([floorplanContainerRef.current, gallerContainerRef.current], {
        autoAlpha: 0,
        duration: 0.3,
      }).to(viewContainerRef.current, { autoAlpha: 1, duration: 0.6 }, "<")
    } else if (unitDetailsSection === "gallery") {
      tl.to([floorplanContainerRef.current, viewContainerRef.current], {
        autoAlpha: 0,
        duration: 0.3,
      }).to(gallerContainerRef.current, { autoAlpha: 1, duration: 0.6 }, "<")
    }
  }, [unitDetailsSection])

  const exceptions = ["PH16A", "PH16D", "PH16E", "PH17A"]

  const isDuplex = (unitType: any) => {
    return (
      unitType?.startsWith("TH") ||
      (unitType?.startsWith("PH") && !exceptions.includes(unitType))
    )
  }

  const toggleDuplexFloorplan = () => {
    syncState({
      activeDuplexFloorplan:
        activeDuplexFloorplan === "UPPER" ? "LOWER" : "UPPER",
    })
  }

  return (
    <Wrapper
      ref={detailsRef}
      visible={residenceDetailsVisible}
      activeSection={unitDetailsSection}
      id="residenceDetails"
    >
      <div
        className="back"
        onClick={() => {
          syncState({ residenceDetailsVisible: false })
          syncState({ unitDetailsSection: null })
          syncState({ selectedGalleryImage: null })
        }}
      >
        <ArrowLeftIcon
          height={20}
          width={20}
          color="#000"
          // color={unitDetailsSection === "view" ? "#fff" : "#000"}
        />
        <StyledButton
          border={false}
          color="#000"
          // color={unitDetailsSection === "view" ? "#fff" : "#000"}
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          Back
        </StyledButton>
      </div>
      <div
        className={`duplex-toggle ${isDuplex(selectedUnit?.unitType) && unitDetailsSection === 'floorplan' ? "show" : "hide"}`}
      >
        <span
          onClick={toggleDuplexFloorplan}
          className={activeDuplexFloorplan === "UPPER" ? "active" : ""}
        >
          UPPER
        </span>
        <span
          onClick={toggleDuplexFloorplan}
          className={activeDuplexFloorplan === "LOWER" ? "active" : ""}
        >
          LOWER
        </span>
      </div>
      <ul className="menu-items">
        <li
          style={{
            fontWeight: unitDetailsSection === "floorplan" ? "bold" : "",
          }}
          onClick={() => syncState({ unitDetailsSection: "floorplan" })}
        >
          Floorplan
        </li>
        <li
          style={{
            fontWeight: unitDetailsSection === "view" ? "bold" : "",
          }}
          onClick={() => syncState({ unitDetailsSection: "view" })}
        >
          View
        </li>
        <li
          style={{
            fontWeight: unitDetailsSection === "gallery" ? "bold" : "",
          }}
          onClick={() => syncState({ unitDetailsSection: "gallery" })}
        >
          Gallery{" "}
        </li>
      </ul>
      <div className="right-panel">
        <div style={{ opacity: 0, width: "100%" }} ref={floorplanContainerRef}>
          <Floorplan
            unitType={selectedUnit?.unitType}
            isDuplex={isDuplex(selectedUnit?.unitType)}
            activeDuplexFloorplan={activeDuplexFloorplan}
          />
        </div>
        <div ref={viewContainerRef}>
          <View views={selectedUnit?.view} />
        </div>
        <div ref={gallerContainerRef}>
          <Gallery />
        </div>
      </div>
    </Wrapper>
  )
}

export default ResidenceDetails




const Wrapper = styled.div<{
  visible: boolean
  activeSection: TUnitDetailsSection
}>`
  position: absolute;
  z-index: 200;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-family: "Masque-Hairline";
  line-height: 1;
  text-transform: uppercase;
  background-color: #fff;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  // z-index: ${({ visible }) => (visible ? 261 : -1)};
  transition: opacity 0.3s ease-in-out;

  .menu-items {
    position: absolute;
    bottom: 3em;
    left: 3em;
    color: #000;
    /* color: ${({ activeSection }) =>
      activeSection === "view" ? "#000" : "#000"}; */
    list-style: none;
    font-family: "Masque-Hairline";
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    margin: 0;
    padding: 0;
    counter-reset: item-counter;

    li {
      font-size: 2.5em;
      letter-spacing: 0.1em;
      font-weight: 400;
      opacity: 1;
    }

    li:active {
      opacity: 0.5;
      transition: all 50ms ease-in-out;
    }
  }

  .back {
    color: #000;
    position: absolute;
    display: flex;
    align-items: center;
    top: 3em;
    left: 30px;
    gap: 1em;
    padding: 1em 1em 1em 0;
    z-index: 20;
  }

  .right-panel {
    height: 100%;
    width: 63%;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }

  .duplex-toggle {
    z-index: 1;
    display: flex;
    font-weight: bold;

    &.show {
      opacity: 1;
      pointer-events: auto;
      transition: all 0.3s ease-in-out 0s;
      position: absolute;
      left: 3em;
      bottom: 20%;
    }

    &.hide {
      opacity: 0;
      left: -5em;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
    }

    span {
      color: #000;
      font-size: 2em;
      background: none;
      padding: 0.5em 1em;
      border: 1px solid #000;
      transition: all 0.3s ease-in-out;

      &.active {
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
      }
    }
  }
`


