export default function CloseSVG({ ...props }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 1.66599L24.334 0L13 11.334L1.66562 0L0 1.66599L11.3344 13L0 24.334L1.66562 26L13 14.666L24.334 26L26 24.334L14.6663 13L26 1.66599Z"
        fill={props.color ? props.color : "#fff"}
      />
    </svg>
  )
}
