import InfoCard from "@pages/tablet/residences/components/InfoCard"
import useStore from "@state/store"
import styled from "styled-components"

interface IFloorplanScreenProps {
  unit: any
}

export default function Floorplan(props: IFloorplanScreenProps) {
  const { unit } = props
  const { activeDuplexFloorplan, residenceDetailsVisible } = useStore(
    (state) => ({
      activeDuplexFloorplan: state.activeDuplexFloorplan,
      residenceDetailsVisible: state.residenceDetailsVisible,
    }),
  )
  const exceptions = ["PH16A", "PH16D", "PH16E", "PH17A"]

  const isDuplex = (unit: any) => {
    return (
      unit?.unitType?.startsWith("TH") ||
      (unit?.unitType?.startsWith("PH") && !exceptions.includes(unit?.unitType))
    )
  }

  if (!unit) {
    return null
  }

  return (
    <Wrapper className="floorplan-wrapper" visible={residenceDetailsVisible}>
      <div className="single-unit">
      <div className="disclaimer">PURCHASER ACKNOWLEDGES AND ACCEPTS THAT THIS DRAWING (A) REFLECTS A PRELIMINARY SKETCH FOR
ILLUSTRATIVE PURPOSES ONLY, (B) IS BEING PROVIDED AT PURCHASER’S REQUEST, AND (C) HAS NEITHER BEEN
REVIEWED NOR APPROVED BY APPLICABLE GOVERNMENTAL AUTHORITIES (INCLUDING WITHOUT LIMITATION DOB
AND AG, TO THE EXTENT APPLICABLE), AND SPONSOR MAKES NO REPRESENTATIONS REGARDING WHETHER SUCH
GOVERNMENTAL AUTHORITIES SHALL APPROVE THIS DRAWING IN ITS CURRENT FORM. ALL MEASUREMENTS AND
SQUARE FOOTAGES ARE APPROXIMATE AND EXCEED USABLE FLOOR AREA. THIS IS NOT AN OFFER. NEITHER THIS
DRAWING NOR ANY OTHER INFORMATION SET FORTH HEREIN SHALL BE CONSTRUED TO OBLIGATE OR BIND EITHER
PARTIES TO ANY SALES, TERMS OF SALE, AND/OR ANY OTHER OBLIGATIONS. SPONSOR MAKES NO
REPRESENTATIONS OR GUARANTEES EXCEPT AS MAY BE SET FORTH IN THE OFFERING PLAN THE COMPLETE
OFFERING TERMS ARE IN AN OFFERING PLAN AVAILABLE FROM SPONSOR. FILE NO. CD220253. SPONSOR: 720 WEA
VENTURES LLC, C/O INTERVEST CAPITAL PARTNERS, 425 PARK AVENUE, 35TH FLOOR, NEW YORK, NY 10022. EQUAL
HOUSING OPPORTUNITY.
</div>
        <div className="residence-info">
          <InfoCard selectedUnit={unit} />
        </div>
        <div className="unit-floorplan">
          <div className={`duplex ${isDuplex(unit) ? "show" : "hide"}`}>
            <div className="active">
              Duplex &#x2192; <span>{activeDuplexFloorplan}</span>
            </div>
          </div>
          <img
            src={`/assets/images/residences/floorplans/${unit?.unitType}-F${isDuplex(unit) ? "-" + activeDuplexFloorplan : ""}.png`}
            alt="Floorplan"
          />
        </div>
        <div className="unit-floorplate">
          <img
            alt="Floorplan"
            src={`/assets/images/residences/floorplans/${unit?.unitType}-P${isDuplex(unit) ? "-" + activeDuplexFloorplan : ""}.png`}
          />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ visible: boolean }>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};

  .no-unit {
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0s ease-in;

    &.visible {
      opacity: 1;
      transition: opacity 0.6s ease-in;
    }
  }

  .disclaimer {
      width: 30%;
      font-size: 1rem;
      line-height: 2.25vh;
      position: fixed;
      font-family: stolzl;
      left: 3em;
      top: 50%;
      transform: translateY(-50%);
      text-transform: none;
      color: #a1a1a1;
      text-align: justify;
    }

  .single-unit {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: all 0.6s;

    &.hidden {
      opacity: 0;
      transition: all 0.6s;
    }

    .residence-info {
      position: relative;
      margin-top: 2%;
      height: 25%;
      white-space: nowrap;
      width: 50%;

      .unit-info {
        transform: scale(1.2);
        transform-origin: left;
      }

      .separator-root[data-orientation="vertical"] {
        height: 100%;
        width: 0.5px;
        margin: 0 10px;
        background-color: #000;
      }
    }

    .unit-floorplan {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 5em;
      position: relative;

      .duplex {
        display: flex;
        position: absolute;
        bottom: 5%;
        right: 0;

        &.show {
          opacity: 1;
          margin-left: 0em;
          pointer-events: auto;
          transition: all 0.3s ease-in-out;
        }

        &.hide {
          opacity: 0;
          margin-left: -5em;
          pointer-events: none;
          transition: all 0.3s ease-in-out;
        }

        div.active {
          text-transform: uppercase;
          background-color: rgba(0, 0, 0, 0.8);
          color: white;
          font-size: 1.3em;
          padding: 0.5em 1em;
          border: 1px solid #000;
          transition: all 0.3s ease-in-out;
        }

        span {
          font-size: 1em;
          font-weight: bold;
        }
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        margin: auto;
      }
    }

    .unit-floorplate {
      position: absolute;
      bottom: 3%;
      left: 3em;
      height: 15rem;
      display: flex;

      img {
        object-fit: contain;
      }
    }
  }
`
