import { useEffect } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"
import useStore from "@state/store"
import "../../tablet/residences/Residences.css"
import Floorplan from "./components/Floorplan"
import View from "./components/View"
import Gallery from "./components/Gallery"
import ApartmentFinderOrbit from "@components/apartmentfinder-orbit/ApartmentFinderOrbit"
import InfoCard from "@pages/tablet/residences/components/InfoCard"
import SyncOnMount from "@components/SyncOnMount"

const Residences = () => {
  const { residenceDetailsVisible, unitDetailsSection, selectedUnit } =
    useStore((state) => ({
      residenceDetailsVisible: state.residenceDetailsVisible,
      unitDetailsSection: state.unitDetailsSection,
      selectedUnit: state.selectedUnit,
    }))

  useEffect(() => {
    const fadeIn = (selector: any) =>
      gsap.to(selector, { opacity: 1, duration: 0.6, ease: "power3.inOut" })
    const fadeOut = (selector: any) =>
      gsap.to(selector, { opacity: 0, duration: 0.6, ease: "power3.inOut" })

    switch (unitDetailsSection) {
      case "floorplan":
        fadeIn(".floorplan-wrapper")
        fadeOut(".view-wrapper, .galleryWrapper")
        break
      case "view":
        fadeIn(".view-wrapper")
        fadeOut(".floorplan-wrapper, .galleryWrapper")
        break
      case "gallery":
        fadeIn(".galleryWrapper")
        fadeOut(".view-wrapper, .floorplan-wrapper")
        break
      default:
        fadeOut(".view-wrapper, .floorplan-wrapper, .galleryWrapper")
        break
    }
  }, [residenceDetailsVisible, unitDetailsSection])

  return (
    <Wrapper>
      <SyncOnMount />
      <ApartmentFinderOrbit showUI={false} />
      <InfoCard selectedUnit={selectedUnit} isScreen={true} />
      <Floorplan unit={selectedUnit} />
      <View unit={selectedUnit} />
      <Gallery />
    </Wrapper>
  )
}

export { Residences }

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
