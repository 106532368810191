import { useEffect } from "react"
import styled from "styled-components"
import useStore from "@state/store"
import pois from "../data/pois"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { Icons } from "./Icons"
import gsap from "gsap"

type Props = {}

export function Overlay(props: Props) {
  const selectedMapCategory = useStore((state) => state.selectedMapCategory)
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const selectedPoi = useStore((state) => state.selectedPoi)
  const categoryOffsets = {
    Boutiques: 1,
    "Cuisine And Night Life": 16,
    Convenience: 43,
    Fitness: 50,
    "Culture, Entertainment & Parks": 59,
    Transportation: 78,
    Playground: 80,
  }

  function selectedPoiAnim(id) {
    pois[selectedMapCategory].forEach((_, index) => {
      const startIndex = categoryOffsets[selectedMapCategory]
      const elementId = index + startIndex
      const elementText = index + startIndex
      const element = document.getElementById(elementId.toString())
      const elementTextTest = document.getElementsByClassName(
        elementText.toString(),
      )
      gsap.to(element, { scale: 1, autoAlpha: 1, duration: 0.3 })
      gsap.to(elementTextTest, { scale: 1, autoAlpha: 1, duration: 0.3 })
    })

    gsap.to(document.getElementById(id), {
      autoAlpha: 1,
      scale: 4,
      transformOrigin: "center center",
      duration: 0.6,
      ease: "power3.inOut",
    })

    gsap.to(document.getElementsByClassName(id), {
      autoAlpha: 1,
      scale: 4,
      transformOrigin: "center center",
      duration: 0.6,
      ease: "power3.inOut",
    })

    const currentPoiImage = `/assets/images/poiImages/${id}.jpg`
    syncState({ poiImage: currentPoiImage })
  }

  function resetPoiAnim() {
    pois[selectedMapCategory].forEach((_, index) => {
      const startIndex = categoryOffsets[selectedMapCategory]
      const elementId = index + startIndex
      const elementText = index + startIndex
      const element = document.getElementById(elementId.toString())
      const elementTextTest = document.getElementsByClassName(
        elementText.toString(),
      )
      if (element) {
        gsap.to(element, {
          autoAlpha: 1,
          scale: 1,
          duration: 0.3,
          ease: "power3.inOut",
        })
        gsap.to(elementTextTest, {
          autoAlpha: 1,
          scale: 1,
          duration: 0.3,
          ease: "power3.inOut",
        })
      }
    })
  }

  useEffect(() => {
    if (selectedPoi) {
      const index = pois[selectedMapCategory].findIndex(
        (poi) => poi === selectedPoi,
      )
      const offset = categoryOffsets[selectedMapCategory]
      selectedPoiAnim(index + offset)
    } else if (!selectedPoi) {
      resetPoiAnim()
    }
  }, [selectedPoi])

  function handlePoiClick(e) {
    var id = (e.target as SVGPathElement).id
    const idNum = parseInt(id, 10)
    if (selectedMapCategory in categoryOffsets) {
      const offset = categoryOffsets[selectedMapCategory]
      const poi = idNum - offset
      syncState({
        selectedPoi: pois[selectedMapCategory][poi]
          ? pois[selectedMapCategory][poi]
          : null,
      })
    }
    if (id || selectedPoi) resetPoiAnim()
    if (id && id != "logo") selectedPoiAnim(id)
  }

  return (
    <Wrapper>
      <Icons onClick={handlePoiClick} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`
