// imagePaths.ts

export type ImageCategory = {
  name: string
  paths: string[]
}

export type thumbnailCategory = {
  name: string
  paths: string[]
}

export type ScreenImageCategory = {
  name: string
  paths: string[]
}

export const imagePaths: { [key: string]: ImageCategory } = {
  Interiors: {
    name: "Interiors",
    paths: [
      "/assets/images/gallery/Interiors/1.webp",
      "/assets/images/gallery/Interiors/2.webp",
      "/assets/images/gallery/Interiors/3.webp",
      "/assets/images/gallery/Interiors/4.webp",
      "/assets/images/gallery/Interiors/5.webp",
      "/assets/images/gallery/Interiors/6.webp",
      "/assets/images/gallery/Interiors/7.webp",
      "/assets/images/gallery/Interiors/8.webp",
      "/assets/images/gallery/Interiors/9.webp",
      "/assets/images/gallery/Interiors/10.webp",
      "/assets/images/gallery/Interiors/11.webp",
    ],
  },
  Exteriors: {
    name: "Exteriors",
    paths: [
      "/assets/images/gallery/exteriors/1.webp",
      "/assets/images/gallery/exteriors/2.webp",
      "/assets/images/gallery/exteriors/3.webp",
      "/assets/images/gallery/exteriors/4.webp",
      "/assets/images/gallery/exteriors/5.webp",
    ],
  },
  Amenities: {
    name: "Amenities",
    paths: [
      "/assets/images/amenities/ipad/327_720_West_End_Lobby_cam3.webp",
      "/assets/images/amenities/ipad/327_720_West_End_Lobby_cam2.webp",
      "/assets/images/amenities/ipad/327_720_West_End_Lobby_cam1.webp",
      "/assets/images/amenities/ipad/thumbnail_Door_Detail_05.jpg",
      "/assets/images/amenities/ipad/thumbnail_Elevator_Cab_16.jpg",
      "/assets/images/amenities/ipad/thumbnail_Entrance_Door_09.jpg",
      "/assets/images/amenities/ipad/thumbnail_image001.jpg",
      "/assets/images/amenities/ipad/327_720_West_End_Bar.webp",
      "/assets/images/amenities/ipad/327_Game_Lounge_Post.webp",
      "/assets/images/amenities/ipad/327_720_West_End_Co_Working_Space.webp",
      "/assets/images/amenities/ipad/327_720_West_End_Dining_Room.webp",
      "/assets/images/amenities/ipad/327_720_West_End_fitness_center_basketball_court.webp",
      "/assets/images/amenities/ipad/327_720_West_End_Fitness_Center.webp",
      "/assets/images/amenities/ipad/327_720_West_End_Kid's_room.webp",
    ],
  },
}

export const thumbnailPaths: { [key: string]: thumbnailCategory } = {
  Interiors: {
    name: "Interiors",
    paths: [
      "/assets/images/gallery/thumbnail/Interiors/1.webp",
      "/assets/images/gallery/thumbnail/Interiors/2.webp",
      "/assets/images/gallery/thumbnail/Interiors/3.webp",
      "/assets/images/gallery/thumbnail/Interiors/4.webp",
      "/assets/images/gallery/thumbnail/Interiors/5.webp",
      "/assets/images/gallery/thumbnail/Interiors/6.webp",
      "/assets/images/gallery/thumbnail/Interiors/7.webp",
      "/assets/images/gallery/thumbnail/Interiors/8.webp",
      "/assets/images/gallery/thumbnail/Interiors/9.webp",
      "/assets/images/gallery/thumbnail/Interiors/10.webp",
      "/assets/images/gallery/thumbnail/Interiors/11.webp",
    ],
  },
  Exteriors: {
    name: "Exteriors",
    paths: [
      "/assets/images/gallery/thumbnail/exteriors/1.webp",
      "/assets/images/gallery/thumbnail/exteriors/2.webp",
      "/assets/images/gallery/thumbnail/exteriors/3.webp",
      "/assets/images/gallery/thumbnail/exteriors/4.webp",
      "/assets/images/gallery/thumbnail/exteriors/5.webp",
    ],
  },
  Amenities: {
    name: "Amenities",
    paths: [
      "/assets/images/amenities/lowres/327_720_West_End_Lobby_cam3.webp",
      "/assets/images/amenities/lowres/327_720_West_End_Lobby_cam2.webp",
      "/assets/images/amenities/lowres/327_720_West_End_Lobby_cam1.webp",
      "/assets/images/amenities/lowres/thumbnail_Door_Detail_05.jpg",
      "/assets/images/amenities/lowres/thumbnail_Elevator_Cab_16.jpg",
      "/assets/images/amenities/lowres/thumbnail_Entrance_Door_09.jpg",
      "/assets/images/amenities/lowres/thumbnail_image001.jpg",
      "/assets/images/amenities/lowres/327_720_West_End_Bar.webp",
      "/assets/images/amenities/lowres/327_Game_Lounge_Post.webp",
      "/assets/images/amenities/lowres/327_720_West_End_Co_Working_Space.webp",
      "/assets/images/amenities/lowres/327_720_West_End_Dining_Room.webp",
      "/assets/images/amenities/lowres/327_720_West_End_fitness_center_basketball_court.webp",
      "/assets/images/amenities/lowres/327_720_West_End_Fitness_Center.webp",
      "/assets/images/amenities/lowres/327_720_West_End_Kid's_room.webp",
    ],
  },
}

export const imagePathsScreen: { [key: string]: ScreenImageCategory } = {
  Interiors: {
    name: "Interiors",
    paths: [
      "/assets/images/galleryScreen/Interiors/1.webp",
      "/assets/images/galleryScreen/Interiors/2.webp",
      "/assets/images/galleryScreen/Interiors/3.webp",
      "/assets/images/galleryScreen/Interiors/4.webp",
      "/assets/images/galleryScreen/Interiors/5.webp",
      "/assets/images/galleryScreen/Interiors/6.webp",
      "/assets/images/galleryScreen/Interiors/7.webp",
      "/assets/images/galleryScreen/Interiors/8.webp",
      "/assets/images/galleryScreen/Interiors/9.webp",
      "/assets/images/galleryScreen/Interiors/10.webp",
      "/assets/images/galleryScreen/Interiors/11.webp",
    ],
  },
  Exteriors: {
    name: "Exteriors",
    paths: [
      "/assets/images/galleryScreen/Exteriors/1.webp",
      "/assets/images/galleryScreen/Exteriors/2.webp",
      "/assets/images/galleryScreen/Exteriors/3.webp",
      "/assets/images/galleryScreen/Exteriors/4.webp",
      "/assets/images/galleryScreen/Exteriors/5.webp",
    ],
  },
  Amenities: {
    name: "Amenities",
    paths: [
      "/assets/images/amenities/tv/327_720_West_End_Lobby_cam3.webp",
      "/assets/images/amenities/tv/327_720_West_End_Lobby_cam2.webp",
      "/assets/images/amenities/tv/327_720_West_End_Lobby_cam1.webp",
      "/assets/images/amenities/tv/thumbnail_Door_Detail_05.jpg",
      "/assets/images/amenities/tv/thumbnail_Elevator_Cab_16.jpg",
      "/assets/images/amenities/tv/thumbnail_Entrance_Door_09.jpg",
      "/assets/images/amenities/tv/thumbnail_image001.jpg",
      "/assets/images/amenities/tv/327_720_West_End_Bar.webp",
      "/assets/images/amenities/tv/327_Game_Lounge_Post.webp",
      "/assets/images/amenities/tv/327_720_West_End_Co_Working_Space.webp",
      "/assets/images/amenities/tv/327_720_West_End_Dining_Room.webp",
      "/assets/images/amenities/tv/327_720_West_End_fitness_center_basketball_court.webp",
      "/assets/images/amenities/tv/327_720_West_End_Fitness_Center.webp",
      "/assets/images/amenities/tv/327_720_West_End_Kid's_room.webp",
    ],
  },
}
