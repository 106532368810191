import React, { ReactNode } from "react"
import styled from "styled-components"

type ButtonProps = {
  onClick?: () => void
  color?: string
  style?: React.CSSProperties
}

const CloseButton: React.FC<ButtonProps> = ({ onClick, style, color }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        ...style,
      }}
    >
      <svg
        width="25"
        height="25"
        viewBox="0 0 40 60"
        xmlns="http://www.w3.org/2000/svg"
        fill={color ? color : "black"}
      >
        <path d="M2.57599 58.73H9.32501L23.8129 31.9139L6.80538 1.13835H0.506286V0.868386H19.6735V1.13835H13.8244L27.3224 25.5248L40.5505 1.13835H33.4415V0.868386H47.6595V1.13835H40.9105L27.5024 25.7948L45.7698 58.73H51.9789V59H32.8116V58.73H38.6608L23.9929 32.2739L9.68496 58.73H16.7939V59H2.57599V58.73Z" />
      </svg>
    </Button>
  )
}

export { CloseButton }

const Button = styled.button`
  border-radius: 50%;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 50ms ease-in-out;

  &:active {
    opacity: 0.5;
  }
`
