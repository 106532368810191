import { useEffect, useRef } from "react"
import gsap from "gsap"
import styled from "styled-components"
import CloseSVG from "@pages/tablet/amenities/components/CloseSVG"
import { useSocketIO } from "@providers/sockets/SocketIOProvider"
import useRoomSelector from "../../hooks/useRoomSelector"
import StyledButton from "@components/buttons/StyledButton"
import globalStyles from "@data/style.globals"
import useStore from "@state/store"

type Props = {
  isVisible: any
  onClose: any
}

export default function Menu(props: Props) {
  const menuRef = useRef<HTMLDivElement>(null)
  const buttons = ["room1", "room2", "room3", "reset"]
  const labels = ["Upper West Side", "Amenities", "Residences", "Reset"]
  const { handleRoomSelector } = useRoomSelector()

  const { roomTakenOver } = useStore((s) => ({
    roomTakenOver: s.roomTakenOver,
  }))

  useEffect(() => {
    if (menuRef.current) {
      if (props.isVisible) {
        // Animate in
        gsap.to(menuRef.current, {
          autoAlpha: 1, // Controls both opacity and visibility
          x: 0,
          duration: 0.4,
          stagger: 0.1,
          ease: "power1.in",
        })
      } else {
        // Animate out
        gsap.to(menuRef.current, {
          autoAlpha: 0,
          x: 60,
          duration: 0.4,
          stagger: 0.1,
          ease: "power1.out",
        })
      }
    }
  }, [props.isVisible])

  return (
    <MenuWrapper ref={menuRef} visible={props.isVisible}>
      <div className="top-controls">
        <CloseSVG onClick={props.onClose} color="black" />
      </div>
      <PopupMessage>
        Take over a screen in one of the following rooms to present all sections
        of the app.
      </PopupMessage>
      <ul className="menu-items">
        {buttons.map((txt, i) => {
          return (
            <li key={i}>
              <StyledButton
                className={`menu-button ${labels[i]?.toLocaleLowerCase()} ${roomTakenOver === txt.replaceAll(" ", "") ? "active" : ""}`}
                onClick={() => {
                  handleRoomSelector(txt)
                }}
              >
                <div style={{ fontSize: "0.8em" }}>{labels[i]}</div>
              </StyledButton>
            </li>
          )
        })}
      </ul>
    </MenuWrapper>
  )
}

const MenuWrapper = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
  left: 60px;
  background-color: #fff;
  z-index: 1000;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? 1 : 0)};

  .top-controls {
    position: absolute;
    top: 4em;
    right: 4em;
    display: flex;
    height: 3em;
  }

  .menu-items {
    list-style: none;
    font-family: "Masque-Hairline";

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    margin: 0;
    padding: 0;
    counter-reset: item-counter;

    li {
      font-size: 2em;
      font-weight: 400;
      position: relative;
      counter-increment: item-counter;
      opacity: 1;

      .menu-button {
        width: 13em;
        height: 1em;
        padding: 1em 0;
        display: flex;
        font-weight: 100;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        color: #000;

        background: rgba(0, 0, 0, 0.1);
        border-radius: 0.3em;
        cursor: pointer;
        font-size: 1em;
        font-family: Stolzl;
        text-transform: uppercase;
        transition: background-color 0.3s ease-in-out;

        &.active {
          background: ${globalStyles.colors.clay};
          border: none !important;
          color: ${globalStyles.colors.white};

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0.5em;
            transform: translateY(-50%);
            width: 0.5em;
            height: 0.5em;
            border-radius: 50%;
            background-color: #ff8800;
            animation: pulsate 2.5s ease-in-out infinite;
          }
        }

        & img {
          width: 1.5em;
          height: 1.5em;
        }

        &.reset {
          border: none !important;
          background: none;
          color: #000;
          text-decoration: underline;

          h3 {
            font-size: 1.5rem;
          }
        }
      }

      @keyframes pulsate {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.3;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
`

const PopupMessage = styled.div`
  width: 50%;
  transition: opacity 0.3s ease-in-out;
  font-size: 1.8rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.13rem;
  text-align: center;

  span {
    font-weight: bold;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    white-space: nowrap;
  }
`
