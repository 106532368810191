import React, { useEffect } from "react"
import styled from "styled-components"
import * as Separator from "@radix-ui/react-separator"
import { formatPrice } from "@components/apartmentfinder-orbit/components/utils"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import cross from "@assets/icons/cross.svg"
import gsap from "gsap"

type InfoCardProps = {
  selectedUnit: any
  isScreen?: boolean
}

const InfoCard: React.FC<InfoCardProps> = ({ selectedUnit, isScreen }) => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const {
    residenceDetailsVisible,
    unitDetailsSection,
    isTablet,
    showUnitsTable,
  } = useStore((state) => ({
    residenceDetailsVisible: state.residenceDetailsVisible,
    unitDetailsSection: state.unitDetailsSection,
    isTablet: state.isTablet,
    showUnitsTable: state.showUnitsTable,
  }))

  useEffect(() => {
    if (selectedUnit != null) {
      gsap.to(".info-card-content", { opacity: 1, duration: 0.6 })
    }
  }, [selectedUnit])

  return (
    <InfoCardWrapper
      isScreen={isScreen}
      residenceDetailsVisible={residenceDetailsVisible}
      unitDetailsSection={unitDetailsSection}
      showUnitsTable={showUnitsTable}
      onClick={() => {
        if (selectedUnit) {
          syncState({ selectedUnit: selectedUnit })
          syncState({ residenceDetailsVisible: true })
          syncState({ unitDetailsSection: "floorplan" })
        }
      }}
      id="infoCardWrapper"
    >
      {selectedUnit && (
        <div className="info-card-content">
          <div className="unit-info">
            <div className="unit-title">
              {selectedUnit?.unitType?.includes("TH")
                ? "TOWNHOUSE"
                : "Residence"}
            </div>
            <div style={{ display: "flex" }}>
              <div className="unit-number">{selectedUnit?.unitType}</div>
            </div>
            <div className="details">
              <div className="bed">{selectedUnit?.beds} Bed</div>
              <Separator.Root
                className="separator-root"
                decorative
                orientation="vertical"
              />
              <div className="bath">{selectedUnit?.baths} Bath</div>
              <Separator.Root
                className="separator-root"
                decorative
                orientation="vertical"
              />
              <div className="sqft">{selectedUnit?.sqft} sq ft</div>
              <Separator.Root
                className="separator-root"
                decorative
                orientation="vertical"
              />
              <div className="price">${formatPrice(selectedUnit?.price)}</div>
            </div>
          </div>
          {!isScreen && (
            <div
              className="deselect-unit"
              onClick={(e) => {
                e.stopPropagation()
                syncState({ selectedUnit: null })
              }}
            >
              <img src={cross} alt="" />
            </div>
          )}
        </div>
      )}
    </InfoCardWrapper>
  )
}

export default InfoCard

const InfoCardWrapper = styled.div<{
  isScreen: boolean
  residenceDetailsVisible: boolean
  unitDetailsSection: string
  showUnitsTable: boolean
}>`
  font-family: Stolzl;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isScreen }) => (isScreen ? "end" : "center")};
  overflow: hidden;

  width: 100%;
  height: 100%;
  padding: ${({ isScreen }) => (isScreen ? "3em" : 0)};
  border-radius: 1rem;
  align-items: ${({ isScreen }) => (isScreen ? "start" : "start")};

  .deselect-unit {
    cursor: pointer;
    z-index: 9999999999;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0%;
    right: 0%;
    margin: 2em;

    img {
      width: 100%;
      height: 100%;
    }
  }

  * {
    color: ${({ isScreen, residenceDetailsVisible }) =>
      isScreen && !residenceDetailsVisible ? "#fff" : "inherit"};
  }

  .no-unit {
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }

  .info-card-content {
    height: ${({ showUnitsTable, isScreen }) =>
      showUnitsTable && !isScreen ? "100%" : "auto"};

    transform: scale(${({ isScreen }) => (isScreen ? 0.65 : 1)});
    transform-origin: bottom left;
    width: ${({ isScreen }) => (isScreen ? "" : "100%")};

    background-color: ${({ isScreen }) =>
      isScreen ? "rgba(0, 0, 0, 0.85)" : "#ffffff14"};
    border: ${({ isScreen }) => (isScreen ? "#ffffffab solid 3px" : "")};
    opacity: 0;

    padding: 2.5em 4em;
    border-radius: 1rem;
    /* position: absolute; */
    font-family: "Masque-Hairline";
    display: flex;
    align-items: center;

    .unit-title {
      font-size: ${({ isScreen, residenceDetailsVisible }) =>
        isScreen && residenceDetailsVisible === false ? "5em" : "3em"};
      line-height: 1;
    }

    .unit-number {
      font-size: ${({ isScreen, residenceDetailsVisible }) =>
        isScreen && residenceDetailsVisible === false ? "15em" : "9em"};
      text-align: left;
      line-height: 0.75;
    }

    .details {
      height: 2em;
      margin-top: 1em;
      font-family: "Stolzl";
      display: flex;
      align-items: center;

      .bed,
      .bath,
      .sqft,
      .price {
        font-size: ${({ isScreen, residenceDetailsVisible }) =>
          isScreen && residenceDetailsVisible === false ? "1.5em" : "1em"};
        letter-spacing: 0.1em;
      }
    }

    .separator-root {
      background-color: ${({ unitDetailsSection, residenceDetailsVisible }) =>
        unitDetailsSection === "view" || !residenceDetailsVisible
          ? "#000"
          : "#000"};
      transition: background-color 0.3s ease-in-out;
    }

    .separator-root[data-orientation="vertical"] {
      height: 100%;
      width: 1px;
      margin: 0 10px;
    }
  }
`
