import { useState } from "react"

const useToast = () => {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')

 
  return { open, setOpen, title, setTitle }

}

export default useToast;
