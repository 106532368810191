import {
  amenitiesData,
  amenitiesMaps,
  amenitiesMeshes,
} from "@data/amenitiesData"
import { shaderMaterial, useGLTF, useTexture } from "@react-three/drei"
// @ts-ignore
import vertex from "./shader/vertex.glsl"
// @ts-ignore
import fragment from "./shader/fragment.glsl"
import { extend } from "@react-three/fiber"
import { useEffect, useRef } from "react"
import useStore from "@state/store"
import { gsap } from "gsap"
import CanvasText from "./CanvasText"
import { DoubleSide, RepeatWrapping } from "three"
const labels = {
  UPPER: "UPPER",
  LOWER: "LOWER",
}

const amenitiesOffsets = {
  UPPER: {
    position: { x: 15, y: 12, z: -40 },
    rotation: { x: 0.8, y: 2.2, z: 0 },
    scale: 0.004,
  },
  LOWER: {
    position: { x: 19, y: -10, z: -40 },
    rotation: { x: 0.6, y: 2.2, z: 0 },
    scale: 0.004,
  },
}

export default function Amenities({ screen = "ipad" }) {
  const { nodes: model } = useGLTF(`models/amenities-post-transform.glb`)

  const { nodes: boxes } = useGLTF(`models/boxes.glb`)
  const amenitiesSelector = useStore((state) => state.amenitiesSelector)
  const amenitiesActiveMesh = useStore((state) => state.amenitiesActiveMesh)
  const groupRefs = useRef({})
  const metalmap = useTexture(`/assets/maps/metal.webp`)
  const alpha = useTexture(`/assets/maps/alpha.webp`)
  metalmap.wrapS = metalmap.wrapT = RepeatWrapping
  alpha.wrapS = alpha.wrapT = RepeatWrapping

  alpha.repeat.set(2, 2)
  metalmap.repeat.set(2, 2)
  const boxmaps = {
    metalmap,
    alpha,
  }

  //
  let textures = {}
  Object.keys(amenitiesMeshes).forEach((floor) => {
    Object.keys(amenitiesMeshes[floor]).forEach((key) => {
      const texture = useTexture(`/assets/maps/amenities/${key}.webp`)
      texture.flipY = false
      textures[key] = texture
    })
  })

  //Animate pos of group
  useEffect(() => {
    const timeouts = []
    if (groupRefs.current) {
      Object.keys(amenitiesMeshes).forEach((floor) => {
        const timeoutId = setTimeout(() => {
          gsap.to(groupRefs?.current[floor]?.position, {
            x:
              labels[floor] === amenitiesSelector
                ? screen === "ipad"
                  ? 0
                  : 0
                : amenitiesOffsets[floor]?.position?.x,
            y:
              labels[floor] === amenitiesSelector
                ? 0
                : amenitiesOffsets[floor]?.position?.y,
            z:
              labels[floor] === amenitiesSelector
                ? 0
                : amenitiesOffsets[floor]?.position?.z,
            ease: "power4.out",
            duration: 1.5,
            overwrite: true,
          })
          gsap.to(groupRefs.current[floor].rotation, {
            x: amenitiesSelector ? 0 : amenitiesOffsets[floor]?.rotation?.x,
            y: amenitiesSelector ? 0 : amenitiesOffsets[floor]?.rotation?.y,
            z: amenitiesSelector ? 0 : amenitiesOffsets[floor]?.rotation?.z,
            ease: "power4.out",
            overwrite: true,
            duration: 1.5,
          })
        }, 20)
        timeouts.push(timeoutId)
      })
    }
    // Cleanup function to clear timeouts
    return () => {
      timeouts.forEach(clearTimeout)
    }
  }, [amenitiesSelector, amenitiesActiveMesh])

  return (
    <>
      {Object.keys(amenitiesMeshes).map((floor, index) => {
        return (
          <group
            key={index}
            position-x={amenitiesSelector === "UPPER" ? 5 : 0}
            position-z={amenitiesSelector === "UPPER" ? -1 : 0}
          >
            <group
              ref={(el) => (groupRefs.current[floor] = el)}
              scale={amenitiesOffsets[floor].scale}
              position-x={amenitiesOffsets[floor].position.x}
              position-y={amenitiesOffsets[floor].position.y}
              position-z={amenitiesOffsets[floor].position.z}
              rotation-x={amenitiesOffsets[floor].rotation.x}
              rotation-y={amenitiesOffsets[floor].rotation.y}
              rotation-z={amenitiesOffsets[floor].rotation.z}
            >
              {amenitiesSelector !== null &&
                labels[floor] === amenitiesSelector &&
                Object.entries(amenitiesData)
                  .filter(([key, value]) => value.floor === amenitiesSelector)
                  .map((key, index) => {
                    return (
                      <CanvasText
                        key={index}
                        name={key[0]}
                        pos={[
                          amenitiesData[key[0]].textX,
                          amenitiesData[key[0]].textY,
                          amenitiesData[key[0]].textZ,
                        ]}
                      />
                    )
                  })}
              {Object.keys(amenitiesMeshes[floor]).map((key, index) => {
                const map = textures[key]
                return (
                  <>
                    <MeshElement
                      boxmaps={boxmaps}
                      boxes={boxes}
                      index={index}
                      name={key}
                      screen={screen}
                      key={index}
                      geometry={(model[key] as any)?.geometry}
                      map={map}
                      floor={floor}
                    />
                  </>
                )
              })}
            </group>
          </group>
        )
      })}
    </>
  )
}

const CustomShaderMaterial = shaderMaterial(
  // Uniforms
  {
    uOpacity: 0.0,
    uBW: 0.0,
    uTexture: null,
  },
  // Vertex Shader
  vertex,
  // Fragment Shader
  fragment,
)

extend({ CustomShaderMaterial })

function MeshElement({
  geometry,
  map,
  screen,
  floor,
  name,
  index,
  boxes,
  boxmaps,
}) {
  const amenitiesSelector = useStore((state) => state.amenitiesSelector)
  const amenitiesActiveMesh = useStore((state) => state.amenitiesActiveMesh)

  const metalRef = useRef<any>(null)
  const materialRef = useRef<any>(null)

  // Set map + opacity + B&W effect
  useEffect(() => {
    if (materialRef.current) {
      // @ts-ignore
      materialRef.current.uniforms.uTexture.value = map

      // @ts-ignore
      materialRef.current.uniforms.uOpacity.value = screen === "ipad" ? 0 : 1

      if (metalRef.current) {
        metalRef.current.opacity = screen === "ipad" ? 0 : 1
      }

      // @ts-ignore
      materialRef.current.uniforms.uBW.value = 1.0
    }
  }, [map])

  // Animate opacity
  useEffect(() => {
    if (materialRef.current) {
      const active = labels[floor] === amenitiesSelector
      //@ts-ignore
      gsap.to(materialRef.current.uniforms.uOpacity, {
        value:
          amenitiesSelector === null && screen === "tv" ? 1 : active ? 1.0 : 0,
        duration: 0.3,
        ease: "none",
        overwrite: true,
        onStart: () => {
          if (
            (materialRef.current &&
              amenitiesSelector === null &&
              screen === "tv") ||
            active
          ) {
            // @ts-ignore
            materialRef.current.visible = true
          }
        },
        onComplete: () => {
          if ((amenitiesSelector === null && screen === "tv") || active) {
          } else {
            // @ts-ignore
            if (materialRef.current) {
              materialRef.current.visible = false
            }
          }
        },
      })
    }
  }, [floor, amenitiesSelector, screen])

  // Set B&W effect
  useEffect(() => {
    if (materialRef.current) {
      const active = amenitiesMeshes[floor][name]?.state === amenitiesActiveMesh
      //@ts-ignore
      gsap.to(materialRef.current.uniforms.uBW, {
        value: amenitiesActiveMesh === null || active ? 1.0 : 0.0,
        duration: 0.6,
        ease: "none",
        overwrite: true,
      })
    }
  }, [amenitiesActiveMesh])

  const showBoxes = name === "Residential_Storage"

  useEffect(() => {
    if (!metalRef.current) return
    if (amenitiesSelector === null || amenitiesSelector === "LOWER") {
      gsap.to(metalRef.current, {
        opacity: 1,
        duration: 0.3,
        ease: "none",
      })
    } else {
      gsap.to(metalRef.current, {
        opacity: 0,
        duration: 0.3,
        ease: "none",
      })
    }
  }, [amenitiesSelector])

  return (
    <>
      <mesh geometry={geometry}>
        {showBoxes && (
          <mesh
            position={[-1000, 0, 150]}
            scale={1}
            geometry={boxes["mesh_0"].geometry}
          >
            <meshPhysicalMaterial
              ref={metalRef}
              color="#ababab"
              map={boxmaps.metalmap}
              flatShading
              // alphaMap={boxmaps.alpha}
              side={DoubleSide}
              transparent
            />
          </mesh>
        )}
        {/* @ts-ignore */}
        <customShaderMaterial transparent side={DoubleSide} ref={materialRef} />
      </mesh>
    </>
  )
}
