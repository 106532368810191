import { initialFilters } from "@state/initialState"
import useStore from "@state/store"
import useFilteredData from "@utils/useFilteredData"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import styled from "styled-components/macro"
import ObitPoly from "./ObitPoly"

type Props = {
  data: any[]
  selectedUnit: any
  isTablet: boolean
  orbitRunning?: boolean
}

const OrbitSVG = ({ data, selectedUnit, isTablet, orbitRunning }: Props) => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const unitClick = (v: any) => {
    const unitData = residenceData.find((unit) => unit.unitID === v)
    syncState({ selectedUnit: unitData ? unitData : null })
  }
  const residenceData = useFilteredData()
  const filters = useStore((state) => state.filters)

  return (
    <MarkersWrapper>
      <svg
        onClick={() => selectedUnit && syncState({ selectedUnit: null })}
        x="0"
        y="0"
        width="100%"
        height="100%"
        viewBox="0 0 1920 1080"
        className="markers"
        preserveAspectRatio="xMidYMid slice"
      >
        <g className="svgOverlays">
          {data?.map((data, i) => (
            <ObitPoly
              initialFilters={initialFilters}
              filters={filters}
              unitClick={unitClick}
              residenceData={residenceData}
              isTablet={isTablet}
              selectedUnit={selectedUnit}
              key={`shape${i}`}
              data={data}
            />
          ))}
        </g>
      </svg>
    </MarkersWrapper>
  )
}

export default OrbitSVG

const MarkersWrapper = styled.div`
  pointer-events: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
