import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import ReactDOMServer from "react-dom/server"
import globalStyles from "@data/style.globals"
import EmailTemplate from "./EmailTemplate"
import { validation } from "@utils/validation"
import LoadingIcon from "@components/loading-icon"
import * as Checkbox from "@radix-ui/react-checkbox"
import { CheckIcon } from "@radix-ui/react-icons"
import { useFavoritesStore } from "@state/favoritesStore"
import AutocompleteInput from "./AutocompleteInput"
import { emailPlainText } from "./EmailPlainText"

type TForm = {
  name: string
  email: string
  cc?: string[] | null
  link?: string
  withAttachments?: boolean
}

const initialForm = {
  name: null,
  email: null,
  cc: null,
}

const agents = [
  { email: "blake.weissberg@corcoransunshine.com", name: "Blake Weissberg" },
  { email: "crista.villella@corcoransunshine.com", name: "Crista Villella" },
  { email: "dina.wyche@corcoransunshine.com", name: "Dina Wyche" },
]

function Form() {
  const { favorites, loadFavorites } = useFavoritesStore((state) => ({
    favorites: state.favorites,
    loadFavorites: state.loadFavorites,
  }))
  const promptRef = useRef<HTMLDivElement>()
  const [formData, setFormData] = useState<TForm | null>(initialForm)
  const [formMessage, setFormMessage] = useState("")
  const [readyToSend, setReadyToSend] = useState(false)
  const [withAttachments, setWithAttachments] = useState(false)
  const [sending, setSending] = useState(false)
  const favIDS = favorites?.map((fav: any) => fav.unitID)
  const combinedEncoded = btoa(`${favIDS?.join(",")}£${formData?.name}`)
  const [CCAgents, setCCAgents] = useState<string[]>([])
  const url = `https://720westend.app/?${combinedEncoded}`

  function resetPrompt() {
    if (!formMessage) return
    promptRef.current.style.opacity = "0"
    setTimeout(() => {
      setFormMessage("")
    }, 800)
  }

  function handleSubmit(e) {
    e.preventDefault()
    loadFavorites()
    const form = document.getElementById("form") as HTMLFormElement
    const errors = validation(formData)

    if (errors !== undefined) {
      setFormMessage(errors)
      promptRef.current.style.opacity = "1"
    } else {
      setFormData({
        ...formData,
        link: url,
        cc: CCAgents.length > 0 ? CCAgents : null,
        withAttachments: withAttachments,
      })
      setSending(true)
      setReadyToSend(true)
      form.reset()
    }
  }

  const handleCheckedChange = (email: string, isChecked: boolean) => {
    if (isChecked) {
      setCCAgents((prevAgents) => [...prevAgents, email])
    } else {
      setCCAgents((prevAgents) => prevAgents.filter((agent) => agent !== email))
    }
  }

  useEffect(() => {
    if (formData && readyToSend) {
      const htmlTemplate = ReactDOMServer.renderToStaticMarkup(
        <EmailTemplate link={url} name={formData?.name} />,
      )
      fetch(
        `https://pacific-harmony-production.up.railway.app/mailer/sendWithAttachments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": "9wudPL4CYBevmCjg",
          },
          body: JSON.stringify({
            ...formData,
            from: "720 West End <hello@720westend.com>",
            html: htmlTemplate,
            // using plain text as fallback for email clients that don't support html
            text: emailPlainText({ name: formData.name, url }),
            subject: `720 West End Avenue: Thank you for Visiting`,
          }),
        },
      )
        .then((res) => res.json())
        .then((res) => {
          if (res?.statusCode === 200 || res?.success === true) {
            setReadyToSend(false)
            setFormData(initialForm)
            setSending(false)
            setFormMessage("Email sent")
            promptRef.current.style.opacity = "1"
          } else {
            throw new Error("Could not send email.")
          }
        })
        .catch((err) => {
          setReadyToSend(false)
          setFormData(initialForm)
          setSending(false)
          setFormMessage("Email failed to send. Please try again later.")
          promptRef.current.style.opacity = "1"
          console.error(
            "There has been a problem with your fetch operation:",
            err,
          )
        })
    }
    return () => {
      setReadyToSend(false)
      setFormMessage("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToSend])

  return (
    <FormWrapper>
      <h6 ref={promptRef}>{formMessage}</h6>
      <form id="form">
        <input
          placeholder="Name"
          name="fName"
          type="text"
          max="20"
          autoComplete="off"
          onPointerDown={(e) => {
            e.currentTarget.focus()
          }}
          onInput={(e) => {
            setFormData({
              ...formData,
              name: (e.target as HTMLInputElement).value,
            })
            resetPrompt()
          }}
        />

        <AutocompleteInput
          value={formData.email || ""}
          onChange={(email: string) => {
            setFormData({
              ...formData,
              email,
            })
            resetPrompt()
          }}
        />

        <div className="cc-agents">
          <div className="title">CC Agents:</div>
          {agents.map((agent) => (
            <CheckboxItem
              key={agent.email}
              email={agent.email}
              name={agent.name}
              isChecked={CCAgents.includes(agent.email)}
              onCheckedChange={handleCheckedChange}
            />
          ))}
        </div>
        <div
          className="cc-agents"
          style={{ borderTop: "1px solid #fff", paddingTop: "2em" }}
        >
          <CheckboxItem
            email="withAttachments"
            name="Send with attachments"
            isChecked={withAttachments}
            onCheckedChange={() => {
              setWithAttachments((prev) => !prev)
            }}
          />
        </div>

        <button
          className="Email"
          onClick={(e) => {
            handleSubmit(e)
          }}
        >
          {sending ? (
            <LoadingIcon color={globalStyles.colors.white} />
          ) : (
            "SUBMIT"
          )}
        </button>
      </form>
    </FormWrapper>
  )
}

const CheckboxItem = ({ email, name, isChecked, onCheckedChange }) => {
  return (
    <StyledContainer>
      <Checkbox.Root
        className="CheckboxRoot"
        id={email}
        checked={isChecked}
        onCheckedChange={(checked) => onCheckedChange(email, checked)}
      >
        <Checkbox.Indicator className="CheckboxIndicator">
          <CheckIcon />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label className="Label" htmlFor={email}>
        {name}
      </label>
    </StyledContainer>
  )
}

export default Form

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${globalStyles.colors.white};
  padding: 0 5em;
  user-select: none !important;

  * {
    touch-action: auto !important;
    pointer-events: all !important;
  }

  & h6 {
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 1.5rem;
    transition: opacity 0.8s ease-in-out;
    opacity: 0;
  }

  & form {
    color: ${globalStyles.colors.white};
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 5em;

    & input {
      width: 80%;
      height: 2rem;
      border: none;
      border-bottom: 0.1rem solid ${globalStyles.colors.white};
      background-color: transparent;
      color: ${globalStyles.colors.white};
      font-size: 1.5rem;
      pointer-events: all;

      ::placeholder {
        color: ${globalStyles.colors.white};
        letter-spacing: 0.2rem;
        font-size: 1.5rem;
        opacity: 0.8;
      }
      :focus-visible {
        outline: none;
        background-color: transparent;
      }
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        color: ${globalStyles.colors.white} !important;
      }
    }

    & button {
      background: none;
      border: none;
      font-size: 1.5rem;
      color: ${globalStyles.colors.white};
      width: 80%;
      height: 4rem;
      border: 2px solid #fff;
      max-height: 4rem;
      border-radius: 0.5em;
      pointer-events: all;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cc-agents {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1em;

      .title {
        font-size: 1.5rem;
        padding: 0.5em 0;
        color: white;
      }

      .CheckboxRoot {
        width: 2rem;
        height: 2rem;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        &:disabled {
          background-color: #7a7a7a;
        }
      }

      .CheckboxIndicator {
        color: #000;
      }

      .Label {
        color: white;
        padding-left: 15px;
        font-size: 1.5rem;
        line-height: 1;
        letter-spacing: 0.1rem;
        width: 100%;
      }
    }
  }
`
