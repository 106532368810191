import useStore from "@state/store"
import styled from "styled-components"
import roomMapper from "@data/roomToNav.map.json"
import useRoomSelector from "../../hooks/useRoomSelector"

const RoomTakenOver = () => {
  const [roomTakenOver] = useStore((s) => [s.roomTakenOver])
  const { handleRoomSelector } = useRoomSelector()

  return roomTakenOver ? (
    <RoomTakenOverLabel
      onClick={() => {
        handleRoomSelector("reset")
      }}
    >
      <div>
        Taking over <span>{roomMapper[roomTakenOver].title}</span> room. (Click
        to reset.)
      </div>
    </RoomTakenOverLabel>
  ) : null
}

export default RoomTakenOver

const RoomTakenOverLabel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  div {
    font-size: 1.3em;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      width: 0.6em;
      height: 0.6em;
      border-radius: 50%;
      background-color: #ff8800;
      animation: pulsate 2.5s ease-in-out infinite;
    }

    @keyframes pulsate {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.3;
      }
      100% {
        opacity: 1;
      }
    }
  }

  span {
    font-size: inherit;
    font-weight: bold;
  }
`
