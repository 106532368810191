import styled from "styled-components"

const TableRow = ({
  cells,
  row,
  rowIndex,
  isSelected,
  onClick,
  disabled = false,
}) => {
  return (
    <TableRowContainer
      className={`flex-table-row ${isSelected ? "selected" : ""}`}
      disabled={disabled}
      onClick={onClick}
    >
      {cells.map((cell: any, index: any) => {
        return (
          <div key={index} className={`flex-table-cell `}>
            {typeof cell === "object" && cell?.render !== undefined
              ? cell.render(row, rowIndex)
              : row[cell]}
          </div>
        )
      })}
    </TableRowContainer>
  )
}

export { TableRow }

const TableRowContainer = styled.div<{ disabled: boolean }>`
  margin: 1rem 0;
  color: #edededef;

  &.flex-table-row {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3em;
    height: 3em;
    transition: background-color 0.3s ease-in-out;

    :active {
      background-color: #ffffff4e;
    }

    &.selected {
      background-color: #ffffff4e;
    }

    ${({ disabled }) => {
      if (disabled) {
        return `
          opacity: 0.4;
          pointer-events: none;
        `
      }
    }}
  }

  .flex-table-cell {
    flex: 1 0 0;
    font-size: 1.25rem;
    min-width: 6rem;
    text-align: center;
    white-space: nowrap;
  }
`
