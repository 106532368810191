const pois = {
  Boutiques: [
    "Blue Mercury",
    "Bocnyc",
    "CPW",
    "Daryls",
    "Greenstones",
    "Harry’s",
    "Jacadi Paris Kids",
    "Lululemon ",
    "Olde Good Things",
    "Patagonia ",
    "Sephora ",
    "Stoopher and Boots",
    "The Strand",
    "West Side Kids",
    "Westsider Rare & Used Books",
  ],
  "Cuisine And Night Life": [
    "Barney Greengrass ",
    "Bodrum Mediterranean Restaurant",
    "Carmines",
    "Cibo e Vino",
    "Dagon ",
    "Edgar’s Café ",
    "Elea ",
    "Ellington in the Park",
    "Gennaro ",
    "Joe & The Juice ",
    "Jacob’s Pickles",
    "La Pecora Bianca ",
    "Levain Bakery – Upper West Side, NYC ",
    "Lucciola ",
    "Maison Pickle ",
    "Milk Bar ",
    "Momoya Upper West Side ",
    "Murray’s Sturgeon ",
    "Osteria 106 ",
    "Prohibition ",
    "Serafina ",
    "Smoke Jazz & Supper Club ",
    "Sushi Iskikawa ",
    "TESSA ",
    "The Consulate ",
    "The Ellington ",
    "The Milling Room ",
    "Amelie Bistro & Wine Bar", // 1
    "Buceo 95" ,
    "Van Leeuwen Ice Cream",
    "Cafe Du Soliel" // 2
  ],
  Convenience: [
    "Barzini’s",
    "D’Agostino at 91st Street ",
    "The Kosher Marketplace ",
    "Trader Joe’s ",
    "Westside Market NYC ",
    "Whole Foods Market ",
    "Zabar’s",
    "Health Nuts",
  ],
  Fitness: [
    "Andy Kessler Skate Park",
    "Crunch Fitness – 83rd Street",
    "Equinox West 92nd Street ",
    "New York Sports Club",
    "Orangetheory Fitness ",
    "Pure Barre",
    "Westside Pilates",
    "96th Street Clay Tennis Courts",
    "104th Street Baseball Field",
  ],
  "Culture, Entertainment & Parks": [
    "American Museum of Natural History",
    "Beacon Theater",
    "Central Park",
    "Children’s Museum of Manhattan",
    "Columbia University",
    "Dinosaur Playground",
    "Hudson River Waterfront Greenway",
    "Joan of Arc Park",
    "Lincoln Center for the Performing Arts",
    "Lotus Garden",
    "Nicholas Roerich Museum",
    "Peter Jay Sharp Theater at Symphony Space",
    "Riverside Park",
    "Symphony Space",
    "The Garden People ",
    "West Side Comedy Club",
    "West Side Community Garden ",
    "West 79th Street Boat Basin",
    "West 104th Street Garden",
  ],
  Transportation: [
    "96 St & Broadway ",
    "96 & Central Park West",
  ],
  Playground: [
    "W 110th Street Playground",
    "The Tarr Family Playground",
    "Rudin Family Playground",
    "Tarr-Coyne Wild West Playground",
    "Toll Family Playground",
    "Abraham & Joseph Spector Playground",
    "Diana Ross Playground",
    "Adventure Playground",
  ],
}

export default pois
