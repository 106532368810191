import { useEffect } from "react"
import styled from "styled-components"
import { TableButton } from "@components/buttons/TableButton"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import "./Residences.css"
import ApartmentFinderOrbit from "@components/apartmentfinder-orbit/ApartmentFinderOrbit"
import { ResidenceTextBlock } from "./components/ResidenceTextBlock"
import { PresetButton } from "@components/buttons"
import Preset from "@components/preset"
import ResidenceDetails from "./components/ResidenceDetails"
import UnitsTable from "./components/UnitsTable"
import SyncOnMount from "@components/SyncOnMount"

const Residences = () => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const {
    unitDetailsSection,
    selectedUnit,
    showUnitsTable,
    presetVisible,
    orbitRunning,
    residenceDetailsVisible,
    galleryVisible,
    favoritesVisible,
  } = useStore((state) => ({
    attemptedRoom: state.attemptedRoom,
    unitDetailsSection: state.unitDetailsSection,
    selectedUnit: state.selectedUnit,
    showUnitsTable: state.showUnitsTable,
    presetVisible: state.presetVisible,
    orbitRunning: state.orbitRunning,
    residenceDetailsVisible: state.residenceDetailsVisible,
    galleryVisible: state.galleryVisible,
    favoritesVisible: state.favoritesVisible,
  }))

  useEffect(() => {
    return () => {
      syncState({
        unitDetailsSection: null,
        selectedUnit: null,
        showUnitsTable: false,
        presetVisible: false,
        orbitRunning: false,
        residenceDetailsVisible: false,
      })
    }
  }, [])

  return (
    <Wrapper
      onClick={() => {}}
      residenceDetailsVisible={residenceDetailsVisible}
      visible={selectedUnit}
      orbitRunning={orbitRunning}
      unitDetailsSection={unitDetailsSection}
      location={location.pathname}
      galleryVisible={galleryVisible}
      favoritesVisible={favoritesVisible}
    >
      <SyncOnMount />
      {showUnitsTable && (
        <UnitsTable
          show={showUnitsTable}
          onClose={() => syncState({ showUnitsTable: false })}
        />
      )}

      <div className="left-bottom-controls">
        <TableButton
          onClick={() => {
            syncState({ showUnitsTable: !showUnitsTable })
          }}
        />
        <PresetButton
          onClick={() => {
            if (!presetVisible) syncState({ presetVisible: true })
            else syncState({ presetVisible: false })
          }}
        />
      </div>
      <ResidenceTextBlock visible={true} />
      <ResidenceDetails />
      <ApartmentFinderOrbit />
      {presetVisible && (
        <Preset
          show={presetVisible}
          onClose={() =>
            syncState({ selectedPreset: null, presetVisible: false })
          }
        />
      )}
    </Wrapper>
  )
}

export { Residences }

export const Wrapper = styled.div<{
  residenceDetailsVisible: any
  unitDetailsSection: string
  visible: any
  orbitRunning: boolean
  location: string
  galleryVisible: boolean
  favoritesVisible: boolean
}>`
  width: 100%;
  height: 100%;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;

  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: ${({ visible, orbitRunning }) =>
      visible && !orbitRunning ? 1 : 0};
    transition: all ease-in-out 0.3s;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .title {
    font-family: "Masque-Hairline";
    font-size: 2em;
    display: flex;
    align-items: center;
    padding: 1em 0;
  }

  .presets-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 7em 5em;
    height: 60vh;
    visibility: hidden;
    .header {
      font-family: "Masque-Hairline";
      font-size: 6em;
    }
  }

  .presets-buttons {
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1em;
    transition: all 0.3s ease-in-out;
  }

  .table > .header {
    font-family: "Masque-Hairline";
    font-size: 6em;
    z-index: 100;
  }

  .table > .title.share {
    display: flex;
    justify-content: space-between;
    border-top: none;
  }

  .left-bottom-controls {
    position: absolute;
    bottom: 2em;
    left: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    z-index: 1;
    background-color: ${({ location, favoritesVisible, galleryVisible }) =>
      location === "/residences" && !galleryVisible && !favoritesVisible
        ? "rgba(0,0,0,0.5)"
        : "transparent"};
    border-radius: 1rem;
    padding: 1rem;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex.fill {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .flex.center {
    justify-content: center;
  }

  .unit-title {
    font-size: 5em;
  }

  .unit-number {
    font-size: 15em;
    text-align: left;
    line-height: 0.64;
  }

  .floor {
    font-family: "Stolzl";
    font-size: 3em;
    letter-spacing: 0.2em;
    font-weight: 100;
    margin: 80px 0 0;
  }

  .details {
    height: 2em;
    margin-top: 1em;
    font-family: "Stolzl";
    display: flex;
    align-items: center;

    .bed,
    .bath,
    .sqft,
    .price {
      font-size: 1.5em;
      letter-spacing: 0.1em;
    }
  }

  .view-button {
    position: relative;
    margin-top: 2em;
    font-family: "Stolzl";
    font-style: italic;
    font-size: 1.5em;
    letter-spacing: 0.2em;

    .arrow-icon {
      position: absolute;
      right: -5px;
      transform: translateY(-50%);
    }

    transition: transform 0.3s ease-in-out;
  }

  .view-text {
    font-size: 1.5em;
  }

  .separator-root {
    background-color: ${({ unitDetailsSection, residenceDetailsVisible }) =>
      unitDetailsSection === "view" || !residenceDetailsVisible
        ? "#fff"
        : "#000"};
    transition: background-color 0.3s ease-in-out;
  }

  .separator-root[data-orientation="horizontal"] {
    height: 1px;
    width: 100%;
    position: relative;
  }

  .separator-root[data-orientation="vertical"] {
    height: 100%;
    width: 1px;
    margin: 0 10px;
  }
`
