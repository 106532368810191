import useDisplayMessage from "@utils/useDisplayMessage"
import useNetworkStatus from "@utils/useNetworkStatus"
import { useLayoutEffect, useRef } from "react"
import { NetworkMessage } from "./NetworkMessage"
// import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
// import useNavigator from "@hooks/useNavigator"

const NetworkStatusIndicator = () => {
  const { show, close, message } = useDisplayMessage()
  const isOnline = useNetworkStatus()
  const firstUpdate = useRef(true)
  // const { resyncAllStatesWithSocket }  = useSyncStatesWithSocket()
  // const { joinRoomBasedOnPath } = useNavigator()

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    if(isOnline) {
      show("You are back ONLINE")
      // TODO: Rejoin room based on path and sync all states with socket
      // joinRoomBasedOnPath(window.location.pathname, (newRoom) => {
      //   console.log("Rejoined room based on path....", newRoom)
      //   resyncAllStatesWithSocket()
      // })
    } else {
      show("You are currently OFFLINE")
    }
  }, [close, show, isOnline])

  return message ? (
    <NetworkMessage message={message} isOnline={isOnline} />
  ) : null
}

export { NetworkStatusIndicator }
