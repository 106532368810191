import styled from "styled-components"
import React, { useEffect, useRef, useState } from "react"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"

type ViewProps = {
  views: string
}

export const partIndex = {
  n: ["7", "9"],
  e: ["8", "10"],
  s: ["3", "2", "1"],
  w: ["6", "5", "4"],
}

export function findMatchingKeys(item) {
  return Object.keys(partIndex).filter((key) => partIndex[key].includes(item))
}

const View: React.FC<ViewProps> = React.memo(function View({ views }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeView, setActiveView] = useState<string>("")

  const imgRef = useRef<HTMLImageElement>(null)
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const parts = views?.split("-")

  const suffix = parts?.pop()?.toUpperCase() || ""

  useEffect(() => {
    if (!views) return
    if (parts.length > 0) {
      const view = `${parts[activeIndex]}-${suffix}.webp`
      setActiveView(view)
      syncState({ activeView: view })
    }
    return () => {
      syncState({ activeView: null })
    }
  }, [parts, activeIndex, suffix, syncState])

  const handleSetActiveView = (index) => {
    if (index >= 0 && index < parts.length) {
      setActiveIndex(index)
    }
  }

  useEffect(() => {
    setActiveIndex(0)
  }, [views])

  if (!views) return null

  return (
    <Wrapper>
      <img
        style={{
          width: "100%",
          height: "100%",
        }}
        ref={imgRef}
        src={`/assets/images/residences/views/${activeView}`}
        alt="View"
      />

      {parts.length > 1 && (
        <div className="views-buttons">
          {parts.map((part, index) => (
            <button key={index} onClick={() => handleSetActiveView(index)}>
              {findMatchingKeys(part)}
            </button>
          ))}
        </div>
      )}
    </Wrapper>
  )
})

export default View

const Wrapper = styled.div`
  object-fit: contain;

  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .views-buttons {
    position: absolute;
    bottom: 3em;
    right: 3em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1.5em;
    background: rgba(255, 255, 255, 1);
    border-radius: 1em;

    button {
      padding: 1em 1.5em;
      background: transparent;
      border: 1px solid #000;
      cursor: pointer;

      &:hover {
        background: #000;
        color: #fff;
      }
    }

    &.hide {
      display: none;
    }
  }

  img {
    object-fit: contain;
  }
`
