export const amenitiesData = {
  Lobby: {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "UPPER",
    x: 6.5,
    y: 3.5,
    z: -40,
    rx: 0.8,
    ry: 0.7,
    rz: 0,
    textX: -2000,
    textY: 0,
    textZ: 1000,
  },
  "The Marcy Library": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "UPPER",
    x: 7,
    y: 0.5,
    z: -30,
    rx: 1.1,
    ry: 0.1,
    rz: 0,
    textX: -350,
    textY: 0,
    textZ: 0,
  },
  "Private Dining Room": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "UPPER",
    x: 6.5,
    y: -0.5,
    z: -20,
    rx: 1.1,
    ry: 0.4,
    rz: 0,
    textX: -800,
    textY: 0,
    textZ: -1100,
  },
  "Emery’s Bar": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "UPPER",
    x: 6,
    y: -3.5,
    z: -13,
    rx: 1.2,
    ry: -0.3,
    rz: 0,
    textX: -1350,
    textY: 0,
    textZ: -900,
  },
  "Billiards/VR Sports Lounge": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "UPPER",
    x: 10,
    y: -1.5,
    z: -22,
    rx: 1,
    ry: 0.2,
    rz: 0,
    textX: -2250,
    textY: 0,
    textZ: -1000,
  },
  // "VR Sports Lounge": {
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
  //   floor: "UPPER",
  //   x: -2.5,
  //   y: 7,
  //   z: -30,
  //   rx: 0.8,
  //   ry: 2,
  //   rz: 0,
  //   textX: -2250,
  //   textY: 0,
  //   textZ: -200,
  // },
  "Garden Terrace": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "UPPER",
    x: 1.5,
    y: 0.8,
    z: -23,
    rx: 0.7,
    ry: 2.9,
    rz: 0,
    textX: -180,
    textY: 0,
    textZ: -1000,
  },
  "Fitness Suite": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "LOWER",
    x: 2,
    y: -1.0,
    z: -35,
    rx: 0.9,
    ry: 2.4,
    rz: 0,
    textX: 400,
    textY: 0,
    textZ: 1400,
  },
  "Children’s Playroom": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "LOWER",
    x: 5,
    y: -5.5,
    z: -30,
    rx: 1.1,
    ry: 0.4,
    rz: 0,
    textX: 300,
    textY: 0,
    textZ: -1600,
  },
  "Music Practice Room": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "LOWER",
    x: -5,
    y: 3.5,
    z: -25,
    rx: 1.2,
    ry: 1.9,
    rz: 0,
    textX: -1500,
    textY: 0,
    textZ: 1100,
  },
  "Makers' Studio": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "LOWER",
    x: 4,
    y: 1.5,
    z: -20,
    rx: 1,
    ry: 2.4,
    rz: 0,
    textX: 300,
    textY: 0,
    textZ: -800,
  },
  "Bicycle Storage": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "LOWER",
    x: 6,
    y: 0.5,
    z: -20,
    rx: 1.1,
    ry: 1,
    rz: 0,
    textX: -600,
    textY: 0,
    textZ: -900,
  },
  "Residential Storage": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "LOWER",
    x: 4.5,
    y: 1.5,
    z: -30,
    rx: 1.1,
    ry: 0.4,
    rz: 0,
    textX: -900,
    textY: 0,
    textZ: 100,
  },
  // "Laundry Room": {
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
  //   floor: "LOWER",
  //   x: 5,
  //   y: -2.5,
  //   z: -50,
  //   rx: 1.1,
  //   ry: 0.4,
  //   rz: 0,
  //   textX: 700,
  //   textY: 0,
  //   textZ: 100,
  // },
  // "Pet Spa": {
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
  //   floor: "LOWER",
  //   x: 5,
  //   y: -2.5,
  //   z: -50,
  //   rx: 1.1,
  //   ry: 0.4,
  //   rz: 0,
  //   textX: 500,
  //   textY: 0,
  //   textZ: 500,
  // },
  "Play Garden": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "LOWER",
    x: -2,
    y: -7.5,
    z: -20,
    rx: 1.2,
    ry: 0.4,
    rz: 0,
    textX: 1500,
    textY: 0,
    textZ: -1500,
  },
  "Pet Wash": {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    floor: "LOWER",
    x: 6.5,
    y: 3.5,
    z: -40,
    rx: 0.8,
    ry: 0.7,
    rz: 0,
    textX: 350,
    textY: 0,
    textZ: 90,
  },
}

export const amenitiesImages = {
  "327_720_West_End_Lobby_cam3": {
    state: "Lobby",
    x: "15rem",
    y: "4rem",
    height: "20rem",
  },
  "327_720_West_End_Lobby_cam2": {
    state: "Lobby",
    x: "30rem",
    y: "4rem",
    height: "19rem",
  },
  "327_720_West_End_Dining_Room": {
    state: "Private Dining Room",
    x: "12rem",
    y: "4.9rem",
    height: "18rem",
  },
  "327_720_West_End_Lobby_cam1": {
    state: "Lobby",
    x: "21.4rem",
    y: "3rem",
    height: "16rem",
  },
  "327_720_West_End_fitness_center_basketball_court": {
    state: "Fitness Suite",
    x: "20rem",
    y: "3rem",
    height: "23rem",
  },
  "327_720_West_End_Fitness_Center": {
    state: "Fitness Suite",
    x: "16rem",
    y: "3rem",
    height: "18rem",
  },
  "327_720_West_End_Bar": {
    state: "Emery’s Bar",
    x: "19rem",
    y: "3rem",
    height: "18rem",
  },
  "327_720_West_End_Co_Working_Space": {
    state: "The Marcy Library",
    x: "14rem",
    y: "3rem",
    height: "14rem",
  },
  "327_Game_Lounge_Post": {
    state: "Billiards/VR Sports Lounge",
    x: "17rem",
    y: "3rem",
    height: "17rem",
  },
  "327_720_West_End_Kid's_room": {
    state: "Children’s Playroom",
    x: "14",
    y: "3rem",
    height: "17rem",
  },
}

export const amenitiesMaps = [
  "R_1",
  "R_2",
  "R_3",
  "R_4",
  "CR_1",
  "CR_2",
  "CR_3",
  "CR_4",
]

export const amenitiesMeshes = {
  UPPER: {
    Lobby: {
      state: "Lobby",
      map: "CR_1",
    },
    Mailroom: {
      state: "Lobby",
      map: "CR_1",
    },
    Package: {
      state: "Lobby",
      map: "CR_1",
    },
    Lounge_Bar: {
      state: "Emery’s Bar",
      map: "R_3",
    },

    Game_Room: {
      state: "Billiards/VR Sports Lounge",
      map: "R_3",
    },

    Amenity_Courtyard: {
      state: "Garden Terrace",
      map: "R_2",
    },
    Dining_Lounge: {
      state: "Private Dining Room",
      map: "R_2",
    },

    Co_Working_Room: {
      state: "The Marcy Library",
      map: "R_2",
    },
    Vestibule_amenity: {
      state: null,
      map: "CR_1",
    },
    Virtual_Reality_Room: {
      state: "VR Sports Lounge",
      map: "R_3",
    },
  },
  LOWER: {
    Bicycle_Storage: {
      state: "Bicycle Storage",
      map: "CR_3",
    },
    Childrens_Playroom: {
      state: "Children’s Playroom",
      map: "CR_1",
    },
    Lower_Courtyard: {
      state: "Play Garden",
      map: "CR_1",
    },
    Fitness_Room: {
      state: "Fitness Suite",
      map: "CR_2",
    },
    Multipurpose_Squash: {
      state: "Fitness Suite",
      map: "CR_2",
    },
    Pilates_Room: {
      state: "Fitness Suite",
      map: "CR_2",
    },
    Makers_Studio: {
      state: "Makers' Studio",
      map: "CR_1",
    },
    Music_Room: {
      state: "Music Practice Room",
      map: "CR_3",
    },
    Yoga_Room: {
      state: "Fitness Suite",
      map: "CR_3",
    },
    Residential_Storage: {
      state: "Residential Storage",
      map: "CR_3",
    },
    Pet_Wash: {
      state: "Pet Wash",
      map: "CR_3",
    },
    // R_1: {
    //   state: null,
    //   map: "CR_1",
    // },
  },
}
