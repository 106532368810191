import React from "react"
import * as Dialog from "@radix-ui/react-dialog"
import { Cross2Icon, PlusIcon } from "@radix-ui/react-icons"
import styled from "styled-components"
import "./styles.css"

const PresetDialog = ({
  isOpen,
  presetName,
  onOpenChange,
  presets,
  onSave,
}) => {
  const [value, setValue] = React.useState(presetName || "")

  const generateUniqueName = (name: string, presets: any) => {
    let uniqueName = name
    let counter = 1

    const presetNames = presets.map((preset: any) => preset.name)

    while (presetNames.includes(uniqueName)) {
      uniqueName = `${name} (${counter})`
      counter++
    }

    return uniqueName
  }

  const handleSave = () => {
    const uniqueName = generateUniqueName(value, presets)
    onSave(uniqueName)
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <Button
          style={{
            margin: 0,
            padding: "1em",
            width: "max-content",
            border: "1px solid #fff",
            borderRadius: 15,
          }}
        >
          <PlusIcon
            height={20}
            width={20}
            style={{ marginRight: "10px" }}
            color="#fff"
          />
          Add new
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Add preset</Dialog.Title>
          <Dialog.Description className="DialogDescription">
            Add a name for your preset and click save
          </Dialog.Description>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="name">
              Name
            </label>
            <input
              className="Input"
              id="name"
              defaultValue={presetName || ""}
              autoComplete="off"
              onChange={(e) => setValue(e.target.value)}
            />
          </fieldset>
          <div
            style={{
              display: "flex",
              marginTop: 25,
              justifyContent: "flex-end",
            }}
          >
            <Dialog.Close asChild>
              <button className="Button green" onClick={handleSave}>
                Save changes
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon height={20} width={20} color="#000" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default PresetDialog

const Button = styled.button`
  font-family: Stolzl;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;

  &:active {
    background-color: #ffffff4e;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  & img {
    width: 1.5rem;
    height: 1.5rem;
  }
`
