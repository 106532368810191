import styled from "styled-components"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import * as Separator from "@radix-ui/react-separator"
import {
  CaretRightIcon,
  HeartFilledIcon,
  HeartIcon,
} from "@radix-ui/react-icons"
import "../Residences.css"
import { formatPrice } from "@components/apartmentfinder-orbit/components/utils"
import { useFavoritesStore } from "@state/favoritesStore"

const ResidenceTextBlock = ({ visible }) => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const isTablet = true
  // @ts-ignore
  const { favorites, addToFavorites } = useFavoritesStore()

  const {
    selectedUnit,
    unitDetailsSection,
    residenceDetailsVisible,
    showUnitsTable,
    orbitRunning,
    imageExpanded,
  } = useStore((state) => ({
    selectedUnit: state.selectedUnit,
    unitDetailsSection: state.unitDetailsSection,
    residenceDetailsVisible: state.residenceDetailsVisible,
    orbitRunning: state.orbitRunning,
    showUnitsTable: state.showUnitsTable,
    imageExpanded: state.imageExpanded,
  }))

  const handleFavClick = (e: any, row: any) => {
    e.preventDefault()
    addToFavorites(row)
  }

  return (
    <Wrapper
      visible={visible && !orbitRunning}
      residenceDetailsVisible={residenceDetailsVisible}
      unitDetailsSection={unitDetailsSection}
    >
      {((selectedUnit &&
        unitDetailsSection != "view" &&
        !showUnitsTable &&
        (unitDetailsSection != "gallery" || !imageExpanded)) ||
        (residenceDetailsVisible &&
          unitDetailsSection != "view" &&
          (unitDetailsSection != "gallery" || !imageExpanded))) && (
        <div
          className="content"
          style={{
            padding: "1.5em 1.5em",
          }}
        >
          <div className="unit-info">
            <div className="unit-title">
              {selectedUnit?.unitType?.includes("TH")
                ? "TOWNHOUSE"
                : "Residence"}
            </div>
            <div style={{ display: "flex" }}>
              <div className="unit-number">{selectedUnit?.unitType}</div>
            </div>
            <div className="details">
              <div className="bed">{selectedUnit?.beds} Bed</div>
              <Separator.Root
                className="separator-root"
                decorative
                orientation="vertical"
              />
              <div className="bath">{selectedUnit?.baths} Bath</div>
              <Separator.Root
                className="separator-root"
                decorative
                orientation="vertical"
              />
              <div className="sqft">{selectedUnit?.sqft} sq ft</div>
              <Separator.Root
                className="separator-root"
                decorative
                orientation="vertical"
              />
              <div className="price">${formatPrice(selectedUnit?.price)}</div>
            </div>
          </div>
          <div
            className={`view-button ${!isTablet ? "hidden" : ""}`}
            onClick={() => {
              syncState({ selectedUnit: selectedUnit })
              syncState({ residenceDetailsVisible: true })
              syncState({ unitDetailsSection: "floorplan" })
            }}
          >
            <div className="view-text">Explore</div>
            <Separator.Root
              className="separator-root"
              style={{ margin: "1px 0", backgroundColor: "#000" }}
            >
              <CaretRightIcon className="arrow-icon" height={15} width={15} />
            </Separator.Root>
          </div>
          <div
            style={{ position: "absolute", top: "5%", right: "0" }}
            onClick={(e) => handleFavClick(e, selectedUnit)}
          >
            {favorites.some(
              (unit) => unit.unitType === selectedUnit.unitType,
            ) ? (
              <HeartFilledIcon height={20} width={20} />
            ) : (
              <HeartIcon height={20} width={20} />
            )}
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export { ResidenceTextBlock }

const determineColor = (
  residenceDetailsVisible: boolean,
  unitDetailsSection: string,
) => {
  if (unitDetailsSection === "view") {
    return "#000"
  } else if (residenceDetailsVisible) {
    return "#000"
  } else {
    return "#000"
  }
}

export const Wrapper = styled.div<{
  visible: boolean
  unitDetailsSection: string
  residenceDetailsVisible: boolean
}>`
  z-index: 262;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;

  .deselect-unit {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
  }

  .block-container {
    width: 100%;
    height: 100%;
    z-index: 20;
    visibility: hidden;
  }

  .block-container > #div {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex.fill {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .flex.center {
    justify-content: center;
  }

  .content {
    position: absolute;
    font-family: "Masque-Hairline";
    top: 8em;
    border-radius: 1rem;
    left: 1.5em;
    /* z-index: ${({ residenceDetailsVisible }) =>
      residenceDetailsVisible ? 261 : 259}; */
    color: ${({ residenceDetailsVisible, unitDetailsSection }) =>
      determineColor(residenceDetailsVisible, unitDetailsSection)};
    transition: color 0.5s ease-in-out;

    .separator-root {
      background-color: #000;
      transition: background-color 0.3s ease-in-out;
    }
  }

  .unit-title {
    font-size: 5em;
  }

  .unit-number {
    font-size: 15em;
    text-align: left;
    line-height: 0.64;
  }

  .floor {
    font-family: "Stolzl";
    font-size: 3em;
    letter-spacing: 0.2em;
    font-weight: 100;
    margin: 80px 0 0;
  }

  .details {
    height: 2em;
    margin-top: 1em;
    font-family: "Stolzl";
    display: flex;
    align-items: center;

    .bed,
    .bath,
    .sqft,
    .price {
      font-size: 1.5em;
      letter-spacing: 0.1em;
    }
  }

  .view-button {
    position: relative;
    margin-top: 2em;
    font-family: "Stolzl";
    font-style: italic;
    font-size: 1.5em;
    letter-spacing: 0.2em;

    .arrow-icon {
      position: absolute;
      right: -5px;
      transform: translateY(-50%);
    }

    transform: ${({ residenceDetailsVisible }) =>
      !residenceDetailsVisible ? "translateX(0)" : "translateX(-150%)"};
    transition: transform 0.3s ease-in-out;

    &.hidden {
      display: none;
    }
  }

  .view-text {
    cursor: pointer;
    font-size: 1.5em;
  }

  .separator-root {
    background-color: #000;
    transition: background-color 0.3s ease-in-out;
  }

  .separator-root[data-orientation="horizontal"] {
    height: 1px;
    width: 100%;
    position: relative;
  }

  .separator-root[data-orientation="vertical"] {
    height: 100%;
    width: 1px;
    margin: 0 10px;
  }
`
