import { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { amenitiesImages } from "@data/amenitiesData"
import useStore from "@state/store"
import { useGSAP } from "@gsap/react"
import ImageScrollLogic from "./ImageScrollLogic"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"

export default function ScreenImages({ screen = "ipad" }) {
  const loaded = useStore((state) => state.loaded)
  const amenitiesActiveImage = useStore((state) => state.amenitiesActiveImage)
  const amenitiesFullScreenImage = useStore(
    (state) => state.amenitiesFullScreenImage,
  )
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const [allowPointerEvents, setAllowPointerEvents] = useState(false)

  const imageRefs = useRef({})

  function handleImageClick(img) {
    if (!img) return
    if (amenitiesFullScreenImage === img) {
      const image = imageRefs.current[img]
      gsap.to(image, {
        duration: 0.5,
        scale: 1,
        x: 0,
        y: 0,
        z: 0,
        ease: "power4.inOut",
      })
      syncState({
        amenitiesFullScreenImage: null,
        amenitiesActiveImage: null,
      })
    } else {
      // const image = imageRefs.current[img]
      //Fade in img, fade out elements
      syncState({
        amenitiesFullScreenImage: img,
        amenitiesActiveImage: amenitiesImages[img].state,
      })
    }
  }

  useGSAP(
    () => {
      if (!loaded) return
      const images = gsap.utils.toArray(".image-item")
      gsap.set(images, {
        opacity: 0,
        y: 30,
      })
      setTimeout(() => {
        gsap.to(images, {
          duration: 2,
          opacity: 1,
          y: 0,
          stagger: 0.05,
          // delay: 0.3 * index,
          ease: "back.out",
          onComplete: () => {
            setAllowPointerEvents(true)
          },
        })
      }, 2000)
      // Object.keys(amenitiesImages).map((item, index) => {
      //   gsap.set(`#amenities-imgs-${index}`, { opacity: 0, y: 30 })

      // })
    },
    { dependencies: [loaded] },
  )

  return (
    <div
      className="image-gallery"
      style={{
        opacity: 0,
        pointerEvents: "none",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        placeSelf: "start",
        display: "grid",
        placeItems: "start",
        gridAutoFlow: "row",
        rowGap: "2.5rem",
        overflowY: "scroll",
        width: "100%",
        zIndex: 10,
        transform: `translate(${screen === "ipad" ? `35rem` : `10rem`}, -8rem)`,
      }}
    >
      {Object.keys(amenitiesImages).map((key, index) => {
        const active = amenitiesActiveImage === amenitiesImages[key].state
        return (
          <div ref={(el) => (imageRefs.current[key] = el)} key={key}>
            <img
              onClick={() => {
                if (amenitiesFullScreenImage) return
                // syncState({ amenitiesActiveImage: amenitiesImages[key].state })
                key && handleImageClick(key)
              }}
              id={`amenities-imgs-${index}`}
              className="image-item"
              alt={`Amenity Image: ${amenitiesImages[key]}`}
              src={`/assets/images/amenities/${screen}_thumbnail/${key}.webp`}
              style={{
                cursor: "pointer",
                // visibility: "hidden",
                opacity: 0,
                height: amenitiesImages[key].height,
                transform: `translate(${amenitiesImages[key].x}, 0)`,
                filter: active ? "grayscale(0%)" : "grayscale(100%)",
                transition: "filter 0.5s",
                overflow: "auto",
                pointerEvents: allowPointerEvents ? "auto" : "none",
              }}
            />
          </div>
        )
      })}
      <ImageScrollLogic />
    </div>
  )
}
