import { createRoot } from "react-dom/client"

import "./style.css"

import App from "./App"
import { SocketIOProvider } from "./providers/sockets"
import * as Sentry from "@sentry/react"
import { BrowserRouter } from "react-router-dom"

Sentry.init({
  dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, //  Capture 50% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const container: HTMLElement = document.getElementById("root")
const root = createRoot(container!)

const url =
  import.meta.env.MODE === "development"
    ? import.meta.env.VITE_SOCKET_URL_DEV
    : import.meta.env.VITE_SOCKET_URL_LIVE

root.render(
  <SocketIOProvider url={url as any}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SocketIOProvider>,
)
