import { Route, Routes } from "react-router-dom"
import ScreenLayout from "./layouts/ScreenLayout"
import TabletLayout from "./layouts/TabletLayout"
import RoutesConfig from "@routes/routes"
import TransitionComponent from "@components/transition"
import { Fragment } from "react"

function App() {
  const { routes, isTablet } = RoutesConfig()

  return (
    <Fragment>
      <Routes>
        <Route element={ <TabletLayout />}>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <TransitionComponent>
                    <route.element />
                  </TransitionComponent>
                }
              />
            )
          })}
        </Route>
      </Routes>
    </Fragment>
  )
}

export default App
