import { useState, useEffect } from "react"
import { fetchLiveData } from "@utils/fetchLiveData"
import { isEqual } from "lodash-es"

export function useLiveData() {
  const [data, setData] = useState(() => {
    const savedData = localStorage.getItem("residenceLiveData")
    return savedData ? JSON.parse(savedData) : null
  })

  const saveToLocalStorage = (data) => {
    try {
      localStorage.setItem("residenceLiveData", JSON.stringify(data))
    } catch (error) {
      console.error("Error saving to local storage:", error)
    }
  }

  const fetchData = async () => {
    try {
      const formattedData = await fetchLiveData()
      if (formattedData) {
        // Compare with the last fetched data before updating so we don't sync state unnecessarily
        if (!isEqual(formattedData, data)) {
          setData(formattedData)
          saveToLocalStorage(formattedData)
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const updateRecordViaAirtable = async (payload: {
    id: string
    fields: { [key: string]: string | number }
  }) => {
    const response = await fetch(
      `${import.meta.env.VITE_DATA_URL}/${payload.id}`,
      {
        method: "PATCH",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_DATA_API_KEY}`,
        },
        body: JSON.stringify({
          fields: payload.fields,
        }),
      },
    )

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return response.json()
  }

  useEffect(() => {
    fetchData()
    const interval = setInterval(
      () => {
        fetchData()
      },
      1000 * 60 * 5,
    ) // Fetch every 5 minutes

    return () => clearInterval(interval)
  }, [updateRecordViaAirtable])

  return { data, fetchData, updateRecordViaAirtable }
}
