export const emailPlainText = ({ name, url }) => {
  return `Hello ${name},

Thank you for visiting 720 West End Avenue. We hope you enjoyed your visit. Your personal e-brochure from 720 West End Avenue includes the floorplans and pricing that we discussed during your appointment. Click on the link directly below to view your favorite residences.

Your Favorite Residences: ${url}

Enclosed please also find the following documents for your review and consideration:

    - Digital Brochure
    - Fact Sheet
    - Procedure to Purchase
    - Preferred Attorneys List
    - *Preferred Lenders List and Affiliated Business Arrangement Disclosure Statement
    - Closing Costs and Adjustments
    - Fair Housing Notice

To recap, 720 West End Avenue is an Upper West Side pre-war landmark retold with modern finesse. Originally designed in 1927 by celebrated architect Emery Roth, 720 West End Avenue is re-envisioned today as 131 elegant residences by the award-winning designer Thomas Juul-Hansen. A grand welcome through a restored Renaissance Revival entrance sets the tone for a wide array of exceptional amenities, including on-site parking, basketball/squash, coworking lounge, fitness suite, music rehearsal studio and much more. Take in Hudson River views from new angles and find yourself in conversation with a neighborhood anew, where progress is beautiful.

Condominium Pricing Starts at:

    - One Bedrooms from: $975,000
    - Two Bedrooms from: $2,650,000
    - Three Bedrooms from: $2,995,000
    - Four Bedrooms from: $5,575,000
    - Five Bedrooms from: $10,275,000

Please note, if you would like to submit a formal offer to purchase an apartment at 720 West End Avenue, the Sponsor requires a completed Procedure to Purchase Package (attached). Please let us know if you have any additional questions or if you require any further information.

Thank you,

The 720 West End Avenue Sales Team`
}
