import styled from "styled-components"
import { HeartFilledIcon, HeartIcon } from "@radix-ui/react-icons"
import { FlexTable } from "@components/table"
import useSortConfig from "@utils/useSortConfig"
import { headers } from "@utils/tableHeaders"
import { formatPrice } from "@components/apartmentfinder-orbit/components/utils"
import useFilteredData from "@utils/useFilteredData"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import useStore from "@state/store"
import { useFavoritesStore } from "@state/favoritesStore"

type ResidenceTableProps = {
  openResidenceInfo: (unit: Unit) => void
  selectedUnits: Unit[]
}

export type Unit = {
  unitID: string
  unitType: string
  aspect: string
  beds: number
  floor: number
  baths: number
  sqft: number
  price: number
  status: string
  exsqft?: number
  view?: string
}

const ResidenceTable: React.FC<ResidenceTableProps> = ({
  openResidenceInfo,
  selectedUnits,
}) => {
  const residenceData = useFilteredData()
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const { selectionChanged } = useStore((state) => ({
    selectionChanged: state.selectionChanged,
  }))
  const { favorites, addToFavorites } = useFavoritesStore((state) => ({
    favorites: state.favorites,
    addToFavorites: state.addToFavorites,
  }))

  const { sortConfig, setSortConfig } = useSortConfig()
  const cells = [
    "unitType",
    "beds",
    "baths",
    "aspect",
    {
      key: "sqft",
      render: (row: any, index: number) => `${formatPrice(row.sqft)}`,
    },
    {
      key: "price",
      render: (row: any, index: number) => `$${formatPrice(row.price)}`,
    },
    "status",
    {
      key: "favourite",
      render: (row: any, index: number) => {
        return (
          <div onClick={(e) => handleFavClick(e, row)}>
            {favorites.some((unit: any) => unit.unitID === row.unitID) ? (
              <HeartFilledIcon height={20} width={20} />
            ) : (
              <HeartIcon height={20} width={20} />
            )}
          </div>
        )
      },
    },
  ]

  const handleFavClick = (e: any, row: Unit) => {
    e.stopPropagation()
    addToFavorites(row)
  }

  return (
    <TableContainer className="tableContainer">
      {residenceData.length === 0 ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            fontSize: "2em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No data found
        </div>
      ) : (
        <FlexTable
          uniqueKey="unitID"
          headers={headers}
          data={residenceData}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
          cells={cells}
          selection="single"
          disabledRows={residenceData.filter(
            (unit) => unit.status !== "Available",
          )}
          preselectedRows={selectedUnits}
          onSelectionChange={(row) => {
            openResidenceInfo(row)
            syncState({ selectionChanged: !selectionChanged })
          }}
        />
      )}
    </TableContainer>
  )
}

export default ResidenceTable

const TableContainer = styled.div`
  height: 100%;
  font-family: Stolzl;
  text-transform: uppercase;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 3em;
  border-radius: 1rem;

  .title {
    font-family: "Masque-Hairline";
    font-size: 2rem;
    display: flex;
    align-items: center;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    position: relative;

    & span {
      position: absolute;
      right: 0;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    margin-top: 2em;
    border-bottom: 1px solid #fff;
    padding: 1.5em 0;
    transition: all 0.3s ease-in-out;
  }

  .buttons > :nth-child(2) {
    margin-left: auto;
  }
`
