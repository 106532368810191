import { HeartFilledIcon } from "@radix-ui/react-icons";

export const headers = [
  "unitID",
  "beds",
  "baths",
  "aspect",
  "sqft",
  "price",
  "status",
  <HeartFilledIcon height={20} width={20} />
]

export const HeaderToLabelMap = {
  unitID: "Residence",
  beds: "Bed",
  baths: "Bath",
  aspect: "View",
  sqft: "SQ FT",
  price: "Price ($)",
  status: "Status",
  favourite: null
}
