import InfoCard from "@pages/tablet/residences/components/InfoCard"
import {
  findMatchingKeys,
  partIndex,
} from "@pages/tablet/residences/components/View"
import useStore from "@state/store"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

interface IViewScreenProps {
  unit: any
}

export default function View(props: IViewScreenProps) {
  const { unit } = props
  const imgRef = useRef<HTMLImageElement>(null)
  const { viewXPosition, activeView } = useStore((state) => ({
    viewXPosition: state.viewXPosition,
    activeView: state.activeView,
  }))

  useEffect(() => {
    const updateImagePosition = () => {
      if (imgRef.current) {
        const imgWidth = imgRef.current.naturalWidth
        const screenWidth = window.innerWidth

        // Calculate the maximum translation distance on the small screen
        const maxTranslationDistanceSmall = imgWidth + screenWidth - 1366

        // Calculate the maximum translation distance on the large screen
        const maxTranslationDistanceLarge = imgWidth - screenWidth

        // Calculate the translation ratio based on the position on the small screen
        const translateRatio = viewXPosition / maxTranslationDistanceSmall

        // Calculate the corresponding translation for the large screen
        const translation = maxTranslationDistanceLarge * translateRatio

        // Ensure the image doesn't go out of bounds
        const clampedTranslation = Math.max(
          -maxTranslationDistanceLarge,
          Math.min(0, translation),
        )

        imgRef.current.style.transform = `translateX(${clampedTranslation}px)`
      }
    }

    updateImagePosition()
  }, [viewXPosition, activeView])

  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.style.transform = `translateX(0px)`
    }

    return () => {
      if (imgRef.current) {
        imgRef.current.style.transform = `translateX(0px)`
      }
    }
  }, [activeView])

  const viewTitle = {
    w: "West",
    e: "East",
    n: "North",
    s: "South",
  }

  return (
    <Wrapper
      // style={{backgroundImage: `/assets/images/residences/views/${activeView}`}  }
      className="view-wrapper"
    >
      {/* <div className="single-unit"> */}
      <div
        style={{
          position: "absolute",
          margin: "3rem",
          fontSize: "8rem",
          fontFamily: "Masque-Hairline",
        }}
      >
        View {viewTitle[findMatchingKeys(activeView?.split("-")[0])[0]]}
      </div>
      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
        ref={imgRef}
        src={`/assets/images/residences/views/${activeView}`}
        alt="Floorplan"
      />
      {/* </div> */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* object-fit: cover; */
  background-size: contain;
  background-color: #fff;
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;

  .single-unit {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.6s;

    &.hidden {
      opacity: 0;
      transition: all 0.6s;
    }

    .residence-info {
      position: relative;
      width: 100%;
      flex: 1;
      font-size: 40rem;

      /* .info-card-content {
        padding: 0;
      }

      .unit-info {
        transform: scale(1.2);
        transform-origin: left;
      }

      .separator-root[data-orientation="vertical"] {
        height: 100%;
        width: 0.5px;
        margin: 0 10px;
        background-color: #000;
      } */
    }

    .unit-view {
      height: 100vh;
      width: auto;
      position: absolute;

      img {
        height: auto;
        width: 100%;
        object-fit: contain;
      }
    }
  }
`
