import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import useStore from "@state/store"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import Carousel from "@components/gallery/Carousel"

type DetailsProps = {}

const Gallery: React.FC<DetailsProps> = ({}) => {
  const { selectedUnit, zoomRef } = useStore((s) => ({
    selectedUnit: s.selectedUnit,
    zoomRef: s.zoomRef,
  }))
  const [images, setImages] = useState<string[]>([])

  const transformWrapperRef = useRef(null)

  useEffect(() => {
    const loadImages = async () => {
      let imageModules = {}
      const images = import.meta.glob(
        "../../../../../public/assets/images/galleryScreen/Interiors/*.{jpg,png,webp}",
      )
      Object.assign(imageModules, await images)

      const imagePaths: string[] = await Promise.all(
        Object.values(imageModules).map(async (importer: any) => {
          const module: any = await importer()
          return module.default
        }),
      )

      setImages(imagePaths)
    }

    loadImages()

    return () => {
      setImages([])
    }
  }, [selectedUnit])

  useEffect(() => {
    if (transformWrapperRef.current)
      transformWrapperRef.current.setTransform(
        zoomRef?.[0],
        zoomRef?.[1],
        zoomRef?.[2],
      )
  }, [zoomRef])

  return (
    <ViewWrapper className="galleryWrapper">
      <TransformWrapper
        ref={transformWrapperRef}
        smooth={true}
        minScale={1}
        maxScale={2.5}
      >
        <TransformComponent>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Carousel slideEffect="fade" slides={images} />
          </div>
        </TransformComponent>
      </TransformWrapper>
    </ViewWrapper>
  )
}

export default Gallery

const ViewWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  font-family: "Masque-Hairline";
  z-index: 20;
  display: flex;
  opacity: 0;
  backdrop-filter: blur(10px);
  background: rgb(255 255 255 / 40%);
  align-items: center;
  justify-content: center;

  .image {
    height: 100vh;
    object-fit: contain;
  }
`
