/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useLayoutEffect } from "react"
import styled from "styled-components"
import { isVideoPlaying } from "../../pages/tablet/location/components/video"

type TVideoOrbit = {
  fwdVideos: string[]
  bwdVideos: string[]
  indexState: number
  indexSet?: (n: number) => void
  direction?: boolean
  directionSet?: (v: boolean) => void
  playState: boolean
  playSet: (v: boolean) => void
  videoProps?: { [x: string]: unknown }
  wrapperProps?: { [x: string]: unknown }
  lockWhilePLaying?: boolean
  delayMS?: number
  onComplete?: () => void
}

function VideoOrbit(props: TVideoOrbit) {
  const {
    fwdVideos,
    bwdVideos,
    direction,
    directionSet,
    indexState,
    indexSet,
    playState,
    playSet,
    videoProps,
    wrapperProps,
    lockWhilePLaying = false,
    delayMS = 0,
    onComplete,
  } = props
  const wrapperRef = useRef(null)
  const fwdRef = useRef<HTMLDivElement>(null)
  const bwdRef = useRef<HTMLDivElement>(null)
  const fwdVideosRef = useRef<HTMLVideoElement[] | []>([])
  const bwdVideosRef = useRef<HTMLVideoElement[] | []>([])
  const videoStyle = videoProps?.style ?? {}
  const overlay = document.createElement("div")
  overlay.style.position = "fixed"
  overlay.style.top = "0"
  overlay.style.left = "0"
  overlay.style.width = "100%"
  overlay.style.height = "100%"
  overlay.style.zIndex = "99999999999999999"
  overlay.style.pointerEvents = "all"

  function lockControls() {
    document.body.appendChild(overlay)
  }

  function unlockControls() {
    document.body.removeChild(overlay)
  }

  function playOrbit() {
    const target = direction
      ? fwdVideosRef.current[indexState]
      : bwdVideosRef.current[indexState]
    if (!target) return
    lockWhilePLaying && lockControls()

    // This is to prevent the play promise from being unhandled
    if (target && !isVideoPlaying(target)) {
      target.play().catch((e) => {
        console.error("Error while trying to play video:", e)
      })
    }
    if (!indexSet) {
      target.onended = () => {
        target.onended = null
        setTimeout(() => {
          playSet(false)
          lockWhilePLaying && unlockControls()
          onComplete && onComplete()
          directionSet && directionSet(!direction)
          setTimeout(() => {
            target.currentTime = 0
          }, 100)
        }, 100)
      }
      return
    } else {
      target.onended = () => {
        const nextIndex = direction ? indexState + 1 : indexState - 1
        if (direction) {
          const newIndexFWD = nextIndex > fwdVideos.length - 1 ? 0 : nextIndex
          indexSet(newIndexFWD)
        } else {
          const newIndexBWD = nextIndex < 0 ? fwdVideos.length - 1 : nextIndex
          indexSet(newIndexBWD)
        }
        target.onended = null
        setTimeout(() => {
          target.currentTime = 0
          playSet(false)
          lockWhilePLaying && unlockControls()
          onComplete && onComplete()
        }, 100)
      }
    }
  }

  useEffect(() => {
    if (playState) {
      setTimeout(() => {
        playOrbit()
      }, delayMS)
    }
  }, [playState])

  useLayoutEffect(() => {
    fwdRef.current!.style.opacity = `${direction ? 1 : 0}`
    bwdRef.current!.style.opacity = `${direction ? 0 : 1}`
  }, [direction])

  return (
    <OrbitWrapper ref={wrapperRef} {...wrapperProps}>
      <Videos ref={fwdRef}>
        {fwdVideos.map((src, i) => {
          return (
            <Video
              src={src}
              $active={!!direction && indexState === i}
              {...videoProps}
              style={{
                ...(videoStyle as any),
                opacity: !!direction && indexState === i ? 1 : 0,
              }}
              id={`fwd-${i}`}
              key={`fwd-${i}`}
              ref={(node) => {
                if (node) fwdVideosRef.current[i] = node
              }}
            />
          )
        })}
      </Videos>
      <Videos ref={bwdRef}>
        {bwdVideos.map((src, i) => {
          return (
            <Video
              src={src}
              $active={!direction && indexState === i}
              {...videoProps}
              style={{
                ...(videoStyle as any),
                opacity: !direction && indexState === i ? 1 : 0,
              }}
              ref={(node) => {
                if (node) bwdVideosRef.current[i] = node
              }}
              id={`bwd-${i}`}
              key={`bwd-${i}`}
            />
          )
        })}
      </Videos>
    </OrbitWrapper>
  )
}

export default VideoOrbit

const OrbitWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;

  pointer-events: none;
`

const Videos = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

type TVideoProps = {
  $active: boolean
}

const Video = styled.video<TVideoProps>`
  position: absolute;
  transition-duration: ${(p) => (p.$active ? "0ms" : "50ms")};
  height: 100%;
  width:100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`
