import { useSocketIO } from "@providers/sockets/SocketIOProvider"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"
import { useEffect } from "react"

const SyncOnMount = () => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()

  const { room } = useSocketIO()

  const store = useStore((state) => state)
  const {
    setLocalState,
    resetState,
    attemptedRoom,
    isTablet,
    roomTakenOver,
    loadProgress,
    loaded,
    showMapScreenSaver,
    ...relevantState
  } = store;

  useEffect(() => {
    syncState(relevantState)
  }, [room])

  return null
}

export default SyncOnMount
