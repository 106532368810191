import useStore from "@state/store"
import { SortConfig } from "@state/types.store"
import { useEffect } from "react"
import { useSyncStatesWithSocket } from "./useSyncStatesWithSockets"

const useSortConfig = () => {
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  const { sortConfig, resetState } = useStore((state) => ({
    resetState: state.resetState,
    sortConfig: state.sortConfig,
  }))

  const setSortConfig = (newSortConfig: SortConfig) => {
    syncState({ sortConfig: newSortConfig })
  }

  useEffect(() => {
    return () => {
      resetState("sortConfig")
      syncState({ sortConfig: { field: null, direction: "ascending" } })
    }
  }, [])
  return { setSortConfig, sortConfig }
}

export default useSortConfig
