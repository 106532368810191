import { amenitiesData, amenitiesMeshes } from "@data/amenitiesData"
import useStore from "@state/store"
import { useSyncStatesWithSocket } from "@utils/useSyncStatesWithSockets"

const labels = {
  UPPER: "UPPER",
  LOWER: "LOWER",
}

export default function MeshSelector({ screen = "ipad" }) {
  const amenitiesSelector = useStore((s) => s.amenitiesSelector)
  const amenitiesActiveMesh = useStore((s) => s.amenitiesActiveMesh)
  const amenitiesFullScreenImage = useStore((s) => s.amenitiesFullScreenImage)
  const { syncStatesWithSocket: syncState } = useSyncStatesWithSocket()
  return (
    <div
      id="amenities-selector"
      style={{
        visibility: "hidden",
        opacity: 0,
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        placeSelf: "end start",
        padding: screen === "ipad" ? "4rem" : "2rem 2rem",
        display: "grid",
        rowGap: "2rem",
        gridAutoFlow: "row",
        textTransform: "uppercase",
        color: "white",
        zIndex: 2,
      }}
    >
      {Object.keys(labels).map((floor, index) => {
        return (
          <div
            onClick={(e) => {
              if (amenitiesFullScreenImage) return
              // console.log("cc")
              if (amenitiesSelector !== labels[floor]) {
                // console.log("stop")
                e.stopPropagation()
              }
              syncState({
                amenitiesSelector: labels[floor],
                amenitiesActiveMesh: null,
              })
            }}
            key={index}
            style={{
              zIndex: 4,
              opacity: labels[floor] === amenitiesSelector ? 1 : 0.5,
              transition: "opacity 0.4s",
            }}
          >
            <p
              style={{
                cursor: "pointer",
                fontSize: "1.3rem",
                letterSpacing: "0.04rem",
                opacity: 0.8,
                marginBottom: "0.4rem",
              }}
            >
              {labels[floor]} LEVEL
            </p>
            {Object.entries(amenitiesData)
              .filter(([key, value]) => value.floor === labels[floor])
              .map(([meshName], index) => {
                return (
                  <p
                    onClick={(e) => {
                      // return
                      if (amenitiesFullScreenImage) return
                      if (amenitiesSelector === labels[floor]) {
                        // console.log("stop")
                        e.stopPropagation()
                      }
                      syncState({
                        amenitiesActiveMesh:
                          amenitiesActiveMesh === meshName ? null : meshName,
                        amenitiesSelector: labels[floor],
                      })
                    }}
                    style={{
                      zIndex: 5,
                      cursor: "pointer",
                      fontSize: screen === "ipad" ? "1.8rem" : "1.3rem",
                      letterSpacing: "0.04rem",
                      opacity:
                        !amenitiesActiveMesh || amenitiesActiveMesh === meshName
                          ? 1
                          : 0.5,
                      transition: "opacity 0.4s",
                    }}
                    key={index}
                  >
                    {meshName}
                  </p>
                )
              })}
          </div>
        )
      })}
    </div>
  )
}
